import React, { ChangeEvent, useCallback, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { ValueType } from 'models/valueType';

export function useInput<T>(
  initialValue: T
): [
  T,
  React.Dispatch<React.SetStateAction<T>>,
  (key: React.ReactText, valueType: ValueType) => (event: ChangeEvent<HTMLInputElement>) => void,
  <S>(
    objectKey: keyof T,
    key: keyof S,
    valueType: ValueType
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void
] {
  const [value, setValue] = useState<T>(initialValue);

  const getEventValueBasedOnType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, valueType: ValueType) => {
      switch (valueType) {
        case ValueType.STRING:
          return event.target.value;

        case ValueType.NUMBER:
          return +event.target.value;

        case ValueType.BOOLEAN:
          return event.target.checked;

        default:
          return null;
      }
    },
    []
  );

  const onSimpleValueChange = (key: React.ReactText, valueType: ValueType) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (value) {
      let copy = cloneDeep(value);

      copy = { ...copy, [key]: getEventValueBasedOnType(event, valueType) };

      setValue(copy);
    }
  };

  const onObjectValueChange = <S>(objectKey: keyof T, key: keyof S, valueType: ValueType) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (value) {
      let copy = cloneDeep(value);

      const objectCopy = {
        ...copy[objectKey],
        [key]: getEventValueBasedOnType(event, valueType),
      };

      copy = { ...copy, [objectKey]: objectCopy };

      setValue(copy);
    }
  };

  return [value, setValue, onSimpleValueChange, onObjectValueChange];
}
