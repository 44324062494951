import { TFunction } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import PageContainer from '../PageContainer';
import {
  classCellBg,
  classCellC,
  classTable,
  createBaseTd,
  createTh,
  dateToStr,
  toStr,
} from 'components/grouppages/gpUtil';
import { useApi } from 'hooks/useApi';
import Loader from 'hyper/components/Loader';
import CuttingTransfer from 'models/grouppages/cuttingTransfer';

const classTableDiv = 'react-bootstrap-table table-responsive align-top';
const classSmallTable = `${classTable} mb-1 gp-table`;

function createCutterHeaderRow1(t: TFunction): JSX.Element {
  return (
    <tr>
      {createTh('', 0)}
      {createTh(t('cutting-live-weight'), 1)}
      {createTh(t('cutting-warm-carcass'), 2, '', { colSpan: 2 })}
      {createTh(t('cutting-cold-carcass'), 3, '', { colSpan: 2 })}
      {createTh(t('cutting-liver'), 4, '', { colSpan: 2 })}
      {createTh(t('cutting-head'), 5, '', { colSpan: 2 })}
      {createTh(t('cutting-chest'), 6, '', { colSpan: 2 })}
      {createTh(t('cutting-loin'), 7, '', { colSpan: 2 })}
      {createTh(t('cutting-thighs'), 8, '', { colSpan: 2 })}
      {createTh(t('cutting-loin-fillet'), 9, '', { colSpan: 2 })}
      {createTh(t('cutting-thigh-fillet'), 10, '', { colSpan: 2 })}
    </tr>
  );
}

function createCutterHeaderRow2(cutting: CuttingTransfer | undefined, t: TFunction): JSX.Element {
  return (
    <tr>
      {createTh(t('cutting-weight-g'), 11)}
      {createTh(toStr(cutting?.liveWeightSum), 12)}
      {createTh(toStr(cutting?.warmCarcassSum), 13, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.coldCarcassSum), 14, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.liverSum), 15, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.headSum), 16, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.chestSum), 17, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.middleSum), 18, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.backSum), 19, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.loinFilletSum), 20, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.thighSum), 21, '', { colSpan: 2 })}
    </tr>
  );
}

function createCutterHeaderRow3(cutting: CuttingTransfer | undefined, t: TFunction): JSX.Element {
  return (
    <tr>
      {createTh(t('cutting-num'), 22)}
      {createTh(toStr(cutting?.liveWeightNo), 23)}
      {createTh(toStr(cutting?.warmCarcassNo), 24, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.coldCarcassNo), 25, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.liverNo), 26, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.headNo), 27, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.chestNo), 28, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.middleNo), 29, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.backNo), 30, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.loinFilletNo), 31, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.thighNo), 32, '', { colSpan: 2 })}
    </tr>
  );
}

function createCutterHeaderRow4(cutting: CuttingTransfer | undefined, t: TFunction): JSX.Element {
  return (
    <tr>
      {createTh(t('cutting-avg-g'), 33)}
      {createTh(toStr(cutting?.liveWeightAvg, 0), 34)}
      {createTh(toStr(cutting?.warmCarcassAvg, 0), 35, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.coldCarcassAvg, 0), 36, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.liverAvg, 0), 37, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.headAvg, 0), 38, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.chestAvg, 0), 39, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.middleAvg, 0), 40, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.backAvg, 0), 41, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.loinFilletAvg, 0), 42, '', { colSpan: 2 })}
      {createTh(toStr(cutting?.thighAvg, 0), 43, '', { colSpan: 2 })}
    </tr>
  );
}

function createCutterHeaderRow5(t: TFunction): JSX.Element {
  const cuttingGram = t('cutting-gram');
  const cuttingPercent = t('cutting-percent');
  return (
    <tr>
      {createTh('', 44)}
      {createTh(cuttingGram, 45)}
      {createTh(cuttingGram, 46)}
      {createTh(cuttingPercent, 47)}
      {createTh(cuttingGram, 48)}
      {createTh(cuttingPercent, 49)}
      {createTh(cuttingGram, 50)}
      {createTh(cuttingPercent, 51)}
      {createTh(cuttingGram, 52)}
      {createTh(cuttingPercent, 53)}
      {createTh(cuttingGram, 54)}
      {createTh(cuttingPercent, 55)}
      {createTh(cuttingGram, 56)}
      {createTh(cuttingPercent, 57)}
      {createTh(cuttingGram, 58)}
      {createTh(cuttingPercent, 59)}
      {createTh(cuttingGram, 60)}
      {createTh(cuttingPercent, 61)}
      {createTh(cuttingGram, 62)}
      {createTh(cuttingPercent, 63)}
    </tr>
  );
}

const createCutterHeaderRows = (
  cutting: CuttingTransfer | undefined,
  t: TFunction
): JSX.Element[] => {
  const allTr: JSX.Element[] = [];
  allTr.push(createCutterHeaderRow1(t));
  allTr.push(createCutterHeaderRow2(cutting, t));
  allTr.push(createCutterHeaderRow3(cutting, t));
  allTr.push(createCutterHeaderRow4(cutting, t));
  allTr.push(createCutterHeaderRow5(t));
  return allTr;
};

const createCutterBodyRows = (cutting: CuttingTransfer | undefined): JSX.Element[] => {
  if (!cutting || !cutting.cuttingData || cutting.cuttingData.length < 1) return [<></>];
  const allTr: JSX.Element[] = [];
  for (let i = 0; i < cutting?.cuttingData?.length; i++) {
    const cuttingData = cutting?.cuttingData[i];
    allTr.push(
      <tr key={`${cuttingData.id}`}>
        {createBaseTd(toStr(i + 1), classCellC)}
        {createBaseTd(toStr(cuttingData.liveWeightWeight), classCellBg)}
        {createBaseTd(toStr(cuttingData.warmCarcassWeight), classCellBg)}
        {createBaseTd(toStr(cuttingData.warmCarcassPercent, 2), classCellBg)}
        {createBaseTd(toStr(cuttingData.coldCarcassWeight), classCellBg)}
        {createBaseTd(toStr(cuttingData.coldCarcassPercent, 2), classCellBg)}
        {createBaseTd(toStr(cuttingData.liverWeight), classCellBg)}
        {createBaseTd(toStr(cuttingData.liverPercent, 2), classCellBg)}
        {createBaseTd(toStr(cuttingData.headWeight), classCellBg)}
        {createBaseTd(toStr(cuttingData.headPercent, 2), classCellBg)}
        {createBaseTd(toStr(cuttingData.chestWeight), classCellBg)}
        {createBaseTd(toStr(cuttingData.chestPercent, 2), classCellBg)}
        {createBaseTd(toStr(cuttingData.middleWeight), classCellBg)}
        {createBaseTd(toStr(cuttingData.middlePercent, 2), classCellBg)}
        {createBaseTd(toStr(cuttingData.backWeight), classCellBg)}
        {createBaseTd(toStr(cuttingData.backPercent, 2), classCellBg)}
        {createBaseTd(toStr(cuttingData.loinFilletWeight), classCellBg)}
        {createBaseTd(toStr(cuttingData.loinFilletPercent, 2), classCellBg)}
        {createBaseTd(toStr(cuttingData.thighWeight), classCellBg)}
        {createBaseTd(toStr(cuttingData.thighPercent, 2), classCellBg)}
      </tr>
    );
  }
  return allTr;
};

interface CuttingFatteningPageParams {
  groupId: string;
  slaughteringId: string;
}

function CuttingFatteningPage() {
  const { groupId, slaughteringId } = useParams<CuttingFatteningPageParams>();
  const { t } = useTranslation();
  const { get: load, loading } = useApi();
  const [cutting, setCutting] = useState<CuttingTransfer>();

  const baseURI = `/api/v1/group/fattening/${groupId}/slaughtering/${slaughteringId}`;

  const loadCutting = useCallback(() => {
    const uri = `${baseURI}/cutting`;
    load<CuttingTransfer>(uri, (result: CuttingTransfer) => setCutting(result));
  }, [baseURI, load]);

  useEffect(() => {
    loadCutting();
  }, [loadCutting]);

  const isLoading = useCallback(() => loading, [loading]);

  return (
    <PageContainer
      title={
        <>
          <Row className="my-2 clearfix align-items-center d-flex">
            <Col sm="auto" className="align-items-center d-flex">
              <h4 className="float-left pr-3">{`${t('cutting-title')}`}</h4>
              <h4 className="float-left pr-3">{`${cutting?.groupFormattedName}`}</h4>
              <h4 className="float-left pr-3">{`${dateToStr(cutting?.createDate)}`}</h4>
            </Col>
          </Row>
        </>
      }
    >
      {isLoading() && <Loader />}

      <Row className="justify-content-around">
        <Col>
          <div className={classTableDiv}>
            <table className={classSmallTable} id="cutting-data-table">
              <thead>{createCutterHeaderRows(cutting, t)}</thead>
              <tbody>{createCutterBodyRows(cutting)}</tbody>
            </table>
          </div>
        </Col>
      </Row>
    </PageContainer>
  );
}

export default CuttingFatteningPage;
