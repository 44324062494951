import React, { CSSProperties } from 'react';
import BootstrapTable, { SelectRowProps, TableChangeHandler } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Col, Row } from 'reactstrap';
import Page from '../../models/page';
import Column from 'models/column';
import DefaultSorted from 'models/defaultSorted';
import { getRowCountLocaleString } from 'helpers/tables';

interface RemoteTableProps {
  wrapperClasses?: string;
  condensed?: boolean;
  data?: Page;
  columns: Column[];
  defaultSorted: DefaultSorted;
  keyField: string;
  onTableChange: TableChangeHandler<any>;
  sizePerPage?: number;
  onPageChange?: (page: number, sizePerPage: number) => void;
  dataCy?: string;
  showTotalNumber?: boolean;
  selectRow?: SelectRowProps<any>;
  id?: string;
  rowStyle?: ((row: any, rowIndex: number) => CSSProperties) | CSSProperties;
  paginationSize?: number;
}

function RemoteTable({
  wrapperClasses,
  condensed,
  data = { content: [], totalElements: 0, number: 0 },
  columns,
  defaultSorted,
  keyField,
  onTableChange,
  sizePerPage,
  onPageChange,
  dataCy,
  showTotalNumber = true,
  selectRow,
  id,
  rowStyle,
  paginationSize,
}: RemoteTableProps) {
  const cy = dataCy ? ` data-cy-${dataCy}` : '';

  return (
    <>
      {showTotalNumber && (
        <Row className="justify-content-end">
          <Col xs="auto">
            <span>{getRowCountLocaleString(data.totalElements)}</span>
          </Col>
        </Row>
      )}
      <BootstrapTable
        remote
        bootstrap4
        striped
        onTableChange={onTableChange}
        keyField={keyField}
        data={data.content}
        columns={columns}
        defaultSorted={[defaultSorted]}
        pagination={paginationFactory({
          hideSizePerPage: true,
          page: data.number + 1,
          sizePerPage: sizePerPage || 10,
          totalSize: data.totalElements,
          paginationSize: paginationSize || 5,
          onPageChange,
        })}
        wrapperClasses={wrapperClasses || `table-responsive${cy}`}
        condensed={condensed}
        selectRow={selectRow}
        id={id}
        rowStyle={rowStyle}
      />
    </>
  );
}

export default RemoteTable;
