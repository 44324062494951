import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Col, Row } from 'reactstrap';
import PageContainer from '../PageContainer';
import { DownloadExport } from './export/DownloadExport';
import FatteningGroupSummary from 'models/grouppages/fatteningGroupSummary';
import Loader from 'hyper/components/Loader';
import { useApi } from 'hooks/useApi';
import {
  classCellC,
  classCellL,
  classCellR,
  classTable,
  convertDateToStr,
  createTd,
  createTh,
  dateStr,
  toStr,
} from 'components/grouppages/gpUtil';
import Datepicker from 'hyper/components/Datepicker';
import FatteningWeeklyWeightGain from 'models/grouppages/fatteningWeeklyWeightGain';

function createURI(base: string, from: Date | undefined, to: Date | undefined): string {
  let uri = base;
  if (from) uri += `?from=${dateStr(from)}`;
  if (to) uri += `${uri === base ? '?' : '&'}to=${dateStr(to)}`;
  return uri;
}

const findWeightForWeek = (
  weights: FatteningWeeklyWeightGain[],
  week: number
): number | undefined => weights.filter((w) => w.weekOfAge === week)[0]?.weight;

const addWeightsColumns = (
  rowId: number,
  iidx: number,
  weights: FatteningWeeklyWeightGain[] | null | undefined,
  cell: string,
  age: number
) => {
  const result: JSX.Element[] = [];
  if (!weights) return result;
  for (let i = 4; i <= 13; i++) {
    if (i < (age / 7) >> 0) {
      result.push(createTd(rowId, iidx++, '-', classCellC));
      continue;
    }

    result.push(createTd(rowId, iidx++, toStr(findWeightForWeek(weights, i), 1), cell));
  }
  return result;
};

const getNameCellContent = (row: FatteningGroupSummary): string | JSX.Element | undefined => {
  const gid = `${row.groupId}`;
  return (
    <a href={`/grouppage-fattening/${gid}/edit`} rel="noopener noreferrer" target="_blank">
      <span id={gid}>{row.generatedName}</span>
    </a>
  );
};

const createDataRow = (row: FatteningGroupSummary, idx: number) => {
  const rowId = idx;
  let iidx = 0;
  return (
    <tr key={rowId}>
      {createTd(rowId, iidx++, getNameCellContent(row), classCellL)}
      {createTd(rowId, iidx++, row.stableName, classCellL)}
      {createTd(rowId, iidx++, toStr(row.actualAge), classCellR)}
      {createTd(rowId, iidx++, convertDateToStr(row.startDate), classCellR)}
      {createTd(rowId, iidx++, convertDateToStr(row.endDate), classCellR)}

      {createTd(rowId, iidx++, toStr(row.originNumSum), classCellR)}
      {createTd(rowId, iidx++, toStr(row.originWeightSum, 0), classCellR)}

      {createTd(rowId, iidx++, toStr(row.actualNum), classCellR)}
      {createTd(rowId, iidx++, toStr(row.actualWeight, 0), classCellR)}

      {createTd(rowId, iidx++, toStr(row.deadSum), classCellR)}

      {createTd(rowId, iidx++, toStr(row.feedAmountSum), classCellR)}
      {createTd(rowId, iidx++, toStr(row.weaningFeedAmountSum), classCellR)}
      {createTd(rowId, iidx++, toStr(row.finisherFeedAmountSum), classCellR)}
      {createTd(rowId, iidx++, toStr(row.femaleStarterFeedAmountSum), classCellR)}
      {createTd(rowId, iidx++, toStr(row.lactationFeedAmountSum), classCellR)}

      {createTd(rowId, iidx++, toStr(row.soldNumSum), classCellR)}
      {createTd(rowId, iidx++, toStr(row.soldWeightSum), classCellR)}

      {createTd(rowId, iidx++, toStr(row.soldWeightPerNum, 2), classCellR)}

      {createTd(rowId, iidx++, toStr(row.lastWeight), classCellR)}

      {createTd(rowId, iidx++, toStr(row.deadPercent, 2), classCellR)}

      {addWeightsColumns(rowId, iidx++, row.weeklyWeightGains, classCellR, row.age)}
    </tr>
  );
};

const renderBody = (summaries: FatteningGroupSummary[] | null | undefined) => {
  if (!summaries || summaries.length < 1) {
    return <></>;
  }
  const result: JSX.Element[] = [];
  summaries.map((row, idx) => {
    result.push(createDataRow(row, idx));
  });
  return result;
};

function FatteningSummary() {
  const { t } = useTranslation();
  const { get: load, loading } = useApi();
  const [summaries, setSummaries] = useState<FatteningGroupSummary[]>([]);
  const [nodata, setNoData] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(moment().add(-2, 'years').toDate());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const reload = useCallback(() => {
    const uri = createURI('/api/v1/group/fattening/summary', startDate, endDate);
    load<FatteningGroupSummary[]>(uri, (searchResult: FatteningGroupSummary[]) =>
      setSummaries(searchResult)
    );
  }, [endDate, load, startDate]);

  const isLoading = useCallback(() => loading, [loading]);

  useEffect(() => {
    reload();
  }, [endDate, reload, startDate]);

  useEffect(() => {
    setNoData(!summaries || summaries.length < 1);
  }, [summaries]);

  const strDB = t('piece');
  const strKG = t('kilogram');

  return (
    <PageContainer
      title={
        <div className="my-3 clearfix">
          <Row>
            <Col md="auto">
              <h4>{t('fattening-summary')}</h4>
            </Col>
            <Col md="auto">
              <Row>
                <Datepicker
                  id="startDate"
                  startDate={startDate}
                  dateFormat="yyyy-MM-dd"
                  onDateChange={setStartDate}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  todayButton={t('date-picker-today')}
                />
                <h4 className="mx-1"> - </h4>
                <Datepicker
                  id="endDate"
                  startDate={endDate}
                  dateFormat="yyyy-MM-dd"
                  onDateChange={setEndDate}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  todayButton={t('date-picker-today')}
                />
              </Row>
            </Col>
            <Col className="d-flex justify-content-end mr-2">
              <DownloadExport
                spreadSheetName={'fattening-summary'}
                type={'fattening'}
                isSummary
                from={dateStr(startDate)}
                to={dateStr(endDate)}
              />
            </Col>
          </Row>
        </div>
      }
    >
      {isLoading() && <Loader />}
      {!nodata && (
        <div
          className="react-bootstrap-table table-responsive mt-2 gp-summary-table-div"
          style={{ whiteSpace: 'pre-line' }}
        >
          <table id={'summary-fattening-groups'} className={`${classTable} table-striped gp-table`}>
            <thead>
              <tr>
                {createTh(t('group-sum-generatedName'), 0, '', { rowSpan: 2 })}
                {createTh(t('group-sum-stableName'), 1, '', { rowSpan: 2 })}
                {createTh(t('group-sum-ageday'), 2, '', { rowSpan: 2 })}
                {createTh(t('group-sum-startDate'), 3, '', { rowSpan: 2 })}
                {createTh(t('group-sum-endDate'), 4, '', { rowSpan: 2 })}
                {createTh(t('fgroup0-sum-origin'), 5, '', { colSpan: 2 })}
                {createTh(t('fgroup0-sum-actual'), 6, '', { colSpan: 2 })}
                {createTh(t('fgroup-sum-dead'), 7, '', { rowSpan: 2 })}
                {createTh(t('fgroup0-sum-feed'), 8, '', { colSpan: 5 })}
                {createTh(t('fgroup0-sum-sold'), 9, '', { colSpan: 2 })}
                {createTh(t('fgroup-sold-per-weight'), 10, '', { rowSpan: 2 })}
                {createTh(t('fgroup-last-weight'), 11, '', { rowSpan: 2 })}
                {createTh(t('fgroup-dead-percent'), 12, '', { rowSpan: 2 })}
                {createTh(t('fgroup0-weekly-weight-gain'), 13, '', { colSpan: 10 })}
              </tr>
              <tr>
                {createTh(strDB, 14)}
                {createTh(strKG, 15)}
                {createTh(strDB, 16)}
                {createTh(strKG, 17)}
                {createTh(t('fgroup1-sum-feed-amount'), 18, t('fgroup1-sum-feed-amount-tt'))}
                {createTh(t('fgroup1-sum-feed-weaning'), 19, t('fgroup1-sum-feed-weaning-tt'))}
                {createTh(t('fgroup1-sum-feed-finisher'), 20, t('fgroup1-sum-feed-finisher-tt'))}
                {createTh(
                  t('fgroup1-sum-feed-female-starter'),
                  21,
                  t('fgroup1-sum-feed-female-starter-tt')
                )}
                {createTh(t('fgroup1-sum-feed-lactation'), 22, t('fgroup1-sum-feed-lactation-tt'))}
                {createTh(strDB, 23)}
                {createTh(strKG, 24)}
                {createTh('4', 25)}
                {createTh('5', 26)}
                {createTh('6', 27)}
                {createTh('7', 28)}
                {createTh('8', 29)}
                {createTh('9', 30)}
                {createTh('10', 31)}
                {createTh('11', 32)}
                {createTh('12', 33)}
                {createTh('13', 34)}
              </tr>
            </thead>
            <tbody>{renderBody(summaries)}</tbody>
          </table>
        </div>
      )}
      {nodata && <Alert color="warning">{t('no-summary')}</Alert>}
    </PageContainer>
  );
}

export default FatteningSummary;
