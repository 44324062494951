import React, { useState, useEffect, useCallback } from 'react';
import { Card, Col, Badge, CardFooter, UncontrolledTooltip, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useApi, useApiPost, useSendApiGet } from '../../hooks/useApi';
import DedicateUserModal from './dedicateUserModal';
import IATaskDetails from 'models/iaTaskDetails';
import { TaskStatus } from 'models/taskStatus';
import { TaskType } from 'models/taskType';
import IATaskUser from 'models/iaTaskUser';
import { getLoggedInUser } from 'helpers/authUtils';
import { useDownloadSpreadsheet } from 'hooks/useDownloadFile';
import Loader from 'hyper/components/Loader';

interface IATaskDetailsProps {
  iaGroupId: number;
  iaGroupName: string | null;
  onIaTaskChanged: () => void;
}

const statusColor = (status: TaskStatus) => {
  let color;
  switch (status) {
    case TaskStatus.TO_DO:
      color = 'warning';
      break;

    case TaskStatus.IN_PROGRESS:
      color = 'primary';
      break;

    case TaskStatus.DONE:
      color = 'success';
      break;

    default:
      break;
  }
  return color;
};

const formatDate = (date: Date) =>
  `${date.getFullYear()}.${`0${date.getMonth() + 1}`.slice(-2)}.${`0${date.getDate()}`.slice(-2)}.`;

const calculateDate = (iaTaskDetails: IATaskDetails) => {
  const date = new Date(iaTaskDetails.estimatedStartAt);
  const date2week = new Date(date);
  const date3week = new Date(date);
  date2week.setDate(date.getDate() + 7);
  date3week.setDate(date.getDate() + 14);

  return (
    <div>
      <h5>{formatDate(date)}</h5>
    </div>
  );
};

interface UserIds {
  userIds: number[];
}

function IATaskDetailsComponent({ iaGroupId, iaGroupName, onIaTaskChanged }: IATaskDetailsProps) {
  const [iaTaskDetails, setIaTaskDetails] = useState<IATaskDetails[]>();
  const [url, setUrl] = useState<string>();
  const [iaTask, setIaTask] = useState<IATaskDetails>();
  const [adjustmentUrl, setAdjustmentUrl] = useState<string>();
  const [isDedicatedUserOpen, setIsDedicatedUserOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { sendDelete, loading: deletingUser } = useApi();
  const [loadIaTaskDetails, { loading }] = useSendApiGet<IATaskDetails[]>(
    `/api/v1/ia-group/${iaGroupId}/tasks`,
    setIaTaskDetails
  );

  const [userIds, { loading: savingDedicatedUser }] = useApiPost<UserIds>(
    `/api/v1/ia-group/${iaGroupId}/task/${iaTask?.iaTaskId}/dedicated-user`,
    () => {
      loadIaTaskDetails();
      onIaTaskChanged();
    }
  );

  useEffect(() => {
    setIaTask(undefined);
    loadIaTaskDetails();
  }, [loadIaTaskDetails]);

  useEffect(() => {
    if (iaGroupId) {
      setUrl(`/api/v1/export/ia-group/${iaGroupId}/farrowing-separation`);
      setAdjustmentUrl(`/adjustment/${iaGroupId}`);
    }
  }, [iaGroupId, setUrl, setAdjustmentUrl]);

  const fileName = useCallback(
    () =>
      `${t('farrowing-export')} - ${getLoggedInUser().username} -
        ${new Date().toLocaleDateString()} -
        ${new Date().toLocaleTimeString()}.xlsx`,
    [t]
  );

  const [download, { loading: downloading }] = useDownloadSpreadsheet();

  const toggleUserModal = useCallback(() => setIsDedicatedUserOpen(!isDedicatedUserOpen), [
    isDedicatedUserOpen,
  ]);

  const handleDeleteUser = useCallback(
    (iaTaskDetail: IATaskDetails, user: IATaskUser) => (event: React.MouseEvent) => {
      sendDelete(
        `/api/v1/ia-group/${iaGroupId}/task/${iaTaskDetail.iaTaskId}/dedicated-user/${user.userId}`,
        () => {
          loadIaTaskDetails();
          onIaTaskChanged();
        }
      );
      event.preventDefault();
    },
    [iaGroupId, loadIaTaskDetails, onIaTaskChanged, sendDelete]
  );

  const handleDedicateUser = useCallback(
    (iaTaskDetail: IATaskDetails) => (event: React.MouseEvent) => {
      setIaTask({ ...iaTaskDetail });
      toggleUserModal();
      event.preventDefault();
    },
    [toggleUserModal]
  );

  const isLoading = useCallback(
    () => loading || downloading || deletingUser || savingDedicatedUser,
    [loading, downloading, deletingUser, savingDedicatedUser]
  );

  const downloadExport = useCallback(() => {
    if (url) {
      download(url, fileName());
    }
  }, [url, download, fileName]);

  const renderUser = (user: IATaskUser, iaTaskDetail: IATaskDetails) =>
    user.dedicated ? (
      <div
        key={user.userId}
        className="text-center cursor-pointer p-1"
        style={{ width: '110px', height: '80px' }}
      >
        <span
          id={`delete-worker-${iaTaskDetail.iaTaskId}`}
          onClick={handleDeleteUser(iaTaskDetail, user)}
        >
          <i className="mdi mdi-36px mdi-account-minus" />
          <h5 className="m-0">{user.name}</h5>
        </span>
        <UncontrolledTooltip placement="right" target={`delete-worker-${iaTaskDetail.iaTaskId}`}>
          {t('hint-remove-user')}
        </UncontrolledTooltip>
      </div>
    ) : user.working ? (
      <span
        key={user.userId}
        className="text-center p-1"
        style={{ width: '110px', height: '80px' }}
      >
        <i className="mdi mdi-36px mdi-account" />
        <h5 className="m-0">{user.name}</h5>
      </span>
    ) : (
      <div key={user.userId} className="m-0 p-0" />
    );

  return (
    <>
      {iaGroupId && iaTask?.iaTaskId && (
        <DedicateUserModal
          iaGroupName={iaGroupName}
          iaTask={iaTask}
          iaGroupId={iaGroupId}
          isOpen={isDedicatedUserOpen}
          toggle={toggleUserModal}
          onSave={userIds}
        />
      )}
      <div className="mx-3">
        {isLoading() && <Loader />}
        {iaTaskDetails && iaTaskDetails.length === 0 && (
          <Card>
            <div className="text-center mt-2 mb-3">
              <h2>{t('empty-tasklist')}</h2>
            </div>
          </Card>
        )}
        {iaTaskDetails && iaTaskDetails.length > 0 && (
          <Card className="px-2">
            <div className="text-center mt-2 mb-3">
              <h2>{iaGroupName}</h2>
            </div>
            <Row>
              {iaTaskDetails &&
                iaTaskDetails.map((iaTaskDetail: IATaskDetails) => (
                  <Col xs="4" key={iaTaskDetail.iaTaskId}>
                    <Card className="p-2" style={{ maxWidth: '450px' }}>
                      <div className="text-center mb-2">
                        <h2>
                          <Badge color={statusColor(iaTaskDetail.status)}>
                            {t(iaTaskDetail.status)}
                          </Badge>
                        </h2>
                      </div>
                      <div className="p-2">
                        <h4>{t(TaskType[iaTaskDetail.type])}</h4>
                        {calculateDate(iaTaskDetail)}
                      </div>
                      <div className="d-flex justify-content-center mb-2">
                        <Col className="d-flex flex-wrap p-0">
                          {iaTaskDetail.iaTaskUsers &&
                            iaTaskDetail.iaTaskUsers
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((user: IATaskUser) => renderUser(user, iaTaskDetail))}
                        </Col>
                        <Col xs="3" className="d-flex flex-wrap justify-content-center">
                          <span
                            id={`new-worker-${iaTaskDetail.iaTaskId}`}
                            className="cursor-pointer p-1"
                            onClick={handleDedicateUser(iaTaskDetail)}
                          >
                            <i className="mdi mdi-36px mdi-account-plus" />
                          </span>
                          <UncontrolledTooltip
                            placement="right-start"
                            target={`new-worker-${iaTaskDetail.iaTaskId}`}
                          >
                            {t('hint-dedicate-user')}
                          </UncontrolledTooltip>
                        </Col>
                      </div>
                      <CardFooter>
                        <div className="d-flex align-items-center justify-content-center">
                          <span
                            id={`farrowing-list-${iaTaskDetail.iaTaskId}`}
                            className="mx-2 btn"
                            onClick={downloadExport}
                          >
                            <i className="mdi mdi-48px mdi-file-excel-outline" />
                          </span>
                          <span id={`adjustment-${iaTaskDetail.iaTaskId}`} className="mx-2 btn">
                            {adjustmentUrl && (
                              <Link to={adjustmentUrl}>
                                <img
                                  className="mr-2"
                                  src="/rabbit-logo-blue2.png"
                                  height="48"
                                  alt="rabbit-logo"
                                />
                              </Link>
                            )}
                          </span>
                          <UncontrolledTooltip
                            placement="right"
                            target={`farrowing-list-${iaTaskDetail.iaTaskId}`}
                          >
                            {t('hint-farrowing-export')}
                          </UncontrolledTooltip>
                          <UncontrolledTooltip
                            placement="right"
                            target={`adjustment-${iaTaskDetail.iaTaskId}`}
                          >
                            {t('hint-farrowing-result')}
                          </UncontrolledTooltip>
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                ))}
            </Row>
          </Card>
        )}
      </div>
    </>
  );
}

export default IATaskDetailsComponent;
