import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  ModalFooter,
  Container,
  Row,
  Col,
  Alert,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import NumericInput from './numericInput';
import SelectInput from './selectInput';
import Cover from 'models/cover';
import CoverEditDetails from 'models/coverEditDetails';
import { useApiPost } from 'hooks/useApi';
import Loader from 'hyper/components/Loader';
import AutoCompleteInput from 'components/shared/autoCompleteInput';
import SearchResult from 'models/searchResult';
import { defaultOrValue, isInputValueInvalid } from 'helpers/rabbit';
import { Pedigree } from 'models/pedigree';
import { Unit } from 'models/unit';

const WEIGHT_LIMIT = 15000;
const COUNT_LIMIT = 25;
const MIN_TERM_LENGTH = 2;

function getDefaultNumber(value: number) {
  return value !== null ? value : '';
}

function isAdjustedEditAvailable(cover: CoverEditDetails) {
  return cover.viable + cover.unviable > 0;
}

interface EditCoverDetailsProps {
  cover: Cover;
  isOpen: boolean;
  toggle: () => void;
  onSave: () => void;
}

interface InputErrors {
  viable: boolean;
  unviable: boolean;
  added: boolean;
  taken: boolean;
  adjusted: boolean;
  nestCount3Week: boolean;
  weight3Week: boolean;
  smallestWeight: boolean;
  largestWeight: boolean;
  nestCount: boolean;
  weight4Week: boolean;
}

const defaultInputErrors: InputErrors = {
  viable: false,
  unviable: false,
  added: false,
  taken: false,
  adjusted: false,
  nestCount3Week: false,
  weight3Week: false,
  smallestWeight: false,
  largestWeight: false,
  nestCount: false,
  weight4Week: false,
};

function EditCoverDetails({ cover, isOpen, toggle, onSave }: EditCoverDetailsProps) {
  const {
    pedigree,
    id,
    viable,
    unviable,
    nestCount,
    adjusted,
    added,
    taken,
    nestCount3Week,
    weight3Week,
    weight4Week,
    smallestWeight,
    largestWeight,
  } = cover;

  const defaultCoverEditDetails: CoverEditDetails = {
    pedigree,
    id,
    spermId: null,
    viable,
    unviable,
    nestCount,
    adjusted,
    added,
    taken,
    nestCount3Week,
    weight3Week,
    weight4Week,
    smallestWeight,
    largestWeight,
  };

  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<InputErrors>(defaultInputErrors);
  const [hasFormError, setHasFormError] = useState<boolean>(false);
  const [isAllValid, setIsAllValid] = useState<boolean>(false);
  const [backendMessages, setBackendMessages] = useState<string[] | null>(null);

  const [coverEditDetails, setCoverEditDetails] = useState<CoverEditDetails>(
    defaultCoverEditDetails
  );

  const [saveCoverDetails, { loading: saving }] = useApiPost<{ messages: string[] }>(
    `/api/v1/rabbit/ia-cover/${coverEditDetails.id}`,
    (response) => {
      if (response.messages.length > 0) {
        setBackendMessages(response.messages);
      } else {
        setBackendMessages(null);
        onSave();
        toggle();
      }
    }
  );

  useEffect(() => {
    if (isOpen) {
      setCoverEditDetails(defaultCoverEditDetails);
      setFormErrors(defaultInputErrors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setHasFormError(Object.values(formErrors).includes(true));
  }, [setHasFormError, formErrors]);

  const submit = useCallback(() => {
    setSubmitted(true);
    const isWrong = hasFormError || coverEditDetails.spermId === 0;
    if (!isWrong) {
      setBackendMessages(null);
      saveCoverDetails(coverEditDetails);
    }

    setIsAllValid(!isWrong);
  }, [coverEditDetails, hasFormError, saveCoverDetails]);

  useEffect(() => {
    setIsAllValid(true);
  }, [coverEditDetails]);

  const onPedigreeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const temp = event.target.value;
      const pedigree = temp !== '' ? temp : null;
      const copy = { ...coverEditDetails, pedigree };
      setCoverEditDetails(copy);
    },
    [coverEditDetails]
  );

  const onFatherSelected = useCallback(
    (spermResult: SearchResult) => {
      setSubmitted(false);
      const spermId = spermResult.id;
      const copy = { ...coverEditDetails, spermId };
      setCoverEditDetails(copy);
    },
    [coverEditDetails]
  );

  const onViableChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const viable = +event.target.value;
      const copy = { ...coverEditDetails, viable };
      setFormErrors({ ...formErrors, viable: isInputValueInvalid(viable, COUNT_LIMIT) });
      setCoverEditDetails(copy);
    },
    [coverEditDetails, formErrors]
  );

  const onUnviableChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const unviable = +event.target.value;
      const copy = { ...coverEditDetails, unviable };
      setFormErrors({ ...formErrors, unviable: isInputValueInvalid(unviable, COUNT_LIMIT) });
      setCoverEditDetails(copy);
    },
    [coverEditDetails, formErrors]
  );

  const onAddedChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const added = +event.target.value;
      const copy = { ...coverEditDetails, added };
      setFormErrors({ ...formErrors, added: isInputValueInvalid(added, COUNT_LIMIT) });
      setCoverEditDetails(copy);
    },
    [coverEditDetails, formErrors]
  );

  const onTakenChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const taken = +event.target.value;
      const copy = { ...coverEditDetails, taken };
      setFormErrors({ ...formErrors, taken: isInputValueInvalid(taken, COUNT_LIMIT) });
      setCoverEditDetails(copy);
    },
    [coverEditDetails, formErrors]
  );

  const onAdjustedChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const adjusted = +event.target.value;
      const copy = { ...coverEditDetails, adjusted };
      setFormErrors({ ...formErrors, adjusted: isInputValueInvalid(adjusted, COUNT_LIMIT) });
      setCoverEditDetails(copy);
    },
    [coverEditDetails, formErrors]
  );

  const onNestCount3WeekChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const nestCount3Week = +event.target.value;
      const copy = { ...coverEditDetails, nestCount3Week };
      setFormErrors({
        ...formErrors,
        nestCount3Week: isInputValueInvalid(nestCount3Week, COUNT_LIMIT),
      });
      setCoverEditDetails(copy);
    },
    [coverEditDetails, formErrors]
  );

  const onWeight3WeekChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const weight3Week = +event.target.value;
      const copy = { ...coverEditDetails, weight3Week };
      setFormErrors({ ...formErrors, weight3Week: isInputValueInvalid(weight3Week, WEIGHT_LIMIT) });
      setCoverEditDetails(copy);
    },
    [coverEditDetails, formErrors]
  );

  const onSmallestWeightChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const smallestWeight = +event.target.value;
      const copy = { ...coverEditDetails, smallestWeight };
      setFormErrors({
        ...formErrors,
        smallestWeight: isInputValueInvalid(smallestWeight, WEIGHT_LIMIT),
      });
      setCoverEditDetails(copy);
    },
    [coverEditDetails, formErrors]
  );

  const onLargestWeightChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const largestWeight = +event.target.value;
      const copy = { ...coverEditDetails, largestWeight };
      setFormErrors({
        ...formErrors,
        largestWeight: isInputValueInvalid(largestWeight, WEIGHT_LIMIT),
      });
      setCoverEditDetails(copy);
    },
    [coverEditDetails, formErrors]
  );

  const onNestCount = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const nestCount = +event.target.value;
      const copy = { ...coverEditDetails, nestCount };
      setFormErrors({ ...formErrors, nestCount: isInputValueInvalid(nestCount, COUNT_LIMIT) });
      setCoverEditDetails(copy);
    },
    [coverEditDetails, formErrors]
  );

  const onWeight4WeekChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const weight4Week = +event.target.value;
      const copy = { ...coverEditDetails, weight4Week };
      setFormErrors({ ...formErrors, weight4Week: isInputValueInvalid(weight4Week, WEIGHT_LIMIT) });
      setCoverEditDetails(copy);
    },
    [coverEditDetails, formErrors]
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="modal-content-size"
      className="modal-align"
    >
      <ModalHeader toggle={toggle}>{`${t('edit-ia-cover')}`}</ModalHeader>
      <ModalBody>
        {saving && <Loader />}
        <Container fluid>
          <Row className="">
            <Col className="d-flex justify-content-center">
              <div style={{ width: '330px' }}>
                <SelectInput
                  label={'pedigree'}
                  value={coverEditDetails.pedigree || ''}
                  onChange={onPedigreeChange}
                  options={Object.values(Pedigree)}
                  defaultOption={'not-pedigree'}
                />

                <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                  <Col sm="4">
                    <b>{t('buck')}:</b>
                  </Col>
                  <Col className="d-flex justify-content-start">
                    <AutoCompleteInput
                      key={'sperm'}
                      onSelected={onFatherSelected}
                      searchType={'sperm'}
                      defaultValue={cover.sperm}
                      submitted={submitted}
                      minTermLength={MIN_TERM_LENGTH}
                    />
                  </Col>
                </Row>
                <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                  <Col sm="4">
                    <b>{t('ia-date')}:</b>
                  </Col>
                  <Col className="d-flex justify-content-start">
                    <Input
                      value={defaultOrValue(cover.group.startDate)}
                      className="text-right"
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                  <Col sm="4">
                    <b>{t('ia-group')}:</b>
                  </Col>
                  <Col className="d-flex justify-content-start">
                    <Input value={cover.group.formattedName} className="text-right" disabled />
                  </Col>
                </Row>

                <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                  <Col sm="4">
                    <b>{t('pregnant')}:</b>
                  </Col>
                  <Col className="d-flex justify-content-start">
                    <Input value={cover.pregnant ? '+' : '-'} className="text-right" disabled />
                  </Col>
                </Row>
              </div>
            </Col>

            <Col className="d-flex justify-content-center">
              <div style={{ width: '330px' }}>
                <NumericInput
                  value={coverEditDetails.viable + coverEditDetails.unviable}
                  label={'born'}
                  unit={Unit.piece}
                  disabled
                />

                <NumericInput
                  onChange={onViableChange}
                  value={getDefaultNumber(coverEditDetails.viable)}
                  label={'viable'}
                  max={COUNT_LIMIT}
                  unit={Unit.piece}
                  invalid={formErrors.viable}
                />

                <NumericInput
                  onChange={onUnviableChange}
                  value={getDefaultNumber(coverEditDetails.unviable)}
                  label={'unviable'}
                  max={COUNT_LIMIT}
                  unit={Unit.piece}
                  invalid={formErrors.unviable}
                />

                <NumericInput
                  onChange={onAddedChange}
                  value={getDefaultNumber(coverEditDetails.added)}
                  label={'added-sign'}
                  max={COUNT_LIMIT}
                  unit={Unit.piece}
                  invalid={formErrors.added}
                />

                <NumericInput
                  onChange={onTakenChange}
                  value={getDefaultNumber(coverEditDetails.taken)}
                  label={'taken-sign'}
                  max={COUNT_LIMIT}
                  unit={Unit.piece}
                  invalid={formErrors.taken}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col className="d-flex justify-content-center">
              <div style={{ width: '330px' }}>
                {isAdjustedEditAvailable(coverEditDetails) && (
                  <NumericInput
                    onChange={onAdjustedChange}
                    value={getDefaultNumber(coverEditDetails.adjusted)}
                    label={'adjusted'}
                    max={COUNT_LIMIT}
                    unit={Unit.piece}
                    invalid={formErrors.adjusted}
                  />
                )}

                <NumericInput
                  onChange={onNestCount3WeekChange}
                  value={getDefaultNumber(coverEditDetails.nestCount3Week)}
                  label={'3week-nest-count'}
                  max={COUNT_LIMIT}
                  unit={Unit.piece}
                  invalid={formErrors.nestCount3Week}
                />

                <NumericInput
                  onChange={onWeight3WeekChange}
                  value={getDefaultNumber(coverEditDetails.weight3Week)}
                  label={'3week-nest-weight'}
                  unit={Unit.weight}
                  max={WEIGHT_LIMIT}
                  invalid={formErrors.weight3Week}
                />

                <NumericInput
                  onChange={onSmallestWeightChange}
                  value={getDefaultNumber(coverEditDetails.smallestWeight)}
                  label={'3week-nest-smallest-weight'}
                  unit={Unit.weight}
                  max={WEIGHT_LIMIT}
                  invalid={formErrors.smallestWeight}
                />
              </div>
            </Col>

            <Col className="d-flex justify-content-center">
              <div style={{ width: '330px' }}>
                <NumericInput
                  onChange={onLargestWeightChange}
                  value={getDefaultNumber(coverEditDetails.largestWeight)}
                  label={'3week-nest-largest-weight'}
                  unit={Unit.weight}
                  max={WEIGHT_LIMIT}
                  invalid={formErrors.largestWeight}
                />

                <NumericInput
                  onChange={onNestCount}
                  value={getDefaultNumber(coverEditDetails.nestCount)}
                  label={'nest-count'}
                  max={COUNT_LIMIT}
                  unit={Unit.piece}
                  invalid={formErrors.nestCount}
                />

                <NumericInput
                  onChange={onWeight4WeekChange}
                  value={getDefaultNumber(coverEditDetails.weight4Week)}
                  label={'nest-count-weight'}
                  unit={Unit.weight}
                  max={WEIGHT_LIMIT}
                  invalid={formErrors.weight4Week}
                />
              </div>
            </Col>
          </Row>
          <Row className="pt-2">
            {
              <Col>
                {!isAllValid && (
                  <Alert color="danger" className="text-center">
                    {t('form-has-errors')}
                  </Alert>
                )}
                {backendMessages && backendMessages.length > 0 && (
                  <Alert color="danger" className="text-center">
                    {backendMessages.map((msg: string) => (
                      <li key={msg}>{t(msg)}</li>
                    ))}
                  </Alert>
                )}
              </Col>
            }
          </Row>
        </Container>
        <Form className="form d-flex flex-column justify-content-center pl-5">
          <div className="" />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={submit} className="mr-2">
          {t('save')}
        </Button>
        <Button color="primary" onClick={toggle}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditCoverDetails;
