export enum CagePosition {
  DOWN = 'DOWN',
  UP = 'UP',
  OUTER = 'OUTER',
  INNER = 'INNER',
}

export function getTAlias(position: CagePosition): string {
  switch (position) {
    case CagePosition.DOWN:
      return 'bottom';
    case CagePosition.UP:
      return 'up';
    case CagePosition.OUTER:
      return 'outer';
    case CagePosition.INNER:
      return 'inner';
    default:
      return '';
  }
}
