import React, { useEffect, useState } from 'react';
import NumericInput from './numericInput';
import MotherEditTransfer from 'models/motherEditDetails';
import { getDefaultNumber, isInputValueInvalid } from 'helpers/rabbit';
import { Unit } from 'models/unit';

const WEIGHT_LIMIT = 15000;

interface WeightInputErrors {
  weight32d: boolean;
  weight66d: boolean;
  weight18w: boolean;
  weight3rd: boolean;
  weightLast: boolean;
}

interface EditRabbitWeightProps {
  onWeight32DayChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onWeight66DayChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onWeight18WeekChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onWeightThirdYeanChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onStatisticalWeightChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  editMotherDetails: MotherEditTransfer;
  setHasFormError: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultInputErrors: WeightInputErrors = {
  weight32d: false,
  weight66d: false,
  weight18w: false,
  weight3rd: false,
  weightLast: false,
};

function EditRabbitWeight({
  onWeight32DayChange,
  onWeight66DayChange,
  onWeight18WeekChange,
  onWeightThirdYeanChange,
  onStatisticalWeightChange,
  editMotherDetails,
  setHasFormError,
}: EditRabbitWeightProps) {
  const [formErrors, setFormErrors] = useState<WeightInputErrors>(defaultInputErrors);

  useEffect(() => {
    setFormErrors({
      weight32d: isInputValueInvalid(editMotherDetails.weight32d, WEIGHT_LIMIT),
      weight66d: isInputValueInvalid(editMotherDetails.weight66d, WEIGHT_LIMIT),
      weight18w: isInputValueInvalid(editMotherDetails.weight18w, WEIGHT_LIMIT),
      weight3rd: isInputValueInvalid(editMotherDetails.weightThirdYean, WEIGHT_LIMIT),
      weightLast: isInputValueInvalid(editMotherDetails.statisticalWeight, WEIGHT_LIMIT),
    });
  }, [
    editMotherDetails.statisticalWeight,
    editMotherDetails.weight18w,
    editMotherDetails.weight32d,
    editMotherDetails.weight66d,
    editMotherDetails.weightThirdYean,
  ]);

  useEffect(() => {
    setHasFormError(Object.values(formErrors).includes(true));
  }, [setHasFormError, formErrors]);

  return (
    <>
      <NumericInput
        onChange={onWeight32DayChange}
        value={getDefaultNumber(editMotherDetails.weight32d)}
        label={'day32'}
        max={WEIGHT_LIMIT}
        invalid={formErrors.weight32d}
        unit={Unit.weight}
      />

      <NumericInput
        onChange={onWeight66DayChange}
        value={getDefaultNumber(editMotherDetails.weight66d)}
        label={'day66'}
        max={WEIGHT_LIMIT}
        invalid={formErrors.weight66d}
        unit={Unit.weight}
      />

      <NumericInput
        onChange={onWeight18WeekChange}
        value={getDefaultNumber(editMotherDetails.weight18w)}
        label={'week18'}
        max={WEIGHT_LIMIT}
        invalid={formErrors.weight18w}
        unit={Unit.weight}
      />

      <NumericInput
        onChange={onWeightThirdYeanChange}
        value={getDefaultNumber(editMotherDetails.weightThirdYean)}
        label={'3rd-yean'}
        max={WEIGHT_LIMIT}
        invalid={formErrors.weight3rd}
        unit={Unit.weight}
      />

      <NumericInput
        onChange={onStatisticalWeightChange}
        value={getDefaultNumber(editMotherDetails.statisticalWeight)}
        label={'statistical-weight'}
        max={WEIGHT_LIMIT}
        invalid={formErrors.weightLast}
        unit={Unit.weight}
      />
    </>
  );
}

export default EditRabbitWeight;
