import React, { useCallback, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
// @ts-ignore
import MetisMenu from 'metismenujs/dist/metismenujs';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Alert } from 'reactstrap';
import LanguageSelector from './languageSelector';
import { UserRole } from 'models/userRole';
import { getLoggedInUser, getUserRole, isUserAuthenticated } from 'helpers/authUtils';

const Hover = styled.span`
  color: #cedce4;
  :hover {
    color: #ffffff;
    cursor: pointer;
  }
`;

interface MenuItemLinkProps {
  item: MenuItem;
  className?: string;
}

interface MenuItemProps extends MenuItemLinkProps {
  linkClassName: string;
}

interface MenuItem {
  icon?: string;
  name: string;
  path: string;
  children?: MenuItem[];
  roles: UserRole[];
}

const isPathActive = (path: string) => window.location.pathname.startsWith(path);

const renderItem = (item: MenuItem) => {
  if (item.roles.length && !item.roles.includes(getUserRole())) {
    return null;
  }

  return (
    <MenuItem
      key={item.name}
      item={item}
      className={classNames({ 'mm-active': isPathActive(item.path) })}
      linkClassName="side-nav-link"
    />
  );
};

const MenuItemWithChildren = ({ item, linkClassName }: MenuItemProps) => {
  const { t } = useTranslation();

  if (item.roles.length && !item.roles.includes(getUserRole())) {
    return null;
  }

  return (
    <li className="side-nav-item">
      <Link
        to="#"
        className={classNames('has-arrow', 'side-sub-nav-link', linkClassName)}
        aria-expanded={isPathActive(item.path)}
      >
        {item.icon && <i className={item.icon} />}
        <span> {t(item.name)} </span>
      </Link>

      <ul className={classNames('side-nav-second-level mm-collapse')}>
        {item.children && item.children.map((child) => renderItem(child))}
      </ul>
    </li>
  );
};

const MenuItem = ({ item, className, linkClassName }: MenuItemProps) => (
  <li className={classNames('side-nav-item', className)}>
    <MenuItemLink item={item} className={linkClassName} />
  </li>
);

const MenuItemLink = ({ item, className }: MenuItemLinkProps) => {
  const { t } = useTranslation();

  return (
    <Link
      to={item.path}
      className={classNames('side-nav-link-ref', 'side-sub-nav-link', className)}
    >
      {item.icon && <i className={item.icon} />}
      <span> {t(item.name)} </span>
    </Link>
  );
};

const MenuItems: MenuItem[] = [
  {
    name: 'stock',
    path: '/rabbits',
    roles: [UserRole.ADMIN],
    children: [
      {
        name: 'stock-list',
        path: '/rabbits',
        roles: [UserRole.ADMIN],
      },
    ],
  },
  {
    name: 'ia-groups',
    path: '/ia-groups',
    roles: [UserRole.ADMIN],
  },
  {
    name: 'grouppage',
    path: '/grouppage-mother',
    roles: [UserRole.ADMIN],
    children: [
      {
        name: 'grouppage-mother',
        path: '/grouppage-mother',
        roles: [UserRole.ADMIN],
      },
      {
        name: 'grouppage-young',
        path: '/grouppage-young',
        roles: [UserRole.ADMIN],
      },
      {
        name: 'grouppage-fattening',
        path: '/grouppage-fattening',
        roles: [UserRole.ADMIN],
      },
    ],
  },
  {
    name: 'process',
    path: '/insemination',
    roles: [UserRole.ADMIN],
    children: [
      {
        name: 'insemination',
        path: '/insemination',
        roles: [UserRole.ADMIN],
      },
      {
        name: 'farrowing-result',
        path: '/adjustment',
        roles: [UserRole.ADMIN],
      },
      {
        name: 'equalization',
        path: '/equalization',
        roles: [UserRole.ADMIN],
      },
      {
        name: 'ia-task-management',
        path: '/ia-task-management',
        roles: [UserRole.ADMIN],
      },
      {
        name: 'ear-tagging-title',
        path: '/ear-tagging',
        roles: [UserRole.ADMIN],
      },
    ],
  },
  {
    name: 'farm-management',
    path: '/farms',
    roles: [UserRole.ADMIN, UserRole.OWNER],
    children: [
      {
        name: 'user-management',
        path: '/users',
        roles: [],
      },
      {
        name: 'farm-stable',
        path: '/farms',
        roles: [UserRole.ADMIN, UserRole.OWNER],
      },
      {
        name: 'production-group-menu',
        path: '/production-group-management',
        roles: [UserRole.ADMIN],
      },
    ],
  },
  {
    name: 'user-management',
    path: '/users',
    roles: [UserRole.SUPERADMIN],
  },
  {
    name: 'statistic',
    path: '/statistic',
    roles: [UserRole.ADMIN],
  },
  {
    name: 'ear-tags',
    path: '/ear-tag-management',
    roles: [UserRole.SUPERADMIN],
  },
  {
    name: 'commands',
    path: '/commands',
    roles: [UserRole.SUPERADMIN],
  },
];

interface Language {
  language: string;
  timer: number;
}

export const AppMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState<Language>({ language: '', timer: 0 });

  useEffect(() => {
    const menuRef = new MetisMenu('#menu-bar').on('shown.metisMenu', (event: any) => {
      const menuClick = (e: any) => {
        menuRef.hide(event.detail.shownElement);
        window.removeEventListener('click', menuClick);
      };
      window.addEventListener('click', menuClick);
    });
  }, []);

  const stopTimer = useCallback(() => {
    const { timer } = language;
    if (timer) {
      clearTimeout(timer);
    }
    setLanguage({ ...language, timer: 0 });
  }, [language]);

  const onOk = () => {
    stopTimer();
    setLanguage({ language: '', timer: 0 });
  };

  const onError = (language: string) => {
    stopTimer();
    const newTimer = setInterval(() => onOk(), 5000);
    setLanguage({ language, timer: newTimer });
  };

  return (
    <>
      <div className="topnav">
        <nav className="navbar-dark">
          <div className="topbar-nav align-items-center navbar">
            <span>
              <img className="mr-2" src="/rabbit-logo-blue2.png" height="50" alt="rabbit-logo" />
            </span>
            <ul className="metismenu side-nav mr-auto" id="menu-bar">
              {MenuItems.map((item) =>
                item.children ? (
                  <MenuItemWithChildren key={item.name} item={item} linkClassName="side-nav-link" />
                ) : (
                  renderItem(item)
                )
              )}
            </ul>

            <LanguageSelector onLanguageChanged={onOk} onError={onError} />
            <div className="d-flex flex-column mr-4 align-items-center side-nav-user">
              {isUserAuthenticated() && <b>{getLoggedInUser().username}</b>}
              <span className="side-nav-user">{t(getUserRole())}</span>
            </div>
            <Link to="/logout">
              <Hover>{t('logout')}</Hover>
            </Link>
          </div>
        </nav>
      </div>
      {language && language.language.length > 0 && (
        <Alert key="top-nav-warning" color="danger" className="text-center m-2">
          {t('set-language-error', { language: language.language })}
        </Alert>
      )}
    </>
  );
};

export default withRouter(AppMenu);
