import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from 'reactstrap/lib/Alert';
import { useTranslation } from 'react-i18next';
import { useApiPost, useApi } from '../hooks/useApi';
import IAGroup from '../models/IAGroup';
import CreateIA from '../models/createIA';
import PageContainer from './PageContainer';
import IAGroupCreator from 'components/iaGroup/IAGroupCreator';
import Loader from 'hyper/components/Loader';
import { Pedigree } from 'models/pedigree';

const initIaGroup: IAGroup = {
  startDate: new Date(),
  name: '',
  pairings: [],
  formattedName: '-',
};

function mapGroupToCreateGroup({ name, startDate }: IAGroup): CreateIA {
  return {
    motherGroup: name,
    startDate: startDate ? startDate.valueOf() : null,
  };
}

function CreateIAGroup() {
  const history = useHistory();
  const { loading } = useApi();
  const { t } = useTranslation();
  const [sameIaGroupNameError, setSameIaGroupNameError] = useState(false);
  const [iaGroup, setIaGroup] = useState<IAGroup>(initIaGroup);
  const [noIaGroupName, setNoIaGroupName] = useState(false);

  const [saveGroup, { error, loading: saving }] = useApiPost<{ id: number }>(
    '/api/v1/ia-group',
    ({ id }) => {
      history.push(`/ia-group/${id}/edit`);
    },
    (error: any) => {
      if (error && error.status && error.status === 405) {
        setSameIaGroupNameError(true);
      }
    }
  );

  const onDataChanged = useCallback(
    (name?: string, startDate?: Date) => {
      if (iaGroup) {
        if (name) {
          setIaGroup({ ...iaGroup, name });
        }
        if (startDate) {
          setIaGroup({ ...iaGroup, startDate });
        }
      }
    },
    [iaGroup]
  );

  const onSave = useCallback(() => {
    if (iaGroup) {
      setNoIaGroupName(iaGroup.name.length < 1);
      if (iaGroup.name.length > 0) {
        saveGroup(mapGroupToCreateGroup(iaGroup));
      }
    }
  }, [iaGroup, saveGroup]);

  const onCancel = useCallback(() => history.push('/ia-groups'), [history]);

  const isLoading = useCallback(() => loading || saving, [loading, saving]);

  return (
    <PageContainer title="new-ia">
      {isLoading() && <Loader />}
      {error && !sameIaGroupNameError && <Alert color="danger">{t('failed-to-save')}</Alert>}
      {sameIaGroupNameError && <Alert color="danger">{t('same-iagroup-error')}</Alert>}
      {noIaGroupName && <Alert color="danger">{t('mother-group-error')}</Alert>}
      <IAGroupCreator
        pedigrees={Object.values(Pedigree)}
        onSave={onSave}
        onCancel={onCancel}
        onDataChanged={onDataChanged}
        isEdit={false}
        iaGroup={iaGroup}
      />
    </PageContainer>
  );
}

export default CreateIAGroup;
