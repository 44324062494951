import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Input, Row } from 'reactstrap';
import Page from '../models/page';
import PageContainer from './PageContainer';
import IAGroup from 'models/IAGroup';
import DefaultSorted from 'models/defaultSorted';
import CoverDetails from 'models/coverDetails';
import Loader from 'hyper/components/Loader';
import { useApi, useApiGet } from 'hooks/useApi';
import PaginatedTable from 'components/shared/paginatedTable';
import { getTableClasses } from 'helpers/tables';

function calculateBorn(row: CoverDetails) {
  if (row) {
    return row.viable + row.unviable;
  }
}

function convertTimestampIntoDate(timestamp: number) {
  if (timestamp) {
    return new Date(timestamp).toLocaleDateString();
  }
}

function convertTouching(row: CoverDetails) {
  if (row) {
    return row.touching ? '+' : '-';
  }
}

function Insemination() {
  const { t } = useTranslation();
  const { data: iaGroups, loading: loadingIAGroups } = useApiGet<Page<IAGroup>>(
    '/api/v1/ia-groups'
  );
  const { get, loading } = useApi();
  const [selectedIAGroupId, setSelectedIAGroupId] = useState<number>();
  const [coverDetails, setCoverDetails] = useState<CoverDetails[]>([]);

  const columns = [
    {
      dataField: 'cage',
      text: t('cage'),
      sort: true,
    },
    {
      dataField: 'earTag',
      text: t('ear-tag'),
      sort: true,
    },
    {
      dataField: 'tattoo',
      text: t('tattoo'),
      sort: true,
    },
    {
      dataField: 'pedigree',
      text: t('pedigree'),
      sort: true,
    },
    {
      dataField: 'sperm',
      text: t('male'),
      sort: true,
    },
    {
      dataField: 'touchingDate',
      text: t('touching-date'),
      sort: true,
      formatter: (cell: unknown, row: CoverDetails) =>
        row && convertTimestampIntoDate(row.touchingDate),
    },
    {
      dataField: 'touching',
      text: t('touching'),
      sort: true,
      formatter: (cell: unknown, row: CoverDetails) => convertTouching(row),
    },
    {
      dataField: 'farrowingDate',
      text: t('farrowing-date'),
      sort: true,
      formatter: (cell: unknown, row: CoverDetails) =>
        row && convertTimestampIntoDate(row.farrowingDate),
    },
    {
      dataField: 'born',
      text: t('born'),
      sort: true,
      isDummyField: true,
      formatter: (cell: unknown, row: CoverDetails) => calculateBorn(row),
    },
    {
      dataField: 'viable',
      text: t('viable'),
      sort: true,
    },
    {
      dataField: 'unviable',
      text: t('unviable'),
      sort: true,
    },
    {
      dataField: 'added',
      text: t('added'),
      sort: true,
    },
    {
      dataField: 'taken',
      text: t('taken'),
      sort: true,
    },
    {
      dataField: 'status',
      text: t('waste'),
      sort: true,
      formatter: (cell: unknown, row: CoverDetails) =>
        `${t(row.status)} - ${t(row.healthCondition)}`,
    },
  ];

  const defaultSorted: DefaultSorted = {
    dataField: columns[0].dataField,
    order: 'asc',
  };

  const handleOnSelect = useCallback(
    (iaGroupId: number) => {
      get<CoverDetails[]>(`/api/v1/ia-group/${iaGroupId}/covers`, (coverDetails: CoverDetails[]) =>
        setCoverDetails(coverDetails)
      );
    },
    [get]
  );

  const isLoading = useCallback(() => loading || loadingIAGroups, [loading, loadingIAGroups]);

  return (
    <PageContainer
      title={
        <Row className="my-3">
          <Col xs={2}>
            <h4>{t('insemination-result')}</h4>
          </Col>
          <Col xs={5}>
            <Row>
              <h5>{t('select-ia-group')}:</h5>
              <div>
                <Input
                  className="ml-2"
                  type="select"
                  name="siteSelect"
                  onChange={(e) => {
                    setSelectedIAGroupId(+e.target.value);
                  }}
                >
                  {!selectedIAGroupId && <option>{t('choose')}</option>}
                  {iaGroups &&
                    iaGroups.content.map((iaGroup: IAGroup) => (
                      <option key={iaGroup.id} value={iaGroup.id}>
                        {iaGroup.formattedName}
                      </option>
                    ))}
                </Input>
              </div>
              <Button
                color="primary"
                className="ml-4"
                onClick={() => selectedIAGroupId && handleOnSelect(selectedIAGroupId)}
              >
                {t('select')}
              </Button>
            </Row>
          </Col>
        </Row>
      }
    >
      {isLoading() && <Loader />}
      {!isLoading() && coverDetails && (
        <PaginatedTable
          wrapperClasses={getTableClasses('insemination-table')}
          data={coverDetails}
          keyField={columns[1].dataField}
          columns={columns}
          defaultSorted={defaultSorted}
        />
      )}
    </PageContainer>
  );
}

export default Insemination;
