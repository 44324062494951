import { useState, useCallback } from 'react';
import { uploadFile } from 'hyper/helpers/api';

type UseFileUpload = [
  <T>(file: File, path: string, onData?: (data: T) => void, onError?: (error: T) => void) => void,
  { error: boolean; loading: boolean }
];

function useFileUpload(): UseFileUpload {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const send = useCallback(
    <T>(file: File, path: string, onData?: (data: T) => void, onError?: (error: T) => void) => {
      const sendRequest = async () => {
        setLoading(true);
        try {
          const response = await uploadFile<T>(file, path);
          onData && onData(response);
          setError(false);
        } catch (e) {
          setError(true);
          onError && onError(e);
        } finally {
          setLoading(false);
        }
      };

      return sendRequest();
    },
    []
  );

  return [send, { error, loading }];
}

export default useFileUpload;
