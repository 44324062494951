import React, { useCallback, useState } from 'react';
import { Col, Row, Button, UncontrolledTooltip } from 'reactstrap';
import { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import RemoteTable from '../components/shared/remoteTable';
import usePaginatedData, { PageRequest } from '../hooks/usePaginatedData';
import PageContainer from './PageContainer';
import Loader from 'hyper/components/Loader';
import EarTagFilter, { EarTagFilterOptions } from 'components/earTag/earTagFilter';
import EarTagDetails from 'models/earTagDetails';
import { getTableClasses } from 'helpers/tables';
import { EarTagModal, EarTagOperationType } from 'components/earTag/modal/earTagModal';
import ImportModal from 'components/stock/importModal';

const PAGE_SIZE = 1000;

function EarTagList() {
  const { t } = useTranslation();
  const { loadMore, data: earTags, loading } = usePaginatedData<EarTagDetails[]>(
    '/api/v1/ear-tags'
  );
  const [filter, setFilter] = useState<EarTagFilterOptions>({});
  const [sort, setSort] = useState<{ dataField: string; order: 'asc' | 'desc' }>({
    dataField: 'tag',
    order: 'asc',
  });
  const [isFilteredNoFarm, setFilteredNoFarm] = useState<boolean>(false);
  const [isFilteredExistingFarm, setExistingFarm] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [earTagOperationType, setEarTagOperationType] = useState<EarTagOperationType>('placement');
  const [clearFilterValues, setClearFilterValues] = useState<number>(0);
  const [isEarTagsModalOpen, setEarTagsModalOpen] = useState<boolean>(false);

  const toggleEarTagsModal = useCallback(() => setEarTagsModalOpen((prevValue) => !prevValue), [
    setEarTagsModalOpen,
  ]);

  const loadEarTags = useCallback(
    (pageRequest: PageRequest, filter?: EarTagFilterOptions) => {
      loadMore(pageRequest, { ...filter });
    },
    [loadMore]
  );

  const toggleValue = useCallback(
    (isSuccess?: boolean) => (
      event: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLInputElement>
    ) => {
      event.preventDefault();
      setIsOpen((currenValue) => !currenValue);
      if (isSuccess) {
        setClearFilterValues((value) => ++value);
      }
    },
    []
  );

  const onFilterChange = useCallback(
    (newFilter: EarTagFilterOptions) => {
      setFilter(newFilter);
      const { farmId, used, active } = newFilter;
      setFilteredNoFarm(farmId === -1);
      setExistingFarm((farmId && farmId > 0 && used === false && active) || false);
      loadEarTags(
        {
          page: 1,
          pageSize: PAGE_SIZE,
          sortFields: [sort.dataField],
          sortOrder: sort.order,
        },
        { ...newFilter }
      );
    },
    [loadEarTags, setFilter, sort]
  );

  const getSortField = useCallback((sortField: string) => {
    if (sortField === 'farmName') return 'f.name';
    if (sortField === 'tattoo') return 'm.tattoo';
    return sortField;
  }, []);

  const onTableChange = useCallback(
    (type: TableChangeType, newState: TableChangeState<EarTagDetails>) => {
      setSort({ dataField: getSortField(newState.sortField), order: newState.sortOrder });
      loadEarTags(
        {
          page: newState.page,
          pageSize: newState.sizePerPage,
          sortFields: [getSortField(newState.sortField)],
          sortOrder: newState.sortOrder,
        },
        { ...filter }
      );
    },
    [getSortField, loadEarTags, filter]
  );

  const activeFormatter = (cell: boolean) =>
    cell ? (
      <i className="mdi mdi-check mdi-18px ml-2" />
    ) : (
      <i className="mdi mdi-close mdi-18px ml-2" />
    );

  const onPageChange = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  const columns = [
    {
      dataField: 'active',
      text: t('active'),
      formatter: activeFormatter,
    },
    {
      dataField: 'tag',
      text: t('ear-tag'),
      sort: true,
    },
    {
      dataField: 'eid',
      text: t('ear-tag-eid'),
      sort: true,
    },
    {
      dataField: 'farmName',
      text: t('ear-tag-farm'),
      sort: true,
    },
    {
      dataField: 'tattoo',
      text: t('tattoo'),
      sort: true,
    },
    {
      dataField: 'status',
      text: t('status'),
      formatter: (cell: string) => t(cell),
    },
    {
      dataField: 'healthCondition',
      text: t('health-condition'),
      formatter: (cell: string) => t(cell),
    },
  ];

  const toggleModal = useCallback(
    (operationType: EarTagOperationType) => (event: React.MouseEvent<HTMLInputElement>) => {
      event.preventDefault();
      setEarTagOperationType(operationType);
      toggleValue()(event);
    },
    [toggleValue]
  );

  return (
    <PageContainer title={''}>
      <EarTagModal
        isOpen={isOpen}
        toggle={toggleValue}
        type={earTagOperationType}
        earTagFilterOptions={filter}
        totalElements={earTags?.totalElements}
      />
      <ImportModal isOpen={isEarTagsModalOpen} toggle={toggleEarTagsModal} modalType="EAR_TAGS" />
      <div className="my-3">
        <Row>
          <Col>
            <EarTagFilter
              clearFilterValues={clearFilterValues}
              totalNum={earTags?.totalElements || 0}
              disabled={false}
              onFilter={onFilterChange}
            />
          </Col>
          <Col sm="auto" className="align-items-start">
            <Row className="mt-1">
              <Col>
                <Button
                  onClick={toggleModal('placement')}
                  block
                  color="primary"
                  disabled={!isFilteredNoFarm || earTags?.totalElements === 0}
                >
                  {t('ear-tag-attachment')}
                </Button>
              </Col>
              <Col sm="auto" className="p-0 d-flex align-items-center justify-content-center">
                <i id="placement" className="mdi mdi-information-outline mdi-18px" />
              </Col>

              <UncontrolledTooltip placement="top" target="placement">
                {t('ear-tag-attachment-info')}
              </UncontrolledTooltip>
            </Row>
            <Row className="mt-2">
              <Col>
                <Button
                  onClick={toggleModal('relocation')}
                  block
                  color="primary"
                  disabled={!isFilteredExistingFarm || earTags?.totalElements === 0}
                >
                  {t('ear-tag-replacement')}
                </Button>
              </Col>
              <Col sm="auto" className="p-0 d-flex align-items-center justify-content-center">
                <i id="relocation" className="mdi mdi-information-outline mdi-18px m-0" />
              </Col>

              <UncontrolledTooltip placement="top" target="relocation">
                {t('ear-tag-replacement-info')}
              </UncontrolledTooltip>
            </Row>
            <Row className="mt-2">
              <Col>
                <Button onClick={toggleEarTagsModal} block color="primary">
                  {t('import')}
                </Button>
              </Col>
              <Col sm="auto" className="p-0 d-flex align-items-center justify-content-center">
                <i id="import" className="mdi mdi-information-outline mdi-18px m-0 invisible" />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {loading && <Loader />}
      <RemoteTable
        wrapperClasses={getTableClasses('eartaglist-table')}
        data={earTags}
        onTableChange={onTableChange}
        columns={columns}
        defaultSorted={sort}
        condensed
        keyField="id"
        sizePerPage={PAGE_SIZE}
        onPageChange={onPageChange}
      />
    </PageContainer>
  );
}

export default EarTagList;
