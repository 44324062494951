import React, { useCallback, useEffect, useState } from 'react';
import { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import { classSideButton, dateTimeStr } from './gpUtil';
import Page from 'models/page';
import StatWeightTransfer from 'models/grouppages/statWeightTransfer';
import { getTableClasses } from 'helpers/tables';
import RemoteTable from 'components/shared/remoteTable';

type SortType = { dataField: keyof StatWeightTransfer; order: 'asc' | 'desc' };

const renderRowNum = (cell: any, row: StatWeightTransfer, rowIndex: number, formatExtraData: any) =>
  `${rowIndex + 1}`;

const renderActions = (
  cell: any,
  row: StatWeightTransfer,
  rowIndex: number,
  formatExtraData: any
) => {
  if (!row || !row.id || !formatExtraData) return undefined;
  return (
    <>
      <Button
        id={`edit-${row.id}`}
        color="primary"
        className={`${classSideButton} mr-1 diary-weight-edit`}
        onClick={() => formatExtraData.callEdit(row.id)}
      >
        <i className="mdi mdi-pencil p-1" />
      </Button>
      <Button
        id={`del-${row.id}`}
        color="primary"
        className={`${classSideButton} diary-weight-del`}
        onClick={() => formatExtraData.callDel(row.id)}
      >
        <i className="mdi mdi-delete p-1" />
      </Button>
    </>
  );
};

const sortByTime = (a: StatWeightTransfer, b: StatWeightTransfer): number =>
  new Date(a.at).getTime() - new Date(b.at).getTime();

const sortWeights = (a: StatWeightTransfer, b: StatWeightTransfer, sorting: SortType): number => {
  const order = sorting.order === 'asc' ? 1 : -1;
  let result = 0;
  if (sorting.dataField === 'weight') result = (a.weight || 0) - (b.weight || 0);
  else if (sorting.dataField === 'userName') result = a.userName.localeCompare(b.userName);
  if (result === 0) result = sortByTime(a, b);
  return result * order;
};

interface DiaryWeightsTableProps {
  transfer: StatWeightTransfer[] | null | undefined;
  callAdd: () => void;
  callDelAll: () => void;
  callImport: () => void;
  callEdit: (weightId: number) => void;
  callDel: (weightId: number) => void;
}

function DiaryWeightsTable({
  transfer,
  callAdd,
  callDelAll,
  callImport,
  callEdit,
  callDel,
}: DiaryWeightsTableProps) {
  const { t } = useTranslation();
  const [weights, setWeights] = useState<Page<StatWeightTransfer>>({
    content: [],
    totalElements: 0,
    number: 0,
  });
  const [sort, setSort] = useState<SortType>({
    dataField: 'at',
    order: 'asc',
  });

  useEffect(() => {
    transfer &&
      setWeights({
        content: transfer,
        totalElements: transfer.length,
        number: transfer.length,
      });
  }, [transfer]);

  const columns = [
    {
      dataField: '',
      isDummyField: true,
      text: '#',
      align: 'right',
      formatter: renderRowNum,
      headerAlign: 'right',
    },
    {
      dataField: 'at',
      text: t('sw-table-time'),
      align: 'right',
      sort: true,
      formatter: dateTimeStr,
    },
    {
      dataField: 'weight',
      text: t('sw-table-weight'),
      align: 'right',
      sort: true,
    },
    {
      dataField: 'userName',
      text: t('sw-table-user'),
      sort: true,
    },
    {
      dataField: 'id',
      isDummyField: true,
      text: t('operations'),
      formatExtraData: { translate: t, callEdit, callDel },
      formatter: renderActions,
    },
  ];

  const onTableChange = useCallback(
    (type: TableChangeType, newState: TableChangeState<StatWeightTransfer>) => {
      setSort({
        dataField: newState.sortField as keyof StatWeightTransfer,
        order: newState.sortOrder,
      });
    },
    []
  );

  useEffect(() => {
    setWeights((prev) => ({
      content: prev.content.sort((a, b) => sortWeights(a, b, sort)),
      totalElements: prev.totalElements,
      number: prev.number,
    }));
  }, [sort]);

  return (
    <span>
      <Row className="mb-1">
        <Col className="md-auto d-flex justify-content-end">
          <Button id={'sw-add'} size="sm" className="ml-2 py-0" color="primary" onClick={callAdd}>
            {t('sw-new-title')}
          </Button>
          <Button
            id={'sw-del-all'}
            size="sm"
            className="ml-2 py-0"
            color="primary"
            onClick={callDelAll}
          >
            {t('sw-del-all')}
          </Button>
          <Button
            id={'sw-import'}
            size="sm"
            className="ml-2 py-0"
            color="primary"
            onClick={callImport}
          >
            {t('sw-import')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <RemoteTable
            id={'sw-diary-weights'}
            wrapperClasses={getTableClasses('statistical-weight-table')}
            data={weights}
            onTableChange={onTableChange}
            columns={columns}
            defaultSorted={sort}
            keyField="id"
            sizePerPage={weights.totalElements}
            condensed
          />
        </Col>
      </Row>
    </span>
  );
}

export default DiaryWeightsTable;
