import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Input, Row } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import Page from '../models/page';
import PageContainer from './PageContainer';
import IAGroup from 'models/IAGroup';
import DefaultSorted from 'models/defaultSorted';
import CoverDetails from 'models/coverDetails';
import Loader from 'hyper/components/Loader';
import { useApi, useApiGet } from 'hooks/useApi';
import PaginatedTable from 'components/shared/paginatedTable';
import IAGroupSummaryDetails from 'models/iaGroupSummaryDetails';
import IAGroupSummary from 'components/iaGroup/iaGroupSummary';
import { getIaGroupNameWithDate } from 'helpers/iaGroup';
import { getTableClasses } from 'helpers/tables';
import { useDownloadSpreadsheet } from 'hooks/useDownloadFile';
import { getLoggedInUser } from 'helpers/authUtils';
import ImportModal from 'components/stock/importModal';

function calculateBorn(row: CoverDetails) {
  if (row) {
    row.born = row.viable + row.unviable;
    return row.viable + row.unviable;
  }
}

function convertTouching(row: CoverDetails) {
  if (row) {
    return row.touching ? '+' : '-';
  }
}

function defaultFormatter(value: any) {
  return value !== null ? value : '-';
}

function adjustedFormatter(cover: CoverDetails) {
  return calculateBorn(cover) === 0 ? '-' : defaultFormatter(cover.adjusted);
}

function navigateFormatter(coverDetails: CoverDetails, isEarTag: boolean) {
  return (
    <Link to={`/rabbits/${coverDetails.motherId}`}>
      <span>
        {isEarTag ? defaultFormatter(coverDetails.earTag) : defaultFormatter(coverDetails.tattoo)}
      </span>
    </Link>
  );
}

const fileName = (name: string) =>
  `${name} - ${
    getLoggedInUser().username
  } - ${`${new Date().toLocaleDateString()} -${new Date().toLocaleTimeString()}`}.xlsx`;

function Adjustment() {
  const { t } = useTranslation();
  const { data: iaGroups, loading: loadingIAGroups } = useApiGet<Page<IAGroup>>(
    '/api/v1/ia-groups'
  );
  const { get, loading } = useApi();
  const { iaGroupId } = useParams<{ iaGroupId: string }>();
  const [selectedIAGroupId, setSelectedIAGroupId] = useState<number>();
  const [coverDetails, setCoverDetails] = useState<CoverDetails[]>([]);
  const [iaGroupSummaryDetails, setIAGroupSummaryDetails] = useState<IAGroupSummaryDetails>();
  const [loadedIaGroup, setLoadedIaGroup] = useState<number | undefined>();
  const [download, { loading: downloading }] = useDownloadSpreadsheet();
  const [isFarrowingImportModalOpen, setFarrowingImportModalOpen] = useState<boolean>(false);

  const columns = [
    {
      dataField: 'cage',
      text: t('cage'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'earTag',
      text: t('ear-tag'),
      sort: true,
      formatter: (cell: unknown, row: CoverDetails) => navigateFormatter(row, true),
    },
    {
      dataField: 'tattoo',
      text: t('tattoo'),
      sort: true,
      formatter: (cell: unknown, row: CoverDetails) => navigateFormatter(row, false),
    },
    {
      dataField: 'pedigree',
      text: t('pedigree'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'sperm',
      text: t('male'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'touching',
      text: t('touching'),
      sort: true,
      formatter: (cell: unknown, row: CoverDetails) => convertTouching(row),
    },
    {
      dataField: 'born',
      text: t('born'),
      sort: true,
      formatter: (cell: unknown, row: CoverDetails) => calculateBorn(row),
    },
    {
      dataField: 'viable',
      text: t('viable'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'unviable',
      text: t('unviable'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'added',
      text: t('added-sign'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'taken',
      text: t('taken-sign'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'adjusted',
      text: t('adjusted'),
      sort: true,
      formatter: (cell: unknown, row: CoverDetails) => adjustedFormatter(row),
    },
    {
      dataField: 'nestCount3Week',
      text: t('3week-nest-count'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'weight3Week',
      text: t('3week-nest-weight'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'smallestWeight',
      text: t('3week-nest-smallest-weight'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'largestWeight',
      text: t('3week-nest-largest-weight'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'nestCount',
      text: t('nest-count'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'weight4Week',
      text: t('nest-count-weight'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'healthCondition',
      text: t('header-health-condition'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'status',
      text: t('status'),
      sort: true,
      formatter: defaultFormatter,
    },
  ];

  const defaultSorted: DefaultSorted = {
    dataField: columns[0].dataField,
    order: 'asc',
  };

  const handleOnSelect = useCallback(
    (iaGroupId: number | undefined) => {
      if (!iaGroupId) {
        setLoadedIaGroup(undefined);
        return;
      }
      get<CoverDetails[]>(
        `/api/v1/ia-group/${iaGroupId}/covers`,
        (coverDetails: CoverDetails[]) => {
          const translatedCoverDetails: CoverDetails[] = coverDetails.map((cover) => ({
            ...cover,
            status: t(cover.status),
            healthCondition: t(cover.healthCondition),
          }));

          setCoverDetails(translatedCoverDetails);
          const groupId = +iaGroupId;
          if (groupId) {
            const group = iaGroups?.content.find((g) => g.id === groupId);
            const isActive = group && group.active && !group.postAdditionCovers;
            setLoadedIaGroup(isActive ? groupId : undefined);
          }
        }
      );
      get<IAGroupSummaryDetails>(
        `/api/v1/ia-group/${iaGroupId}/covers/summary`,
        (iaGroupSummaryDetails: IAGroupSummaryDetails) =>
          setIAGroupSummaryDetails(iaGroupSummaryDetails)
      );
    },
    [get, iaGroups, t]
  );

  useEffect(() => {
    if (!iaGroupId) return;
    setSelectedIAGroupId(+iaGroupId);
    handleOnSelect(+iaGroupId);
  }, [iaGroupId, handleOnSelect]);

  const isLoading = useCallback(() => loading || loadingIAGroups || downloading, [
    downloading,
    loading,
    loadingIAGroups,
  ]);

  const downloadFarrowingExport = useCallback(
    (groupId: number) => {
      download(
        `/api/v1/export/ia-group/${groupId}/farrowing`,
        fileName(t('iagroup-farrowing-export'))
      );
    },
    [download, t]
  );

  const toggleFarrowingImportModal = useCallback(
    () => setFarrowingImportModalOpen(!isFarrowingImportModalOpen),
    [isFarrowingImportModalOpen, setFarrowingImportModalOpen]
  );

  const farrowingImportClosed = useCallback(() => handleOnSelect(loadedIaGroup), [
    handleOnSelect,
    loadedIaGroup,
  ]);

  return (
    <PageContainer
      title={
        <Row className="my-3">
          <Col xs={2}>
            <h4>{t('farrowing-result')}</h4>
          </Col>
          <Col xs={7}>
            <Row>
              <h5>{t('select-ia-group')}:</h5>
              <div>
                <Input
                  className="ml-2"
                  type="select"
                  name="siteSelect"
                  value={selectedIAGroupId}
                  onChange={(e) => {
                    setSelectedIAGroupId(+e.target.value);
                  }}
                >
                  {!selectedIAGroupId && <option>{t('choose')}</option>}
                  {iaGroups &&
                    iaGroups.content.map((iaGroup: IAGroup) => (
                      <option key={iaGroup.id} value={iaGroup.id}>
                        {getIaGroupNameWithDate(iaGroup)}
                      </option>
                    ))}
                </Input>
              </div>
              <Button
                color="primary"
                className="ml-4"
                onClick={() => selectedIAGroupId && handleOnSelect(selectedIAGroupId)}
              >
                {t('select')}
              </Button>
            </Row>
          </Col>
          <Col>
            {loadedIaGroup && (
              <Row className="d-flex justify-content-end">
                <Button
                  color="primary"
                  onClick={() => loadedIaGroup && toggleFarrowingImportModal()}
                >
                  {t('import')}
                </Button>
                <Button
                  color="primary"
                  className="mx-2"
                  onClick={() => loadedIaGroup && downloadFarrowingExport(loadedIaGroup)}
                >
                  {t('export')}
                </Button>
              </Row>
            )}
          </Col>
        </Row>
      }
    >
      {isLoading() && <Loader />}
      {!isLoading() && coverDetails && (
        <PaginatedTable
          wrapperClasses={getTableClasses('adjustment-table')}
          data={coverDetails}
          keyField={columns[1].dataField}
          columns={columns}
          defaultSorted={defaultSorted}
        />
      )}
      {iaGroupSummaryDetails && <IAGroupSummary iaGroupSummaryDetails={iaGroupSummaryDetails} />}
      <ImportModal
        isOpen={isFarrowingImportModalOpen}
        toggle={toggleFarrowingImportModal}
        onUploadClosed={farrowingImportClosed}
        groupId={loadedIaGroup}
        modalType="FARROWING"
      />
    </PageContainer>
  );
}

export default Adjustment;
