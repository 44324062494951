import { TFunction } from 'i18next';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import {
  boolToStr,
  classBase,
  classCellC,
  classCellL,
  classCellR,
  classCellTr,
  classSideButton,
  classTable,
  createTd,
  createTh,
  createThL,
  dateStr,
  monthDayToStr,
  renderWeightCell,
  toStr,
} from './gpUtil';
import EditYoungDiariesModalModal from './modal/young/editYoungDiariesModal';
import EditYoungTransfer from 'models/grouppages/editYoungTransfer';
import YoungDiaryElementTransfer from 'models/grouppages/youngDiaryElementTransfer';
import { DailyWeightType } from 'models/grouppages/dailyWeightType';

interface YoungEditDiariesProps {
  transfer: EditYoungTransfer | null | undefined;
  refresh: () => void;
}

const isWeekend = (row: YoungDiaryElementTransfer): boolean =>
  ['SUNDAY', 'SATURDAY'].includes(row.dayOfWeek);

interface WeeklyData {
  weekLength: Map<number, number>;
}

const calcWeeklyData = (data: YoungDiaryElementTransfer[] | undefined): WeeklyData => {
  const ret: WeeklyData = {
    weekLength: new Map<number, number>(),
  };
  if (!data) return ret;

  let oldWeek = -1;
  let oldLength = -1;
  data.forEach((row) => {
    if (oldWeek !== row.weekOfAge) {
      if (oldWeek !== -1 && oldLength > 0) {
        ret.weekLength.set(oldWeek, oldLength);
      }
      oldLength = 0;
      oldWeek = row.weekOfAge;
    }
    oldLength += 1;
  });
  if (oldWeek !== -1 && oldLength > 0) {
    ret.weekLength.set(oldWeek, oldLength);
  }

  return ret;
};

const createDiaryButton = (
  title: string,
  closeDay: boolean,
  disabled: boolean,
  today = false,
  allButton = true,
  diary: YoungDiaryElementTransfer,
  toggle: (diary: YoungDiaryElementTransfer) => void
) => {
  const color = disabled ? 'secondary' : 'primary';
  let classname = `text-center ${classBase}`;
  if (today) {
    classname = `bg-warning ${classname}`;
  }
  return !disabled || allButton ? (
    <td className={classname}>
      <Button
        color={color}
        className={`${classSideButton} px-1`}
        onClick={() => {
          toggle(diary);
        }}
      >
        <span style={{ fontSize: 'smaller' }}>{title}</span>
      </Button>
    </td>
  ) : (
    <td className={classCellC}>{title}</td>
  );
};

const createDataRow = (
  now: string,
  closedAt: string,
  row: YoungDiaryElementTransfer,
  idx: number,
  week: number,
  weeklyData: WeeklyData,
  t: TFunction,
  toggle: (diary: YoungDiaryElementTransfer) => void
) => {
  const fisrtWeekday = idx === 0 || row.dayOfAge % 7 === 0;
  const feedType = row.value.feed?.type ? t(`${row.value.feed?.type}`) : '';
  const dayName = row.dayOfWeek ? t(`${row.dayOfWeek}`) : '';
  const actDay = dateStr(row.actualDate);
  const closeDay = actDay === closedAt;
  const noButton = (closedAt.length > 0 ? closedAt : now) < actDay;
  const today = now === actDay;
  const background = closeDay ? ' bg-danger' : today ? ' bg-warning' : '';
  const cellL = `${classCellL}${background}`;
  const cellC = `${classCellC}${background}`;
  const cellR = `${classCellR}${background}`;
  const cellTr = `${classCellTr}${background}`;
  const weightPS = row.value.weights.find((w) => w.type === DailyWeightType.PS);
  const weightGP = row.value.weights.find((w) => w.type === DailyWeightType.GP);
  const deadRespPercentPS = row.value.deadPS ? toStr(row.deadRespPercentPS, 2) : '';
  const deadRespPercentGP = row.value.deadGP ? toStr(row.deadRespPercentGP, 2) : '';

  const deadErePercentPS = row.value.deadPS ? toStr(row.deadErePercentPS, 2) : '';
  const deadErePercentGP = row.value.deadGP ? toStr(row.deadErePercentGP, 2) : '';
  let iidx = 0;
  const firstColRowSpan = weeklyData.weekLength.get(row.weekOfAge);
  return (
    <tr key={row.id}>
      {fisrtWeekday &&
        createTd(row.id, iidx++, toStr(row.weekOfAge), classCellC, undefined, {
          rowSpan: firstColRowSpan,
        })}
      {createDiaryButton(
        monthDayToStr(row.actualDate),
        closeDay,
        noButton,
        today,
        false,
        row,
        toggle
      )}
      {createTd(row.id, iidx++, `${dayName}.`, cellC, false, undefined, isWeekend(row))}
      {createTd(row.id, iidx++, `${row.dayOfAge}`, cellR)}

      {createTd(row.id, iidx++, toStr(row.value.deadPS), cellR)}
      {createTd(row.id, iidx++, toStr(row.value.deadGP), cellR)}

      {createTd(row.id, iidx++, deadRespPercentPS, cellR)}
      {createTd(row.id, iidx++, deadRespPercentGP, cellR)}

      {createTd(row.id, iidx++, deadErePercentPS, cellR)}
      {createTd(row.id, iidx++, deadErePercentGP, cellR)}

      {createTd(row.id, iidx++, toStr(row.value.wastePS), cellR)}
      {createTd(row.id, iidx++, toStr(row.value.wasteGP), cellR)}

      {createTd(row.id, iidx++, renderWeightCell(row, weightPS?.weight, 'PS', noButton), cellR)}
      {createTd(row.id, iidx++, renderWeightCell(row, weightGP?.weight, 'GP', noButton), cellR)}

      {createTd(row.id, iidx++, toStr(row.value.indoorData?.temperature, 1), cellR)}
      {createTd(row.id, iidx++, toStr(row.value.indoorData?.relativeHumidity), cellC)}

      {createTd(row.id, iidx++, toStr(row.value.dailyFeedAmount), cellR)}
      {createTd(row.id, iidx++, toStr(row.value.feed?.amount), cellR)}
      {createTd(row.id, iidx++, row.value.feed?.productNumber, cellTr, true)}
      {createTd(row.id, iidx++, feedType, cellTr)}
      {createTd(row.id, iidx++, row.value.feed?.siloName, cellL)}
      {createTd(row.id, iidx++, row.value.feed?.serialNumber, cellTr, true)}

      {createTd(row.id, iidx++, row.value.comment, cellTr, true)}

      {createTd(row.id, iidx++, row.value.medicine?.treatment, cellTr, true)}
      {createTd(row.id, iidx++, boolToStr(row.value.medicine?.vaccination), cellC)}
      {createTd(row.id, iidx++, boolToStr(row.value.medicine?.drinkingWater), cellC)}
      {createTd(row.id, iidx++, boolToStr(row.value.medicine?.medication1), cellC)}
      {createTd(row.id, iidx++, boolToStr(row.value.medicine?.medication2), cellC)}

      {createTd(row.id, iidx++, toStr(row.value.soldPS), cellR)}
      {createTd(row.id, iidx++, toStr(row.value.soldGP), cellR)}

      {createTd(row.id, iidx++, toStr(row.value.ownReplacementPS), cellR)}
      {createTd(row.id, iidx++, toStr(row.value.ownReplacementGP), cellR)}

      {createTd(row.id, iidx++, toStr(row.dayEndNumPS), cellR)}
      {createTd(row.id, iidx++, toStr(row.dayEndNumGP), cellR)}
    </tr>
  );
};

const renderBody = (
  transfer: EditYoungTransfer | null | undefined,
  t: TFunction,
  toggle: (diary: YoungDiaryElementTransfer) => void
) => {
  const data = transfer?.diary.diaries;
  if (!data) {
    return <></>;
  }
  const weeklyData = calcWeeklyData(data);
  const result: JSX.Element[] = [];
  const closedAt = dateStr(transfer?.endDate);
  const now = dateStr(new Date());
  data.map((row, idx) => {
    result.push(createDataRow(now, closedAt, row, idx, (idx / 7) >> 0, weeklyData, t, toggle));
  });
  return result;
};

function YoungEditDiaries({ transfer, refresh }: YoungEditDiariesProps) {
  const { t } = useTranslation();

  const itemNum = t('ygpd-1-num');
  const itemPercent = t('ygpd-1-percent');
  const ps = t('ygpt2-ps');
  const gp = t('ygpt2-gp');
  const vaccinationTT = 'ygpd-1-vaccination-tt';
  const drinkingWaterTT = 'ygpd-1-drinking-water-tt';
  const medication1TT = 'ygpd-1-medication1-tt';
  const medication2TT = 'ygpd-1-medication2-tt';
  const psDeadTT = 'ygpt2-ps-dead-tt';
  const gpDeadTT = 'ygpt2-gp-dead-tt';
  const psDeadRespTT = 'ygpt2-ps-dead-resp-tt';
  const gpDeadRespTT = 'ygpt2-gp-dead-resp-tt';
  const psDeadEreTT = 'ygpt2-ps-ere-resp-tt';
  const gpDeadEreTT = 'ygpt2-gp-ere-resp-tt';
  const psWasteTT = 'ygpt2-ps-waste-tt';
  const gpWasteTT = 'ygpt2-gp-waste-tt';
  const psWeightTT = 'ygpt2-ps-weight-tt';
  const gpWeightTT = 'ygpt2-gp-weight-tt';

  const psSoldTT = 'ygpt2-ps-sold-tt';
  const gpSoldTT = 'ygpt2-gp-sold-tt';
  const psOwnReplTT = 'ygpt2-ps-own-repl-tt';
  const gpOwnReplTT = 'ygpt2-gp-own-repl-tt';
  const psDayEndTT = 'ygpt2-ps-day-end-tt';
  const gpDayEndTT = 'ygpt2-gp-day-end-tt';

  const [isYoungDiaryEditOpen, setIsYoungDiaryEditOpen] = useState<boolean>(false);
  const [selectedDiary, setSelectedDiary] = useState<YoungDiaryElementTransfer | undefined>(
    undefined
  );

  const refreshPage = useCallback(() => {
    refresh();
  }, [refresh]);

  const toggle = useCallback(() => {
    setIsYoungDiaryEditOpen(!isYoungDiaryEditOpen);
  }, [isYoungDiaryEditOpen]);

  const toggleDiaryEditModal = useCallback(
    (diary: YoungDiaryElementTransfer) => {
      setSelectedDiary(diary);
      toggle();
    },
    [toggle]
  );

  return (
    <>
      {selectedDiary && (
        <EditYoungDiariesModalModal
          id={transfer?.id}
          toggle={toggle}
          isOpen={isYoungDiaryEditOpen}
          onSave={refreshPage}
          diaryDetails={selectedDiary}
        />
      )}
      <div className="react-bootstrap-table table-responsive">
        <table
          className={`${classTable} table-striped gp-table data-cy-diary-table`}
          id={'young-diary-table'}
        >
          <thead>
            <tr>
              {createTh(t('gpd-0-diary'), 0, '', { colSpan: 4, rowSpan: 2 })}
              {createTh(t('ygpd-0-dead'), 1, '', { colSpan: 2 })}
              {createTh(t('ygpd-0-dead-resp'), 2, '', { colSpan: 2 })}
              {createTh(t('gpd-dead-ere'), 3, '', { colSpan: 2 })}
              {createTh(t('ygpd-0-waste'), 4, '', { colSpan: 2 })}
              {createTh(t('ygpd-0-weight'), 5, '', { colSpan: 2 })}
              {createTh(t('gpd-0-indoor'), 6, '', { colSpan: 2, rowSpan: 2 })}
              {createTh(t('gpd-0-food'), 7, '', { colSpan: 6, rowSpan: 2 })}
              {createTh(t('gpd-comment'), 8, '', { rowSpan: 3 })}
              {createTh(t('gpd-0-medicine'), 9, '', { colSpan: 5, rowSpan: 1 })}
              {createTh(t('ygpd-0-sold'), 10, '', { colSpan: 2 })}
              {createTh(t('ygpd-0-own-repl'), 11, '', { colSpan: 2 })}
              {createTh(t('ygpd-0-day-end'), 12, '', { colSpan: 2 })}
            </tr>
            <tr>
              {createTh(itemNum, 13, '', { colSpan: 2 })}
              {createTh(itemPercent, 14, '', { colSpan: 2 })}
              {createTh(itemPercent, 15, '', { colSpan: 2 })}
              {createTh(itemNum, 16, '', { colSpan: 2 })}
              {createTh(t('ygpd-1-gramm'), 17, '', { colSpan: 2 })}
              {createTh('', 18, '')}
              {createTh(
                toStr(transfer?.diary.medicineSumValues.vaccinationSum) || '0',
                19,
                t(vaccinationTT),
                undefined,
                vaccinationTT
              )}
              {createTh(
                toStr(transfer?.diary.medicineSumValues.drinkingWaterSum) || '0',
                20,
                t(drinkingWaterTT),
                undefined,
                drinkingWaterTT
              )}
              {createTh(
                toStr(transfer?.diary.medicineSumValues.medicine1Sum) || '0',
                21,
                t(medication1TT),
                undefined,
                medication1TT
              )}
              {createTh(
                toStr(transfer?.diary.medicineSumValues.medicine2Sum) || '0',
                22,
                t(medication2TT),
                undefined,
                medication2TT
              )}
              {createTh(itemNum, 23, '', { colSpan: 2 })}
              {createTh(itemNum, 24, '', { colSpan: 2 })}
              {createTh(itemNum, 25, '', { colSpan: 2 })}
            </tr>
            <tr>
              {createThL(t('gpd-week'), 26, t('gpd-week-tt'))}
              {createThL(t('gpd-date'), 27)}
              {createThL(t('gpd-day'), 28, t('gpd-day-tt'))}
              {createThL(t('gpd-age'), 29, t('gpd-age-tt'))}
              {createThL(ps, 30, t(psDeadTT), undefined, psDeadTT)}
              {createThL(gp, 31, t(gpDeadTT), undefined, gpDeadTT)}
              {createThL(ps, 32, t(psDeadRespTT), undefined, psDeadRespTT)}
              {createThL(gp, 33, t(gpDeadRespTT), undefined, gpDeadRespTT)}
              {createThL(ps, 34, t(psDeadEreTT), undefined, psDeadEreTT)}
              {createThL(gp, 35, t(gpDeadEreTT), undefined, gpDeadEreTT)}
              {createThL(ps, 36, t(psWasteTT), undefined, psWasteTT)}
              {createThL(gp, 37, t(gpWasteTT), undefined, gpWasteTT)}
              {createThL(ps, 38, t(psWeightTT), undefined, psWeightTT)}
              {createThL(gp, 39, t(gpWeightTT), undefined, gpWeightTT)}
              {createThL(t('gpd-degrees'), 40, t('gpd-degrees-tt'))}
              {createThL(t('gpd-rh'), 41, t('gpd-rh-tt'))}
              {createThL(t('gpd-daily-feed'), 42, t('gpd-daily-feed-tt'))}
              {createThL(t('gpd-feed-amount'), 43, t('gpd-feed-amount-tt'))}
              {createThL(t('gpd-product'), 44)}
              {createThL(t('gpd-type'), 45, t('gpd-type-tt'))}
              {createThL(t('gpd-silo'), 46)}
              {createThL(t('gpd-serial'), 47)}
              {createThL(t('gpd-treatment'), 48)}
              {createThL(t('gpd-vaccination'), 49, t('gpd-vaccination-tt'))}
              {createThL(
                t('gpd-drinking-water'),
                50,
                t('gpd-drinking-water-tt'),
                undefined,
                'gpd-drinking-water'
              )}
              {createThL(t('gpd-medication1'), 51, t('gpd-medication1-tt'))}
              {createThL(t('gpd-medication2'), 52, t('gpd-medication2-tt'))}
              {createThL(ps, 53, t(psSoldTT), undefined, psSoldTT)}
              {createThL(gp, 54, t(gpSoldTT), undefined, gpSoldTT)}
              {createThL(ps, 55, t(psOwnReplTT), undefined, psOwnReplTT)}
              {createThL(gp, 56, t(gpOwnReplTT), undefined, gpOwnReplTT)}
              {createThL(ps, 57, t(psDayEndTT), undefined, psDayEndTT)}
              {createThL(gp, 58, t(gpDayEndTT), undefined, gpDayEndTT)}
            </tr>
          </thead>
          <tbody>{renderBody(transfer, t, toggleDiaryEditModal)}</tbody>
        </table>
      </div>
    </>
  );
}

export default YoungEditDiaries;
