import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import debounce from 'lodash/debounce';
import _uniqueId from 'lodash/uniqueId';
import { useApi } from 'hooks/useApi';
import '../../css/auto-complete.css';
import SearchResult from 'models/searchResult';

interface AutoCompleteProps {
  searchType: string;
  onSelected: (result: SearchResult) => void;
  submitted: boolean;
  defaultValue: string;
  minTermLength: number;
  iaGroupId?: number;
  noSpecialClass?: boolean;
  changeCounter?: number;
  disabled?: boolean;
  otherInvalid?: boolean;
  onInvalidValue?: (value: string) => void;
}

const defaultResult: SearchResult = {
  id: null,
  label: '',
  info: undefined,
};

const wrongResult: SearchResult = {
  id: 0,
  label: '',
};

function getItemLabel(value: SearchResult): string | JSX.Element {
  if (value && value.label) {
    return value.info && '0' !== value.info ? (
      <b>{`${value.label} (${value.info})`}</b>
    ) : (
      value.label
    );
  }
  return '';
}

function getParentDivClass(noSpecialClass: boolean): string {
  return noSpecialClass ? '' : 'auto-container';
}

function AutoCompleteInput({
  searchType,
  onSelected,
  defaultValue,
  submitted,
  minTermLength,
  iaGroupId,
  noSpecialClass,
  changeCounter,
  disabled,
  otherInvalid,
  onInvalidValue,
}: AutoCompleteProps) {
  const { t } = useTranslation();
  const { get } = useApi();
  const [isValid, setIsValid] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [display, setDisplay] = useState(false);
  const [options, setOptions] = useState<SearchResult[]>([]);
  const [selectedLabel, setSelectedLabel] = useState(defaultValue);
  const [id] = useState(_uniqueId(`${searchType}-`));
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [counter, setCounter] = useState(changeCounter || 0);

  useEffect(() => {
    setSelectedLabel(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setCounter(changeCounter || 0);
  }, [changeCounter]);

  useEffect(() => {
    if (submitted) {
      const validResult = options.find(
        (result: SearchResult) => result.label.trim() === selectedLabel.trim()
      );
      const badValue =
        searchType === 'ear-tag'
          ? validResult === undefined
          : validResult === undefined && selectedLabel.length > 0;
      setIsInvalid(badValue);
      if (badValue && onInvalidValue) {
        onInvalidValue(selectedLabel);
      }
    }
  }, [onInvalidValue, options, searchType, selectedLabel, submitted]);

  useEffect(() => {
    setIsInvalid(false);
    const search = selectedLabel.trim();
    const validResult = options.find((result: SearchResult) => result.label.trim() === search);
    setIsValid(validResult !== undefined);

    if (validResult) return onSelected(validResult);
    if (validResult === undefined && selectedLabel.length > 0) return onSelected(wrongResult);

    onSelected(defaultResult);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, selectedLabel]);

  const refreshOptions = useCallback(
    (searchTerm: string) => {
      if (searchTerm.length > minTermLength) {
        const groupId = iaGroupId ? `iaGroupId=${iaGroupId}&` : '';
        get<SearchResult[]>(
          `/api/v1/search/${searchType}?${groupId}search=${searchTerm}`,
          (searchResult: SearchResult[]) => setOptions(searchResult)
        );
      } else {
        setOptions([]);
      }
    },
    [get, iaGroupId, minTermLength, searchType]
  );

  useEffect(() => {
    refreshOptions(defaultValue);
  }, [refreshOptions, defaultValue, counter]);

  const deb = debounce((searchTerm: string) => {
    refreshOptions(searchTerm);
  }, 250);

  const setValue = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLabel(event.target.value);
    deb(event.target.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSearchText = useCallback(
    (result: SearchResult) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setSelectedLabel(result.label);
      refreshOptions(result.label);
      setDisplay(false);
      event.preventDefault();
    },
    [refreshOptions]
  );

  const handleClickOutside = (event: any) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target as Node)) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div className={getParentDivClass(noSpecialClass || false)}>
      <div ref={wrapperRef} className="pos-rel">
        <Input
          id={id}
          onFocus={() => setDisplay(true)}
          value={selectedLabel}
          placeholder={`${t(searchType)} ${t('searching')}`}
          valid={isValid}
          invalid={isInvalid || otherInvalid}
          autoComplete="off"
          onChange={setValue}
          disabled={disabled}
        />
        {display && options.length === 0 && (
          <div className="autoContainer">
            <div className="option">
              <span>{t('no-result')}</span>
            </div>
          </div>
        )}
        {display && (
          <div className="autoContainer">
            {options.map((value: SearchResult) => (
              <div
                onClick={updateSearchText(value)}
                className="option"
                key={value.label}
                tabIndex={0}
              >
                <span>{getItemLabel(value)}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default AutoCompleteInput;
