import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import StatWeightTransfer from 'models/grouppages/statWeightTransfer';
import { roundAndRemove } from 'helpers/rabbit';
import '../../css/modal.css';

const WEIGHT_MIN = 10;
const WEIGHT_MAX = 15000;

function isValidWeight(weight: number | undefined): boolean {
  if (weight === undefined) return true;
  return WEIGHT_MIN <= weight && weight <= WEIGHT_MAX;
}

interface EditWeightModalProps {
  isEditing: boolean;
  isOpen: boolean;
  origStatWeight: StatWeightTransfer;
  onSave: (newStatWeight?: StatWeightTransfer) => void;
  toggle: () => void;
}

function EditWeightModal({
  isEditing,
  isOpen,
  origStatWeight,
  toggle,
  onSave,
}: EditWeightModalProps) {
  const { t } = useTranslation();
  const [isAllValid, setIsAllValid] = useState<boolean>(true);
  const [backendMessages, setBackendMessages] = useState<string[] | null>(null);
  const [statWeight, setStatWeight] = useState(origStatWeight);

  useEffect(() => {
    setBackendMessages(null);
    setStatWeight(origStatWeight);
  }, [origStatWeight]);

  const submit = useCallback(() => {
    const isValid = isValidWeight(statWeight.weight);

    if (statWeight.weight && isValid) {
      setBackendMessages(null);
      onSave(statWeight);
      toggle();
    }
    setIsAllValid(isValid);
  }, [onSave, statWeight, toggle]);

  const onWeightValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const next = +event.target.value;
      setStatWeight({ ...statWeight, weight: next });
      if (isValidWeight(next)) {
        setIsAllValid(true);
        setBackendMessages(null);
      }
    },
    [statWeight]
  );

  const doToggle = useCallback(() => {
    setBackendMessages(null);
    setIsAllValid(true);
    toggle();
  }, [toggle]);

  const handleEnterPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        submit();
      }
    },
    [submit]
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const setFocusToInput = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={doToggle}
      contentClassName="modal-content-small-size"
      className="modal-align"
      onOpened={setFocusToInput}
    >
      <ModalHeader toggle={doToggle}>{t(isEditing ? 'sw-edit-title' : 'sw-new-title')}</ModalHeader>
      <ModalBody>
        {/* {saving && <Loader />} */}
        <Container fluid>
          <Row className="" />
        </Container>
        <Form className="form d-flex flex-column justify-content-center">
          <Row className="d-flex justify-content-center align-items-center">
            <Col className="text-right mx-0 px-0">{t('sw-edit-weight')}</Col>

            <Col className="d-flex flex-column align-items-center">
              <Input
                autoFocus
                type="number"
                name={'measured-weight'}
                min={WEIGHT_MIN}
                max={WEIGHT_MAX}
                className="text-right"
                invalid={!isValidWeight(statWeight.weight)}
                defaultValue={statWeight.weight}
                onInput={roundAndRemove}
                onChange={onWeightValueChange}
                innerRef={inputRef}
                onKeyPress={handleEnterPress}
              />
            </Col>

            <Col className="mx-0 px-0">{t('sw-edit-gram')}</Col>
          </Row>

          <Row className="pt-2">
            <Col>
              {!isAllValid && (
                <Alert color="danger" className="text-center">
                  {t('form-has-errors')}
                </Alert>
              )}
              {backendMessages !== null && backendMessages.length > 0 && (
                <Alert color="danger" className="text-center">
                  {backendMessages.map((msg: string) => (
                    <li key={msg}>{t(msg)}</li>
                  ))}
                </Alert>
              )}
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          onClick={submit}
          className="mr-2"
          autoFocus={false}
          disabled={statWeight.weight === undefined}
        >
          {t('save')}
        </Button>
        <Button color="primary" onClick={doToggle} autoFocus={false}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditWeightModal;
