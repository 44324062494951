import React from 'react';
import { Redirect } from 'react-router-dom';
import { getUserRole } from '../helpers/authUtils';
import { UserRole } from 'models/userRole';

function HomePage() {
  const role = getUserRole();
  const fromLogin = window.location.href.endsWith('/login');
  return (
    <>
      {fromLogin && role === UserRole.SUPERADMIN && <Redirect to="/users" />}
      {fromLogin && role === UserRole.OWNER && <Redirect to="/farms" />}
      {fromLogin && role === UserRole.ADMIN && <Redirect to="/ia-groups" />}
    </>
  );
}

export default HomePage;
