import React, { useState, useCallback } from 'react';
import { Form, FormGroup, Label, Input, FormFeedback, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Farm from 'models/farm';
import User from 'models/user';

const RegExp = {
  username: /^[a-zA-Z0-9áÁéÉóÓőŐúÚűŰ._]*$/,
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]*$/,
  phone: /^\+?[0-9]\d*$/,
};

interface UserFormProps {
  user?: User;
  onSave: (user: User) => void;
  onCancel: () => void;
  farms: Farm[];
  canSelectSite: boolean;
}

class FormError {
  email = false;
  username = false;
  password = false;
  phoneNumber = false;
  assignedFarm = false;
}

const defaultUser = {
  email: '',
  username: '',
  password: null,
  phoneNumber: '',
  role: '',
  enabled: true,
  assignedFarm: undefined,
};

const isInputValid = (regExp: RegExp, value: string | null) => value && regExp.test(value);

function UserForm({ user, onSave, onCancel, farms, canSelectSite }: UserFormProps) {
  const [formError, setFormError] = useState<FormError>(new FormError());
  const [newUser, setNewUser] = useState<User>(
    (user && { ...user, password: null, assignedFarm: user.farm ? user.farm.id : null }) ||
      defaultUser
  );
  const { t } = useTranslation();
  const isEdit = !!user?.id;

  const handleUsernameChange = (value: string) => {
    setFormError({ ...formError, username: !isInputValid(RegExp.username, value) });
    setNewUser({ ...newUser, username: value });
  };

  const handleEmailChange = (value: string) => {
    setFormError({ ...formError, email: !isInputValid(RegExp.email, value) });
    setNewUser({ ...newUser, email: value });
  };

  const isPasswordValid = useCallback(
    (password: string | null) =>
      (isEdit && password === null) || isInputValid(RegExp.password, password),
    [isEdit]
  );

  const handlePasswordChange = (value: string) => {
    const password = value || null;
    setFormError({ ...formError, password: !isPasswordValid(password) });
    setNewUser({ ...newUser, password: value });
  };

  const handlePhoneChange = (value: string) => {
    setFormError({ ...formError, phoneNumber: !isInputValid(RegExp.phone, value) });
    setNewUser({ ...newUser, phoneNumber: value });
  };

  const handleFarmChange = (value: number) => {
    setNewUser({ ...newUser, assignedFarm: value });
  };

  const handleEnabledChanged = (enabled: boolean) => {
    setNewUser({ ...newUser, enabled });
  };

  const submit = () => {
    const errors: FormError = {
      username: !isInputValid(RegExp.username, newUser.username),
      email: !isInputValid(RegExp.email, newUser.email),
      password: !isPasswordValid(newUser.password),
      phoneNumber: !isInputValid(RegExp.phone, newUser.phoneNumber),
      assignedFarm: canSelectSite && !newUser.assignedFarm,
    };

    setFormError(errors);

    if (!Object.values(errors).includes(true)) {
      onSave(newUser);
    }
  };

  return (
    <Form className="form">
      <FormGroup>
        <Label>
          {t('username')}
          <span className="text-danger">*</span>
        </Label>
        <Input
          type="text"
          name="username"
          defaultValue={newUser.username}
          invalid={formError.username}
          onChange={(e) => handleUsernameChange(e.target.value)}
        />
        <FormFeedback>{t('username-error')}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>
          {t('email')}
          <span className="text-danger">*</span>
        </Label>
        <Input
          type="email"
          name="email"
          placeholder="Email"
          defaultValue={newUser.email}
          invalid={formError.email}
          onChange={(e) => handleEmailChange(e.target.value)}
        />
        <FormFeedback>{t('email-error')}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>
          {t('password')}
          {!isEdit && <span className="text-danger">*</span>}
        </Label>
        <Input
          type="password"
          name="password"
          invalid={formError.password}
          defaultValue={newUser.password || undefined}
          onChange={(e) => handlePasswordChange(e.target.value)}
        />
        <FormFeedback>{t('password-error')}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>
          {t('phone')}
          <span className="text-danger">*</span>
        </Label>
        <Input
          type="text"
          name="phoneNumber"
          invalid={formError.phoneNumber}
          defaultValue={newUser.phoneNumber}
          onChange={(e) => handlePhoneChange(e.target.value)}
        />
        <FormFeedback>{t('phone-error')}</FormFeedback>
      </FormGroup>
      {canSelectSite && (
        <FormGroup>
          <Label>{t('farm')}</Label>
          <Input
            type="select"
            name="siteSelect"
            defaultValue={newUser.farm ? newUser.farm.id : undefined}
            invalid={formError.assignedFarm}
            onChange={(e) => handleFarmChange(+e.target.value)}
          >
            <option>{t('choose')}</option>
            {farms &&
              farms.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
          </Input>
          <FormFeedback>{t('farm-error')}</FormFeedback>
        </FormGroup>
      )}
      <FormGroup check className="mb-3">
        <Label check>
          <Input
            type="checkbox"
            name="enabled"
            checked={newUser.enabled}
            onChange={(e) => handleEnabledChanged(e.target.checked)}
          />
          {t('active')}
        </Label>
      </FormGroup>
      <Button color="success" onClick={submit} className="mr-3">
        {t('save')}
      </Button>
      <Button color="primary" onClick={onCancel}>
        {t('cancel')}
      </Button>
    </Form>
  );
}

export default UserForm;
