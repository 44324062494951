import React, { useState, useEffect } from 'react';

interface CompleteIAListExportProps {
  onPathChange: (path: string) => void;
}

function CompleteIAListExport({ onPathChange }: CompleteIAListExportProps) {
  const [path] = useState<string>('/api/v1/export/ia-group');

  useEffect(() => {
    onPathChange(path);
  }, [path, onPathChange]);

  return <></>;
}

export default CompleteIAListExport;
