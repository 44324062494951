import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Col, Row } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import PageContainer from '../PageContainer';
import { DownloadExport } from './export/DownloadExport';
import Loader from 'hyper/components/Loader';
import { useApi } from 'hooks/useApi';
import {
  classCellL,
  classCellR,
  classTable,
  convertDateToStr,
  createTd,
  createTh,
  dateStr,
  toStr,
} from 'components/grouppages/gpUtil';
import Datepicker from 'hyper/components/Datepicker';
import MotherGroupSummary from 'models/grouppages/motherGroupSummary';

interface ColumnProp {
  dataField: keyof MotherGroupSummary;
  text: string;
  isPercent?: boolean;
  precision?: number;
  alignItem?: string;
  isDate?: boolean;
}

function createURI(base: string, from: Date | undefined, to: Date | undefined): string {
  let uri = base;
  if (from) uri += `?from=${dateStr(from)}`;
  if (to) uri += `${uri === base ? '?' : '&'}to=${dateStr(to)}`;
  return uri;
}

function getPrecision(columnProp: ColumnProp): number | undefined {
  if (!columnProp) return undefined;
  if (columnProp.isPercent) return 2;
  return columnProp.precision;
}

function MotherSummary() {
  const { t } = useTranslation();
  const { get: load, loading } = useApi();
  const [summaries, setSummaries] = useState<MotherGroupSummary[]>([]);
  const [nodata, setNoData] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(moment().add(-2, 'years').toDate());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const reload = useCallback(() => {
    const uri = createURI('/api/v1/group/mother/summary', startDate, endDate);
    load<MotherGroupSummary[]>(uri, (searchResult: MotherGroupSummary[]) =>
      setSummaries(searchResult)
    );
  }, [endDate, load, startDate]);

  const isLoading = useCallback(() => loading, [loading]);

  useEffect(() => {
    reload();
  }, [endDate, reload, startDate]);

  useEffect(() => {
    setNoData(!summaries || summaries.length < 1);
  }, [summaries]);

  const columns: ColumnProp[] = [
    {
      dataField: 'generatedName',
      text: t('group-sum-generatedName'),
      alignItem: classCellL,
    },
    {
      dataField: 'stableName',
      text: t('group-sum-stableName'),
      alignItem: classCellL,
    },
    {
      dataField: 'startDate',
      text: t('group-sum-startDate'),
      isDate: true,
    },
    {
      dataField: 'endDate',
      text: t('group-sum-endDate'),
      isDate: true,
    },
    {
      dataField: 'weanedKgIA',
      text: t('group-sum-weanedKgIA'),
      precision: 2,
    },
    {
      dataField: 'weanedIA',
      text: t('group-sum-weanedIA'),
      precision: 2,
    },
    {
      dataField: 'populatedSum',
      text: t('group-sum-populatedSum'),
    },
    {
      dataField: 'motherDeadSum',
      text: t('group-sum-motherDeadSum'),
    },
    {
      dataField: 'motherWasteSum',
      text: t('group-sum-motherWasteSum'),
    },
    {
      dataField: 'nextIAVirgin',
      text: t('group-sum-nextIAVirgin'),
    },
    {
      dataField: 'feedAmountSum',
      text: t('group-sum-feedAmountSum'),
    },
    {
      dataField: 'motherPeriodEnd',
      text: t('group-sum-motherPeriodEnd'),
    },
    {
      dataField: 'sucklingPeriodEnd',
      text: t('group-sum-sucklingPeriodEnd'),
    },
    {
      dataField: 'sucklingWeaned',
      text: t('group-sum-sucklingWeaned'),
    },
    {
      dataField: 'dailyWeight5Week',
      text: t('group-sum-dailyWeight5Week'),
    },
    {
      dataField: 'dailyWeight4Week',
      text: t('group-sum-dailyWeight4Week'),
    },
    {
      dataField: 'fcrNest',
      text: t('group-sum-fcrNest'),
      precision: 2,
    },
    {
      dataField: 'pregnantChancePercent',
      text: t('group-sum-pregnantChancePercent'),
      isPercent: true,
    },
    {
      dataField: 'farrowedChancePercent',
      text: t('group-sum-farrowedChancePercent'),
      isPercent: true,
    },
    {
      dataField: 'motherDeadRespPercent',
      text: t('group-sum-motherDeadRespPercent'),
      isPercent: true,
    },
    {
      dataField: 'motherDeadErePercent',
      text: t('group-sum-motherDeadErePercent'),
      isPercent: true,
    },
    {
      dataField: 'motherDeadDissectedOtherPercent',
      text: t('group-sum-motherDeadDissectedOtherPercent'),
      isPercent: true,
    },
    {
      dataField: 'motherWastePercent',
      text: t('group-sum-motherWastePercent'),
      isPercent: true,
    },
    {
      dataField: 'motherDropOutPercent',
      text: t('group-sum-motherDropOutPercent'),
      isPercent: true,
    },
    {
      dataField: 'iaVirginReplacementPercent',
      text: t('group-sum-iaVirginReplacementPercent'),
      isPercent: true,
    },
    {
      dataField: 'sucklingDeadPercentFromAdjusted',
      text: t('group-sum-sucklingDeadPercentFromAdjusted'),
      isPercent: true,
    },
    {
      dataField: 'sucklingDeadPercentFromDead',
      text: t('group-sum-sucklingDeadPercentFromDead'),
      isPercent: true,
    },
    {
      dataField: 'egalizedNest',
      text: t('group-sum-egalizedNest'),
      precision: 2,
    },
    {
      dataField: 'pregnantPalpationAccuracy',
      text: t('group-sum-pregnantPalpationAccuracy'),
      isPercent: true,
    },
    {
      dataField: 'iaSum',
      text: t('group-sum-iaSum'),
    },
    {
      dataField: 'iaVirgin',
      text: t('group-sum-iaVirgin'),
    },
    {
      dataField: 'pregnantSum',
      text: t('group-sum-pregnantSum'),
    },
    {
      dataField: 'farrowedSum',
      text: t('group-sum-farrowedSum'),
    },
    {
      dataField: 'nursingMotherSum',
      text: t('group-sum-nursingMother'),
    },
    {
      dataField: 'sucklingPerNest',
      text: t('group-sum-sucklingPerNest'),
      precision: 2,
    },
    {
      dataField: 'motherDissectedSum',
      text: t('group-sum-motherDissectedSum'),
    },
    {
      dataField: 'motherDeadRespSum',
      text: t('group-sum-motherDeadRespSum'),
    },
    {
      dataField: 'motherDeadEreSum',
      text: t('group-sum-motherDeadEreSum'),
    },
    {
      dataField: 'sucklingAdjusted',
      text: t('group-sum-sucklingAdjusted'),
    },
    {
      dataField: 'sucklingDeadAndExterm',
      text: t('group-sum-sucklingDeadAndExterm'),
    },
  ];

  const getCellContent = (
    row: MotherGroupSummary,
    value: ColumnProp
  ): string | JSX.Element | undefined => {
    if ('generatedName' !== value.dataField) {
      const cellValue = row[value.dataField];
      if (cellValue instanceof Date || value.isDate) return convertDateToStr(cellValue);
      return toStr(row[value.dataField], getPrecision(value));
    }
    const gid = `${row.groupId}`;
    return (
      <a href={`/grouppage-mother/${gid}/edit`} rel="noopener noreferrer" target="_blank">
        <span id={gid}>{row[value.dataField]}</span>
      </a>
    );
  };

  const createDataRow = (row: MotherGroupSummary, idx: number) => {
    let iidx = 0;
    const rowId = idx;
    const result: JSX.Element[] = [];

    _.each(columns, (value) => {
      result.push(
        createTd(
          rowId,
          iidx++,
          getCellContent(row, value), //toStr(row[value.dataField], getPrecision(value)),
          value.alignItem ?? classCellR
        )
      );
    });

    return <tr key={rowId}>{result}</tr>;
  };

  const renderBody = (summaries: MotherGroupSummary[] | null | undefined) => {
    if (!summaries || summaries.length < 1) {
      return <></>;
    }

    const result: JSX.Element[] = [];

    summaries.map((row, idx) => {
      result.push(createDataRow(row, idx));
    });

    return result;
  };

  const createHeader = () => {
    const result: JSX.Element[] = [];

    _.each(columns, (value, idx) => {
      result.push(createTh(value.text, idx));
    });

    return <tr key={'header'}>{result}</tr>;
  };

  return (
    <PageContainer
      title={
        <div className="my-3 clearfix">
          <Row>
            <Col md="auto">
              <h4>{t('mother-summary')}</h4>
            </Col>
            <Col md="auto">
              <Row>
                <Datepicker
                  id="startDate"
                  startDate={startDate}
                  dateFormat="yyyy-MM-dd"
                  onDateChange={setStartDate}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  todayButton={t('date-picker-today')}
                />
                <h4 className="mx-1"> - </h4>
                <Datepicker
                  id="endDate"
                  startDate={endDate}
                  dateFormat="yyyy-MM-dd"
                  onDateChange={setEndDate}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  todayButton={t('date-picker-today')}
                />
              </Row>
            </Col>
            <Col className="d-flex justify-content-end mr-2">
              <DownloadExport
                spreadSheetName={'mother-summary'}
                type={'mother'}
                isSummary
                from={dateStr(startDate)}
                to={dateStr(endDate)}
              />
            </Col>
          </Row>
        </div>
      }
    >
      {isLoading() && <Loader />}
      {!nodata && (
        <div className="react-bootstrap-table table-responsive mt-2 gp-summary-table-div">
          <table id={'summary-mother-groups'} className={`${classTable} table-striped gp-table`}>
            <thead>{createHeader()}</thead>

            <tbody>{renderBody(summaries)}</tbody>
          </table>
        </div>
      )}
      {nodata && <Alert color="warning">{t('no-summary')}</Alert>}
    </PageContainer>
  );
}

export default MotherSummary;
