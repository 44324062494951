import React, { useState, useEffect } from 'react';

interface CageListExportProps {
  onPathChange: (path: string) => void;
}

function CageListExport({ onPathChange }: CageListExportProps) {
  const [selectedStableIds, setSelectedStableIds] = useState<number[]>([]);

  useEffect(() => {
    let path = '/api/v1/export/cage-list';

    if (selectedStableIds.length) {
      path += `?stables=${selectedStableIds.join(',')}`;
    }

    onPathChange(path);
  }, [selectedStableIds, onPathChange]);

  return <div />;
}

export default CageListExport;
