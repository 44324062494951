import { TFunction } from 'i18next';
import React, { useCallback, useState } from 'react';
import { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import PageContainer from '../PageContainer';
import CreateGroupPageModal from 'components/grouppages/createGroupPage';
import ResultInfo from 'components/grouppages/resultInfo';
import ConfirmModal from 'components/shared/confirmModal';
import RemoteTable from 'components/shared/remoteTable';
import { getTableClasses } from 'helpers/tables';
import { useApi } from 'hooks/useApi';
import usePaginatedData, { PageRequest } from 'hooks/usePaginatedData';
import Loader from 'hyper/components/Loader';
import ListYoungTransfer from 'models/grouppages/listYoungTransfer';

const renderActions = (t: TFunction, toggle: (group: ListYoungTransfer) => void) =>
  function getButtons(id: number, group: ListYoungTransfer) {
    return (
      <ButtonGroup>
        <Button
          className="mr-2 py-0"
          size="sm"
          color="primary"
          tag={Link}
          to={`/grouppage-young/${id}/edit`}
        >
          {t('edit-grouppage')}
        </Button>
        <Button
          className="py-0"
          size="sm"
          color="primary"
          onClick={() => {
            toggle(group);
          }}
        >
          {t('delete')}
        </Button>
      </ButtonGroup>
    );
  };

const convertTimestampIntoDate = (timestamp: number) => {
  if (timestamp) {
    return new Date(timestamp).toLocaleDateString();
  }
};

const defaultPageRequest: PageRequest = {
  page: 1,
  pageSize: 10,
  sortFields: ['startDate', 'name'],
  sortOrder: 'desc',
};

function YoungList() {
  const { t } = useTranslation();
  const [pageRequest, setPageRequest] = useState<PageRequest>(defaultPageRequest);
  const [selectedGroup, setSelectedGroup] = useState<ListYoungTransfer>();
  const [isCreateOpen, setCreateOpen] = useState<boolean>(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<boolean>(false);
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
  const { loadMore, data: groups, loading: groupLoading } = usePaginatedData<ListYoungTransfer>(
    '/api/v1/group/young'
  );
  const { sendDelete: deleteGroupPage, loading: deletingGroupPage } = useApi();

  const toggleCreateModal = useCallback(() => {
    setDeleteError(false);
    setDeleteSuccess(false);
    setCreateOpen(!isCreateOpen);
  }, [isCreateOpen]);

  const reload = useCallback(
    (pageRequest: PageRequest) =>
      loadMore({
        page: pageRequest.page,
        pageSize: pageRequest.pageSize,
        sortFields: pageRequest.sortFields,
        sortOrder: pageRequest.sortOrder,
      }),
    [loadMore]
  );
  const onNewGroupSaved = useCallback(() => reload(pageRequest), [pageRequest, reload]);

  const onTableChange = useCallback(
    (type: TableChangeType, newState: TableChangeState<ListYoungTransfer>) => {
      const pageRequest = {
        page: newState.page,
        pageSize: newState.sizePerPage,
        sortFields:
          newState.sortField === 'generatedName' ? ['startDate', 'name'] : [newState.sortField],
        sortOrder: newState.sortOrder,
      };
      setPageRequest({ ...pageRequest });
      loadMore({ ...pageRequest });
    },
    [loadMore]
  );

  const toggleConfirmModal = useCallback(() => setIsConfirmOpen(!isConfirmOpen), [isConfirmOpen]);

  const toggle = useCallback(
    (group: ListYoungTransfer) => {
      setDeleteError(false);
      setDeleteSuccess(false);
      setSelectedGroup(group);
      toggleConfirmModal();
    },
    [toggleConfirmModal]
  );

  const deleteGroup = useCallback(() => {
    if (selectedGroup?.id) {
      deleteGroupPage(
        `/api/v1/group/young/${selectedGroup.id}`,
        () => {
          loadMore({ ...pageRequest });
          setDeleteSuccess(true);
        },
        () => {
          setDeleteError(true);
        }
      );
    }
  }, [deleteGroupPage, loadMore, pageRequest, selectedGroup]);

  const columns = [
    {
      dataField: 'generatedName',
      isDummyField: true,
      text: t('grouppage-name'),
      sort: true,
      formatter: (cell: string, row: ListYoungTransfer) => row.generatedName,
    },
    {
      dataField: 'startDate',
      text: t('grouppage-start-young'),
      sort: false,
      formatter: (cell: number) => convertTimestampIntoDate(cell),
    },
    {
      dataField: 'stableName',
      text: t('grouppage-stable'),
      sort: true,
      // formatter: (cell: string, row: ListYoungTransfer) => row.stableName,
    },
    {
      dataField: 'actualAge',
      text: t('grouppage-age-young'),
      sort: true,
    },
    {
      dataField: 'originName',
      text: t('grouppage-origin'),
      sort: false,
    },
    {
      dataField: 'actualNumPS',
      text: t('grouppage-numps'),
      sort: false,
    },
    {
      dataField: 'actualNumGP',
      text: t('grouppage-numgp'),
      sort: false,
    },
    {
      dataField: 'endDate',
      text: t('grouppage-closed-at'),
      sort: false,
      formatter: (cell: number) => convertTimestampIntoDate(cell),
    },
    {
      dataField: 'id',
      text: t('operations'),
      sort: false,
      formatter: renderActions(t, toggle),
    },
  ];

  const isLoading = useCallback(() => groupLoading || deletingGroupPage, [
    groupLoading,
    deletingGroupPage,
  ]);

  return (
    <PageContainer
      title={
        <div className="mb-3 mt-3 clearfix">
          <h4 className="float-left">{t('grouppage-young-list-title')}</h4>
          <Button color="primary" className="ml-3" onClick={toggleCreateModal}>
            <i className="mdi mdi-plus-circle mr-2" />
            {t('new-grouppage')}
          </Button>
          <Button color="primary" className="ml-3" tag={Link} to={'/grouppage-young-summary'}>
            {t('summary-button')}
          </Button>
        </div>
      }
    >
      {isLoading() && <Loader />}
      <CreateGroupPageModal
        onSave={onNewGroupSaved}
        isOpen={isCreateOpen}
        toggle={toggleCreateModal}
        baseDetails={{ startDate: new Date(), name: '', age: 28 }}
        isYoung
      />
      <ConfirmModal
        title={`${selectedGroup?.generatedName} ${t('delete-grouppage-young')}`}
        bodyText={t('delete-grouppage-text')}
        isOpen={isConfirmOpen}
        toggle={toggleConfirmModal}
        onSave={deleteGroup}
      />
      <ResultInfo
        success={deleteSuccess}
        successMsg={`${selectedGroup?.generatedName} ${t('delete-grouppage-success')}`}
        error={deleteError}
        errorMsg={`${selectedGroup?.generatedName} ${t('delete-grouppage-failed')}`}
      />
      <RemoteTable
        wrapperClasses={getTableClasses('younglist-table')}
        onTableChange={onTableChange}
        data={groups}
        columns={columns}
        sizePerPage={100}
        defaultSorted={{ dataField: 'generatedName', order: 'desc' }}
        keyField="id"
      />
    </PageContainer>
  );
}

export default YoungList;
