import React, { useCallback } from 'react';
import {
  Card,
  CardHeader,
  NavLink,
  Collapse,
  CardBody,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Farm from '../../models/farm';
import Stable from '../../models/stable';

interface FarmTreeProps {
  farms: Farm[];
  selectedFarm: Farm | null;
  selectedStable: Stable | null;
  onStableSelected: (stable: Stable & { farmId: number }) => void;
  onFarmSelected: (farm: Farm) => void;
}

function FarmTree({
  farms,
  selectedFarm,
  onFarmSelected,
  selectedStable,
  onStableSelected,
}: FarmTreeProps) {
  const isOpen = useCallback(
    (farm: Farm) => {
      if (selectedStable && farm.stables) {
        return !!farm.stables.find(({ id }) => selectedStable.id === id);
      } else {
        return !!selectedFarm && selectedFarm.id === farm.id;
      }
    },
    [selectedStable, selectedFarm]
  );

  const { t } = useTranslation();

  return (
    <div className="custom-accordion">
      {farms.map((farm) => (
        <Card className="mb-0" key={farm.id}>
          <CardHeader>
            <h5 className="m-0">
              <NavLink
                className="custom-accordion-title d-block pt-2 pb-2"
                id={`group${farm.id}`}
                onClick={() => onFarmSelected(farm)}
                href="#"
              >
                {farm.name}
                <span className="float-right">
                  <i className="mdi mdi-chevron-right font-18 accordion-arrow" />
                </span>
              </NavLink>
            </h5>
          </CardHeader>
          <Collapse isOpen={isOpen(farm)} toggler={`#group${farm.id}`}>
            <CardBody>
              <ListGroup>
                <h4 className="header-title mb-3">{t('stables')}:</h4>
                {farm.stables?.map((stable) => (
                  <ListGroupItem
                    key={stable.id}
                    className={classNames({ active: selectedStable?.id === stable.id })}
                    onClick={() => onStableSelected({ ...stable, farmId: farm.id })}
                    tag="a"
                    href="#"
                    action
                  >
                    {stable.name}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </CardBody>
          </Collapse>
        </Card>
      ))}
    </div>
  );
}

export default FarmTree;
