import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form, Col, FormGroup, Input, Label, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import CreatePairings from '../../models/createPairings';
import PairingList from './pairingList';
import IAGroupForm from 'components/iaGroup/IAGroupForm';
import MotherSelector from 'components/iaGroup/motherSelector';
import Mother from 'models/mother';
import IAGroup from 'models/IAGroup';
import ImportModal from 'components/stock/importModal';
import AutoCompleteInput from 'components/shared/autoCompleteInput';
import SearchResult from 'models/searchResult';
import IAPairing from 'models/IAPairing';
import { checkPostAdditionLimit } from 'pages/ListIAGoups';
import Loader from 'hyper/components/Loader';
import { getLoggedInUser } from 'helpers/authUtils';
import { useDownloadSpreadsheet } from 'hooks/useDownloadFile';

const MIN_TERM_LENGTH = -1;

function compareIAPairings(latest: IAPairing, current: IAPairing): IAPairing {
  return (latest.iaCoverId || 0) > current.iaCoverId ? latest : current;
}

function isDisabledForModify(iaGroup: IAGroup, cancel = false): boolean {
  return iaGroup && (iaGroup.active ? !cancel : false);
}

function isPostAddition(iaGroup: IAGroup): boolean {
  const isAdding = iaGroup.postAdditionCovers ? iaGroup.postAdditionCovers : false;
  return iaGroup && (isAdding || checkPostAdditionLimit(iaGroup));
}

function isDisabled(iaGroup: IAGroup, isEdit: boolean, isImport = false): boolean {
  const isAdding = isPostAddition(iaGroup);
  if (isImport && iaGroup.active) return true;
  return !(iaGroup && (!iaGroup.active || isAdding) && isEdit);
}

function isDisabledForClose(iaGroup: IAGroup, isEdit: boolean): boolean {
  return !(
    iaGroup &&
    (!iaGroup.active || isPostAddition(iaGroup)) &&
    isEdit &&
    iaGroup.pairings &&
    iaGroup.pairings.length > 0
  );
}

function isIAGroupCreated(iaGroup: IAGroup): boolean {
  return iaGroup && iaGroup.formattedName.length > 0 && '-' !== iaGroup.formattedName;
}

function getModifyTitle(iaGroup: IAGroup, t: TFunction): string {
  return isIAGroupCreated(iaGroup) ? t('modify-ia') : t('start-ia');
}

function getBackTitle(iaGroup: IAGroup, t: TFunction): string {
  return isIAGroupCreated(iaGroup) ? t('go-back') : t('cancel');
}

function getCloseTitle(iaGroup: IAGroup, t: TFunction): string {
  return iaGroup && iaGroup.active && isPostAddition(iaGroup)
    ? t('closed-adding-close')
    : t('close-pairing-list');
}

const fileName = (name: string, iaGroupName: string) =>
  `${name} - ${iaGroupName} - ${
    getLoggedInUser().username
  } - ${`${new Date().toLocaleDateString()} -${new Date().toLocaleTimeString()}`}.xlsx`;

interface IAGroupCreatorProps {
  mothers: Mother[];
  pedigrees: string[];
  onSave: () => void;
  onClose: () => void;
  onCancel: () => void;
  onPairingsAdded: (createPairings: CreatePairings) => void;
  onPairingRemoved: (motherId: number, earTag: string) => void;
  onDataChanged: (name?: string, startDate?: Date) => void;
  onImported: () => void;
  iaGroup: IAGroup;
  isEdit: boolean;
}

const defaultProps: Partial<IAGroupCreatorProps> = {
  mothers: [],
  pedigrees: [],
  onClose: () => undefined,
  onPairingsAdded: () => undefined,
  onPairingRemoved: () => undefined,
  onDataChanged: () => undefined,
  onImported: () => undefined,
};

function IAGroupCreator({
  mothers,
  pedigrees,
  iaGroup,
  isEdit,
  onSave,
  onClose,
  onCancel,
  onPairingsAdded,
  onPairingRemoved,
  onDataChanged,
  onImported,
}: IAGroupCreatorProps) {
  const [selectedMothers, setSelectedMothers] = useState<Mother[]>([]);
  const [selectedPedigree, setSelectedPedigree] = useState<string | undefined>(undefined);
  const [selectedSperm, setSelectedSperm] = useState<number>(0);
  const [selectedSpermLabel, setSelectedSpermLabel] = useState<string>('');
  const [lastSperm, setLastSperm] = useState<string>('');
  const [avaliableMothers, setAvaliableMothers] = useState<Mother[]>(mothers);
  const { t } = useTranslation();
  const [isImportModalOpen, setImportModalOpen] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [changeCounter, setChangeCounter] = useState<number>(0);
  const incChangeCounter = useCallback(() => setChangeCounter(changeCounter + 1), [changeCounter]);
  const [download, { loading: downloading }] = useDownloadSpreadsheet();

  const toggleImportModal = useCallback(() => {
    const needRefresh = isImportModalOpen;
    setImportModalOpen(!isImportModalOpen);
    if (needRefresh) {
      incChangeCounter();
    }
  }, [incChangeCounter, isImportModalOpen]);

  useEffect(() => {
    if (iaGroup.pairings && iaGroup.pairings.length) {
      const latest = iaGroup.pairings.reduce(compareIAPairings);
      if (latest) {
        latest.spermId && setSelectedSperm(latest.spermId);
        if (latest.spermLabel) {
          setSelectedSpermLabel(latest.spermLabel);
          setLastSperm(latest.spermLabel);
        }
      }
    }
  }, [iaGroup.pairings]);

  const onMotherGroupChange = useCallback(
    (value: string) => {
      onDataChanged(value);
    },
    [onDataChanged]
  );

  const onStartDateChange = useCallback(
    (value: Date) => {
      onDataChanged(undefined, value);
    },
    [onDataChanged]
  );

  const onPairingDeleted = useCallback(
    (value: string) => {
      const removedMother = mothers.find(({ earTag }) => earTag === value);
      if (removedMother) {
        onPairingRemoved(removedMother.id, value);
        incChangeCounter();
      }
    },
    [incChangeCounter, mothers, onPairingRemoved]
  );

  useEffect(() => {
    const pairings = iaGroup.pairings || [];
    setAvaliableMothers(mothers.filter(({ earTag }) => !pairings.find((p) => p.earTag === earTag)));
  }, [iaGroup, mothers]);

  const addPairings = useCallback(() => {
    setSubmitted(true);
    if (!selectedSperm) return;
    const allMothers = selectedMothers.map(({ id }) => id);
    if (!allMothers.length) return;
    onPairingsAdded({
      spermId: selectedSperm,
      spermLabel: selectedSpermLabel,
      pedigree: selectedPedigree || null,
      motherIds: allMothers,
    });
    setSelectedMothers([]);
    incChangeCounter();
  }, [
    selectedSperm,
    selectedMothers,
    onPairingsAdded,
    selectedSpermLabel,
    selectedPedigree,
    incChangeCounter,
  ]);

  const onPedigreeChange = useCallback((value: string) => {
    setSelectedPedigree(value || undefined);
  }, []);

  const onSpermSelected = useCallback((sperm: SearchResult) => {
    setSubmitted(false);
    setSelectedSperm(sperm?.id ? sperm.id : 0);
    setSelectedSpermLabel(sperm?.label ? sperm.label : '');
  }, []);

  const onIaClosed = useCallback(() => {
    onClose();
  }, [onClose]);

  const onIaImport = useCallback(() => {
    if (!isImportModalOpen) {
      onImported();
    }
  }, [isImportModalOpen, onImported]);

  const downloadPairingExport = useCallback(
    (iaGroup: IAGroup) => {
      download(
        `/api/v1/export/ia-group/${iaGroup.id}/pairing-list`,
        fileName(t('pairing-list-export'), iaGroup.formattedName)
      );
    },
    [download, t]
  );

  const handleDownloadPairingExport = useCallback(() => {
    downloadPairingExport(iaGroup);
  }, [downloadPairingExport, iaGroup]);

  const isDisabledAddPairings = useCallback((): boolean => {
    if (selectedMothers.length < 1) return true;
    return !selectedSperm || isDisabled(iaGroup, isEdit);
  }, [iaGroup, isEdit, selectedMothers, selectedSperm]);

  const onInvalidValue = useCallback(() => setSelectedSperm(0), []);

  return (
    <>
      {downloading && <Loader />}
      <Container fluid>
        <Row>
          <Col sm={2}>
            <IAGroupForm
              startDate={iaGroup.startDate}
              onMotherGroupChange={onMotherGroupChange}
              onStartDateChange={onStartDateChange}
              motherGroup={iaGroup.name}
              disabled={isDisabledForModify(iaGroup)}
            />
            <Button
              className="mr-2"
              color="primary"
              onClick={onSave}
              disabled={isDisabledForModify(iaGroup)}
            >
              {getModifyTitle(iaGroup, t)}
            </Button>
            <Button
              color="primary"
              onClick={onCancel}
              disabled={isDisabledForModify(iaGroup, true)}
            >
              {getBackTitle(iaGroup, t)}
            </Button>
          </Col>
          <Col sm={3}>
            <h4>{t('add-mothers')}</h4>
            <div style={{ maxHeight: 600, overflow: 'auto' }}>
              <MotherSelector
                mothers={avaliableMothers}
                selectedMothers={selectedMothers}
                onSelectionChange={setSelectedMothers}
              />
            </div>
          </Col>
          <Col sm={2}>
            <Form className="form mt-4">
              <FormGroup>
                <Label>{t('pedigree')}:</Label>
                <Input
                  type="select"
                  name="pedigreeSelect"
                  disabled={isDisabled(iaGroup, isEdit)}
                  onChange={(e) => onPedigreeChange(e.target.value)}
                >
                  <option value={''}>{t('not-pedigree')}</option>
                  {pedigrees &&
                    pedigrees.map((item) => (
                      <option key={`pedigree-${item}`} value={item}>
                        {item}
                      </option>
                    ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>{t('sperm')}:</Label>
                <AutoCompleteInput
                  key={'sperm'}
                  onSelected={onSpermSelected}
                  searchType={'sperm'}
                  defaultValue={lastSperm}
                  submitted={submitted}
                  minTermLength={MIN_TERM_LENGTH}
                  iaGroupId={iaGroup.id}
                  noSpecialClass
                  changeCounter={changeCounter}
                  disabled={isDisabled(iaGroup, isEdit)}
                  onInvalidValue={onInvalidValue}
                />
              </FormGroup>
              <Button
                color="primary"
                disabled={isDisabledAddPairings()}
                onClick={addPairings}
                block
              >
                {t('add-pairings')}
              </Button>
            </Form>
          </Col>
          <Col sm={5}>
            <Row>
              <Col>
                <h4>{t('pairing-list')}</h4>
              </Col>
              <Col>
                {!isDisabled(iaGroup, isEdit, true) && (
                  <Button color="primary" className="float-center" onClick={toggleImportModal}>
                    {t('pairing-import')}
                  </Button>
                )}
              </Col>
              <Col>
                {isEdit && !iaGroup.active && (
                  <Button
                    color="primary"
                    className="float-center"
                    disabled={iaGroup.pairings.length === 0}
                    onClick={handleDownloadPairingExport}
                  >
                    {t('pairing-export')}
                  </Button>
                )}
              </Col>
              <Col>
                <Button
                  color="primary"
                  className="float-right mb-2"
                  disabled={isDisabledForClose(iaGroup, isEdit)}
                  onClick={onIaClosed}
                >
                  {getCloseTitle(iaGroup, t)}
                </Button>
              </Col>
            </Row>
            <div style={{ maxHeight: 600, overflow: 'auto' }}>
              <PairingList pairings={iaGroup.pairings || []} onPairingRemoved={onPairingDeleted} />
            </div>
          </Col>
        </Row>
        <ImportModal
          isOpen={isImportModalOpen}
          toggle={toggleImportModal}
          modalType="IAGROUPS"
          groupId={iaGroup.id}
          onUploadClosed={onIaImport}
        />
      </Container>
    </>
  );
}

IAGroupCreator.defaultProps = defaultProps;

export default IAGroupCreator;
