import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { ModeBarDefaultButtons } from 'plotly.js';
import { toStr } from './gpUtil';
import DiaryWeightsStatsTransfer from 'models/grouppages/diaryWeightsStatsTransfer';
import Chart from 'components/shared/chart';

const HISTOGRAM_STEP = 50;

const classTd = 'text-right px-1';
const weightConfig = {
  responsive: true,
  modeBarButtonsToRemove: [
    'zoom2d',
    'pan2d',
    'select2d',
    'lasso2d',
    'zoomIn2d',
    'zoomOut2d',
    'autoScale2d',
    'resetScale2d',
    'hoverClosestCartesian',
    'hoverCompareCartesian',
    'zoom3d',
    'pan3d',
    'resetCameraDefault3d',
    'resetCameraLastSave3d',
    'hoverClosest3d',
    'orbitRotation',
    'tableRotation',
    'zoomInGeo',
    'zoomOutGeo',
    'resetGeo',
    'hoverClosestGeo',
    'toImage',
    'sendDataToCloud',
    'hoverClosestGl2d',
    'hoverClosestPie',
    'toggleHover',
    'resetViews',
    'toggleSpikelines',
    'resetViewMapbox',
  ] as ModeBarDefaultButtons[],
  displaylogo: false,
};

// Calculates start and end value of x axis (based on data minimum and maximum),
// and sets the width of columns exactly to 50 (because of 50g steps),
// for displaying histogram data by 50g intervalls.
const calcMinMaxBins2 = (
  transfer: DiaryWeightsStatsTransfer | undefined
): { dataMin: number; dataMax: number; xbins: number } => {
  const xbins = HISTOGRAM_STEP;
  let dataMin = transfer?.minimum || 0;
  let dataMax = (transfer?.maximum || 0) + 1;
  dataMin = ((dataMin / xbins) >> 0) * xbins;
  dataMax = (((dataMax / xbins) >> 0) + 1) * xbins;
  return { dataMin, dataMax, xbins };
};

interface DiaryWeightsStatsProps {
  transfer: DiaryWeightsStatsTransfer | undefined;
}

function DiaryWeightsStats({ transfer }: DiaryWeightsStatsProps) {
  const { t } = useTranslation();

  const { dataMin, dataMax, xbins } = calcMinMaxBins2(transfer);

  return (
    <>
      <Row className="justify-content-around">
        <Col>
          <h4>{t('sw-statistics')}</h4>
        </Col>
      </Row>
      <Row className="justify-content-around">
        <Col xs="auto">
          <Row>
            <Col className="border ml-2">
              <Row>
                <Col>
                  <div className="react-bootstrap-table table-responsive mt-1 d-flex justify-content-center">
                    <table>
                      <tbody>
                        <tr>
                          <td className={classTd}>{`${t('sw-all-num')} : `}</td>
                          <td className={classTd}>
                            <b>{transfer?.allNum}</b>
                          </td>
                        </tr>
                        <tr>
                          <td className={classTd}>{`${t('sw-all-time')} : `}</td>
                          <td className={classTd}>
                            <b>{transfer?.allTime || '-'}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="react-bootstrap-table table-responsive d-flex justify-content-center">
                    <table>
                      <tbody>
                        <tr>
                          <td className={classTd}>{`${t('sw-avg')} : `}</td>
                          <td className={classTd}>
                            <b>{toStr(transfer?.avg, 1)}</b>
                          </td>
                          <td style={{ paddingLeft: 5, paddingRight: 10 }} />
                        </tr>
                        <tr>
                          <td className={classTd}>{`${t('sw-median')} : `}</td>
                          <td className={classTd}>
                            <b>{toStr(transfer?.median, 1)}</b>
                          </td>
                          <td style={{ paddingLeft: 5, paddingRight: 10 }} />
                        </tr>
                        <tr>
                          <td className={classTd}>{`${t('sw-min')} : `}</td>
                          <td className={classTd}>
                            <b>{toStr(transfer?.minimum, 1)}</b>
                          </td>
                          <td style={{ paddingLeft: 5, paddingRight: 10 }} />
                        </tr>
                        <tr>
                          <td className={classTd}>{`${t('sw-max')} : `}</td>
                          <td className={classTd}>
                            <b>{toStr(transfer?.maximum, 1)}</b>
                          </td>
                          <td style={{ paddingLeft: 5, paddingRight: 10 }} />
                        </tr>
                        <tr>
                          <td className={classTd}>{`${t('sw-sd')} : `}</td>
                          <td className={classTd}>
                            <b>{`${toStr(transfer?.sd, 1)} `}</b>
                          </td>
                          <td>{` (${toStr(transfer?.sdPercent, 2)}%)`}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs="auto">
          <Chart
            id={'sw-chart-histogram'}
            data={[
              {
                x: transfer?.weights,
                type: 'histogram',
                autobinx: false,
                xbins: {
                  start: dataMin,
                  end: dataMax,
                  size: xbins,
                },
              },
            ]}
            layout={{
              height: 202,
              width: 350,
              showlegend: false,
              bargap: 0.1,
              margin: { t: 10, l: 30, b: 20, r: 10 },
            }}
            config={weightConfig}
          />
        </Col>
        <Col xs="auto">
          <Chart
            id={'sw-chart-box'}
            data={[
              {
                y: transfer?.weights,
                type: 'box',
                name: t('sw-weight'),
              },
            ]}
            layout={{
              height: 202,
              width: 350,
              showlegend: false,
              margin: { t: 10, l: 60, b: 20, r: 10 },
            }}
            config={weightConfig}
          />
        </Col>
      </Row>
    </>
  );
}

export default DiaryWeightsStats;
