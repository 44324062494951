import { TFunction } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import {
  classCellBg,
  classCellLBg,
  classCommon,
  classSideButton,
  classTable,
  createBaseTd,
  createTh,
  dateToStr,
  getSlaughteringCreateDate,
  toStr,
} from './gpUtil';
import ResultInfo from './resultInfo';
import EditFatteningSlaughteringModal from './modal/fattening/editFatteningSlaughteringModal';
import EditFatteningTransfer from 'models/grouppages/editFatteningTransfer';
import Loader from 'hyper/components/Loader';
import { useApi } from 'hooks/useApi';
import ConfirmModal from 'components/shared/confirmModal';
import SlaughteringTransfer from 'models/grouppages/slaughteringTransfer';

const classTableDiv = 'react-bootstrap-table table-responsive align-top';
const classSmallTable = `${classTable} mb-1 gp-table`;
const classCell = `bg-light text-dark text-right ${classCommon}`;

interface CondemnationSums {
  sumNum: number;
  sumWeight: number;
}

interface SlaughteringSums {
  num: number;
  weight: number;
  abscessNum: number;
  abscessWeight: number;
  polyserNum: number;
  polyserWeight: number;
  anorexiaNum: number;
  anorexiaWeight: number;
  bloatNum: number;
  bloatWeight: number;
  purChestNum: number;
  purChestWeight: number;
  brokenNum: number;
  brokenWeight: number;
  pneumoniaNum: number;
  pneumoniaWeight: number;
  trDeadNum: number;
  trDeadWeight: number;
  condemnationSums: CondemnationSums[];
  sumNum: number;
  sumWeight: number;
}

function sum(values: (number | undefined)[] | number | undefined): number {
  let sum = 0;
  if (!values) return sum;
  if (typeof values === 'number') return values;
  for (const value of values) {
    if (value) sum += value;
  }
  return sum;
}

function calculateNumWeightSum(slaughtering: SlaughteringTransfer): CondemnationSums {
  return {
    sumNum: sum([
      slaughtering.abscessNum,
      slaughtering.polyserNum,
      slaughtering.anorexiaNum,
      slaughtering.bloatNum,
      slaughtering.purChestNum,
      slaughtering.brokenNum,
      slaughtering.pneumoniaNum,
      slaughtering.trDeadNum,
    ]),
    sumWeight: sum([
      slaughtering.abscessWeight,
      slaughtering.polyserWeight,
      slaughtering.anorexiaWeight,
      slaughtering.bloatWeight,
      slaughtering.purChestWeight,
      slaughtering.brokenWeight,
      slaughtering.pneumoniaWeight,
      slaughtering.trDeadWeight,
    ]),
  };
}

function calculateSmallNumWeight(result: SlaughteringSums, slaughtering: SlaughteringTransfer) {
  result.abscessNum += slaughtering.abscessNum || 0;
  result.polyserNum += slaughtering.polyserNum || 0;
  result.anorexiaNum += slaughtering.anorexiaNum || 0;
  result.bloatNum += slaughtering.bloatNum || 0;
  result.purChestNum += slaughtering.purChestNum || 0;
  result.brokenNum += slaughtering.brokenNum || 0;
  result.pneumoniaNum += slaughtering.pneumoniaNum || 0;
  result.trDeadNum += slaughtering.trDeadNum || 0;

  result.abscessWeight += slaughtering.abscessWeight || 0;
  result.polyserWeight += slaughtering.polyserWeight || 0;
  result.anorexiaWeight += slaughtering.anorexiaWeight || 0;
  result.bloatWeight += slaughtering.bloatWeight || 0;
  result.purChestWeight += slaughtering.purChestWeight || 0;
  result.brokenWeight += slaughtering.brokenWeight || 0;
  result.pneumoniaWeight += slaughtering.pneumoniaWeight || 0;
  result.trDeadWeight += slaughtering.trDeadWeight || 0;
}

function calculateSlaughteringSums(
  data: EditFatteningTransfer | undefined | null
): SlaughteringSums {
  const result = {
    num: 0,
    weight: 0,
    abscessNum: 0,
    abscessWeight: 0,
    polyserNum: 0,
    polyserWeight: 0,
    anorexiaNum: 0,
    anorexiaWeight: 0,
    bloatNum: 0,
    bloatWeight: 0,
    purChestNum: 0,
    purChestWeight: 0,
    brokenNum: 0,
    brokenWeight: 0,
    pneumoniaNum: 0,
    pneumoniaWeight: 0,
    trDeadNum: 0,
    trDeadWeight: 0,
    condemnationSums: [],
    sumNum: 0,
    sumWeight: 0,
  } as SlaughteringSums;
  if (!data || !data.slaughterings) {
    return result;
  }
  for (let i = 0; i < data.slaughterings.length; i++) {
    const slaughtering = data.slaughterings[i];

    result.num += slaughtering.num || 0;
    result.weight += slaughtering.weight || 0;

    calculateSmallNumWeight(result, slaughtering);

    const { sumNum, sumWeight } = calculateNumWeightSum(slaughtering);
    result.sumNum += sumNum;
    result.sumWeight += sumWeight;
    result.condemnationSums.push({ sumNum, sumWeight });
  }
  return result;
}

interface OriginData {
  origins: SlaughteringTransfer[];
  avgWeights: (string | undefined)[];
  sum: string;
  sumWeight: string;
  avgSumWeight: string;
}

const createSlaughterRows = (
  groupId: number,
  slaughters: SlaughteringTransfer[],
  slaughteringSums: SlaughteringSums,
  toggleEdit: (slaughter: SlaughteringTransfer) => void,
  toggleDelete: (slaughter: SlaughteringTransfer) => void
): JSX.Element[] => {
  const allTr: JSX.Element[] = [];
  const condemnationSumsNum =
    slaughteringSums && slaughteringSums.condemnationSums
      ? slaughteringSums.condemnationSums.length
      : -1;
  for (let i = 0; i < slaughters.length; i++) {
    const slaughter = slaughters[i];
    const condemnationSums =
      i < condemnationSumsNum ? slaughteringSums.condemnationSums[i] : ({} as CondemnationSums);
    const iid = `${slaughter.id}`;
    const uri = `/grouppage-fattening/${groupId}/slaughtering/${iid}/cutting`;
    const when = getSlaughteringCreateDate(slaughter);
    const dateCell = (
      <span id={iid}>
        <a href={uri} rel="noopener noreferrer" target="_blank">
          {when ? dateToStr(when) : '???'}
        </a>
      </span>
    );
    allTr.push(
      <tr key={`${slaughter.id}-a`}>
        {createBaseTd(dateCell, classCellLBg)}
        {createBaseTd(slaughter.lot, classCellLBg)}
        {createBaseTd(slaughter.itemNo, classCellLBg)}
        {createBaseTd(slaughter.item, classCellLBg)}
        {createBaseTd(toStr(slaughter.num), classCellBg)}
        {createBaseTd(toStr(slaughter.weight), classCellBg)}

        {createBaseTd(toStr(slaughter.abscessNum), classCellBg)}
        {createBaseTd(toStr(slaughter.abscessWeight), classCellBg)}
        {createBaseTd(toStr(slaughter.polyserNum), classCellBg)}
        {createBaseTd(toStr(slaughter.polyserWeight), classCellBg)}
        {createBaseTd(toStr(slaughter.anorexiaNum), classCellBg)}
        {createBaseTd(toStr(slaughter.anorexiaWeight), classCellBg)}
        {createBaseTd(toStr(slaughter.bloatNum), classCellBg)}
        {createBaseTd(toStr(slaughter.bloatWeight), classCellBg)}
        {createBaseTd(toStr(slaughter.purChestNum), classCellBg)}
        {createBaseTd(toStr(slaughter.purChestWeight), classCellBg)}
        {createBaseTd(toStr(slaughter.brokenNum), classCellBg)}
        {createBaseTd(toStr(slaughter.brokenWeight), classCellBg)}
        {createBaseTd(toStr(slaughter.pneumoniaNum), classCellBg)}
        {createBaseTd(toStr(slaughter.pneumoniaWeight), classCellBg)}
        {createBaseTd(toStr(slaughter.trDeadNum), classCellBg)}
        {createBaseTd(toStr(slaughter.trDeadWeight), classCellBg)}

        {createBaseTd(toStr(condemnationSums.sumNum), classCell)}
        {createBaseTd(toStr(condemnationSums.sumWeight), classCell)}

        <td className="text-center align-middle m-0 p-0">
          <Button
            color="primary"
            className={`${classSideButton}`}
            onClick={() => toggleEdit(slaughter)}
          >
            <i className="mdi mdi-pencil p-1" />
          </Button>
          <Button
            color="primary"
            className={`${classSideButton} ml-1`}
            onClick={() => toggleDelete(slaughter)}
          >
            <i className="mdi mdi-delete p-1" />
          </Button>
        </td>
      </tr>
    );
  }
  return allTr;
};

function getSlaughteringInfo(
  slaughtering: SlaughteringTransfer | undefined,
  db: string,
  kg: string,
  t: TFunction,
  withDate = false
): string {
  const result = [] as string[];
  if (slaughtering) {
    if (withDate && slaughtering.createDate) {
      result.push(`${t('slaughter-date')}: ${dateToStr(getSlaughteringCreateDate(slaughtering))}`);
    }
    if (slaughtering.lot) {
      result.push(`${t('slaughter-lot')}: ${slaughtering.lot}`);
    }
    if (slaughtering.itemNo) {
      result.push(`${t('slaughter-item-no')}: ${slaughtering.itemNo}`);
    }
    if (slaughtering.item) {
      result.push(`${t('slaughter-item')}: ${slaughtering.item}`);
    }
    result.push(`${slaughtering.num} ${db}`);
    result.push(`${slaughtering.weight} ${kg}`);
  }
  return `${result.join(', ')}`;
}

interface GainWeightRowData {
  weekOfAge: string;
  gPerWeek: string;
  gPerDayStacked: string;
  gPerDayWeekly: string;
}
interface GainWeightData {
  rows: GainWeightRowData[];
  sumGPerWeek: string;
  sumGPerDayStacked: string;
  sumGPerDayWeekly: string;
}

const calcSlaughteringEndDate = (transfer: EditFatteningTransfer | null | undefined): Date => {
  const now = new Date();
  if (!transfer?.diary.diaries) return now;
  const lastDate = new Date(
    transfer.endDate
      ? transfer.endDate
      : transfer.diary.diaries[transfer.diary.diaries.length - 1].actualDate
  );
  return lastDate < now ? lastDate : now;
};

const calcActualNum = (transfer: EditFatteningTransfer | null | undefined): number => {
  const originalNum =
    transfer && transfer.origins
      ? transfer.origins.reduce((sum, { num }) => {
          if (num) sum += num;
          return sum;
        }, 0)
      : 0;
  return (
    (originalNum || 0) - (transfer?.sumValues.deadSum || 0) - (transfer?.sumValues.soldSum || 0)
  );
};

interface FatteningSlaughteringTableProps {
  transfer: EditFatteningTransfer | null | undefined;
  refresh: () => void;
}

function FatteningSlaughteringTable({ transfer, refresh }: FatteningSlaughteringTableProps) {
  const { t } = useTranslation();
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [selectedSlaughtering, setSelectedOrigin] = useState<SlaughteringTransfer>();
  const [actualNum, setActualNum] = useState<number>();
  const [deleteError, setDeleteError] = useState<string>();
  const [deleteSuccess, setDeleteSuccess] = useState<string>();
  const { sendDelete: deleteOrigin, loading: deletingOrigin } = useApi();

  const db = t('slaughter-num');
  const kg = t('slaughter-kg');

  const [slaughteringSums, setSlaughteringSums] = useState<SlaughteringSums>(
    calculateSlaughteringSums(undefined)
  );
  const [slaughterings, setSlaughterings] = useState<SlaughteringTransfer[]>(
    transfer?.slaughterings || []
  );

  const [isSlaughteringModalOpen, setIsSlaughteringModalOpen] = useState<boolean>(false);
  const toggleSlaughteringModal = useCallback(
    () => setIsSlaughteringModalOpen(!isSlaughteringModalOpen),
    [isSlaughteringModalOpen]
  );
  const [groupEndDate, setGroupEndDate] = useState<Date>(calcSlaughteringEndDate(transfer));

  const refreshPage = useCallback(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    setSlaughteringSums(calculateSlaughteringSums(transfer));
    setSlaughterings(transfer?.slaughterings || []);
    setGroupEndDate(calcSlaughteringEndDate(transfer));
    setActualNum(calcActualNum(transfer));
  }, [transfer]);

  const toggleConfirmModal = useCallback(() => setIsConfirmOpen(!isConfirmOpen), [isConfirmOpen]);

  const toggleDelete = useCallback(
    (slaughtering: SlaughteringTransfer) => {
      setDeleteError(undefined);
      setDeleteSuccess(undefined);
      setSelectedOrigin(slaughtering);
      toggleConfirmModal();
    },
    [toggleConfirmModal]
  );

  const toggleEdit = useCallback(
    (slaughtering: SlaughteringTransfer) => {
      setDeleteError(undefined);
      setDeleteSuccess(undefined);
      setSelectedOrigin(slaughtering);
      toggleSlaughteringModal();
    },
    [toggleSlaughteringModal]
  );

  const doDeleteOrigin = useCallback(() => {
    if (selectedSlaughtering?.id) {
      deleteOrigin(
        `/api/v1/group/fattening/${transfer?.id}/slaughtering/${selectedSlaughtering.id}`,
        () => {
          let info = getSlaughteringInfo(selectedSlaughtering, db, kg, t, true);
          info = info ? `(${info})` : '';
          setDeleteSuccess(t('slaughter-delete-success', { info }));
          refreshPage();
        },
        () => {
          setDeleteError(t('slaughter-delete-failed'));
        }
      );
    }
  }, [deleteOrigin, refreshPage, selectedSlaughtering, db, kg, t, transfer]);

  const createDeleteBody = useCallback(() => {
    let info = getSlaughteringInfo(selectedSlaughtering, db, kg, t);
    info = info ? `(${info})` : '';
    const date = dateToStr(getSlaughteringCreateDate(selectedSlaughtering));
    return `${t('slaughter-delete-message', { date, info })}`;
  }, [selectedSlaughtering, db, kg, t]);

  const defaulSlaughtering = useCallback(
    () =>
      ({
        groupId: transfer?.id,
        num: 0,
        weight: 0,
        abscessNum: 0,
        abscessWeight: 0,
        polyserNum: 0,
        polyserWeight: 0,
        anorexiaNum: 0,
        anorexiaWeight: 0,
        bloatNum: 0,
        bloatWeight: 0,
        purChestNum: 0,
        purChestWeight: 0,
        brokenNum: 0,
        brokenWeight: 0,
        pneumoniaNum: 0,
        pneumoniaWeight: 0,
        trDeadNum: 0,
        trDeadWeight: 0,
      } as SlaughteringTransfer),
    [transfer]
  );

  const getSlaughtering = useCallback(() => {
    let result = defaulSlaughtering();
    if (selectedSlaughtering) {
      result = {
        ...selectedSlaughtering,
      };
    }
    return result;
  }, [defaulSlaughtering, selectedSlaughtering]);

  return (
    <>
      {deletingOrigin && <Loader />}

      <ConfirmModal
        title={`${t('slaughter-delete-title')}`}
        bodyText={createDeleteBody()}
        isOpen={isConfirmOpen}
        toggle={toggleConfirmModal}
        onSave={doDeleteOrigin}
      />
      <ResultInfo
        success={!!deleteSuccess}
        successMsg={deleteSuccess}
        error={!!deleteError}
        errorMsg={deleteError}
      />
      {
        <EditFatteningSlaughteringModal
          groupId={transfer?.id || 0}
          startDate={transfer?.diary.diaries[0].actualDate}
          endDate={groupEndDate}
          actualNum={actualNum}
          isOpen={isSlaughteringModalOpen}
          toggle={toggleSlaughteringModal}
          onSave={refresh}
          originalSlaughtering={getSlaughtering()}
        />
      }
      <Row className="justify-content-around">
        <Col>
          <div className={classTableDiv}>
            <table className={classSmallTable} id="slaughtering-data-table">
              <thead>
                <tr>
                  {createTh(t('slaughter-date'), 0, '', { rowSpan: 3 })}
                  {createTh(t('slaughter-slaughtering'), 1, '', { colSpan: 5 })}
                  {createTh(t('slaughter-condemnation'), 2, '', { colSpan: 18 })}
                  <th className="text-center align-middle" key={'th-3'} rowSpan={3}>
                    <Button
                      id={'new-slaughter-row'}
                      color="primary"
                      className={`${classSideButton}`}
                      onClick={() => toggleEdit(defaulSlaughtering())}
                    >
                      <i className="mdi mdi-plus p-1" />
                    </Button>
                  </th>
                </tr>
                <tr>
                  {createTh(t('slaughter-lot'), 4, '', { rowSpan: 2 })}
                  {createTh(t('slaughter-item-no'), 5, '', { rowSpan: 2 })}
                  {createTh(t('slaughter-item'), 6, '', { rowSpan: 2 })}
                  {createTh(t('slaughter-number'), 7, '', { rowSpan: 2 })}
                  {createTh(t('slaughter-weight-kg'), 8, '', { rowSpan: 2 })}

                  {createTh(t('slaughter-abscess'), 9, t('slaughter-abscess-tt'), { colSpan: 2 })}
                  {createTh(t('slaughter-polyser'), 10, t('slaughter-polyser-tt'), { colSpan: 2 })}
                  {createTh(t('slaughter-anorexia'), 11, t('slaughter-anorexia-tt'), {
                    colSpan: 2,
                  })}
                  {createTh(t('slaughter-bloat'), 12, t('slaughter-bloat-tt'), { colSpan: 2 })}
                  {createTh(t('slaughter-purchest'), 13, t('slaughter-purchest-tt'), {
                    colSpan: 2,
                  })}
                  {createTh(t('slaughter-broken'), 14, t('slaughter-broken-tt'), { colSpan: 2 })}
                  {createTh(t('slaughter-pneumonia'), 15, t('slaughter-pneumonia-tt'), {
                    colSpan: 2,
                  })}
                  {createTh(t('slaughter-trdead'), 16, t('slaughter-trdead-tt'), { colSpan: 2 })}
                  {createTh(t('slaughter-sum'), 17, '', { colSpan: 2 })}
                </tr>
                <tr>
                  {createTh(db, 18)}
                  {createTh(kg, 19)}
                  {createTh(db, 21)}
                  {createTh(kg, 22)}
                  {createTh(db, 23)}
                  {createTh(kg, 24)}
                  {createTh(db, 25)}
                  {createTh(kg, 26)}
                  {createTh(db, 27)}
                  {createTh(kg, 28)}
                  {createTh(db, 29)}
                  {createTh(kg, 30)}
                  {createTh(db, 31)}
                  {createTh(kg, 32)}
                  {createTh(db, 33)}
                  {createTh(kg, 34)}
                  {createTh(db, 35)}
                  {createTh(kg, 36)}
                </tr>
              </thead>
              <tbody>
                <>
                  {createSlaughterRows(
                    transfer?.id || 0,
                    slaughterings,
                    slaughteringSums,
                    toggleEdit,
                    toggleDelete
                  )}
                </>
                <tr>
                  {createBaseTd(`${t('slaughter-sum')} : `, classCell, { colSpan: 4 })}
                  {createBaseTd(toStr(slaughteringSums.num), classCell)}
                  {createBaseTd(toStr(slaughteringSums.weight), classCell)}
                  {createBaseTd(toStr(slaughteringSums.abscessNum), classCell)}
                  {createBaseTd(toStr(slaughteringSums.abscessWeight), classCell)}
                  {createBaseTd(toStr(slaughteringSums.polyserNum), classCell)}
                  {createBaseTd(toStr(slaughteringSums.polyserWeight), classCell)}
                  {createBaseTd(toStr(slaughteringSums.anorexiaNum), classCell)}
                  {createBaseTd(toStr(slaughteringSums.anorexiaWeight), classCell)}
                  {createBaseTd(toStr(slaughteringSums.bloatNum), classCell)}
                  {createBaseTd(toStr(slaughteringSums.bloatWeight), classCell)}
                  {createBaseTd(toStr(slaughteringSums.purChestNum), classCell)}
                  {createBaseTd(toStr(slaughteringSums.purChestWeight), classCell)}
                  {createBaseTd(toStr(slaughteringSums.brokenNum), classCell)}
                  {createBaseTd(toStr(slaughteringSums.brokenWeight), classCell)}
                  {createBaseTd(toStr(slaughteringSums.pneumoniaNum), classCell)}
                  {createBaseTd(toStr(slaughteringSums.pneumoniaWeight), classCell)}
                  {createBaseTd(toStr(slaughteringSums.trDeadNum), classCell)}
                  {createBaseTd(toStr(slaughteringSums.trDeadWeight), classCell)}
                  {createBaseTd(toStr(slaughteringSums.sumNum), classCell)}
                  {createBaseTd(toStr(slaughteringSums.sumWeight), classCell)}
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default FatteningSlaughteringTable;
