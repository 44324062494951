import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { getLoggedInUser } from 'helpers/authUtils';
import { useDownloadSpreadsheet } from 'hooks/useDownloadFile';
import Spinner from 'hyper/components/Spinner';

interface DownloadExportProps {
  spreadSheetName: string;
  isSummary: boolean;
  type: string;
  id?: number | undefined;
  generatedName?: string;
  from?: string;
  to?: string;
}

export const DownloadExport: React.FC<DownloadExportProps> = ({
  id,
  spreadSheetName,
  isSummary,
  type,
  generatedName,
  from,
  to,
}: DownloadExportProps): JSX.Element => {
  const { t } = useTranslation();
  const [download, { loading: downloading }] = useDownloadSpreadsheet();

  const URI = useCallback(() => {
    if (!isSummary) return `/api/v1/export/group/${type}/${id}`;

    return `/api/v1/export/group/${type}/summary?from=${from}&to=${to}`;
  }, [from, id, isSummary, to, type]);

  const fileName = useCallback(() => {
    const firstPart = generatedName
      ? `${t(spreadSheetName)} - ${generatedName}`
      : `${t(spreadSheetName)}`;
    return `${firstPart} - ${
      getLoggedInUser().username
    } - ${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}.xlsx`;
  }, [generatedName, spreadSheetName, t]);

  const downloadMotherGroupPageExport = useCallback(() => {
    download(URI(), fileName());
  }, [URI, download, fileName]);

  return (
    <Button color="primary" className="mx-2" onClick={downloadMotherGroupPageExport}>
      {t('export')}
      {downloading && <Spinner className="spinner-border-sm ml-1" tag="span" color="white" />}
    </Button>
  );
};
