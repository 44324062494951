import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';

interface ModalErrorProps {
  isInputValid: boolean;
  serverErrors: string[] | null;
}

function ModalError({ isInputValid, serverErrors }: ModalErrorProps) {
  const { t } = useTranslation();

  return (
    <>
      {!isInputValid && (
        <Alert color="danger" className="text-center">
          {t('form-has-errors')}
        </Alert>
      )}
      {serverErrors && serverErrors.length === 1 && (
        <Alert color="danger" className="text-center">
          {serverErrors.map((msg: string) => `${t(msg)}`)}
        </Alert>
      )}
      {serverErrors && serverErrors.length > 1 && (
        <Alert color="danger" className="text-center">
          {serverErrors.map((msg: string) => (
            <li key={msg}>{t(msg)}</li>
          ))}
        </Alert>
      )}
    </>
  );
}

export default ModalError;
