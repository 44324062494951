import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Form, Label, Input, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';

interface ConfirmModalProps {
  toggle: () => void;
  onSave: () => void;
  isOpen: boolean;
  title: string;
  bodyText: string;
  extraContent?: JSX.Element;
}

function ConfirmModal({
  title,
  bodyText,
  onSave,
  isOpen,
  toggle,
  extraContent,
}: ConfirmModalProps) {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const { t } = useTranslation();

  const submit = useCallback(() => {
    onSave();
    toggle();
  }, [onSave, toggle]);

  const handleCheck = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setIsChecked(false);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <Form className="form d-flex flex-column justify-content-center text-center p-3">
          <div>
            <h5>{bodyText}</h5>
          </div>
          <Label check className="cursor-pointer">
            <Input
              className="cursor-pointer position-relative mr-1"
              type="checkbox"
              onChange={handleCheck}
            />
            {t('confirm')}
          </Label>
          {extraContent && <>{extraContent}</>}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={submit} className="mr-2" disabled={!isChecked}>
          {t('delete')}
        </Button>
        <Button color="primary" onClick={toggle}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmModal;
