// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n, { getLanguage, DEFAULT_LANGUAGE } from 'i18n';

import DatePicker, {
    registerLocale,
    setDefaultLocale
} from 'react-datepicker';
import hu from 'date-fns/locale/hu';
import enGB from 'date-fns/locale/en-GB';
import fr from 'date-fns/locale/fr';
import es from 'date-fns/locale/es';

const localeToLanguage = new Map();
localeToLanguage.set('hu', hu);
localeToLanguage.set('en', enGB);
localeToLanguage.set('fr', fr);
localeToLanguage.set('es', es);

type DatepickerInputProps = {
    onClick?: PropTypes.func,
    value?: string,
};
class DatepickerInput extends Component<DatepickerInputProps> {
    constructor(props) {
        super(props);

        this.onDateValueChange = this.onDateValueChange.bind(this);
    }

    onDateValueChange = () => {
        console.log('date value changed');
    };
    render() {
        return (
            <input
                type="text"
                className="form-control date"
                id="dash-daterange"
                onClick={this.props.onClick}
                value={this.props.value}
                onChange={this.onDateValueChange}
            />
        );
    }
}

type DatepickerInputWithAddonProps = {
    onClick?: PropTypes.func,
    value?: string,
};

class DatepickerInputWithAddon extends Component<DatepickerInputWithAddonProps> {
    render() {
        return (
            <div className="input-group">
                <input
                    type="text"
                    className="form-control form-control-light"
                    id="dash-daterange"
                    onClick={this.props.onClick}
                    value={this.props.value}
                    readOnly
                />

                <div className="input-group-append">
                    <span className="input-group-text bg-primary border-primary text-white">
                        <i className="mdi mdi-calendar-range font-13"></i>
                    </span>
                </div>
            </div>
        );
    }
}

type HyperDatepickerProps = {
    hideAddon?: boolean,
    startDate?: Date
};

type HyperDatepickerState = {
    startDate?: Date,
};

class HyperDatepicker extends Component<HyperDatepickerProps, HyperDatepickerState> {
    constructor(props: HyperDatepickerProps) {
        super(props);
        registerLocale(DEFAULT_LANGUAGE, localeToLanguage.get(DEFAULT_LANGUAGE));
        setDefaultLocale(DEFAULT_LANGUAGE);
        this.lang = getLanguage();
        if (this.lang !== DEFAULT_LANGUAGE) {
            registerLocale(this.lang, localeToLanguage.get(this.lang));
        }
        this.state = {
            startDate: this.props.startDate? this.props.startDate: new Date()
        };
        this.handleDateSelectChange = this.handleDateSelectChange.bind(this);
    }

    handleDateSelectChange = (date: any) => {
        this.props.onDateChange(date);
        this.setState({
            startDate: date,
        });
    };

    render() {
        const input = (this.props.hideAddon || false) === true ? <DatepickerInput /> : <DatepickerInputWithAddon />;

        return (
            <DatePicker
                customInput={input}
                selected={this.state.startDate}
                onChange={this.handleDateSelectChange}
                locale={this.lang}
                {...this.props}
            />
        );
    }
}

export default HyperDatepicker;
