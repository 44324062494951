import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import SelectInput from './selectInput';
import { useApiPost } from 'hooks/useApi';
import Loader from 'hyper/components/Loader';
import { HealthCondition } from 'models/healthCondition';
import RrabbitsBulkActionTransfer from 'models/rabbitsBulkActionTransfer';
import { RabbitStatus } from 'models/rabbitStatus';
import '../../../css/modal.css';
import i18n from 'i18n';

interface EditRabbitStatusProps {
  onSave: () => void;
  isOpen: boolean;
  toggle: () => void;
  motherIds: number[];
}

function EditRabbitStatus({ isOpen, toggle, onSave, motherIds }: EditRabbitStatusProps) {
  const defaultTransfer: RrabbitsBulkActionTransfer = {
    rabbitIds: motherIds,
    healthCondition: undefined,
    rabbitStatus: undefined,
  };

  const { t } = useTranslation();
  const [isAllValid, setIsAllValid] = useState<boolean>(true);
  const [backendMessages, setBackendMessages] = useState<string[] | null>(null);
  const [transfer, setTransfer] = useState<RrabbitsBulkActionTransfer>(defaultTransfer);
  const [saveMotherDetails, { loading: saving }] = useApiPost<{ messages: string[] }>(
    '/api/v1/rabbits/bulk-action',
    (response) => {
      if (response.messages && response.messages.length > 0) {
        setBackendMessages(response.messages);
      } else {
        setBackendMessages(null);
        onSave();
        toggle();
      }
    }
  );

  useEffect(() => {
    if (isOpen) {
      setTransfer(defaultTransfer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setIsAllValid(true);
    setBackendMessages(null);
  }, [transfer]);

  const submit = useCallback(() => {
    const isWrong = transfer.healthCondition === undefined && transfer.rabbitStatus === undefined;

    if (!isWrong) {
      setBackendMessages(null);
      saveMotherDetails(transfer);
    }
    setIsAllValid(!isWrong);
  }, [transfer, saveMotherDetails]);

  const onChange = useCallback(
    (key: keyof RrabbitsBulkActionTransfer) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setTransfer((prevValue) => ({
        ...prevValue,
        [key]: newValue.length < 1 ? undefined : newValue,
      }));
    },
    []
  );

  return (
    motherIds && (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        contentClassName="modal-content-small-size"
        className="modal-align"
      >
        <ModalHeader toggle={toggle}>{`${t('rabbit-status-edit-title')}`}</ModalHeader>
        <ModalBody>
          {saving && <Loader />}
          <Container fluid>
            <Row className="">
              <Col className="d-flex justify-content-center pt-2">
                <b>{i18n.t('rabbit-status-edit-msg', { count: transfer.rabbitIds.length })}</b>
              </Col>
            </Row>
            <Row className="pt-3">
              <Col className="d-flex justify-content-center">
                <SelectInput
                  name="healthConditionSelect"
                  label={'new-health-condition'}
                  value={transfer.healthCondition || ''}
                  onChange={onChange('healthCondition')}
                  options={Object.values(HealthCondition)}
                  defaultOption="no-change"
                  labelSm="5"
                />
              </Col>
            </Row>
            <Row className="pt-1">
              <Col className="d-flex justify-content-center">
                <SelectInput
                  name="statusSelect"
                  label={'new-rabbit-status'}
                  value={transfer.rabbitStatus || ''}
                  onChange={onChange('rabbitStatus')}
                  options={Object.values(RabbitStatus)}
                  defaultOption="no-change"
                  labelSm="5"
                />
              </Col>
            </Row>
            <Row className="pt-2">
              <Col>
                {!isAllValid && (
                  <Alert color="danger" className="text-center">
                    {t('rabbit-status-edit-error')}
                  </Alert>
                )}
                {backendMessages && backendMessages.length > 0 && (
                  <Alert color="danger" className="text-center">
                    {backendMessages.map((msg: string) => (
                      <li key={msg}>{t(msg)}</li>
                    ))}
                  </Alert>
                )}
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={submit} className="mr-2">
            {t('save')}
          </Button>
          <Button color="primary" onClick={toggle}>
            {t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  );
}

export default EditRabbitStatus;
