import i18n, { DEFAULT_LANGUAGE, getLanguage } from 'i18n';

export function getTableClasses(...others: string[]): string {
  const result = 'react-bootstrap-table table-responsive';
  if (others.length > 0) {
    return `${result} ${others.join(' ')}`;
  }
  return result;
}

export function getRowCountLocaleString(itemNum: number | undefined | null): string {
  let count = '0';
  if (itemNum) {
    const lang = getLanguage() || DEFAULT_LANGUAGE;
    count = Number(itemNum).toLocaleString(lang);
  }
  return i18n.t('item-num-in-table', {
    itemNum: count,
  });
}
