import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import FarmDetails from '../components/farmManagement/farmDetails';
import FarmTree from '../components/farmManagement/farmTree';
import StableDetails from '../components/farmManagement/stableDetails';
import { hasRole } from '../helpers/authUtils';
import { useApiPost, useSendApiGet } from '../hooks/useApi';
import Farm from '../models/farm';
import Stable from '../models/stable';
import PageContainer from './PageContainer';
import Loader from 'hyper/components/Loader';
import FarmFormModal from 'components/farmManagement/farmFormModal';
import { UserRole } from 'models/userRole';

function FarmManagement() {
  const [farms, setFarms] = useState<Farm[]>();
  const [selectedFarm, setSelectedFarm] = useState<Farm | null>(null);
  const [selectedStable, setSelectedStable] = useState<(Stable & { farmId: number }) | null>(null);

  const onFarmsLoaded = useCallback(
    (newFarms: Farm[]) => {
      setFarms(newFarms);
      if (selectedFarm && newFarms) {
        setSelectedFarm(newFarms.find(({ id }) => selectedFarm.id === id) || null);
      } else if (newFarms && newFarms.length && !selectedStable) {
        setSelectedFarm(newFarms[0]);
      }
    },
    [selectedFarm, setSelectedFarm, selectedStable]
  );

  const [loadFarms, { loading }] = useSendApiGet<Farm[]>('/api/v1/farms', onFarmsLoaded);
  const [saveFarm, { loading: savingFarm }] = useApiPost('/api/v1/farm', loadFarms);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    loadFarms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedStable) {
      setSelectedFarm(null);
    }
  }, [selectedStable]);

  useEffect(() => {
    if (selectedFarm) {
      setSelectedStable(null);
    }
  }, [selectedFarm]);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <PageContainer title="management-farms">
      <FarmFormModal isOpen={isOpen} toggle={toggle} onSave={saveFarm} />
      {(loading || savingFarm) && <Loader />}
      <Row>
        <Col>
          {hasRole(UserRole.OWNER) && (
            <Button color="primary" className="mb-3" onClick={() => toggle()}>
              {t('new-farm')}
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <FarmTree
            onFarmSelected={setSelectedFarm}
            onStableSelected={setSelectedStable}
            selectedFarm={selectedFarm}
            selectedStable={selectedStable}
            farms={farms || []}
          />
        </Col>
        <Col lg={9}>
          {selectedFarm && <FarmDetails farm={selectedFarm} onChange={loadFarms} />}
          {selectedStable && (
            <StableDetails
              stable={selectedStable}
              onStableSelected={setSelectedStable}
              onChange={loadFarms}
            />
          )}
        </Col>
      </Row>
    </PageContainer>
  );
}

export default FarmManagement;
