import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import React, { PropsWithChildren } from 'react';
import PageTitle from 'hyper/components/PageTitle';

function PageContainer({
  title,
  children,
}: PropsWithChildren<{ title: string | React.ReactElement }>) {
  const { t } = useTranslation();

  return (
    <Card className="mt-3">
      <CardBody className="pt-0">
        {typeof title === 'string' && <PageTitle title={t(title)} />}
        {typeof title !== 'string' && title}
        {children}
      </CardBody>
    </Card>
  );
}

export default PageContainer;
