import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Input, Row } from 'reactstrap';
import moment from 'moment';
import Page from '../models/page';
import PageContainer from './PageContainer';
import IAGroup from 'models/IAGroup';
import Loader from 'hyper/components/Loader';
import { useApi, useApiGet } from 'hooks/useApi';
import { getTableClasses } from 'helpers/tables';
import SimpleTable from 'components/shared/simpleTable';
import EqualizationDetails from 'models/equalizationDetails';
import IAGroupEqualization from 'components/shared/iaGroupEqualization';

const EQUALIZATION_LIMIT = 25;

function convertTimestampToStr(timestamp: number) {
  if (timestamp) {
    return moment(timestamp).format('YYYY.MM.DD HH:mm:ss');
  }
}

function Equalization() {
  const { t } = useTranslation();
  const { data: iaGroups, loading: loadingIAGroups } = useApiGet<Page<IAGroup>>(
    '/api/v1/ia-groups'
  );
  const { get, loading } = useApi();
  const [equalizationDetails, setEqualizationDetails] = useState<EqualizationDetails[]>([]);
  const [selectedIAGroupId, setSelectedIAGroupId] = useState<number>();
  const [counter, setCounter] = useState<number>(0);

  const incCounter = useCallback(() => setCounter(counter + 1), [counter]);

  const refreshEqualizations = useCallback(() => {
    get<EqualizationDetails[]>(
      `/api/v1/ia-group/${selectedIAGroupId}/equalizations`,
      (result: EqualizationDetails[]) => {
        setEqualizationDetails(result);
      }
    );
  }, [get, selectedIAGroupId]);

  const onSaved = useCallback(() => incCounter(), [incCounter]);

  const isLoading = useCallback(() => loading || loadingIAGroups, [loading, loadingIAGroups]);

  useEffect(() => {
    selectedIAGroupId && refreshEqualizations();
  }, [refreshEqualizations, selectedIAGroupId, counter]);

  const columns = [
    {
      dataField: 'cageFrom',
      text: t('cage-from'),
      sort: false,
    },
    {
      dataField: 'cageTo',
      text: t('cage-to'),
      sort: false,
    },
    {
      dataField: 'adjusted',
      text: t('equalized'),
      sort: false,
    },
    {
      dataField: 'createdDate',
      text: t('created-at'),
      sort: false,
      formatter: (cell: unknown, row: EqualizationDetails) =>
        row && convertTimestampToStr(row.createdDate),
    },
  ];

  return (
    <PageContainer
      title={
        <Row className="my-3">
          <Col xs={4}>
            <Row>
              <div>
                <Input
                  className="ml-2"
                  type="select"
                  name="siteSelect"
                  value={selectedIAGroupId}
                  onChange={(e) => {
                    setSelectedIAGroupId(+e.target.value);
                  }}
                >
                  {!selectedIAGroupId && <option>{t('choose')}</option>}
                  {iaGroups &&
                    iaGroups.content.map((iaGroup: IAGroup) => (
                      <option key={iaGroup.id} value={iaGroup.id}>
                        {iaGroup.formattedName}
                      </option>
                    ))}
                </Input>
              </div>
            </Row>
          </Col>
          <Col xs={4}>
            <div className="text-center">
              <h2>{t('equalization')}</h2>
            </div>
          </Col>
        </Row>
      }
    >
      {isLoading() && <Loader />}
      {!isLoading() && selectedIAGroupId && (
        <>
          <IAGroupEqualization
            iaGroupId={selectedIAGroupId}
            adjusted={8}
            adjustLimit={EQUALIZATION_LIMIT}
            autoFillCageTo
            onSaved={onSaved}
          />
          <div className="text-center">
            <h4>{t('old-equalization')}</h4>
          </div>
          <SimpleTable
            wrapperClasses={getTableClasses('insemination-table')}
            data={equalizationDetails}
            keyField={'id'}
            columns={columns}
            condensed
          />
        </>
      )}
    </PageContainer>
  );
}

export default Equalization;
