import { useTranslation } from 'react-i18next';
import React, { useCallback, useState } from 'react';
import { Row, Col, Container, Button } from 'reactstrap';
import { TFunction } from 'i18next';
import MotherDetails from '../../models/motherDetails';
import { RabbitStatus } from '../../models/rabbitStatus';
import InfoBox from '../shared/infoBox';
import EditRabbitDetails from './edit/editRabbitDetails';
import CalculatedDetails from 'models/calculatedDetails';
import { defaultOrValue, formatDate } from 'helpers/rabbit';
import { Unit } from 'models/unit';

function defaultWeight(value: number, t: TFunction) {
  const weight = defaultOrValue(value);
  return weight === '-' ? weight : `${weight} ${t(Unit.weight)}`;
}

interface RabbitDetailsProps {
  data: MotherDetails & CalculatedDetails;
  onDetailsModified: () => void;
}

function RabbitDetails({ data, onDetailsModified }: RabbitDetailsProps) {
  const { t } = useTranslation();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const toggleModal = useCallback(() => {
    setIsEditModalOpen(!isEditModalOpen);
  }, [isEditModalOpen]);

  const onSave = useCallback(() => {
    onDetailsModified();
  }, [onDetailsModified]);

  const infoBox1 = {
    'ear-tag': defaultOrValue(data.earTag),
    tattoo: defaultOrValue(data.tattoo),
    father: defaultOrValue(data.sperm),
    mother: defaultOrValue(data.mother),
    'family-genetics': defaultOrValue(data.familyGenetics),
    breed: defaultOrValue(data.breed, t, true),
  };

  const infoBox2 = {
    origin: defaultOrValue(data.origin, t, true),
    nanny: defaultOrValue(data.nannyCount),
    grid: defaultOrValue(data.gridCount),
    'breast-number': defaultOrValue(data.breastNumber),
    'actual-cage': defaultOrValue(data.cage),
    'health-condition': defaultOrValue(data.healthCondition, t, true),
    status: data.status === RabbitStatus.DEAD ? t('dead') : t(data.status),
  };

  const infoBox3 = {
    'birth-date': formatDate(data.birthDate),
    'bred-from': formatDate(data.firstIaDate),
    'wasted-at': formatDate(data.wastedAt),
    'last-ia-date': formatDate(data.lastIaDate),
    buck: defaultOrValue(data.buck),
    pregnant: data.pregnantInLastIa ? '+' : '-',
  };

  const infoBox4 = {
    day32: defaultWeight(data.weight32d, t),
    day66: defaultWeight(data.weight66d, t),
    week18: defaultWeight(data.weight18w, t),
    '3rd-yean': defaultWeight(data.weightThirdYean, t),
    'statistical-weight': defaultWeight(data.statisticalWeight, t),
  };

  return (
    <Container fluid>
      <Row className="mb-2">
        <EditRabbitDetails
          toggle={toggleModal}
          isOpen={isEditModalOpen}
          onSave={onSave}
          motherDetails={data}
        />
        <Button type="button" color="success" onClick={toggleModal}>
          <i className="mdi mdi-pencil mr-1" /> {t('edit-stock')}
        </Button>
      </Row>
      <Row className="justify-content-around mb-3">
        <Col>
          <InfoBox data={infoBox1} />
        </Col>
        <Col>
          <InfoBox data={infoBox2} />
        </Col>
        <Col>
          <InfoBox data={infoBox3} />
        </Col>
        <Col>
          <InfoBox title={t('weight')} data={infoBox4} />
        </Col>
      </Row>
    </Container>
  );
}

export default RabbitDetails;
