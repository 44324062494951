import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useApiGet } from '../../hooks/useApi';
import Page from '../../models/page';
import IAGroup from '../../models/IAGroup';
import Loader from 'hyper/components/Loader';

interface TattooFormExportProps {
  onPathChange: (path: string) => void;
  onGroupNameChange: (name: string) => void;
}

function TattooFormExport({ onPathChange, onGroupNameChange }: TattooFormExportProps) {
  const { t } = useTranslation();
  const { data: iaGroups, loading } = useApiGet<Page<IAGroup>>('/api/v1/ia-groups');
  const [selectedIaGroupId, setSelectedIaGroupId] = useState<number>();
  const [selectedIaGroupName, setSelectedIaGroupName] = useState<string>();

  useEffect(() => {
    if (selectedIaGroupId) {
      onPathChange(`/api/v1/export/ia-group/${selectedIaGroupId}/tattoo-form`);
      onGroupNameChange(selectedIaGroupName || '');
    }
  }, [selectedIaGroupId, onPathChange, onGroupNameChange, selectedIaGroupName]);

  const handleSelectedIaGroup = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const groupId = +event.target.value;
      setSelectedIaGroupId(groupId);
      setSelectedIaGroupName(
        iaGroups?.content.find((group) => group.id === groupId)?.formattedName
      );
      event.preventDefault();
    },
    [iaGroups]
  );

  return (
    <Row className="d-flex justify-content-center align-items-center">
      <Col xs="auto">
        {loading && <Loader />}
        <Row>
          <Label className="mr-sm-2 mt-2">{t('ia-group')}</Label>
        </Row>
        <Row>
          <Input type="select" name="iaSelect" onChange={handleSelectedIaGroup}>
            {!selectedIaGroupId && <option>{t('choose')}</option>}
            {iaGroups &&
              iaGroups.content.map((iaGroup: IAGroup) => (
                <option key={iaGroup.id} value={iaGroup.id}>
                  {iaGroup.formattedName}
                </option>
              ))}
          </Input>
        </Row>
      </Col>
    </Row>
  );
}

export default TattooFormExport;
