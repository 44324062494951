import React, { ChangeEvent, createElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Input, Row } from 'reactstrap';
import CageListExport from '../components/export/cageListExport';
import FarrowingExport from '../components/export/farrowingExport';
import PageContainer from './PageContainer';
import Loader from 'hyper/components/Loader';
import { useDownloadSpreadsheet } from 'hooks/useDownloadFile';
import { getLoggedInUser } from 'helpers/authUtils';
import CompleteIAListExport from 'components/export/completeIAListExport';
import CullingListExport from 'components/export/cullingListExport';
import IndexOfMothersExport from 'components/export/indexOfMothersExport';
import SeparationListExport from 'components/export/separationListExport';
import WeighingExport from 'components/export/weighingExport';
import TattooFormExport from 'components/export/tattooFormExport';

interface ExportType {
  name: string;
  description: string;
  component: React.StatelessComponent<{
    onPathChange: (path: string) => void;
    onGroupNameChange: (groupName: string) => void;
  }>;
  needIaGroupName?: boolean;
  needUsername?: boolean;
}

const exportTypes = [
  {
    name: 'farrowing-export',
    description: 'farrowing-export-description',
    component: FarrowingExport,
    needUsername: true,
  },
  {
    name: 'cage-list-export',
    description: 'cage-list-export-description',
    component: CageListExport,
    needUsername: true,
  },
  {
    name: 'complete-ia-list-export',
    description: 'complete-ia-list-export-description',
    component: CompleteIAListExport,
    needUsername: true,
  },
  {
    name: 'culling-list-export',
    description: 'culling-list-export-description',
    component: CullingListExport,
    needUsername: true,
  },
  {
    name: 'index-of-mothers-export',
    description: 'index-of-mothers-export-description',
    component: IndexOfMothersExport,
    needUsername: true,
  },
  // {
  //   name: 'separation-list-export',
  //   description: 'separation-list-export-description',
  //   component: SeparationListExport,
  //   needUsername: true,
  // },
  // {
  //   name: '21th-day-weighing-list',
  //   description: '21th-day-weighing-list-description',
  //   component: WeighingExport,
  //   needUsername: true,
  // },
  {
    name: 'tattoo-form',
    description: 'tattoo-form-description',
    component: TattooFormExport,
    needIaGroupName: true,
    needUsername: true,
  },
];

function Statistic() {
  const { t } = useTranslation();
  const [selectedExportType, setSelectedExportType] = useState<ExportType>();
  const [url, setUrl] = useState<string>();
  const [groupName, setGroupName] = useState<string>();

  const fileName = useCallback(() => {
    const iaGroupName =
      selectedExportType && selectedExportType.needIaGroupName ? ` - ${groupName}` : '';
    const username =
      selectedExportType && selectedExportType.needUsername
        ? ` - ${getLoggedInUser().username}`
        : '';
    return selectedExportType
      ? `${t(selectedExportType.name)}${iaGroupName}${username} -
    ${new Date().toLocaleDateString()} -
    ${new Date().toLocaleTimeString()}.xlsx`
      : '';
  }, [groupName, selectedExportType, t]);

  const [download, { loading: downloading }] = useDownloadSpreadsheet();

  const downloadExport = useCallback(() => {
    if (url) {
      download(url, fileName());
    }
  }, [url, download, fileName]);

  const handleExportTypeChange = (name: string) => {
    setUrl(undefined);
    setSelectedExportType(exportTypes.find((exportType) => exportType.name === name));
  };

  const handleEventChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    handleExportTypeChange(event.target.value);
  }, []);

  return (
    <PageContainer title="statistic">
      {downloading && <Loader />}
      <Row className="d-flex justify-content-center align-items-center">
        <Col xs="auto">
          <Input type="select" name="siteSelect" onChange={handleEventChange}>
            {!selectedExportType && <option>{t('select-export-type')}</option>}
            {exportTypes &&
              exportTypes.map((exportType: ExportType) => (
                <option key={exportType.name} value={exportType.name}>
                  {t(exportType.name)}
                </option>
              ))}
          </Input>
        </Col>
        <Col xs="auto">
          <Button color="primary" onClick={downloadExport} block>
            <i className="mdi mdi-download mr-2" />
            {t('download')}
          </Button>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center py-2">
        <Col xs="auto">{selectedExportType && <h5>{t(selectedExportType.description)}</h5>}</Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center">
        <Col>
          {selectedExportType &&
            createElement(selectedExportType.component, {
              onPathChange: setUrl,
              onGroupNameChange: setGroupName,
            })}
        </Col>
      </Row>
    </PageContainer>
  );
}

export default Statistic;
