import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Input,
  Row,
  Col,
  ModalFooter,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Farm from 'models/farm';
import { useInput } from 'hooks/useInput';
import { ValueType } from 'models/valueType';
import { formatGrouppageName } from 'components/grouppages/gpUtil';

interface FarmFormModalProps {
  farm?: Farm;
  onSave: (farm: Farm) => void;
  isOpen: boolean;
  toggle: () => void;
}

interface FarmFormError {
  name: boolean;
  email: boolean;
  shortName: boolean;
}

const defaultFormError = {
  name: false,
  email: false,
  shortName: false,
};

const defaultFarm = {
  id: 0,
  name: '',
  email: '',
  phoneNumber: '',
  stables: [],
  street: '',
  city: '',
  country: '',
  zipCode: '',
};

const RegExp = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  shortName: /^([a-zA-Z]{2}[a-zA-Z0-9]?)$/,
};

const isInputValid = (regExp: RegExp, value: string) => regExp.test(value);

function FarmFormModal({ farm, onSave, isOpen, toggle }: FarmFormModalProps) {
  const [formError, setFormError] = useState<FarmFormError>(defaultFormError);
  const [newFarm, setNewFarm, onSimpleValueChange] = useInput<Farm>(farm || defaultFarm);
  const { t } = useTranslation();

  const handleFarmNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormError({ ...formError, name: !event.target.value });
    setNewFarm({ ...newFarm, name: event.target.value });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormError({
      ...formError,
      email: !!event.target.value && !isInputValid(RegExp.email, event.target.value),
    });
    setNewFarm({ ...newFarm, email: event.target.value });
  };

  const handleShortNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormError({
      ...formError,
      shortName: !event.target.value || !isInputValid(RegExp.shortName, event.target.value),
    });
    setNewFarm({ ...newFarm, shortName: formatGrouppageName(event.target.value) });
  };

  useEffect(() => {
    if (farm && !farm.shortName) {
      farm.shortName = farm.name.substring(0, 2).toUpperCase();
    }

    setNewFarm(farm || defaultFarm);
    setFormError(defaultFormError);
  }, [farm, isOpen, setNewFarm]);

  const submit = () => {
    const errors: FarmFormError = {
      name: !newFarm.name,
      email: !!newFarm.email && !isInputValid(RegExp.email, newFarm.email),
      shortName: !newFarm.shortName || !isInputValid(RegExp.shortName, newFarm.shortName),
    };

    setFormError(errors);

    if (!Object.values(errors).includes(true)) {
      onSave(newFarm);
      toggle();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{farm ? t('edit-farm') : t('create-farm')}</ModalHeader>
      <ModalBody>
        <Form className="form">
          <Row>
            <Col>
              <FormGroup>
                <Label>
                  {t('farm-name')}
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="name"
                  defaultValue={newFarm.name}
                  invalid={formError.name}
                  onChange={handleFarmNameChange}
                />
                <FormFeedback>{t('wrong-farm-name')}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>
                  {t('farm-id')}
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="name"
                  value={newFarm.shortName ?? ''}
                  invalid={formError.shortName}
                  onChange={handleShortNameChange}
                />
                <FormFeedback>{t('wrong-farm-id')}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label>{t('email')}</Label>
            <Input
              type="email"
              name="email"
              defaultValue={newFarm.email}
              invalid={formError.email}
              onChange={handleEmailChange}
            />
            <FormFeedback>{t('email-error')}</FormFeedback>
          </FormGroup>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label>{t('country')}</Label>
                <Input
                  type="text"
                  name="country"
                  defaultValue={newFarm.country}
                  onChange={onSimpleValueChange('country', ValueType.STRING)}
                />
                <FormFeedback>{t('wrong-country')}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>{t('city')}</Label>
                <Input
                  type="text"
                  name="city"
                  defaultValue={newFarm.city}
                  onChange={onSimpleValueChange('city', ValueType.STRING)}
                />
                <FormFeedback>{t('wrong-city')}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label>{t('zip-code')}</Label>
                <Input
                  type="text"
                  name="zipCode"
                  defaultValue={newFarm.zipCode}
                  onChange={onSimpleValueChange('zipCode', ValueType.STRING)}
                />
                <FormFeedback>{t('wrong-zip-code')}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label>{t('street')}</Label>
            <Input
              type="text"
              name="street"
              defaultValue={newFarm.street}
              onChange={onSimpleValueChange('street', ValueType.STRING)}
            />
            <FormFeedback>{t('wrong-street')}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>{t('phone-number')}</Label>
            <Input
              type="text"
              name="phoneNumber"
              defaultValue={newFarm.phoneNumber}
              onChange={onSimpleValueChange('phoneNumber', ValueType.STRING)}
            />
            <FormFeedback>{t('wrong-phone-number')}</FormFeedback>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={submit} className="mr-2">
          {t('save')}
        </Button>
        <Button color="primary" onClick={toggle}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default FarmFormModal;
