import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import '../../css/modal.css';

interface JsonModalProps {
  title: string;
  json: string | undefined;
  isOpen: boolean;
  toggle: () => void;
}

function JsonModal({ title, json, isOpen, toggle }: JsonModalProps) {
  const { t } = useTranslation();
  const [beauty, setBeauty] = useState('');

  const doToggle = useCallback(() => {
    toggle();
  }, [toggle]);

  useEffect(() => {
    if (isOpen) {
      let jsonBeauty = '';
      if (json && json.length > 0) {
        const parsedJson = JSON.parse(json);
        jsonBeauty = JSON.stringify(parsedJson, null, 2);
      }
      setBeauty(jsonBeauty);
    }
  }, [isOpen, json]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={doToggle}
      contentClassName="modal-content-small-size"
      className="modal-align"
    >
      <ModalHeader toggle={doToggle}>{title}</ModalHeader>
      <ModalBody>
        <Row className="d-flex justify-content-center align-items-center">
          <Col className="text-left mx-0 ml-2 px-0">
            <div>
              <pre>{beauty}</pre>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={doToggle} autoFocus={false}>
          {t('close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default JsonModal;
