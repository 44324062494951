import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Input,
  ModalFooter,
  Alert,
  Container,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CageCompositionForm from './cageCompositionForm';
import Row from 'models/row';
import CageComposition from 'models/cageComposition';

interface StableRowFormModalProps {
  row: Row;
  onSave: (row: Row) => void;
  isOpen: boolean;
  toggle: () => void;
  editMode: boolean;
  stableName: string;
}

interface FormError {
  position: boolean;
  cageType: boolean;
  from: boolean;
  to: boolean;
}

interface FormErrors {
  [key: number]: FormError;
}

const cageCompositionObject = (key: number) => ({
  key,
  position: '',
  cageType: undefined,
  from: 1,
  to: undefined,
});

const checkOverlap = (position: string, cages: CageComposition[]) => {
  const overlapItems: any[] = [];
  const tempCages: CageComposition[] = cages.filter((cage) => cage.position === position);

  tempCages.forEach((a: CageComposition) =>
    tempCages.forEach((b: CageComposition) => {
      if (a.to && b.to && a.key !== b.key && a.from <= b.to && b.from <= a.to) {
        if (!overlapItems.includes(a.key)) {
          overlapItems.push(a.key);
        }
        if (!overlapItems.includes(b.key)) {
          overlapItems.push(b.key);
        }
      }
    })
  );

  return overlapItems;
};

const setKeys = (row: Row) => {
  let key = 0;

  if (row) {
    row.cages.forEach((cage) => {
      cage.key = key;
      key += 1;
    });
  }

  return row;
};

const formErrorObject = (cage: CageComposition) => ({
  position: !cage.position,
  cageType: !cage.cageType,
  from: !cage.from,
  to: !cage.to || cage.to < cage.from,
});

const checkCageFormError = (formErrors: FormErrors) => {
  let hasCageFormError = false;
  Object.entries(formErrors).forEach((formError: FormError[]) => {
    const tempFormError: FormError = formError[1];
    if (Object.values(tempFormError).includes(true)) {
      hasCageFormError = true;
    }
  });
  return hasCageFormError;
};

const hasAnyError = (hasCageError: boolean, overlapItems: any, row: number) =>
  hasCageError || (overlapItems.length === 0 && row < 1);

function StableRowFormModal({
  row,
  onSave,
  isOpen,
  toggle,
  editMode,
  stableName,
}: StableRowFormModalProps) {
  const [key, setKey] = useState<number>(row.cages.length);
  const [newRow, setNewRow] = useState<Row>(setKeys(row));
  const [overlapItems, setOverlapItems] = useState<any[]>([]);
  const [formError, setFormError] = useState<FormErrors>({});
  const [rowIdError, setRowIdError] = useState<boolean>(false);
  const [isformError, setIsformError] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      setOverlapItems([]);
      setFormError({});
      setRowIdError(false);
      setIsformError(false);
    }
  }, [toggle, isOpen]);

  useEffect(() => {
    if (row) {
      setNewRow(setKeys(row));
      setKey(row.cages.length);
    }
  }, [row]);

  const setRowIdErrorMessage = (rowId: number) => {
    rowId < 1 ? setRowIdError(true) : setRowIdError(false);
  };

  const handleRowIdChange = (value: number) => {
    setRowIdErrorMessage(value);
    setNewRow({ ...newRow, row: value });
    setIsformError(false);
  };

  const onChange = (cageComposition: CageComposition) => {
    const newCages = newRow.cages.map((cage) => {
      if (cage.key === cageComposition.key) return cageComposition;
      return cage;
    });

    setNewRow({ ...newRow, cages: newCages });

    setFormError({ ...formError, [cageComposition.key]: formErrorObject(cageComposition) });

    setIsformError(false);
  };

  const submit = () => {
    const newOverlapItems = checkOverlap('DOWN', newRow.cages)
      .concat(checkOverlap('UP', newRow.cages))
      .concat(checkOverlap('OUTER', newRow.cages))
      .concat(checkOverlap('INNER', newRow.cages));

    setOverlapItems(newOverlapItems);

    const formErrors: FormErrors = {};

    newRow.cages.forEach((cage) => {
      const formError = { [cage.key]: formErrorObject(cage) };
      Object.assign(formErrors, formError);
    });

    setFormError(formErrors);
    setRowIdErrorMessage(newRow.row);

    const hasCageFormError = checkCageFormError(formErrors);

    if (!hasAnyError(hasCageFormError, newOverlapItems, newRow.row)) {
      onSave(newRow);
      toggle();
    }

    setIsformError(hasAnyError(hasCageFormError, newOverlapItems, newRow.row));
  };

  const addNewCage = () => {
    setNewRow({ ...newRow, cages: [...newRow.cages, cageCompositionObject(key)] });
    setKey(key + 1);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        {editMode ? t('edit-row') : t('add-new-row')} ({t('stable')}: {stableName})
      </ModalHeader>
      <ModalBody>
        <Form className="form">
          <FormGroup>
            <Label>
              {t('stable-row')}
              <span className="text-danger">*</span>
            </Label>
            <Input
              type="number"
              name="rowId"
              min="0"
              invalid={rowIdError}
              defaultValue={newRow.row}
              onChange={(e) => handleRowIdChange(+e.target.value)}
            />
            <FormFeedback>{t('wrong-row-name')}</FormFeedback>
          </FormGroup>
        </Form>
        {newRow.cages.map((cage) => (
          <CageCompositionForm
            hasOverlap={overlapItems.includes(cage.key)}
            formError={formError && formError[cage.key]}
            key={cage.key}
            cageComposition={cage}
            onChange={(cageComposition) => onChange(cageComposition)}
          />
        ))}
        <Container className="text-center">
          <Button
            type="button"
            color="success"
            onClick={addNewCage}
            className="my-2 rounded-circle"
            style={{ padding: '12px 17px' }}
          >
            <i className="mdi mdi-plus" />
          </Button>
          {isformError && <Alert color="danger">{t('form-has-errors')}</Alert>}
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={submit} className="mr-2">
          {t('save')}
        </Button>
        <Button color="primary" onClick={toggle}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default StableRowFormModal;
