import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useApiGet } from '../../hooks/useApi';
import CageComposition from 'models/cageComposition';

interface FormError {
  position: boolean;
  cageType: boolean;
  from: boolean;
  to: boolean;
}

interface CageCompositionFormProps {
  cageComposition: CageComposition;
  onChange: (cageComposition: CageComposition) => void;
  hasOverlap: boolean;
  formError: FormError;
}

const defaultFormError = {
  position: false,
  cageType: false,
  from: false,
  to: false,
};

function CageCompositionForm({
  cageComposition,
  onChange,
  hasOverlap,
  formError = defaultFormError,
}: CageCompositionFormProps) {
  const [newCageComposition, setNewCageComposition] = useState<CageComposition>(cageComposition);
  const { data: cageTypes, loading: loadingCageTypes } = useApiGet<{ id: number; name: string }[]>(
    '/api/v1/cage-types'
  );
  const { t } = useTranslation();

  const handleCageTypeChange = (value: number) => {
    const newValue = { ...newCageComposition, cageType: value };
    setNewCageComposition(newValue);
    onChange(newValue);
  };

  const handleCagePositionChange = (value: string) => {
    const newValue = { ...newCageComposition, position: value };
    setNewCageComposition(newValue);
    onChange(newValue);
  };

  const handleFromChange = (value: number) => {
    const newValue = { ...newCageComposition, from: value };
    setNewCageComposition(newValue);
    onChange(newValue);
  };

  const handleToChange = (value: number) => {
    const newValue = { ...newCageComposition, to: value };
    setNewCageComposition(newValue);
    onChange(newValue);
  };

  return (
    <Form className="form">
      <Row form>
        <Col md={3}>
          <FormGroup>
            <Label>{t('cage-type')}</Label>
            <Input
              type="select"
              name="cageTypeSelect"
              defaultValue={newCageComposition ? newCageComposition.cageType : undefined}
              invalid={formError.cageType}
              onChange={(e) => handleCageTypeChange(+e.target.value)}
            >
              {!cageComposition.cageType && <option>{t('choose')}</option>}
              {!loadingCageTypes &&
                cageTypes?.map((cageType) => (
                  <option key={cageType.id} value={cageType.id}>
                    {cageType.name}
                  </option>
                ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label>{t('cage-position')}</Label>
            <Input
              type="select"
              name="position"
              defaultValue={newCageComposition ? newCageComposition.position : undefined}
              invalid={formError.position}
              onChange={(e) => handleCagePositionChange(e.target.value)}
            >
              {!cageComposition.position && <option>{t('choose')}</option>}
              <option value="DOWN">{t('bottom')}</option>
              <option value="UP">{t('up')}</option>
              <option value="OUTER">{t('outer')}</option>
              <option value="INNER">{t('inner')}</option>
            </Input>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label>{t('from-short')}</Label>
            <Input
              type="number"
              name="from"
              min="0"
              invalid={formError.from || hasOverlap}
              defaultValue={newCageComposition.from}
              onChange={(e) => handleFromChange(+e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label>{t('to-short')}</Label>
            <Input
              type="number"
              name="to"
              min="0"
              invalid={formError.to || hasOverlap}
              defaultValue={newCageComposition.to}
              onChange={(e) => handleToChange(+e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
}

export default CageCompositionForm;
