import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cover from '../../models/cover';
import SimpleTable from '../shared/simpleTable';
import EditCoverDetails from './edit/editCoverDetails';

interface RabbitPerformanceProps {
  covers: Cover[];
  onDetailsModified: () => void;
}

function calculateBorn(cover: Cover) {
  cover.born = cover.viable + cover.unviable;
  return cover.born;
}

function defaultFormatter(value: any) {
  return value !== null ? value : '-';
}

function adjustedFormatter(cover: Cover) {
  return calculateBorn(cover) === 0 ? '-' : defaultFormatter(cover.adjusted);
}

function Covers({ covers, onDetailsModified }: RabbitPerformanceProps) {
  const { t } = useTranslation();
  const [selectedCover, setSelectedCover] = useState<Cover>();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const toggleModal = useCallback(() => {
    setIsEditModalOpen(!isEditModalOpen);
  }, [isEditModalOpen]);

  const openSelectedModal = useCallback(
    (cover: Cover) => (event: React.MouseEvent<any, MouseEvent>) => {
      setSelectedCover(cover);
      toggleModal();
      event.preventDefault();
    },
    [toggleModal]
  );

  const onSave = useCallback(() => {
    onDetailsModified();
  }, [onDetailsModified]);

  const editFormatter = useCallback(
    (cover: Cover) => (
      <>
        <div onClick={openSelectedModal(cover)}>
          <i className="mdi mdi-pencil cursor-pointer" style={{ fontSize: '1.5rem' }} />
        </div>
      </>
    ),
    [openSelectedModal]
  );

  const columns = [
    {
      dataField: 'pedigree',
      text: t('pedigree'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'sperm',
      text: t('male'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'group.startDate',
      text: t('ia-date'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'iaGroup',
      isDummyField: true,
      text: t('ia-group'),
      formatter: (cell: undefined, cover: Cover) => cover.group.formattedName,
    },
    {
      dataField: 'pregnant',
      text: t('pregnant'),
      sort: true,
      formatter: (pregnant: boolean) => (pregnant ? '+' : '-'),
    },
    {
      dataField: 'born',
      text: t('born'),
      sort: true,
      formatter: (cell: undefined, cover: Cover) => calculateBorn(cover),
    },
    {
      dataField: 'viable',
      text: t('viable'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'unviable',
      text: t('unviable'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'added',
      text: t('added-sign'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'taken',
      text: t('taken-sign'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'adjusted',
      text: t('adjusted'),
      sort: true,
      formatter: (cell: undefined, cover: Cover) => adjustedFormatter(cover),
    },
    {
      dataField: 'nestCount3Week',
      text: t('3week-nest-count'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'weight3Week',
      text: t('3week-nest-weight'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'smallestWeight',
      text: t('3week-nest-smallest-weight'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'largestWeight',
      text: t('3week-nest-largest-weight'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'nestCount',
      text: t('nest-count'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'weight4Week',
      text: t('nest-count-weight'),
      sort: true,
      formatter: defaultFormatter,
    },
    {
      dataField: 'id',
      text: '',
      sort: false,
      formatter: (cell: undefined, cover: Cover) => editFormatter(cover),
    },
  ];

  return (
    <>
      <h4 className="page-title">
        {t('inseminations')} - {t('ia-groups')}
      </h4>
      {covers && selectedCover && (
        <EditCoverDetails
          toggle={toggleModal}
          isOpen={isEditModalOpen}
          onSave={onSave}
          cover={selectedCover}
          key={selectedCover.id}
        />
      )}
      <SimpleTable
        data={covers || []}
        columns={columns}
        defaultSorted={{ dataField: 'group.startDate', order: 'desc' }}
        keyField="group.startDate"
      />
    </>
  );
}

export default Covers;
