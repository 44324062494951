import React, { useState, useCallback } from 'react';
import { Col } from 'reactstrap';
import PageContainer from './PageContainer';
import IAGroupWithIATaskList from 'components/iaGroup/IAGroupWithIATaskList';
import IATaskDetails from 'components/iaGroup/IATaskDetails';

function IATaskManagement() {
  const [selectedIaGroupId, setSelectedIaGroupId] = useState<number | null>(null);
  const [selectedIaGroupName, setSelectedIaGroupName] = useState<string | null>(null);
  const [iaTaskChanged, setIaTaskChanged] = useState<number>(0);
  const onIaGroupClicked = useCallback((id: number, name: string) => {
    setSelectedIaGroupId(id);
    setSelectedIaGroupName(name);
  }, []);

  const onIaTaskChanged = useCallback(() => {
    setIaTaskChanged(iaTaskChanged + 1);
  }, [iaTaskChanged]);

  return (
    <PageContainer title="ia-task-management">
      <div className="d-flex">
        <Col md="2">
          <IAGroupWithIATaskList
            onIAGroupClicked={onIaGroupClicked}
            iaTaskChanged={iaTaskChanged}
          />
        </Col>
        <Col>
          {selectedIaGroupId && (
            <IATaskDetails
              iaGroupId={selectedIaGroupId}
              iaGroupName={selectedIaGroupName}
              onIaTaskChanged={onIaTaskChanged}
            />
          )}
        </Col>
      </div>
    </PageContainer>
  );
}

export default IATaskManagement;
