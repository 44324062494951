import { Row, Col } from 'reactstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ImportResults } from './importModal';

interface ImportResultProps {
  results: ImportResults[];
  modalType: string;
}

const LOG_TYPE_SEVERITY_MAP = {
  BACKEND_ERROR: 'danger',
  UNKNOWN_ERROR: 'danger',
  INVALID_DATA: 'danger',
  CAGE_NOT_FOUND: 'warning',
  EAR_TAG_ALREADY_IN_USE: 'danger',
  MISSING_MOTHER: 'warning',
  EID_ALREADY_REGISTERED: 'danger',
  TAG_ALREADY_REGISTERED: 'danger',
  INVALID_MOTHER: 'danger',
  DUPLICATED_MOTHER: 'warning',
  DUPLICATED_TATTOO: 'warning',
  INVALID_SPERM: 'danger',
  INVALID_PEDIGREE: 'danger',
  IMPREGNATED_MOTHER: 'warning',
  SAVE_ERROR: 'danger',
  INVALID_LANGUAGE: 'danger',
  INVALID_PARAMS: 'danger',
};

function getIconClasses(
  logType:
    | 'BACKEND_ERROR'
    | 'UNKNOWN_ERROR'
    | 'EAR_TAG_ALREADY_IN_USE'
    | 'CAGE_NOT_FOUND'
    | 'MISSING_MOTHER'
    | 'EID_ALREADY_REGISTERED'
    | 'TAG_ALREADY_REGISTERED'
    | 'INVALID_DATA'
    | 'INVALID_MOTHER'
    | 'DUPLICATED_MOTHER'
    | 'DUPLICATED_TATTOO'
    | 'INVALID_SPERM'
    | 'INVALID_PEDIGREE'
    | 'IMPREGNATED_MOTHER'
    | 'SAVE_ERROR'
    | 'INVALID_LANGUAGE'
    | 'INVALID_PARAMS'
) {
  const severity = LOG_TYPE_SEVERITY_MAP[logType];
  return `ml-2 mdi mdi-${severity === 'warning' ? 'alert' : 'alert-circle'} text-${severity}`;
}

function ImportResult({ results, modalType }: ImportResultProps) {
  const { t } = useTranslation();

  return (
    <>
      <h3 className="mb-2">{t('import-results')}</h3>
      {results.length === 0 && <h4 className="text-center">{t('successful-import')}</h4>}
      {results.length > 0 && <h4 className="text-center">{t('problem-import')}</h4>}
      <Row tag="dl" className="overflow-auto" style={{ maxHeight: 500 }}>
        {results.map(({ row, col, code, info, logType }, idx) => {
          if (logType === 'UNKNOWN_ERROR')
            return (
              <Row key={`${row}-${col}-${code}-1`} className="mx-2" style={{ width: '100%' }}>
                <Col tag="dt" xs={3} />
                <Col tag="dd" xs={9}>
                  {t(`import-common.${logType}`)}
                  <i className={getIconClasses(logType)} />
                </Col>
              </Row>
            );
          if (logType === 'BACKEND_ERROR')
            return (
              <Row key={`${code}`} className="mx-2" style={{ width: '100%' }}>
                <Col tag="dt" xs={3} />
                <Col tag="dd" xs={9}>
                  {t(`import-common.${logType}`) + code + (info ? ` (${info})` : '')}
                  <i className={getIconClasses(logType)} />
                </Col>
              </Row>
            );
          return (
            <Row key={`${row}-${col || code || idx}`} className="mx-2" style={{ width: '100%' }}>
              <Col tag="dt" xs={3}>
                {row && <b>{`${t('row')}: ${row}${col ? `, ${t('col')}: ${col}` : ''}`}</b>}
              </Col>
              <Col tag="dd" xs={9}>
                {t(`${modalType}.${logType}`)}
                <i className={getIconClasses(logType)} />
              </Col>
            </Row>
          );
        })}
      </Row>
    </>
  );
}

export default ImportResult;
