import React, { useCallback, useEffect, useState } from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Datepicker from 'hyper/components/Datepicker';
import { useApi } from 'hooks/useApi';
import PermittedIAGroupNameTransfer from 'models/PermittedIAGroupNameTransfer';
import Loader from 'hyper/components/Loader';

const baseURI = '/api/v1/ia-group/permitted-name';

interface IAGroupFormProps {
  startDate: Date;
  onStartDateChange: (date: Date) => void;
  onMotherGroupChange: (motherGroup: string) => void;
  motherGroup: string;
  disabled: boolean;
}

function IAGroupForm({
  startDate,
  motherGroup,
  onStartDateChange,
  onMotherGroupChange,
  disabled = false,
}: IAGroupFormProps) {
  const { t } = useTranslation();
  const { get, loading } = useApi();
  const [names, setNames] = useState<PermittedIAGroupNameTransfer[]>([]);

  const loadNames = useCallback(() => {
    get(baseURI, (data: PermittedIAGroupNameTransfer[]) => {
      setNames(data);
    });
  }, [get]);

  useEffect(() => loadNames(), [loadNames]);

  useEffect(() => {
    if (!motherGroup && names && names.length) {
      onMotherGroupChange(names[0].name);
    }
  }, [motherGroup, names, onMotherGroupChange]);

  return (
    <Form className="form">
      <FormGroup>
        <Label>{t('date')}:</Label>
        <Datepicker
          startDate={new Date(startDate)}
          dateFormat="yyyy-MM-dd"
          onDateChange={onStartDateChange}
          disabled={disabled}
        />
      </FormGroup>
      {loading && <Loader />}
      {!loading && (
        <FormGroup>
          <Label>{t('mother-group')}:</Label>
          <Input
            type="select"
            name="motherSelect"
            disabled={disabled}
            value={motherGroup}
            onChange={(e) => onMotherGroupChange(e.target.value)}
          >
            {names &&
              names.map((t: PermittedIAGroupNameTransfer) => (
                <option key={t.id} value={t.name}>
                  {t.name}
                </option>
              ))}
          </Input>
        </FormGroup>
      )}
    </Form>
  );
}

export default IAGroupForm;
