import React, { useEffect, useState } from 'react';
import { Progress, ListGroupItem } from 'reactstrap';
import classNames from 'classnames';
import IAGroupStatusDetails from 'models/iaGroupStatusDetails';

interface IAGroupIATaskStatusProps {
  iaGroupStatusDetails: IAGroupStatusDetails;
  selectedIaGroupId?: number;
}

function IATaskProgress({ iaGroupStatusDetails, selectedIaGroupId }: IAGroupIATaskStatusProps) {
  const [percent, setPercent] = useState<number>();

  const progressColor = (iaGroupStatusDetails: IAGroupStatusDetails) => {
    if (iaGroupStatusDetails.closed) return 'secondary';

    return 'success';
  };

  useEffect(() => {
    if (iaGroupStatusDetails && iaGroupStatusDetails.closed) {
      setPercent(100);
    } else {
      const calculatedPercent =
        iaGroupStatusDetails.numberOfFinishedIATasks > 0
          ? (100 / iaGroupStatusDetails.numberOfCreatedIATasks) *
            iaGroupStatusDetails.numberOfFinishedIATasks
          : 0;
      setPercent(calculatedPercent);
    }
  }, [iaGroupStatusDetails]);

  return (
    <ListGroupItem
      key={iaGroupStatusDetails.iaGroupId}
      className={classNames({ active: selectedIaGroupId === iaGroupStatusDetails.iaGroupId })}
      style={{ borderRadius: '10px' }}
      action
    >
      <p className="text-center">{iaGroupStatusDetails.iaGroupName}</p>
      <Progress value={percent} color={progressColor(iaGroupStatusDetails)} />
    </ListGroupItem>
  );
}

export default IATaskProgress;
