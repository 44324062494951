import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Col, Row } from 'reactstrap';
import Column from 'models/column';
import DefaultSorted from 'models/defaultSorted';
import { getRowCountLocaleString } from 'helpers/tables';

interface PaginatedTableProps {
  wrapperClasses?: string;
  condensed?: boolean;
  data: unknown[];
  columns: Column[];
  defaultSorted: DefaultSorted;
  keyField: string;
  dataCy?: string;
  showTotalNumber?: boolean;
}

function PaginatedTable({
  wrapperClasses,
  condensed,
  data,
  columns,
  defaultSorted,
  keyField,
  dataCy,
  showTotalNumber = true,
}: PaginatedTableProps) {
  const cy = dataCy ? ` data-cy-${dataCy}` : '';

  return (
    <>
      {showTotalNumber && (
        <Row className="justify-content-end">
          <Col xs="auto">
            <span>{getRowCountLocaleString(data.length)}</span>
          </Col>
        </Row>
      )}
      <BootstrapTable
        bootstrap4
        striped
        keyField={keyField}
        data={data}
        columns={columns}
        defaultSorted={[defaultSorted]}
        pagination={paginationFactory({ hideSizePerPage: true, page: 1, sizePerPage: 100 })}
        wrapperClasses={wrapperClasses || `table-responsive${cy}`}
        condensed={condensed}
      />
    </>
  );
}

export default PaginatedTable;
