import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, Row } from 'reactstrap';
import { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import PageContainer from '../PageContainer';
import Loader from 'hyper/components/Loader';
import { dateStr, dateTimeStr } from 'components/grouppages/gpUtil';
import Datepicker from 'hyper/components/Datepicker';
import EarTagging from 'models/earTagging';
import { useDownloadSpreadsheet } from 'hooks/useDownloadFile';
import RemoteTable from 'components/shared/remoteTable';
import usePaginatedData, { PageRequest } from 'hooks/usePaginatedData';
import ListFatteningTransfer from 'models/grouppages/listFatteningTransfer';

const PAGE_SIZE = 100;

const defaultPageRequest: PageRequest = {
  page: 1,
  pageSize: PAGE_SIZE,
  sortFields: ['endAt'],
  sortOrder: 'desc',
};

const fileName = (prefix: string, name: string, user: string, date: Date) =>
  `${prefix} - ${user} - ${name} - ${`${new Date(date).toLocaleDateString()}`}.xlsx`;

function EartaggingList() {
  const { t } = useTranslation();
  const { loadMore, data: list, loading: loading } = usePaginatedData<EarTagging>(
    '/api/v1/ear-tagging'
  );
  const [nodata, setNoData] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(moment().add(-6, 'months').toDate());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [download, { loading: downloading }] = useDownloadSpreadsheet();
  const [pageRequest, setPageRequest] = useState<PageRequest>();

  const changeStartDate = useCallback(
    (date: Date) => {
      setStartDate(date);
      if (endDate.getTime() < date.getTime()) {
        setEndDate(date);
      }
    },
    [endDate]
  );

  const changeEndDate = useCallback(
    (date: Date) => {
      setEndDate(date);
      if (startDate.getTime() > date.getTime()) {
        setStartDate(date);
      }
    },
    [startDate]
  );

  const reload = useCallback(
    (pageRequest: PageRequest) => {
      if (!startDate && !endDate) return;
      loadMore(
        {
          page: pageRequest.page,
          pageSize: pageRequest.pageSize,
          sortFields: pageRequest.sortFields,
          sortOrder: pageRequest.sortOrder,
        },
        { from: dateStr(startDate), to: dateStr(endDate) }
      );
    },
    [endDate, loadMore, startDate]
  );

  const onTableChange = useCallback(
    (type: TableChangeType, newState: TableChangeState<ListFatteningTransfer>) => {
      const pageRequest = {
        page: newState.page,
        pageSize: newState.sizePerPage,
        sortFields: [newState.sortField],
        sortOrder: newState.sortOrder,
      };
      setPageRequest({ ...pageRequest });
    },
    []
  );

  const isLoading = useCallback(() => loading || downloading, [loading, downloading]);

  useEffect(() => {
    if (!startDate && !endDate) return;
    const next = pageRequest || defaultPageRequest;
    reload({ ...next });
  }, [endDate, pageRequest, reload, startDate]);

  useEffect(() => {
    setNoData(!list || list.totalElements < 1);
  }, [list]);

  const earTaggingExport = useCallback(
    (row: EarTagging) => {
      download(
        `/api/v1/export/ear-tagging/${row.id}`,
        fileName(t('ear-tagging-title'), row.listName, row.username, row.endAt)
      );
    },
    [download, t]
  );

  const renderActions = (row: EarTagging) => (
    <Button
      color="primary"
      className="py-0 mx-2"
      onClick={() => {
        earTaggingExport(row);
      }}
    >
      {t('export')}
    </Button>
  );

  const columns = [
    {
      dataField: 'listName',
      text: t('ear-tagging-name'),
      sort: true,
    },
    {
      dataField: 'endAt',
      text: t('ear-tagging-date'),
      sort: true,
      formatter: (cell: undefined, row: EarTagging) => dateTimeStr(row.endAt),
    },
    {
      dataField: 'username',
      text: t('ear-tagging-user'),
    },
    {
      dataField: 'cages',
      text: t('ear-tagging-cage'),
    },
    {
      dataField: 'tattoos',
      text: t('ear-tagging-tattoo'),
    },
    {
      dataField: 'size',
      text: t('ear-tagging-rabbit-num'),
    },
    {
      dataField: 'actions',
      isDummyField: true,
      text: t('operations'),
      sort: false,
      formatter: (cell: undefined, row: EarTagging) => renderActions(row),
    },
  ];

  return (
    <PageContainer
      title={
        <div className="my-3 clearfix">
          <Row>
            <Col md="auto">
              <h4>{`${t('ear-tagging-title')}`}</h4>
            </Col>
            <Col md="auto">
              <Row>
                <div className="ml-5">
                  <Datepicker
                    id="startDate"
                    style={{ zIndex: 10 }}
                    value={dateStr(startDate)}
                    selected={startDate}
                    todayButton={t('date-picker-today')}
                    dateFormat="yyyy-MM-dd"
                    onDateChange={changeStartDate}
                    readOnly={false}
                  />
                </div>
                <h4 className="mx-1"> - </h4>
                <div>
                  <Datepicker
                    id="endDate"
                    value={dateStr(endDate)}
                    selected={endDate}
                    todayButton={t('date-picker-today')}
                    dateFormat="yyyy-MM-dd"
                    onDateChange={changeEndDate}
                  />
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      }
    >
      {isLoading() && <Loader />}
      {nodata && <Alert color="warning">{t('no-ear-tagging')}</Alert>}
      <RemoteTable
        id={'ear-tagging-table'}
        data={list}
        condensed
        onTableChange={onTableChange}
        keyField="id"
        sizePerPage={PAGE_SIZE}
        columns={columns}
        defaultSorted={{ dataField: 'endAt', order: 'desc' }}
      />
    </PageContainer>
  );
}

export default EartaggingList;
