import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Col, Row } from 'reactstrap';
import moment from 'moment';
import { TFunction } from 'i18next';
import { DownloadExport } from './export/DownloadExport';
import {
  classCellC,
  classCellL,
  classCellR,
  classTable,
  convertDateToStr,
  createTd,
  createTh,
  createThL,
  dateStr,
  toStr,
} from 'components/grouppages/gpUtil';
import PageContainer from 'pages/PageContainer';
import Datepicker from 'hyper/components/Datepicker';
import { useApi } from 'hooks/useApi';
import YoungGroupSummary from 'models/grouppages/youngGroupSummary';
import YoungWeeklyWeightGain from 'models/grouppages/YoungWeeklyWeightGain';
import { DailyWeightType } from 'models/grouppages/dailyWeightType';
import Loader from 'hyper/components/Loader';

const findWeightForWeek = (
  weights: YoungWeeklyWeightGain[],
  week: number,
  type: DailyWeightType
): number | undefined => weights.filter((w) => w.weekOfAge === week && w.type === type)[0]?.weight;

const addWeightsColumns = (
  rowId: number,
  iidx: number,
  weights: YoungWeeklyWeightGain[] | null | undefined,
  cell: string,
  age: number
) => {
  const result: JSX.Element[] = [];

  if (!weights) return result;

  for (let i = 5; i <= 20; i++) {
    if (i < (age / 7) >> 0) {
      result.push(
        createTd(rowId, iidx++, '-', classCellC),
        createTd(rowId, iidx++, '-', classCellC)
      );
      continue;
    }

    result.push(
      createTd(rowId, iidx++, toStr(findWeightForWeek(weights, i, DailyWeightType.PS), 1), cell),
      createTd(rowId, iidx++, toStr(findWeightForWeek(weights, i, DailyWeightType.GP), 1), cell)
    );
  }

  return result;
};

const getNameCellContent = (row: YoungGroupSummary): string | JSX.Element | undefined => {
  const gid = `${row.groupId}`;
  return (
    <a href={`/grouppage-young/${gid}/edit`} rel="noopener noreferrer" target="_blank">
      <span id={gid}>{row.generatedName}</span>
    </a>
  );
};

const createDataRow = (row: YoungGroupSummary, idx: number) => {
  let iidx = 0;
  const rowId = idx;
  return (
    <tr key={rowId}>
      {createTd(rowId, iidx++, getNameCellContent(row), classCellL)}
      {createTd(rowId, iidx++, row.stableName, classCellL)}

      {createTd(rowId, iidx++, toStr(row.actualAge), classCellR)}

      {createTd(rowId, iidx++, convertDateToStr(row.startDate), classCellR)}
      {createTd(rowId, iidx++, convertDateToStr(row.endDate), classCellR)}

      {createTd(rowId, iidx++, toStr(row.originNumSumPS), classCellR)}
      {createTd(rowId, iidx++, toStr(row.originNumSumGP), classCellR)}

      {createTd(rowId, iidx++, toStr(row.actualNumPS), classCellR)}
      {createTd(rowId, iidx++, toStr(row.actualNumGP), classCellR)}

      {createTd(rowId, iidx++, toStr(row.deadSumPS), classCellR)}
      {createTd(rowId, iidx++, toStr(row.deadSumGP), classCellR)}

      {createTd(rowId, iidx++, toStr(row.deadRespSumPS), classCellR)}
      {createTd(rowId, iidx++, toStr(row.deadRespSumGP), classCellR)}

      {createTd(rowId, iidx++, toStr(row.deadEreSumPS), classCellR)}
      {createTd(rowId, iidx++, toStr(row.deadEreSumGP), classCellR)}

      {createTd(rowId, iidx++, toStr(row.wasteSumPS), classCellR)}
      {createTd(rowId, iidx++, toStr(row.wasteSumGP), classCellR)}

      {createTd(rowId, iidx++, toStr(row.soldSumPS), classCellR)}
      {createTd(rowId, iidx++, toStr(row.soldSumGP), classCellR)}

      {createTd(rowId, iidx++, toStr(row.ownReplacementSumPS), classCellR)}
      {createTd(rowId, iidx++, toStr(row.ownReplacementSumGP), classCellR)}

      {createTd(rowId, iidx++, toStr(row.deadPercentPS, 2), classCellR)}
      {createTd(rowId, iidx++, toStr(row.deadPercentGP, 2), classCellR)}

      {createTd(rowId, iidx++, toStr(row.wastePercentPS, 2), classCellR)}
      {createTd(rowId, iidx++, toStr(row.wastePercentGP, 2), classCellR)}

      {createTd(rowId, iidx++, toStr(row.dropOutSumPercentPS, 2), classCellR)}
      {createTd(rowId, iidx++, toStr(row.dropOutSumPercentGP, 2), classCellR)}

      {createTd(rowId, iidx++, toStr(row.deadRespRatePS, 2), classCellR)}
      {createTd(rowId, iidx++, toStr(row.deadRespRateGP, 2), classCellR)}

      {createTd(rowId, iidx++, toStr(row.deadEreRatePS, 2), classCellR)}
      {createTd(rowId, iidx++, toStr(row.deadEreRateGP, 2), classCellR)}

      {addWeightsColumns(rowId, iidx++, row.avgWeights, classCellR, row.age)}
    </tr>
  );
};

const renderBody = (summaries: YoungGroupSummary[] | null | undefined) => {
  if (!summaries || summaries.length < 1) {
    return <></>;
  }
  const result: JSX.Element[] = [];

  summaries.map((row, idx) => {
    result.push(createDataRow(row, idx));
  });

  return result;
};

const renderLabels = (t: TFunction) => {
  const result: JSX.Element[] = [];
  const ps = t('ygpt2-ps');
  const gp = t('ygpt2-gp');

  for (let i = 0; i < 58; i++) {
    result.push(i % 2 === 0 ? createThL(ps, i) : createThL(gp, i));
  }

  return result;
};

function createURI(base: string, from: Date | undefined, to: Date | undefined): string {
  let uri = base;

  if (from) uri += `?from=${dateStr(from)}`;
  if (to) uri += `${uri === base ? '?' : '&'}to=${dateStr(to)}`;

  return uri;
}

function YoungSummary() {
  const { t } = useTranslation();

  const { get: load, loading } = useApi();
  const [summaries, setSummaries] = useState<YoungGroupSummary[]>([]);
  const [nodata, setNoData] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(moment().add(-2, 'years').toDate());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const reload = useCallback(() => {
    const uri = createURI('/api/v1/group/young/summary', startDate, endDate);

    load<YoungGroupSummary[]>(uri, (searchResult: YoungGroupSummary[]) =>
      setSummaries(searchResult)
    );
  }, [endDate, load, startDate]);

  const isLoading = useCallback(() => loading, [loading]);

  useEffect(() => {
    reload();
  }, [endDate, reload, startDate]);

  useEffect(() => {
    setNoData(!summaries || summaries.length < 1);
  }, [summaries]);

  return (
    <PageContainer
      title={
        <div className="my-3 clearfix">
          <Row>
            <Col md="auto">
              <h4>{t('young-summary')}</h4>
            </Col>

            <Col md="auto">
              <Row>
                <Datepicker
                  id="startDate"
                  startDate={startDate}
                  dateFormat="yyyy-MM-dd"
                  onDateChange={setStartDate}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  todayButton={t('date-picker-today')}
                />

                <h4 className="mx-1"> - </h4>

                <Datepicker
                  id="endDate"
                  startDate={endDate}
                  dateFormat="yyyy-MM-dd"
                  onDateChange={setEndDate}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  todayButton={t('date-picker-today')}
                />
              </Row>
            </Col>
            <Col className="d-flex justify-content-end mr-2">
              <DownloadExport
                spreadSheetName={'young-summary'}
                type={'young'}
                isSummary
                from={dateStr(startDate)}
                to={dateStr(endDate)}
              />
            </Col>
          </Row>
        </div>
      }
    >
      {isLoading() && <Loader />}
      {!nodata && (
        <div className="react-bootstrap-table table-responsive mt-2 gp-summary-table-div">
          <table id={'summary-young-groups'} className={`${classTable} table-striped gp-table`}>
            <thead>
              <tr>
                {createTh(t('group-sum-generatedName'), 0, '', { rowSpan: 3 })}
                {createTh(t('group-sum-stableName'), 1, '', { rowSpan: 3 })}
                {createTh(t('ygroup-sum-actualAge'), 2, '', { rowSpan: 3 })}
                {createTh(t('group-sum-startDate'), 3, '', { rowSpan: 3 })}
                {createTh(t('group-sum-endDate'), 4, '', { rowSpan: 3 })}
                {createTh(t('ygroup-sum-originNumSum'), 5, '', { colSpan: 2, rowSpan: 2 })}
                {createTh(t('grouppage-num'), 6, '', { colSpan: 2, rowSpan: 2 })}
                {createTh(t('dead'), 7, '', { colSpan: 2, rowSpan: 2 })}
                {createTh(t('ygroup-sum-deadRespSum'), 8, '', { colSpan: 2, rowSpan: 2 })}
                {createTh(t('ygroup-sum-deadEreSum'), 9, '', { colSpan: 2, rowSpan: 2 })}
                {createTh(t('ygroup-sum-wasteSumdeadEreSum'), 10, '', { colSpan: 2, rowSpan: 2 })}
                {createTh(t('ygroup-sum-soldSum'), 11, '', { colSpan: 2, rowSpan: 2 })}
                {createTh(t('ygroup-sum-ownReplacementSum'), 12, '', { colSpan: 2, rowSpan: 2 })}
                {createTh(t('ygroup-sum-deadPercent'), 13, '', { colSpan: 2, rowSpan: 2 })}
                {createTh(t('ygroup-sum-wastePercent'), 14, '', { colSpan: 2, rowSpan: 2 })}
                {createTh(t('ygroup-sum-dropOutSumPercent'), 15, '', { colSpan: 2, rowSpan: 2 })}
                {createTh(t('ygroup-sum-deadRespRate'), 16, '', { colSpan: 2, rowSpan: 2 })}
                {createTh(t('ygroup-sum-deadEreRate'), 17, '', { colSpan: 2, rowSpan: 2 })}
                {createTh(t('ygroup-sum-avgWeights'), 18, '', { colSpan: 32 })}
              </tr>

              <tr>
                {createThL('5', 19, '', { colSpan: 2 })}
                {createThL('6', 20, '', { colSpan: 2 })}
                {createThL('7', 21, '', { colSpan: 2 })}
                {createThL('8', 22, '', { colSpan: 2 })}
                {createThL('9', 23, '', { colSpan: 2 })}
                {createThL('10', 24, '', { colSpan: 2 })}
                {createThL('11', 25, '', { colSpan: 2 })}
                {createThL('12', 26, '', { colSpan: 2 })}
                {createThL('13', 27, '', { colSpan: 2 })}
                {createThL('14', 28, '', { colSpan: 2 })}
                {createThL('15', 29, '', { colSpan: 2 })}
                {createThL('16', 30, '', { colSpan: 2 })}
                {createThL('17', 31, '', { colSpan: 2 })}
                {createThL('18', 32, '', { colSpan: 2 })}
                {createThL('19', 33, '', { colSpan: 2 })}
                {createThL('20', 34, '', { colSpan: 2 })}
              </tr>

              <tr>{renderLabels(t)}</tr>
            </thead>
            <tbody>{renderBody(summaries)}</tbody>
          </table>
        </div>
      )}
      {nodata && <Alert color="warning">{t('no-summary')}</Alert>}
    </PageContainer>
  );
}

export default YoungSummary;
