import React, { useState, useEffect, useCallback } from 'react';
import { SelectRowProps } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import SimpleTable from '../shared/simpleTable';
import Mother from 'models/mother';

interface MotherSelectorProps {
  mothers: Mother[];
  selectedMothers: Mother[];
  onSelectionChange: (mothers: Mother[]) => void;
}

function MotherSelector({ mothers, onSelectionChange, selectedMothers }: MotherSelectorProps) {
  const { t } = useTranslation();
  const [lastSelectedMother, setLastSelectedMother] = useState<Mother>();
  const [shiftPressed, setShiftPressed] = useState<boolean>(false);
  const [sort, setSort] = useState<{ dataField: keyof Mother; order: 'asc' | 'desc' }>({
    dataField: 'cage',
    order: 'asc',
  });

  const handleKeyDown = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === 'Shift') {
        setShiftPressed(true);
      }
    },
    [setShiftPressed]
  );

  const handleKeyUp = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === 'Shift') {
        setShiftPressed(false);
      }
    },
    [setShiftPressed]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  const doShiftSelect = useCallback(
    (selectedMother: Mother) => {
      const sorted = [...mothers].sort((a, b) => {
        const order = sort.order === 'asc' ? 1 : -1;
        return a[sort.dataField] > b[sort.dataField] ? order * 1 : order * -1;
      });

      const lastSelected = lastSelectedMother || mothers[0];

      const from = sorted.findIndex(({ earTag }) => earTag === selectedMother.earTag);
      const to = sorted.findIndex(({ earTag }) => earTag === lastSelected.earTag);

      const selectedEarTags = selectedMothers.map(({ earTag }) => earTag);
      return sorted.reduce<Mother[]>((accumulator, current, currentIndex) => {
        if (
          (currentIndex >= Math.min(from, to) && currentIndex <= Math.max(from, to)) ||
          selectedEarTags.includes(current.earTag)
        ) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
    },
    [sort, lastSelectedMother, selectedMothers, mothers]
  );

  const selectRow: SelectRowProps<Mother> = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: true,
    selected: selectedMothers.map(({ earTag }) => earTag),
    onSelect: (mother, selected) => {
      let newSelectedMothers: Mother[];
      if (selected) {
        if (shiftPressed) {
          newSelectedMothers = doShiftSelect(mother);
        } else {
          newSelectedMothers = [...selectedMothers, mother];
        }
      } else {
        const index = selectedMothers.findIndex(({ earTag }) => earTag === mother.earTag);
        newSelectedMothers = [...selectedMothers];
        newSelectedMothers.splice(index, 1);
      }

      onSelectionChange(newSelectedMothers);
      if (selected) {
        setLastSelectedMother(mother);
      }
    },
  };

  const columns = [
    {
      dataField: 'earTag',
      text: t('ear-tag'),
      sort: false,
    },
    {
      dataField: 'cage',
      text: t('cage'),
      sort: true,
      onSort: (dataField: keyof Mother, order: 'asc' | 'desc') => setSort({ dataField, order }),
    },
    {
      dataField: 'tattoo',
      text: t('tattoo'),
      sort: false,
    },
  ];

  return (
    <SimpleTable
      data={mothers}
      keyField="earTag"
      columns={columns}
      defaultSorted={sort}
      selectRow={selectRow}
      dataCy="mothersList"
    />
  );
}

export default MotherSelector;
