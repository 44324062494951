import React, { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useApiGet } from '../hooks/useApi';
import useSaveUser from '../hooks/useSaveUser';
import Farm from '../models/farm';
import PageContainer from './PageContainer';
import UserForm from 'components/user/userForm';
import User from 'models/user';
import { hasRole } from 'helpers/authUtils';
import Loader from 'hyper/components/Loader';
import { UserRole } from 'models/userRole';

function EditUser() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const goToUsersPage = useCallback(() => history.push('/users'), [history]);
  const { data: user, loading } = useApiGet<User>(`/api/v1/user/${id}`);
  const { error, loading: saving, saveUser } = useSaveUser(goToUsersPage);
  const { data: farms, loading: fetchingFarms } = useApiGet<Farm[]>('/api/v1/user-creation/farm');
  const isLoading = useCallback(() => loading || saving || fetchingFarms, [
    loading,
    saving,
    fetchingFarms,
  ]);

  const { t } = useTranslation();

  const getTitle = useCallback(() => {
    const role = user?.role;
    if (role === UserRole.OWNER) return 'edit-owner';
    if (role === UserRole.ADMIN) return 'edit-admin';
    if (role === UserRole.USER) return 'edit-worker';
    if (role === UserRole.EAR_TAG_TECHNICIAN) return 'edit-ear-tag-technician';
    return 'edit-user';
  }, [user]);

  return (
    <PageContainer title={getTitle()}>
      {isLoading() && <Loader />}
      {error && <Alert color="danger">{t('failed-to-save')}</Alert>}
      {!isLoading() && (
        <UserForm
          user={user || undefined}
          onSave={saveUser}
          onCancel={goToUsersPage}
          farms={farms || []}
          canSelectSite={hasRole(UserRole.OWNER)}
        />
      )}
    </PageContainer>
  );
}

export default EditUser;
