import React, { useState, useCallback, useEffect } from 'react';
import { FormGroup, Label, Input, Col, Row, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { TFunction } from 'i18next';
import RabbitFilterOptions from '../../models/rabbitFilterOptions';
import { RabbitStatus } from '../../models/rabbitStatus';
import { HealthCondition } from '../../models/healthCondition';
import { StockListFilter } from 'models/stockListFilter';
import { CagePosition, getTAlias } from 'models/cagePosition';

interface MultiSelectOption {
  key: string;
  value: any;
  label: string;
}

function getOptionValues(
  values: HealthCondition[] | RabbitStatus[] | undefined,
  t: TFunction
): MultiSelectOption[] {
  const result = [] as MultiSelectOption[];
  if (values) {
    for (const value of values) {
      result.push({ key: `${value}`, label: t(value), value });
    }
  }
  return result;
}

function getAllIllness() {
  return Object.values(HealthCondition)
    .filter((hc) => hc !== HealthCondition.HEALTHY)
    .map((hc) => `${hc}`);
}

function createRowColOption(value: number): MultiSelectOption {
  return { key: `${value}`, value, label: `${value}` };
}

export interface RabbitFilterValues {
  earTag?: string;
  tattoo?: string;
  iaGroup?: number[];
  stables?: number[];
  cagePosition?: string[];
  row?: number[];
  col?: number[];
  rabbitStatus?: string[];
  healthCondition?: string[];
  stockListFilter: string;
}

interface RabbitFilterProps {
  options?: RabbitFilterOptions;
  disabled: boolean;
  outerFilter: RabbitFilterValues;
  onFilter: (filter: RabbitFilterValues) => void;
  onStockListFilterChange?: (stockListFilter: StockListFilter) => void;
}

interface OptionType {
  value: string | number;
  label: string;
}

function range(length: number, first: number) {
  return Array.from(new Array(length), (x, i) => i + first);
}

interface Options {
  hcOptions: MultiSelectOption[];
  posOptions: MultiSelectOption[];
  statusOptions: MultiSelectOption[];
  groupOptions: MultiSelectOption[];
  stableOptions: MultiSelectOption[];
  rowOptions: MultiSelectOption[];
  colOptions: MultiSelectOption[];
  otherOptions: MultiSelectOption[];
}

const initOptions = {
  hcOptions: [],
  posOptions: [],
  statusOptions: [],
  groupOptions: [],
  stableOptions: [],
  rowOptions: [],
  colOptions: [],
  otherOptions: [],
};

function RabbitFilter({
  options,
  disabled,
  outerFilter,
  onFilter,
  onStockListFilterChange,
}: RabbitFilterProps) {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<RabbitFilterValues>(outerFilter);
  const [stockListFilterChanged, setStockListFilterChanged] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const [selectOptions, setSelectOptions] = useState<Options>(initOptions);

  const callEmptyCageFilterChange = useCallback(
    (rabbitFilter: RabbitFilterValues) => {
      if (onStockListFilterChange) {
        const { stockListFilter } = rabbitFilter;
        const slFilter = stockListFilter as StockListFilter;
        onStockListFilterChange(slFilter);
      }
    },
    [onStockListFilterChange]
  );

  const onHandleFilterChange = useCallback(
    (field: keyof RabbitFilterValues, newFilter: RabbitFilterValues): RabbitFilterValues => {
      let result = newFilter;
      if (field === 'stockListFilter') {
        setStockListFilterChanged(true);
        const { stockListFilter } = result;
        if (stockListFilter === StockListFilter.CAGELESS_MOTHER) {
          result = {
            ...result,
            stables: undefined,
            cagePosition: undefined,
            row: undefined,
            col: undefined,
          };
        } else if (stockListFilter !== StockListFilter.ALL_MOTHER) {
          result = {
            ...result,
            earTag: undefined,
            tattoo: undefined,
            iaGroup: undefined,
            rabbitStatus: undefined,
            healthCondition: undefined,
          };
        }
      }
      return result;
    },
    []
  );

  const baseOnChange = useCallback(
    (field: keyof RabbitFilterValues, value: unknown) => {
      let newFilter: RabbitFilterValues;
      if (!value || (Array.isArray(value) && !value.length)) {
        newFilter = { ...filter };
        delete newFilter[field];
      } else {
        newFilter = { ...filter, [field]: value };
      }
      newFilter = onHandleFilterChange(field, newFilter);
      setFilter(newFilter);
    },
    [filter, onHandleFilterChange]
  );

  const onChange = useCallback(
    (field: keyof RabbitFilterValues) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      baseOnChange(field, value);
    },
    [baseOnChange]
  );

  const onSelectChange = useCallback(
    (field: keyof RabbitFilterValues) => (value: any) => {
      const newValue = Array.isArray(value) ? value.map(({ value }) => value) : value?.value;
      baseOnChange(field, newValue);
    },
    [baseOnChange]
  );

  const onIllness = useCallback(
    (e: any) => {
      const newFilter = { ...filter, healthCondition: getAllIllness() };
      setFilter(newFilter);
    },
    [filter]
  );

  const clearFilter = useCallback(() => {
    const { stockListFilter } = filter;
    setFilter({ stockListFilter });
  }, [filter]);

  const doFilter = useCallback(
    (filter: RabbitFilterValues) => {
      onFilter(filter);
      if (stockListFilterChanged && onStockListFilterChange) {
        const { stockListFilter } = filter;
        const slFilter = stockListFilter as StockListFilter;
        onStockListFilterChange(slFilter);
        setStockListFilterChanged(false);
      }
    },
    [onFilter, onStockListFilterChange, stockListFilterChanged]
  );

  const filterWithActual = useCallback(() => doFilter(filter), [doFilter, filter]);

  const getDisabledByEmptyCages = useCallback((): boolean => {
    const { stockListFilter } = filter;
    return disabled || stockListFilter === StockListFilter.EMPTY_CAGE;
  }, [disabled, filter]);

  const getDisabledByCagelessMothers = useCallback((): boolean => {
    const { stockListFilter } = filter;
    return disabled || stockListFilter === StockListFilter.CAGELESS_MOTHER;
  }, [disabled, filter]);

  useEffect(() => {
    if (!options) return;
    setSelectOptions({
      hcOptions: getOptionValues(Object.values(HealthCondition), t),
      statusOptions: getOptionValues(Object.values(RabbitStatus), t),
      posOptions: Object.values(CagePosition).map((item) => ({
        key: `${item}`,
        value: `${item}`,
        label: t(getTAlias(item)),
      })),
      groupOptions: Object.keys(options.iaGroups).map((id) => ({
        key: id,
        value: id,
        label: options.iaGroups[+id],
      })),
      stableOptions: Object.keys(options.stables).map((id) => ({
        key: id,
        value: id,
        label: options.stables[+id],
      })),
      rowOptions: range(options.maxRow, 1).map((value) => createRowColOption(value)),
      colOptions: range(options.maxCol, 1).map((value) => createRowColOption(value)),
      otherOptions: Object.values(StockListFilter).map((item) => ({
        key: `${item}`,
        value: `${item}`,
        label: t(`${item}`),
      })),
    });
  }, [options, t]);

  useEffect(() => {
    callEmptyCageFilterChange(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callEmptyCageFilterChange]);

  const handleEnterPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        filterWithActual();
      }
    },
    [filterWithActual]
  );

  const handleEnterDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Enter' && !menuOpen) {
        doFilter(filter);
      }
    },
    [doFilter, filter, menuOpen]
  );

  const menuOpened = useCallback(() => setMenuOpen(true), []);

  const menuClosed = useCallback(() => setMenuOpen(false), []);

  const noOption = useCallback(() => t('empty-list'), [t]);

  return (
    <Row>
      <Col className="bg-light p-2 rounded">
        <table>
          <tbody>
            <tr>
              <td style={{ width: '100%' }}>
                <Row className="align-items-end">
                  <Col>
                    <FormGroup className="mb-0">
                      <Label className="mr-sm-2">{t('tattoo')}</Label>
                      <Input
                        disabled={getDisabledByEmptyCages()}
                        type="text"
                        name="tattoo"
                        placeholder={t('tattoo')}
                        value={filter.tattoo || ''}
                        onChange={onChange('tattoo')}
                        onKeyPress={handleEnterPress}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-0">
                      <Label className="mr-sm-2">{t('ear-tag')}</Label>
                      <Input
                        disabled={getDisabledByEmptyCages()}
                        type="text"
                        name="ear-tag"
                        placeholder={t('ear-tag')}
                        value={filter.earTag || ''}
                        onChange={onChange('earTag')}
                        onKeyPress={handleEnterPress}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-0">
                      <Label className="mr-sm-2">{t('stables')}</Label>
                      <Select
                        isDisabled={getDisabledByCagelessMothers()}
                        isMulti
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder={t('stables')}
                        noOptionsMessage={noOption}
                        onChange={onSelectChange('stables')}
                        value={selectOptions.stableOptions.filter(({ value }) =>
                          filter.stables?.includes(value)
                        )}
                        options={selectOptions.stableOptions}
                        onKeyDown={handleEnterDown}
                        onMenuOpen={menuOpened}
                        onMenuClose={menuClosed}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-0">
                      <Label className="mr-sm-2">{t('row')}</Label>
                      <Select
                        isDisabled={getDisabledByCagelessMothers()}
                        isMulti
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder={t('row')}
                        noOptionsMessage={noOption}
                        onChange={onSelectChange('row')}
                        value={selectOptions.rowOptions.filter(({ value }) =>
                          filter.row?.includes(value)
                        )}
                        options={selectOptions.rowOptions}
                        onKeyDown={handleEnterDown}
                        onMenuOpen={menuOpened}
                        onMenuClose={menuClosed}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-0">
                      <Label className="mr-sm-2">{t('cage-position')}</Label>
                      <Select
                        isDisabled={getDisabledByCagelessMothers()}
                        isMulti
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder={t('cage-position')}
                        noOptionsMessage={noOption}
                        onChange={onSelectChange('cagePosition')}
                        value={selectOptions.posOptions.filter(({ value }) =>
                          filter.cagePosition?.includes(value)
                        )}
                        options={selectOptions.posOptions}
                        onKeyDown={handleEnterDown}
                        onMenuOpen={menuOpened}
                        onMenuClose={menuClosed}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-0">
                      <Label className="mr-sm-2">{t('cage-number')}</Label>
                      <Select
                        isDisabled={getDisabledByCagelessMothers()}
                        isMulti
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder={t('cage-number')}
                        noOptionsMessage={noOption}
                        onChange={onSelectChange('col')}
                        value={selectOptions.colOptions.filter(({ value }) =>
                          filter.col?.includes(value)
                        )}
                        options={selectOptions.colOptions}
                        onKeyDown={handleEnterDown}
                        onMenuOpen={menuOpened}
                        onMenuClose={menuClosed}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-0">
                      <Label className="mr-sm-2">{t('other-filter')}</Label>
                      <Select
                        isDisabled={disabled}
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder={t('other-filter')}
                        noOptionsMessage={noOption}
                        onChange={onSelectChange('stockListFilter')}
                        value={selectOptions.otherOptions.filter(
                          ({ value }) => filter.stockListFilter === value
                        )}
                        options={selectOptions.otherOptions}
                        onKeyDown={handleEnterDown}
                        onMenuOpen={menuOpened}
                        onMenuClose={menuClosed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </td>
              <td rowSpan={2} className="pl-2 align-bottom">
                <Button block color="primary" className="py-0" onClick={clearFilter}>
                  {t('clear-filters')}
                </Button>
                <Button block color="primary" onClick={filterWithActual}>
                  {t('filter')}
                </Button>
              </td>
            </tr>
            <tr>
              <td style={{ width: '100%' }} className="pt-2">
                <Row className="align-items-end">
                  <Col xs="3">
                    <FormGroup className="mb-0">
                      <Label className="mr-sm-2">{t('ia-group')}</Label>
                      <Select
                        isDisabled={getDisabledByEmptyCages()}
                        isMulti
                        isClearable
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder={t('ia-group')}
                        noOptionsMessage={noOption}
                        onChange={onSelectChange('iaGroup')}
                        value={selectOptions.groupOptions.filter(({ value }) =>
                          filter.iaGroup?.includes(value)
                        )}
                        options={selectOptions.groupOptions}
                        onKeyDown={handleEnterDown}
                        onMenuOpen={menuOpened}
                        onMenuClose={menuClosed}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="3">
                    <FormGroup className="mb-0">
                      <Label className="mr-sm-2">{t('status')}</Label>
                      <Select
                        isDisabled={getDisabledByEmptyCages()}
                        isMulti
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder={t('status')}
                        noOptionsMessage={noOption}
                        onChange={onSelectChange('rabbitStatus')}
                        value={selectOptions.statusOptions.filter(({ value }) =>
                          filter.rabbitStatus?.includes(value)
                        )}
                        options={selectOptions.statusOptions}
                        onKeyDown={handleEnterDown}
                        onMenuOpen={menuOpened}
                        onMenuClose={menuClosed}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup className="mb-0">
                      <Label className="mr-sm-2">
                        {t('health-condition')}
                        <Button
                          size="sm"
                          className="ml-2 py-0"
                          color="primary"
                          disabled={getDisabledByEmptyCages()}
                          onClick={onIllness}
                        >
                          {t('rabbit-filter-ill')}
                        </Button>
                      </Label>
                      <Select
                        isDisabled={getDisabledByEmptyCages()}
                        isMulti
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder={t('health-condition')}
                        noOptionsMessage={noOption}
                        onChange={onSelectChange('healthCondition')}
                        value={selectOptions.hcOptions.filter(({ value }) =>
                          filter.healthCondition?.includes(value)
                        )}
                        options={selectOptions.hcOptions}
                        onKeyDown={handleEnterDown}
                        onMenuOpen={menuOpened}
                        onMenuClose={menuClosed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </td>
            </tr>
          </tbody>
        </table>
      </Col>
    </Row>
  );
}

export default RabbitFilter;
