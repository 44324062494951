import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Input, Button, UncontrolledTooltip } from 'reactstrap';
import '../../../../css/modal.css';
import { TFunction } from 'i18next';
import YoungDiaryEditTransfer from 'models/grouppages/youngDiaryEditTransfer';
import { FeedType } from 'models/grouppages/feedType';
import { FEED_LIMIT, NUMBER_LIMIT } from 'components/grouppages/gpUtil';
import MotherDiaryEditTransfer from 'models/grouppages/motherDiaryEditTransfer';
import FatteningDiaryEditTransfer from 'models/grouppages/fatteningDiaryEditTransfer';
import { ValueType } from 'models/valueType';
import { roundAndRemove } from 'helpers/rabbit';

const GPD_DAILY_FEED_EDIT = 'gpd-daily-feed-edit';

function calculateDailyFeedAmount(energy: string, weight: number): number {
  if (!weight) return 0;
  const result = (Math.pow(Math.pow(weight, 3) / Math.pow(1000, 3), 1 / 4) * 0.69) / +energy;
  return Math.round(result * 1000);
}

function getFeedTypes(t: TFunction): JSX.Element[] {
  const result = [] as JSX.Element[];
  Object.values(FeedType).map((item) =>
    result.push(
      <option key={`origin-${item}`} value={item}>
        {t(`${item}`)}
      </option>
    )
  );
  return result;
}

function energyInput(event: React.FormEvent<HTMLInputElement>) {
  event.currentTarget.value = event.currentTarget.value.replace(/[^0-9\.]/, '');
}

function isEnergyInvalid(value: string | undefined): boolean {
  if (!value || value.length < 1) return false;
  const num = parseFloat(value);
  if (Number.isNaN(num)) return true;
  const dotNum = value.replace(/[^\.]/g, '').length;
  let trimmed = value.replace(/^[0 ]+/, '');
  if (dotNum > 0) trimmed = trimmed.replace(/[0 ]+$/, '');
  if (trimmed.length < 1) {
    trimmed = '0';
  } else if (trimmed.startsWith('.')) {
    trimmed = `0${trimmed}`;
  }
  const result = `${num}` === trimmed || (trimmed.endsWith('.') && dotNum < 2);
  return !result;
}

interface FeedDetailsProps {
  onFeedValueChange: <S>(
    objectKey: React.ReactText,
    key: keyof S,
    valueType: ValueType
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChange: (
    key: keyof YoungDiaryEditTransfer,
    valueType: ValueType
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  editDiaryDetails: MotherDiaryEditTransfer | YoungDiaryEditTransfer | FatteningDiaryEditTransfer;
  isFattening?: boolean;
  setValue?: (key: keyof FatteningDiaryEditTransfer, valueType: ValueType, value: unknown) => void;
}

function FeedDetails({
  onFeedValueChange,
  onChange,
  editDiaryDetails,
  isFattening = false,
  setValue = undefined,
}: FeedDetailsProps) {
  const { t } = useTranslation();
  const [energy, setEnergy] = useState<string>();
  const [shadowEnergy, setShadowEnergy] = useState<string>();
  const [weight, setWeight] = useState<number>();

  const isInvalid = useCallback(
    (value: number | undefined) => (value && value > NUMBER_LIMIT) || false,
    []
  );

  const onEnergyChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEnergy(event.target.value);
  }, []);

  const calculate = useCallback(() => {
    if (energy && !isEnergyInvalid(energy)) {
      const e = parseFloat(energy);
      if (setValue && weight && !Number.isNaN(e)) {
        setValue('dailyFeedAmount', ValueType.NUMBER, calculateDailyFeedAmount(energy, weight));
        const se = `${e}`;
        setEnergy(se);
        setShadowEnergy(se);
      }
    }
  }, [weight, energy, setValue]);

  useEffect(() => {
    if (isFattening) {
      setWeight(
        editDiaryDetails &&
          editDiaryDetails.weights &&
          editDiaryDetails.weights.length > 0 &&
          editDiaryDetails.weights[0].weight
          ? editDiaryDetails.weights[0].weight
          : undefined
      );
    }
  }, [editDiaryDetails, isFattening]);

  return (
    <Row className="d-flex flex-column mt-3">
      <Row className="d-flex justify-content-center align-items-center">
        <h4>{t('diary-feed-details')}</h4>
      </Row>

      {isFattening && (
        <Row className="d-flex flex-row mx-1 mb-1">
          <Col className="d-flex flex-column align-items-center" sm={3}>
            <Row>
              <b>{t(GPD_DAILY_FEED_EDIT)}</b>
            </Row>
            <Row className="w-100">
              <Input
                type="number"
                data-cy={GPD_DAILY_FEED_EDIT}
                min="0"
                max={NUMBER_LIMIT}
                className="text-right"
                invalid={isInvalid(editDiaryDetails?.dailyFeedAmount)}
                defaultValue={editDiaryDetails.dailyFeedAmount}
                key={shadowEnergy}
                onChange={onChange('dailyFeedAmount', ValueType.NUMBER)}
                onInput={roundAndRemove}
              />
            </Row>
          </Col>

          <Col className="d-flex flex-column align-items-center" sm={3}>
            <Row>
              <b>{t('gpd-daily-feed-energy')}</b>
            </Row>
            <Row className="w-100" sm={10}>
              <Col className="px-0 mx-0 w-100">
                <Input
                  type="text"
                  name={'gpd-daily-feed-energy'}
                  min="0"
                  className="text-right"
                  defaultValue={energy}
                  key={shadowEnergy}
                  invalid={isEnergyInvalid(energy)}
                  onInput={energyInput}
                  onChange={onEnergyChange}
                  disabled={!weight}
                />
              </Col>
              <Col className="px-0 ml-1" sm={2}>
                <Button
                  id={'calculate-by-energy'}
                  color="primary"
                  className="m-0 p-0 h-100 w-100"
                  onClick={calculate}
                  disabled={!weight || isEnergyInvalid(energy)}
                >
                  <i className="mdi mdi-settings px-1" />
                </Button>
                <UncontrolledTooltip placement="top" target={'calculate-by-energy'}>
                  {t('gpd-energy-tt')}
                </UncontrolledTooltip>
              </Col>
            </Row>
          </Col>

          <Col className="d-flex flex-column align-items-center" sm={3} />

          <Col className="d-flex flex-column align-items-center" sm={3}>
            <Row>
              <b>{t('gpd-type')}</b>
            </Row>
            <Row className="w-100">
              <Input
                type="select"
                name="label"
                defaultValue={editDiaryDetails?.feed?.type}
                onChange={onFeedValueChange('feed', 'type', ValueType.STRING)}
              >
                {!editDiaryDetails?.feed?.type && <option value={''}>{t('choose-first')}</option>}
                {getFeedTypes(t)}
              </Input>
            </Row>
          </Col>
        </Row>
      )}

      <Row className="d-flex flex-row mx-1">
        {!isFattening && (
          <Col className="d-flex flex-column align-items-center">
            <Row>
              <b>{t(GPD_DAILY_FEED_EDIT)}</b>
            </Row>
            <Row className="w-100">
              <Input
                type="number"
                name={GPD_DAILY_FEED_EDIT}
                min="0"
                max={NUMBER_LIMIT}
                className="text-right"
                invalid={isInvalid(editDiaryDetails?.dailyFeedAmount)}
                defaultValue={editDiaryDetails.dailyFeedAmount}
                onChange={onChange('dailyFeedAmount', ValueType.NUMBER)}
                onInput={roundAndRemove}
              />
            </Row>
          </Col>
        )}

        <Col className="d-flex flex-column align-items-center">
          <Row>
            <b>{t('gpd-feed-amount-edit')}</b>
          </Row>
          <Row className="w-100">
            <Input
              type="number"
              name={'gpd-feed-amount-edit'}
              min="0"
              max={FEED_LIMIT}
              className="text-right"
              invalid={
                (editDiaryDetails?.feed?.amount && editDiaryDetails?.feed?.amount > FEED_LIMIT) ||
                false
              }
              defaultValue={editDiaryDetails?.feed?.amount}
              onInput={roundAndRemove}
              onChange={onFeedValueChange('feed', 'amount', ValueType.NUMBER)}
            />
          </Row>
        </Col>

        <Col className="d-flex flex-column align-items-center">
          <Row>
            <b>{t('gpd-product')}</b>
          </Row>
          <Row className="w-100">
            <Input
              type="text"
              name={'gpd-product'}
              min="0"
              max={NUMBER_LIMIT}
              className="text-right"
              defaultValue={editDiaryDetails?.feed?.productNumber}
              onChange={onFeedValueChange('feed', 'productNumber', ValueType.STRING)}
            />
          </Row>
        </Col>

        {!isFattening && (
          <Col className="d-flex flex-column align-items-center">
            <Row>
              <b>{t('gpd-type')}</b>
            </Row>
            <Row className="w-100">
              <Input
                type="select"
                name="label"
                defaultValue={editDiaryDetails?.feed?.type}
                onChange={onFeedValueChange('feed', 'type', ValueType.STRING)}
              >
                {!editDiaryDetails?.feed?.type && <option value={''}>{t('choose-first')}</option>}
                {getFeedTypes(t)}
              </Input>
            </Row>
          </Col>
        )}

        <Col className="d-flex flex-column align-items-center">
          <Row>
            <b>{t('gpd-silo')}</b>
          </Row>
          <Row className="w-100">
            <Input
              type="text"
              name={'gpd-silo'}
              min="0"
              max={NUMBER_LIMIT}
              className="text-right"
              defaultValue={editDiaryDetails?.feed?.siloName}
              onChange={onFeedValueChange('feed', 'siloName', ValueType.STRING)}
            />
          </Row>
        </Col>

        <Col className="d-flex flex-column align-items-center">
          <Row>
            <b>{t('gpd-serial')}</b>
          </Row>
          <Row className="w-100">
            <Input
              type="text"
              name={'gpd-serial'}
              min="0"
              max={NUMBER_LIMIT}
              className="text-right"
              defaultValue={editDiaryDetails?.feed?.serialNumber}
              onChange={onFeedValueChange('feed', 'serialNumber', ValueType.STRING)}
            />
          </Row>
        </Col>
      </Row>
    </Row>
  );
}

export default FeedDetails;
