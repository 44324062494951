import React, { useState, useEffect } from 'react';

interface IndexOfMothersExportProps {
  onPathChange: (path: string) => void;
}

function IndexOfMothersExport({ onPathChange }: IndexOfMothersExportProps) {
  const [path] = useState<string>('/api/v1/export/index-of-mothers');

  useEffect(() => {
    onPathChange(path);
  }, [path, onPathChange]);

  return <></>;
}

export default IndexOfMothersExport;
