import React, { useState, useEffect } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useApiGet } from '../../hooks/useApi';
import Page from '../../models/page';
import IAGroup from '../../models/IAGroup';
import Loader from 'hyper/components/Loader';

interface FarrowingExportProps {
  onPathChange: (path: string) => void;
}

function FarrowingExport({ onPathChange }: FarrowingExportProps) {
  const { t } = useTranslation();
  const { data: iaGroups, loading } = useApiGet<Page<IAGroup>>('/api/v1/ia-groups');
  const [selectedIaGroupId, setSelectedIaGroupId] = useState<number>();

  useEffect(() => {
    if (selectedIaGroupId) {
      onPathChange(`/api/v1/export/ia-group/${selectedIaGroupId}/farrowing-separation`);
    }
  }, [selectedIaGroupId, onPathChange]);

  return (
    <Row className="d-flex justify-content-center align-items-center">
      <Col xs="auto">
        {loading && <Loader />}
        <Row>
          <Label className="mr-sm-2">{t('ia-group')}</Label>
        </Row>
        <Row>
          <Input
            type="select"
            name="iaSelect"
            onChange={(e) => {
              setSelectedIaGroupId(+e.target.value);
            }}
          >
            {!selectedIaGroupId && <option>{t('choose')}</option>}
            {iaGroups &&
              iaGroups.content.map((iaGroup: IAGroup) => (
                <option key={iaGroup.id} value={iaGroup.id}>
                  {iaGroup.formattedName}
                </option>
              ))}
          </Input>
        </Row>
      </Col>
    </Row>
  );
}

export default FarrowingExport;
