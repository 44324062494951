import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import '../../../../css/modal.css';
import { TFunction } from 'i18next';
import { dateStr, FATTENING_AGE_MAX, FATTENING_AGE_MIN, isDateBetweenRange } from '../../gpUtil';
import ModalError from '../../modalError';
import { useApi, useApiGet } from 'hooks/useApi';
import Loader from 'hyper/components/Loader';
import OriginCommonTransfer from 'models/grouppages/originCommonTransfer';
import Datepicker from 'hyper/components/Datepicker';
import { useInput } from 'hooks/useInput';
import { ValueType } from 'models/valueType';
import { roundAndRemove } from 'helpers/rabbit';
import Farm from 'models/farm';
import GroupNameTransfer from 'models/grouppages/groupNameTransfer';
import { GroupMarker } from 'models/grouppages/groupMarker';
import { getUserFarm } from 'helpers/authUtils';
import FatteningDiaryElementTransfer from 'models/grouppages/fatteningDiaryElementTransfer';

const NUM_LIMIT = 40000;
const WEIGHT_LIMIT = 90000;

function isNumValid(value: number, limit?: number, aboveZero = false) {
  const lower = aboveZero ? 0 < value : 0 <= value;
  const realLimit = limit === undefined ? NUM_LIMIT : limit;
  return lower && value <= realLimit;
}

function isAgeValid(origin: OriginCommonTransfer): boolean {
  const age = origin.age ?? FATTENING_AGE_MIN;
  return FATTENING_AGE_MIN <= age && age <= FATTENING_AGE_MAX;
}

function filterGroupNameOptions(
  groupNames: GroupNameTransfer[] | undefined,
  generatedName: string,
  isMoving: boolean
): GroupNameTransfer[] | undefined {
  if (!isMoving || !groupNames || groupNames.length < 1) return groupNames;
  const result = groupNames.filter((group) => group.formattedName !== generatedName);
  if (!isMoving) return result;
  return result
    .filter((group) => group.groupMarker === GroupMarker.FATTENING)
    .sort((a, b) => new Date(b.diaryStartDate).getTime() - new Date(a.diaryStartDate).getTime());
}

function checkFarm(
  farms: Farm[] | null | undefined,
  farmName: string | undefined | null,
  userFarmId: number | null
): string | null {
  if (farms && farms.length > 0) {
    const first = farms[0];
    if (farmName && first.name === farmName.trim()) return first.name;
    if (userFarmId && first.id === userFarmId) return first.name;
  }
  return null;
}

function calcOriginData(
  originData: OriginCommonTransfer,
  isMoving: boolean,
  isMovingFrom: boolean
): OriginCommonTransfer {
  const { farmName, groupName } = originData;
  if (farmName) originData = { ...originData, farmName: farmName.trim() };
  if (groupName) originData = { ...originData, groupName: groupName.trim() };
  if (isMoving && (isMovingFrom || !originData.id)) {
    const { num, weight } = originData;
    if (num) originData = { ...originData, num: -1 * Math.abs(originData.num) };
    if (weight) originData = { ...originData, weight: -1 * Math.abs(originData.weight) };
  }
  return originData;
}

function calcDataFormOriginal(
  originDetails: OriginCommonTransfer,
  isMoving: boolean,
  farms: Farm[] | null | undefined,
  defaultFarmId: number | null
): OriginCommonTransfer {
  let originData = { ...originDetails };
  if (isMoving) {
    const nextFarmName = checkFarm(farms, null, defaultFarmId);
    if (nextFarmName) originData = { ...originData, farmName: nextFarmName };
    const { num, weight } = originData;
    if (num) originData = { ...originData, num: Math.abs(num) };
    originData = { ...originData, weight: weight ? Math.abs(weight) : 0 };
  }
  return originData;
}

function trimText(event: React.FormEvent<HTMLInputElement>): string {
  const suffixSpace = event.currentTarget.value.endsWith(' ');
  let trimmed = event.currentTarget.value.trim();
  if (trimmed !== '' && suffixSpace) trimmed = `${trimmed} `;
  return trimmed;
}

function getDateInterval(dates: string[] | undefined, validDate: boolean): JSX.Element {
  if (!dates || dates.length < 2) return <p />;
  const className = `text-info font-weight-bold ${validDate ? '' : ' text-danger'}`;
  return (
    <p className={className} style={{ fontSize: '0.8rem' }}>
      <span>{`${dates[0]}`}</span>
      <span className="mx-2"> - </span>
      <span>{`${dates[1]}`}</span>
    </p>
  );
}

function getGroupByName(
  groupNameOptions: GroupNameTransfer[] | undefined,
  groupName: string
): GroupNameTransfer | undefined {
  if (!groupNameOptions) return undefined;
  const realName = groupName.trim();
  if (realName.length < 1) return undefined;
  return groupNameOptions?.find((option) => option.formattedName === realName);
}

function getGroupLifeByGroup(group: GroupNameTransfer | undefined): string[] | undefined {
  if (!group) return undefined;
  return [dateStr(group.diaryStartDate), dateStr(group.diaryEndDate)];
}

function getGroupLife(
  origin: OriginCommonTransfer | undefined,
  groupNameOptions: GroupNameTransfer[] | undefined
): string[] | undefined {
  if (!origin || !groupNameOptions) return undefined;
  const group = getGroupByName(groupNameOptions, origin.groupName || '');
  return getGroupLifeByGroup(group);
}

function isValidDateByGroup(
  origin: OriginCommonTransfer | undefined,
  group: GroupNameTransfer | undefined,
  isMoving: boolean
): boolean {
  if (!isMoving || !origin || !group) return true;
  if (!origin.groupId || !origin.arrivedDate) return false;
  const dates = getGroupLifeByGroup(group);
  if (!dates || dates.length < 2) return false;
  const when = dateStr(origin.arrivedDate);
  return dates[0] <= when && when <= dates[1];
}

function isValidDate(
  origin: OriginCommonTransfer | undefined,
  groupNameOptions: GroupNameTransfer[] | undefined,
  isMoving: boolean
): boolean {
  if (!isMoving || !origin || !groupNameOptions) return true;
  if (!origin.groupId) return false;
  const group = getGroupByName(groupNameOptions, origin.groupName || '');
  if (!group || group.groupId !== origin.groupId) return false;
  return isValidDateByGroup(origin, group, isMoving);
}

function getFirstNonNull(last: number | undefined, max: number | undefined, limit: number) {
  return last === undefined ? max || limit : last;
}

function getOriginNumLimit(
  arriveDate: Date,
  allOrigins: OriginCommonTransfer[] | undefined
): number | undefined {
  const when = dateStr(new Date(arriveDate));
  const selected = allOrigins
    ? allOrigins.filter((origin) => dateStr(origin.arrivedDate) <= when)
    : undefined;
  return selected ? selected.reduce((acc, origin) => acc + origin.num, 0) : undefined;
}

function getOriginWeightLimit(
  arriveDate: Date,
  allOrigins: OriginCommonTransfer[] | undefined
): number | undefined {
  const when = dateStr(new Date(arriveDate));
  const selected = allOrigins
    ? allOrigins.filter((origin) => dateStr(origin.arrivedDate) <= when)
    : undefined;
  return selected ? selected.reduce((acc, origin) => acc + origin.weight, 0) : undefined;
}

function getDiaryForLimit(
  arriveDate: Date,
  diaries: FatteningDiaryElementTransfer[] | undefined,
  allOrigins: OriginCommonTransfer[] | undefined,
  maxNum: number | undefined,
  maxWeight: number | undefined
): FatteningDiaryElementTransfer {
  let lastNum = undefined;
  let lastWeight = undefined;
  const when = dateStr(new Date(arriveDate));
  const selected = diaries
    ? diaries.filter((diary) => dateStr(diary.actualDate) <= when)
    : undefined;
  if (selected && selected.length > 0) {
    for (let i = selected.length - 1; i >= 0; i -= 1) {
      const diary = selected[i];
      if (lastNum === undefined && diary.dayEndNum !== undefined && diary.dayEndNum !== null) {
        lastNum = diary.dayEndNum;
      }
      if (
        lastWeight === undefined &&
        diary.dayEndWeight !== undefined &&
        diary.dayEndWeight !== null
      ) {
        lastWeight = diary.dayEndWeight;
      }
      if (lastNum && lastWeight) break;
    }
  }
  if (lastNum === undefined) lastNum = getOriginNumLimit(arriveDate, allOrigins);
  if (lastWeight === undefined) lastWeight = getOriginWeightLimit(arriveDate, allOrigins);
  return {
    dayEndNum: getFirstNonNull(lastNum, maxNum, NUM_LIMIT),
    dayEndWeight: getFirstNonNull(lastWeight, maxWeight, WEIGHT_LIMIT),
  } as FatteningDiaryElementTransfer;
}

function isNumberValid(
  isMoving: boolean,
  originDetails: OriginCommonTransfer,
  diaries: FatteningDiaryElementTransfer[] | undefined,
  allOrigins: OriginCommonTransfer[] | undefined,
  maxNum: number | undefined
): boolean {
  let limit = NUM_LIMIT;
  if (isMoving) {
    limit =
      getDiaryForLimit(originDetails.arrivedDate, diaries, allOrigins, maxNum, undefined)
        .dayEndNum || 0;
  }
  return isNumValid(originDetails?.num, limit, true);
}

function isWeightValid(
  isMoving: boolean,
  originDetails: OriginCommonTransfer,
  diaries: FatteningDiaryElementTransfer[] | undefined,
  allOrigins: OriginCommonTransfer[] | undefined,
  maxWeight: number | undefined
): boolean {
  if (!originDetails?.weight) return !isMoving;
  const limit = isMoving
    ? getDiaryForLimit(originDetails.arrivedDate, diaries, allOrigins, undefined, maxWeight)
        .dayEndWeight || 0
    : WEIGHT_LIMIT;
  return isNumValid(originDetails?.weight, limit);
}

function isDetailsValid(
  startDate: Date | undefined,
  endDate: Date | undefined,
  originDetails: OriginCommonTransfer,
  groupNameOptions: GroupNameTransfer[] | undefined,
  isMoving: boolean,
  maxNum: number | undefined,
  maxWeight: number | undefined,
  diaries: FatteningDiaryElementTransfer[] | undefined,
  allOrigins: OriginCommonTransfer[] | undefined
): boolean {
  const ageValid = isAgeValid(originDetails);
  const dateValid = isDateBetweenRange(originDetails.arrivedDate, startDate, endDate);
  const isOtherGroupOk = isValidDate(originDetails, groupNameOptions, isMoving);
  let numLimit = NUM_LIMIT;
  let weightLimit = WEIGHT_LIMIT;
  if (isMoving) {
    const { dayEndNum, dayEndWeight } = getDiaryForLimit(
      originDetails.arrivedDate,
      diaries,
      allOrigins,
      maxNum,
      maxWeight
    );
    numLimit = dayEndNum || 0;
    weightLimit = dayEndWeight || 0;
  }
  const numbersValid =
    isNumValid(originDetails?.num, numLimit, true) &&
    isNumValid(originDetails?.num, weightLimit, true);
  return ageValid && dateValid && numbersValid && isOtherGroupOk;
}

function getMovingModalPart(
  editOriginDetails: OriginCommonTransfer,
  initialDate: Date,
  startDate: string | undefined,
  endDate: string | undefined,
  getDayClass: (date: Date) => string,
  onWeaningDateChange: (arrivedDate: Date) => void,
  tYgpt3Farm: string,
  tYgpt3Group: string,
  defaultFarmName: string,
  generatedName: string,
  textTrim: (event: React.FormEvent<HTMLInputElement>) => void,
  handleGroupNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  groupNameOptions: GroupNameTransfer[] | undefined,
  groupLife: string[] | undefined,
  validDateAndGroup: boolean,
  t: TFunction
): JSX.Element {
  const when = editOriginDetails?.arrivedDate
    ? new Date(editOriginDetails?.arrivedDate)
    : new Date(initialDate);
  const dateClassName = `ml-1${validDateAndGroup ? '' : ' text-danger'}`;
  return (
    <>
      <Row className="d-flex justify-content-center">
        <Col className={dateClassName} sm="4">
          <b>{t('gpd-date')}</b>
          <span className="text-danger">*</span>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mt-1">
        <Col sm="4">
          <Datepicker
            startDate={when}
            dateFormat="yyyy-MM-dd"
            minDate={startDate && new Date(startDate)}
            maxDate={endDate && new Date(endDate)}
            dayClassName={getDayClass}
            onDateChange={onWeaningDateChange}
            invalid={!validDateAndGroup}
          />
        </Col>
      </Row>

      <Row className="d-flex justify-content-left mt-5">
        <Col sm="2">
          <div className="text-right mr-2">
            <b>{t('move-origin-from')}</b>
          </div>
        </Col>
        <Col className="ml-1" sm="4">
          <b>{tYgpt3Farm}</b>
        </Col>
        <Col className="ml-1" sm="4">
          <b>{tYgpt3Group}</b>
        </Col>
      </Row>
      <Row className="d-flex justify-content-left mt-1 mb-3">
        <Col sm="2" />
        <Col sm="4">
          <Input
            type="text"
            name={'farmFrom'}
            data-cy="origin-from-farm"
            value={defaultFarmName}
            disabled
          />
        </Col>
        <Col sm="4">
          <Input
            type="text"
            name={'groupFrom'}
            data-cy="origin-from-group"
            value={generatedName}
            disabled
          />
        </Col>
      </Row>

      <Row className="d-flex justify-content-left">
        <Col sm="2">
          <div className="text-right mr-2">
            <b>{t('move-origin-to')}</b>
          </div>
        </Col>
        <Col className="ml-1" sm="4">
          <b>{tYgpt3Farm}</b>
        </Col>
        <Col className="ml-1" sm="4">
          <b>{tYgpt3Group}</b>
          <span className="text-danger">*</span>
        </Col>
      </Row>
      <Row className="d-flex justify-content-left mt-1">
        <Col sm="2" />
        <Col sm="4">
          <Input
            type="text"
            name={'farm'}
            data-cy="origin-to-farm"
            value={defaultFarmName}
            disabled
          />
        </Col>
        <Col sm="4">
          <Input
            type="text"
            name={'group'}
            data-cy="origin-to-group"
            value={editOriginDetails.groupName || ''}
            onInput={textTrim}
            autoComplete="off"
            onChange={handleGroupNameChange}
            list="select-list-groupname-id"
            invalid={!validDateAndGroup}
          />
          <datalist id="select-list-groupname-id">
            {groupNameOptions?.map((groupName, idx) => (
              <option value={groupName.formattedName} key={idx} />
            ))}
          </datalist>
        </Col>
      </Row>
      <Row className="d-flex justify-content-left mt-1">
        <Col sm="6" />
        <Col sm="4">
          <div className="text-center">{getDateInterval(groupLife, validDateAndGroup)}</div>
        </Col>
      </Row>
    </>
  );
}

function getDefaultModalPart(
  editOriginDetails: OriginCommonTransfer,
  startDate: string | undefined,
  endDate: string | undefined,
  getDayClass: (date: Date) => string,
  onWeaningDateChange: (arrivedDate: Date) => void,
  handleFarmNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  farms: Farm[] | null | undefined,
  tYgpt3Farm: string,
  tYgpt3Group: string,
  textTrim: (event: React.FormEvent<HTMLInputElement>) => void,
  handleGroupNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onSimpleValueChange: (
    key: React.ReactText,
    valueType: ValueType
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  groupNameOptions: GroupNameTransfer[] | undefined,
  t: TFunction
): JSX.Element {
  return (
    <>
      <Row className="d-flex justify-content-left">
        <Col sm="2" />
        <Col className="ml-1" sm="4">
          <b>{tYgpt3Farm}</b>
        </Col>
        <Col className="ml-1" sm="4">
          <b>{tYgpt3Group}</b>
        </Col>
      </Row>
      <Row className="d-flex justify-content-left mt-1">
        <Col sm="2" />
        <Col sm="4">
          <Input
            type="text"
            name={'farm'}
            data-cy="origin-from-farm"
            value={editOriginDetails.farmName || ''}
            onInput={textTrim}
            autoComplete="off"
            onChange={handleFarmNameChange}
            list="select-list-farm-id"
          />
          <datalist id="select-list-farm-id">
            {farms && <option value={farms[0].name} key={'farm_name'} />}
          </datalist>
        </Col>
        <Col sm="4">
          <Input
            type="text"
            name={'group'}
            data-cy="origin-from-group"
            value={editOriginDetails.groupName || ''}
            onInput={textTrim}
            autoComplete="off"
            onChange={handleGroupNameChange}
            list="select-list-groupname-id"
          />
          <datalist id="select-list-groupname-id">
            {groupNameOptions?.map((groupName, idx) => (
              <option value={groupName.formattedName} key={idx} />
            ))}
          </datalist>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col>
          <div className="text-center">
            <p className="text-info m-1">{t('origin-farm-hint')}</p>
          </div>
        </Col>
      </Row>

      <Row className="d-flex justify-content-left mt-3">
        <Col sm="2" />
        <Col className="ml-1" sm="4">
          <b> {t('ygpt3-age')}</b>
        </Col>
        <Col className="ml-1" sm="4">
          <Label>
            <b> {t('gpd-date')}</b>
            <span className="text-danger">*</span>
          </Label>
        </Col>
      </Row>
      <Row className="d-flex justify-content-left mt-1">
        <Col sm="2" />
        <Col sm="4">
          <Input
            type="number"
            name={'age'}
            data-cy="origin-age"
            className="text-right"
            min={FATTENING_AGE_MIN}
            max={FATTENING_AGE_MAX}
            invalid={!isAgeValid(editOriginDetails)}
            defaultValue={editOriginDetails?.age}
            onInput={roundAndRemove}
            onChange={onSimpleValueChange('age', ValueType.NUMBER)}
          />
        </Col>
        <Col sm="4">
          <Datepicker
            className="origin-date"
            startDate={editOriginDetails?.arrivedDate && new Date(editOriginDetails?.arrivedDate)}
            dateFormat="yyyy-MM-dd"
            minDate={startDate && new Date(startDate)}
            maxDate={endDate && new Date(endDate)}
            dayClassName={getDayClass}
            onDateChange={onWeaningDateChange}
          />
        </Col>
      </Row>
    </>
  );
}

function habdleError(
  error: any,
  setBackendMessages: React.Dispatch<React.SetStateAction<string[] | null>>
) {
  if (error.status && error.status === 405) return setBackendMessages(['SAVING_ERROR']);
  if (Array.isArray(error)) return setBackendMessages(error);
  if (`${error}`.includes('NetworkError')) return setBackendMessages(['NETWORK_ERROR']);
  setBackendMessages(['SAVING_ERROR']);
}

function handleSave(
  originData: OriginCommonTransfer,
  isMoving: boolean,
  isMovingSource: boolean,
  groupId: number,
  saveOrigin: (groupId: number, originId: number, data: OriginCommonTransfer) => void,
  createOrigin: (groupId: number, data: OriginCommonTransfer) => void
) {
  originData = calcOriginData(originData, isMoving, isMovingSource);
  originData.id
    ? saveOrigin(groupId, originData.id ?? 0, originData)
    : createOrigin(groupId, originData);
}

function handleSubmit(
  startDate: string | undefined,
  endDate: string | undefined,
  maxNum: number | undefined,
  maxWeight: number | undefined,
  editOriginDetails: OriginCommonTransfer,
  groupNameOptions: GroupNameTransfer[] | undefined,
  setBackendMessages: React.Dispatch<React.SetStateAction<string[] | null>>,
  isMoving: boolean,
  doSave: (originData: OriginCommonTransfer) => void,
  setIsAllValid: React.Dispatch<React.SetStateAction<boolean>>,
  diaries: FatteningDiaryElementTransfer[] | undefined,
  allOrigins: OriginCommonTransfer[] | undefined
) {
  const isValid = isDetailsValid(
    startDate ? new Date(startDate) : new Date(),
    endDate ? new Date(endDate) : new Date(),
    editOriginDetails,
    groupNameOptions,
    isMoving,
    maxNum,
    maxWeight,
    diaries,
    allOrigins
  );

  if (isValid) {
    setBackendMessages(null);
    doSave(editOriginDetails);
  }
  setIsAllValid(isValid);
}

function handleGroupNameChange(
  event: React.ChangeEvent<HTMLInputElement>,
  groupNameOptions: GroupNameTransfer[] | undefined,
  onSimpleValueChange: (
    key: React.ReactText,
    valueType: ValueType
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  editOriginDetails: OriginCommonTransfer,
  setEditOriginDetails: React.Dispatch<React.SetStateAction<OriginCommonTransfer>>,
  isMoving: boolean,
  setGroupLife: React.Dispatch<React.SetStateAction<string[] | undefined>>,
  setValidDateAndGroup: React.Dispatch<React.SetStateAction<boolean>>
) {
  const nextName = event.target.value.trim();
  const groupData = getGroupByName(groupNameOptions, nextName);
  onSimpleValueChange('groupName', ValueType.STRING)(event);
  const nextGroupId = groupData?.groupId || undefined;
  const origin = { ...editOriginDetails, groupName: nextName, groupId: nextGroupId };
  setEditOriginDetails(origin);

  if (!isMoving) return;

  const dates = getGroupLifeByGroup(groupData);
  setGroupLife(dates);
  setValidDateAndGroup(isValidDateByGroup(origin, groupData, isMoving));
}

function handleWeaningDateChange(
  arrivedDate: Date,
  editOriginDetails: OriginCommonTransfer,
  setEditOriginDetails: React.Dispatch<React.SetStateAction<OriginCommonTransfer>>,
  groupNameOptions: GroupNameTransfer[] | undefined,
  isMoving: boolean,
  setValidDateAndGroup: React.Dispatch<React.SetStateAction<boolean>>
) {
  const date = new Date(dateStr(arrivedDate));
  const origin = { ...editOriginDetails, arrivedDate: date };
  setEditOriginDetails(origin);
  if (!isMoving) return;

  const groupData = getGroupByName(groupNameOptions, origin.groupName || '');
  setValidDateAndGroup(isValidDateByGroup(origin, groupData, isMoving));
}

function opening(
  isOpen: boolean,
  originDetails: OriginParams,
  defaultFarmId: number | null,
  farms: Farm[] | null | undefined,
  groupNameOptions: GroupNameTransfer[] | undefined,
  endDate: string | undefined,
  setEditOriginDetails: (value: React.SetStateAction<OriginCommonTransfer>) => void,
  setGroupLife: (value: React.SetStateAction<string[] | undefined>) => void,
  setValidDateAndGroup: (value: React.SetStateAction<boolean>) => void,
  setInitialDate: (value: React.SetStateAction<Date>) => void
) {
  if (isOpen) {
    const nextOrigin = calcDataFormOriginal(
      originDetails.origin,
      originDetails.isMoving,
      farms,
      defaultFarmId
    );
    setEditOriginDetails(nextOrigin);
    setGroupLife(getGroupLife(nextOrigin, groupNameOptions));
    setValidDateAndGroup(isValidDate(nextOrigin, groupNameOptions, originDetails.isMoving));
    let when = dateStr(new Date());
    if (endDate && endDate < when) {
      when = endDate;
    }
    setInitialDate(new Date(when));
  } else {
    setGroupLife(undefined);
    setValidDateAndGroup(true);
  }
}

export interface OriginParams {
  origin: OriginCommonTransfer;
  isMoving: boolean;
  isMovingSource: boolean;
  maxNum?: number;
  maxWeight?: number;
}

interface EditFatteningOriginProps {
  groupId: number;
  generatedName: string;
  startDate?: string;
  endDate?: string;
  isOpen: boolean;
  originDetails: OriginParams;
  allOrigins: OriginCommonTransfer[] | undefined;
  diaries?: FatteningDiaryElementTransfer[];
  onSave: () => void;
  toggle: () => void;
  farms: Farm[] | null | undefined;
}

function EditFatteningOriginModal({
  groupId,
  generatedName,
  startDate,
  endDate,
  isOpen,
  originDetails,
  allOrigins,
  diaries,
  toggle,
  onSave,
  farms,
}: EditFatteningOriginProps) {
  const { t } = useTranslation();
  const { data: groupNames, loading: groupNameLoading } = useApiGet<GroupNameTransfer[]>(
    '/api/v1/groups/name'
  );
  const [groupNameOptions, setGroupNameOptions] = useState<GroupNameTransfer[] | undefined>();
  const [editOriginDetails, setEditOriginDetails, onSimpleValueChange] = useInput<
    OriginCommonTransfer
  >({
    ...originDetails.origin,
  });
  const [isAllValid, setIsAllValid] = useState<boolean>(true);
  const [backendMessages, setBackendMessages] = useState<string[] | null>(null);
  const { put, post, loading: saving } = useApi();

  const defaultFarmId = getUserFarm();
  const [defaultFarmName, setDefaultFarmName] = useState('');
  const [isMoving, setIsMoving] = useState<boolean>(false);
  const [isMovingSource, setIsMovingSource] = useState<boolean>(false);
  const [groupLife, setGroupLife] = useState<string[]>();
  const [validDateAndGroup, setValidDateAndGroup] = useState(false);
  const [initialDate, setInitialDate] = useState<Date>(new Date());

  const onData = useCallback(() => {
    setBackendMessages(null);
    onSave();
    toggle();
  }, [onSave, toggle]);

  const textTrim = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    event.currentTarget.value = trimText(event);
  }, []);

  const onError = useCallback((error: any) => habdleError(error, setBackendMessages), []);

  const createOrigin = useCallback(
    (groupId: number, data: OriginCommonTransfer) => {
      post(`/api/v1/group/fattening/${groupId}/origin`, onData, onError, data);
    },
    [onData, onError, post]
  );

  const saveOrigin = useCallback(
    (groupId: number, originId: number, data: OriginCommonTransfer) => {
      put(`/api/v1/group/fattening/${groupId}/origin/${originId}`, onData, onError, data);
    },
    [onData, onError, put]
  );

  const doSave = useCallback(
    (originData: OriginCommonTransfer) =>
      handleSave(originData, isMoving, isMovingSource, groupId, saveOrigin, createOrigin),
    [isMoving, isMovingSource, saveOrigin, groupId, createOrigin]
  );

  const submit = useCallback(
    () =>
      handleSubmit(
        startDate,
        endDate,
        originDetails.maxNum,
        originDetails.maxWeight,
        editOriginDetails,
        groupNameOptions,
        setBackendMessages,
        isMoving,
        doSave,
        setIsAllValid,
        diaries,
        allOrigins
      ),
    [
      doSave,
      editOriginDetails,
      endDate,
      groupNameOptions,
      isMoving,
      startDate,
      originDetails.maxNum,
      originDetails.maxWeight,
      diaries,
      allOrigins,
    ]
  );

  useEffect(() => {
    setIsMoving(isOpen ? originDetails.isMoving : false);
    setIsMovingSource(isOpen ? originDetails.isMovingSource : false);
  }, [isOpen, originDetails.isMoving, originDetails.isMovingSource]);

  useEffect(() => {
    opening(
      isOpen,
      originDetails,
      defaultFarmId,
      farms,
      groupNameOptions,
      endDate,
      setEditOriginDetails,
      setGroupLife,
      setValidDateAndGroup,
      setInitialDate
    );
  }, [
    defaultFarmId,
    farms,
    groupNameOptions,
    isOpen,
    originDetails,
    endDate,
    setEditOriginDetails,
  ]);

  useEffect(() => {
    setGroupNameOptions(
      filterGroupNameOptions(groupNames || undefined, generatedName, originDetails.isMoving)
    );
  }, [groupNames, generatedName, originDetails.isMoving]);

  const handleFarmNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSimpleValueChange('farmName', ValueType.STRING)(event);
      setEditOriginDetails((prevState) => ({
        ...prevState,
        groupName: undefined,
        groupId: undefined,
      }));
    },
    [onSimpleValueChange, setEditOriginDetails]
  );

  const onGroupNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      handleGroupNameChange(
        event,
        groupNameOptions,
        onSimpleValueChange,
        editOriginDetails,
        setEditOriginDetails,
        isMoving,
        setGroupLife,
        setValidDateAndGroup
      ),
    [editOriginDetails, groupNameOptions, isMoving, onSimpleValueChange, setEditOriginDetails]
  );

  const onWeaningDateChange = useCallback(
    (arrivedDate: Date) =>
      handleWeaningDateChange(
        arrivedDate,
        editOriginDetails,
        setEditOriginDetails,
        groupNameOptions,
        isMoving,
        setValidDateAndGroup
      ),
    [editOriginDetails, groupNameOptions, isMoving, setEditOriginDetails]
  );

  const getDayClass = useCallback(
    (date: Date) =>
      isDateBetweenRange(date, startDate, endDate) ? 'available-days' : 'disabled-days',
    [endDate, startDate]
  );

  const isLoading = useCallback(() => saving || groupNameLoading, [groupNameLoading, saving]);

  const tYgpt3Farm = t('ygpt3-farm');
  const tYgpt3Group = t('ygpt3-group');

  useEffect(() => {
    if (farms && farms.length > 0) {
      const { name } = farms.filter((f) => f.id === defaultFarmId)[0];
      setDefaultFarmName(name);
    }
  }, [farms, defaultFarmId]);

  useEffect(() => {
    setIsAllValid(true);
    setBackendMessages(null);
  }, [editOriginDetails]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="modal-content-size"
      className="modal-align"
    >
      <ModalHeader toggle={toggle}>
        {`${isMoving ? t('move-origin') : t('edit-origin')}`}
      </ModalHeader>
      <ModalBody>
        {isLoading() && <Loader />}
        <Form className="form d-flex flex-column justify-content-center">
          {/* Section for fattening moving: date, from, to */}
          {isMoving &&
            getMovingModalPart(
              editOriginDetails,
              initialDate,
              startDate,
              endDate,
              getDayClass,
              onWeaningDateChange,
              tYgpt3Farm,
              tYgpt3Group,
              defaultFarmName,
              generatedName,
              textTrim,
              onGroupNameChange,
              groupNameOptions,
              groupLife,
              validDateAndGroup,
              t
            )}

          {/* Section for default mode: from, age, date */}
          {!isMoving &&
            getDefaultModalPart(
              editOriginDetails,
              startDate,
              endDate,
              getDayClass,
              onWeaningDateChange,
              handleFarmNameChange,
              farms,
              tYgpt3Farm,
              tYgpt3Group,
              textTrim,
              onGroupNameChange,
              onSimpleValueChange,
              groupNameOptions,
              t
            )}

          {/* Common section for pieces / kg values */}
          <Row className="d-flex justify-content-left mt-3">
            <Col sm="2" />
            <Col className="ml-1" sm="4">
              <Label>
                <b>{t('ygpt3-num')}</b>
                <span className="text-danger">*</span>
              </Label>
            </Col>
            <Col className="ml-1" sm="4">
              <b>{t('ygpt3-sum-weight')}</b>
              {isMoving && <span className="text-danger">*</span>}
            </Col>
          </Row>
          <Row className="d-flex justify-content-left mt-1">
            <Col sm="2" />
            <Col sm="4">
              <Input
                type="number"
                name={'num'}
                data-cy="origin-num"
                className="text-right"
                min="0"
                max={NUM_LIMIT}
                invalid={
                  !isNumberValid(
                    isMoving,
                    editOriginDetails,
                    diaries,
                    allOrigins,
                    originDetails.maxNum || NUM_LIMIT
                  )
                }
                defaultValue={editOriginDetails.num}
                onInput={roundAndRemove}
                onChange={onSimpleValueChange('num', ValueType.NUMBER)}
              />
            </Col>
            <Col sm="4">
              <Input
                type="number"
                name={'weight'}
                data-cy="origin-weight"
                className="text-right"
                min="0"
                max={NUM_LIMIT}
                invalid={
                  !isWeightValid(isMoving, editOriginDetails, diaries, allOrigins, WEIGHT_LIMIT)
                }
                defaultValue={editOriginDetails.weight}
                onInput={roundAndRemove}
                onChange={onSimpleValueChange('weight', ValueType.NUMBER)}
              />
            </Col>
          </Row>

          {/* Common section for error messages */}
          <Row className="mt-2">
            <Col>
              <ModalError isInputValid={isAllValid} serverErrors={backendMessages} />
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={submit} className="mr-2">
          {t('save')}
        </Button>
        <Button color="primary" onClick={toggle}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditFatteningOriginModal;
