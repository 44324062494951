import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Input, Row } from 'reactstrap';
import { roundAndRemove } from 'helpers/rabbit';

interface NumericInputProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: number | string;
  label: string;
  unit: string;
  max?: number;
  disabled?: boolean;
  invalid?: boolean;
}

function NumericInput({ onChange, max, value, label, unit, disabled, invalid }: NumericInputProps) {
  const { t } = useTranslation();

  return (
    <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
      <Col sm="4">
        <b>{t(label)}:</b>
      </Col>
      <Col sm="6" className="d-flex justify-content-start p-0 pl-2">
        <Input
          type="number"
          name={label}
          min="0"
          max={max}
          className="text-right"
          disabled={disabled || value === ''}
          value={value}
          onChange={onChange}
          onInput={roundAndRemove}
          invalid={invalid || value < 0}
        />
      </Col>
      <Col className="p-0 pl-1">{t(unit)}</Col>
    </Row>
  );
}

export default NumericInput;
