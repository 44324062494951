import React from 'react';
import { Col, Input, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CheckBox from './checkBox';
import { NUMBER_LIMIT } from 'components/grouppages/gpUtil';
import MedicineTransfer from 'models/grouppages/medicineTransfer';
import { ValueType } from 'models/valueType';

interface HealthDetailsProps {
  onMedicineValueChange: <S>(
    objectKey: React.ReactText,
    key: keyof S,
    valueType: ValueType
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  medicineDetails: MedicineTransfer | undefined;
  isVaccinable?: boolean;
}

function HealthDetails({
  onMedicineValueChange,
  medicineDetails,
  isVaccinable,
}: HealthDetailsProps) {
  const { t } = useTranslation();

  return (
    <Row className="d-flex flex-column mt-3">
      <Row className="d-flex justify-content-center align-items-center">
        <h4>{t('diary-health-details')}</h4>
      </Row>

      <Row className="d-flex flex-row mx-1">
        <Col className="d-flex flex-column align-items-center" sm={4}>
          <Row>
            <b>{t('gpd-treatment')}</b>
          </Row>
          <Row className="w-100">
            <Input
              type="text"
              name={'gpd-treatment'}
              min="0"
              max={NUMBER_LIMIT}
              className="text-right"
              defaultValue={medicineDetails?.treatment}
              onChange={onMedicineValueChange('medicine', 'treatment', ValueType.STRING)}
            />
          </Row>
        </Col>

        {isVaccinable && (
          <CheckBox
            onMedicineValueChange={onMedicineValueChange}
            medicineDetails={medicineDetails}
            label={'gpd-vaccination-tt'}
            medicineKey={'vaccination'}
          />
        )}

        <CheckBox
          onMedicineValueChange={onMedicineValueChange}
          medicineDetails={medicineDetails}
          label={'gpd-drinking-water-tt'}
          medicineKey={'drinkingWater'}
        />

        <CheckBox
          onMedicineValueChange={onMedicineValueChange}
          medicineDetails={medicineDetails}
          label={'medication-1'}
          medicineKey={'medication1'}
        />

        <CheckBox
          onMedicineValueChange={onMedicineValueChange}
          medicineDetails={medicineDetails}
          label={'medication-2'}
          medicineKey={'medication2'}
        />
      </Row>
    </Row>
  );
}

export default HealthDetails;
