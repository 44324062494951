import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardBody, Col, Button, Row, Spinner, Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import Stable from '../../models/stable';
import StableRow from '../../models/row';
import { useSendApiGet, useApi, useApiPut } from '../../hooks/useApi';
import SimpleTable from '../shared/simpleTable';
import { hasRole } from '../../helpers/authUtils';
import CageCompositionTable from './cageCompositionTable';
import StableFormModal from './stableFormModal';
import StableRowFormModal from './stableRowFormModal';

import Loader from 'hyper/components/Loader';
import { UserRole } from 'models/userRole';

interface StableDetailsProps {
  stable: Stable & { farmId: number };
  onStableSelected: (stable: Stable & { farmId: number }) => void;
  onChange: () => void;
}

const cagePositionMapping: { [key: string]: string } = {
  DOWN: 'bottom',
  UP: 'up',
  OUTER: 'outer',
  INNER: 'inner',
};

function mapStableRows(row: StableRow, t: TFunction) {
  const cageCount = row.cages.reduce((prevValue, { from, to = 0 }) => prevValue + to - from + 1, 0);
  const cages = row.cages.map((cage) => ({
    ...cage,
    position: t(cagePositionMapping[cage.position]),
    key: cage.from + cage.position,
  }));

  return {
    ...row,
    cageCount,
    cages,
  };
}

const defaultRow = {
  row: 0,
  cageCount: 0,
  cages: [],
};

const renderCageComposition = (t: TFunction) => ({ cages }: StableRow) => (
  <div className="mx-2 mt-2">
    <h5 className="mb-2">{t('cage-composition')}</h5>
    <CageCompositionTable cages={cages} />
  </div>
);

function StableDetails({ stable, onChange, onStableSelected }: StableDetailsProps) {
  const [rows, setRows] = useState<StableRow[]>();
  const [loadRows, { loading }] = useSendApiGet<StableRow[]>(
    `/api/v1/stable/${stable.id}/cages`,
    setRows
  );
  const [saveStable, { loading: savingStable }] = useApiPut(
    `/api/v1/farm/${stable.farmId}/stable/${stable.id}`,
    onChange
  );
  const { post, loading: savingRow } = useApi();
  const [mappedRows, setMappedRows] = useState<Array<ReturnType<typeof mapStableRows>>>([]);
  const [isStableOpen, setIsStableOpen] = useState<boolean>(false);
  const [isRowOpen, setIsRowOpen] = useState<boolean>(false);
  const [rowToEdit, setRowToEdit] = useState<StableRow>(defaultRow);
  const { t } = useTranslation();
  const { sendDelete, loading: deletingRow, error: rowDeleteError } = useApi();

  const isLoading = useCallback(() => loading || savingStable || savingRow, [
    loading,
    savingStable,
    savingRow,
  ]);

  useEffect(() => {
    loadRows();
  }, [loadRows]);

  const saveRow = useCallback(
    (row: StableRow) => {
      post(
        `/api/v1/stable/${stable.id}/row/${row.row}/cage`,
        () => loadRows(),
        (error: any) => console.log(error),
        row.cages
      );
    },
    [post, stable, loadRows]
  );

  const toggleStable = () => setIsStableOpen(!isStableOpen);

  const toggleRow = () => setIsRowOpen(!isRowOpen);

  const updateStable = useCallback(
    (newStable: Stable & { farmId: number }) => {
      saveStable(newStable);
      onStableSelected(newStable);
    },
    [onStableSelected, saveStable]
  );

  useEffect(() => {
    if (rows && !loading) {
      setMappedRows(rows.map((row) => mapStableRows(row, t)));
    }
  }, [rows, setMappedRows, loading, t]);

  const renderActions = (row: StableRow, stableId: number) => (
    <Button
      color="danger"
      onClick={() => {
        sendDelete(`/api/v1/stable/${stableId}/row/${row.row}`, loadRows);
      }}
    >
      {t('delete')}
      {deletingRow && <Spinner className="spinner-border-sm ml-1" tag="span" color="white" />}
    </Button>
  );

  const columns = [
    {
      dataField: 'row',
      text: t('stable-row'),
      sort: true,
    },
    {
      dataField: 'cageCount',
      text: t('cage-count'),
      sort: true,
    },
    {
      dataField: 'actions',
      isDummyField: true,
      text: t('operations'),
      sort: false,
      formatter: (cell: undefined, row: StableRow) => renderActions(row, stable.id),
    },
  ];

  return (
    <>
      <StableFormModal
        stable={stable}
        isOpen={isStableOpen}
        toggle={toggleStable}
        onSave={updateStable}
      />
      <StableRowFormModal
        row={rowToEdit}
        editMode={rowToEdit.cages.length > 0}
        isOpen={isRowOpen}
        toggle={toggleRow}
        onSave={saveRow}
        stableName={stable.name}
      />
      <Card>
        <CardBody>
          {isLoading() && <Loader />}
          {rowDeleteError && <Alert color="danger">{t('failed-to-delete-stable-row')}</Alert>}
          <Row className="mb-3">
            <Col sm={8}>
              <h4 className="header-title mb-3">{t('stable-details')}</h4>
            </Col>
            <Col sm={4}>
              <div className="text-center mt-sm-0 mt-3 text-sm-right">
                {hasRole(UserRole.ADMIN) && (
                  <>
                    <Button type="button" color="primary" className="mr-2" onClick={toggleStable}>
                      <i className="mdi mdi-pencil-outline mr-1" /> {t('edit')}
                    </Button>
                    <Button
                      type="button"
                      color="success"
                      onClick={() => {
                        setRowToEdit({ ...defaultRow });
                        toggleRow();
                      }}
                    >
                      <i className="mdi mdi-plus mr-1" /> {t('new-row')}
                    </Button>
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            {!loading && (
              <SimpleTable
                striped={false}
                data={mappedRows || []}
                columns={columns}
                keyField="row"
                expandRow={{ renderer: (row) => renderCageComposition(t)(row as any) }}
                defaultSorted={{ dataField: 'row', order: 'asc' }}
              />
            )}
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default StableDetails;
