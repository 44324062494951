import { useState, useCallback } from 'react';
import { downloadFile } from '../../helpers/download';
import { sendRequest } from 'hyper/helpers/api';
import { toQueryString, QueryParams } from 'helpers/api';

type UseDownloadFile = [
  (path: string, fileName: string, queryParams?: QueryParams) => void,
  { error: boolean; loading: boolean }
];

export function useDownloadSpreadsheet(): UseDownloadFile {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const send = useCallback((path: string, fileName: string, queryParams?: QueryParams) => {
    const request = async () => {
      setLoading(true);
      try {
        const query = queryParams ? `?${toQueryString(queryParams)}` : '';
        const response = await sendRequest(`${path}${query}`, {
          method: 'GET',
          headers: {
            Accept:
              'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
        });
        downloadFile(await response.blob(), fileName);
        setError(false);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    return request();
  }, []);

  return [send, { error, loading }];
}
