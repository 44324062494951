import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert } from 'reactstrap';
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import useFileUpload from '../../hooks/useFileUpload';
import { addToClipboard } from '../../helpers/clipboard';
import ImportResult from './importResult';
import FileUploader from 'hyper/components/FileUploader';
import Loader from 'hyper/components/Loader';
import { useDownloadSpreadsheet } from 'hooks/useDownloadFile';

type ImportModalType =
  | 'RABBITS'
  | 'EAR_TAGS'
  | 'SPERMS'
  | 'IAGROUPS'
  | 'FARROWING'
  | 'DAILY_WEIGHTS';

interface ImportModalProps {
  isOpen: boolean;
  toggle: () => void;
  modalType: ImportModalType;
  groupId?: number;
  diaryId?: number;
  weightType?: string;
  onUploadClosed?: () => void;
}

export interface ImportResults {
  row: number;
  col: number;
  code?: number;
  info?: string;
  logType:
    | 'BACKEND_ERROR'
    | 'UNKNOWN_ERROR'
    | 'INVALID_DATA'
    | 'EAR_TAG_ALREADY_IN_USE'
    | 'CAGE_NOT_FOUND'
    | 'MISSING_MOTHER'
    | 'INVALID_MOTHER'
    | 'EID_ALREADY_REGISTERED'
    | 'TAG_ALREADY_REGISTERED'
    | 'INVALID_MOTHER'
    | 'DUPLICATED_MOTHER'
    | 'INVALID_SPERM'
    | 'INVALID_PEDIGREE'
    | 'IMPREGNATED_MOTHER'
    | 'SAVE_ERROR'
    | 'INVALID_PARAMS';
}

function formatResults(results: ImportResults[], modalType: string, t: TFunction) {
  return results.reduce((formatted, { row, col, code, info, logType }) => {
    if (logType === 'UNKNOWN_ERROR') return `${formatted}\n${t(`import-common.${logType}`)}`;
    if (logType === 'BACKEND_ERROR')
      return `${formatted}\n${t(`import-common.${logType}`)}${code}${info ? ` (${info})` : ''}`;
    return `${formatted}\n${t('row')}: ${row}${col ? `, ${t('col')}: ${col}` : ''} - ${t(
      `${modalType}.${logType}`
    )}`;
  }, '');
}

const getModalType = {
  RABBITS: 'rabbit-import',
  EAR_TAGS: 'ear-tags-import',
  SPERMS: 'sperm-import',
  IAGROUPS: 'ia-groups-import',
  FARROWING: 'ia-group-farrowing-import',
  DAILY_WEIGHTS: 'daily-weights-import',
};

const getImportUrlStart = {
  RABBITS: '/api/v1/import/rabbit',
  EAR_TAGS: '/api/v1/import/ear-tag',
  SPERMS: '/api/v1/import/sperm',
  IAGROUPS: '/api/v1/import/ia-groups',
  FARROWING: '/api/v1/import/ia-group',
  DAILY_WEIGHTS: '/api/v1/import/group',
};

const getImportUrlEnd = {
  RABBITS: '',
  EAR_TAGS: '',
  SPERMS: '',
  IAGROUPS: '',
  FARROWING: '/farrowing',
  DAILY_WEIGHTS: '',
};

const calculateUrl = (
  modalType: ImportModalType,
  groupId: number | undefined,
  diaryId: number | undefined,
  weightType: string | undefined
): string => {
  if (modalType === 'DAILY_WEIGHTS') {
    return `${
      getImportUrlStart[modalType]
    }/${groupId}/diary/${diaryId}/weights/${weightType?.toUpperCase()}`;
  }
  return getImportUrlStart[modalType] + (groupId ? `/${groupId}` : '') + getImportUrlEnd[modalType];
};

function ImportModal({
  isOpen,
  toggle,
  modalType,
  groupId,
  diaryId,
  weightType,
  onUploadClosed,
}: ImportModalProps) {
  const { t } = useTranslation();
  const [results, setResults] = useState<ImportResults[]>([]);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [upload, { loading }] = useFileUpload();
  const uploadUrl = calculateUrl(modalType, groupId, diaryId, weightType);
  const [showTemplate, setShowTemplate] = useState<boolean>(false);
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    const template = 'IAGROUPS' === modalType;
    setShowTemplate(template);
    template && setUrl('/api/v1/template/pairing');
  }, [modalType]);

  const reset = useCallback(() => {
    setResults([]);
    setShowResults(false);
    setFile(undefined);
    onUploadClosed && onUploadClosed();
  }, [setResults, setShowResults, onUploadClosed]);

  const onFileAdded = useCallback(
    (files: File[]) => {
      files.length && setFile(files[0]);
    },
    [setFile]
  );

  const onFileUploaded = useCallback(
    (results: ImportResults[]) => {
      setResults(results);
      setShowResults(true);
    },
    [setResults, setShowResults]
  );

  const onFileUploadError = useCallback((error: any) => {
    const errors = [];
    errors.push({
      row: 0,
      col: 0,
      code: error?.status,
      info: error?.statusText ? error?.statusText : undefined,
      logType: error instanceof Response ? 'BACKEND_ERROR' : 'UNKNOWN_ERROR',
    });
    setResults(errors as ImportResults[]);
    setShowResults(true);
  }, []);

  const uploadFile = useCallback(() => {
    file && upload<ImportResults[]>(file, uploadUrl, onFileUploaded, onFileUploadError);
  }, [file, upload, uploadUrl, onFileUploaded, onFileUploadError]);

  const [download, { loading: downloading }] = useDownloadSpreadsheet();

  const downloadTemplate = useCallback(() => {
    url && download(url, `${t('pairing-import-template')}.xlsx`);
  }, [url, download, t]);

  const isLoading = useCallback(() => loading || downloading, [loading, downloading]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} onClosed={reset} size="lg">
      <ModalHeader>{t(`${getModalType[modalType]}.title`)}</ModalHeader>
      <ModalBody>
        {isLoading() && <Loader />}
        {!showResults && <FileUploader onFileUpload={onFileAdded} multiple={false} />}
        {showResults && <ImportResult results={results} modalType={getModalType[modalType]} />}
        {modalType === 'RABBITS' && !showResults && (
          <Alert color="warning" className="mt-1">
            {t('rabbit-import.tattoo-warning')}
          </Alert>
        )}
      </ModalBody>
      <ModalFooter>
        {showTemplate && (
          <Button color="primary" className="mr-auto" onClick={() => downloadTemplate()}>
            {t('sample-excel')}
          </Button>
        )}
        {!showResults && (
          <Button
            color="success"
            disabled={isLoading() || !file}
            onClick={uploadFile}
            className="mr-2"
          >
            {t('upload')}
          </Button>
        )}
        {showResults && (
          <Button
            color="secondary"
            className="mr-2"
            onClick={() => addToClipboard(formatResults(results, getModalType[modalType], t))}
          >
            {t('copy')}
          </Button>
        )}
        <Button color="primary" onClick={toggle}>
          {showResults ? t('ok') : t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ImportModal;
