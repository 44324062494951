import { TFunction } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import PageContainer from '../PageContainer';
import DiaryWeightsBlocks from 'components/grouppages/diaryWeightsBlocks';
import DiaryWeightsStats from 'components/grouppages/diaryWeightsStats';
import DiaryWeightsTable from 'components/grouppages/diaryWeightsTable';
import EditWeightModal from 'components/grouppages/editWeightModal';
import { dateTimeStr, dateToStr, secondToStr } from 'components/grouppages/gpUtil';
import ConfirmModal from 'components/shared/confirmModal';
import ImportModal from 'components/stock/importModal';
import { getLoggedInUser } from 'helpers/authUtils';
import { useApi } from 'hooks/useApi';
import Loader from 'hyper/components/Loader';
import DiaryWeightsStatsTransfer from 'models/grouppages/diaryWeightsStatsTransfer';
import GroupTransfer from 'models/grouppages/groupTransfer';
import StatBlockTransfer from 'models/grouppages/statBlockTransfer';
import StatFullTransfer from 'models/grouppages/statFullTransfer';
import StatInfoTransfer from 'models/grouppages/statInfoTransfer';
import StatWeightTransfer from 'models/grouppages/statWeightTransfer';

const MISSING = '?';
const NO_USER = '><';

const calcPeriodLengthInSec = (info: StatInfoTransfer): number =>
  info ? ((new Date(info.endAt).getTime() - new Date(info.startAt).getTime()) / 1000) >> 0 : 0;

const calcWeightsStatistics = (
  weights: StatWeightTransfer[] | undefined,
  infos: StatInfoTransfer[] | undefined
): DiaryWeightsStatsTransfer | undefined => {
  const allTime = secondToStr(
    infos?.reduce((partialSum, a) => partialSum + calcPeriodLengthInSec(a), 0)
  );
  if (!weights || !weights.length)
    return {
      allNum: 0,
      allTime,
      avg: 0,
      median: 0,
      minimum: 0,
      maximum: 0,
      sd: 0,
      sdPercent: 0.0,
      weights: [],
    };

  const weightsOnly = weights.map((w) => w.weight || 0).sort((a, b) => a - b);

  const weightMin = weightsOnly[0];
  const weightMax = weightsOnly[weightsOnly.length - 1];
  const mid = weightsOnly.length >> 1;
  const weightMed =
    weightsOnly.length % 2 === 1 ? weightsOnly[mid] : (weightsOnly[mid - 1] + weightsOnly[mid]) / 2;
  const weightSum = weightsOnly.reduce((partialSum, a) => partialSum + a, 0);
  const weightAvg = weightSum / weightsOnly.length;

  const sdValues = weights.map((w) => Math.pow((w.weight || 0) - weightAvg, 2));
  const sdValuesSum = sdValues.reduce((partialSum, a) => partialSum + a, 0);
  const sdValuesAvg = sdValuesSum / sdValues.length;
  const SD = Math.sqrt(sdValuesAvg);

  return {
    allNum: weightsOnly.length,
    allTime,
    avg: weightAvg,
    median: weightMed,
    minimum: weightMin,
    maximum: weightMax,
    sd: SD,
    sdPercent: (SD * 100) / weightAvg,
    weights: weightsOnly,
  };
};

const generateDefaultStatBlockTransfer = (name: string | undefined | null): StatBlockTransfer => ({
  userName: name || MISSING,
  blocks: [],
  sumPeriod: 0,
  sumNum: 0,
  sumNumByMinute: 0,
  sumNumByHour: 0,
});

const calculateDiaryBlocks = (
  infos: StatInfoTransfer[] | undefined | null
): StatBlockTransfer[] => {
  const result: StatBlockTransfer[] = [];
  if (!infos || infos.length < 1) {
    return result;
  }

  infos.sort((a, b) => {
    const aUser = a.userName || '';
    const result = aUser.localeCompare(b.userName || '');
    if (result !== 0) return result;
    return new Date(a.startAt).getTime() - new Date(b.startAt).getTime();
  });

  let lastUser = NO_USER;
  let block: StatBlockTransfer = generateDefaultStatBlockTransfer(null);
  for (const info of infos) {
    if (lastUser !== info.userName) {
      block = generateDefaultStatBlockTransfer(info.userName);
      result.push(block);
      lastUser = info.userName || NO_USER;
    }
    block.blocks.push({ ...info, periodLengthInSec: calcPeriodLengthInSec(info) });
    block.sumPeriod += calcPeriodLengthInSec(info);
    block.sumNum += info.measureNum;
  }
  for (const block of result) {
    block.sumNumByMinute = block.sumNum / Math.max(1, block.sumPeriod / 60);
    block.sumNumByHour = block.sumNumByMinute * 60;
  }

  return result;
};

const classTdR = 'text-right px-1';
const classTdL = 'text-left px-1';

const calcExtraContent = (weight: StatWeightTransfer | undefined, t: TFunction): JSX.Element => (
  <div className="react-bootstrap-table table-responsive mt-1 d-flex justify-content-center pt-3">
    <table>
      <tbody>
        <tr>
          <td className={classTdR}>{`${t('sw-edit-weight')} : `}</td>
          <td className={classTdL}>
            <b>{weight?.weight}</b>
            {` ${t('sw-edit-gram')}`}
          </td>
        </tr>
        <tr>
          <td className={classTdR}>{`${t('sw-measured-at')} : `}</td>
          <td className={classTdL}>
            <b>{weight ? dateTimeStr(weight.at) : ''}</b>
          </td>
        </tr>
        <tr>
          <td className={classTdR}>{`${t('sw-measured-user')} : `}</td>
          <td className={classTdL}>
            <b>{weight?.userName}</b>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

const getStableNames = (transfer: GroupTransfer | null): string =>
  transfer?.stablesNames || MISSING;

const getGroupUrl = (groupId: string): string => `/api/v1/group/${groupId}`;

const getWeightsUrl = (groupId: string, diaryId: string, weightType: string): string =>
  `/api/v1/group/${groupId}/diary/${diaryId}/weights/${weightType}`;

const handleSaveWeight = (
  groupId: string,
  diaryId: string,
  weightType: string,
  newStatWeight: StatWeightTransfer | undefined | null,
  sendEdit: <T>(
    path: string,
    onData?: ((data: T) => void) | undefined,
    onError?: ((error: T) => void) | undefined,
    body?: unknown
  ) => void,
  sendCreate: <T>(
    path: string,
    onData?: ((data: T) => void) | undefined,
    onError?: ((error: T) => void) | undefined,
    body?: unknown
  ) => void,
  onSendingData: (response: any) => void,
  onSendingError: (response: any) => void
) => {
  if (newStatWeight) {
    const uri = getWeightsUrl(groupId, diaryId, weightType);
    newStatWeight.id
      ? sendEdit(uri, onSendingData, onSendingError, {
          weightId: newStatWeight.id,
          weight: newStatWeight.weight,
        })
      : sendCreate(uri, onSendingData, onSendingError, {
          weight: newStatWeight.weight,
        });
  }
};

const isMother = (weightType: string): boolean => weightType === 'MOT' || weightType === 'SUC';

const getNumOrMissing = (value: number | undefined): string =>
  value === undefined ? MISSING : `${value}`;

interface DiaryParams {
  diaryId: number;
  actualDate: Date;
  weekOfAge: number;
  dayOfAge: number;
}

interface WeightPageParams {
  id: string;
  diaryId: string;
  weightType: string;
}

function WeightPage() {
  const { id, diaryId, weightType } = useParams<WeightPageParams>();
  const { t } = useTranslation();
  const [group, setGroup] = useState<GroupTransfer | null>();
  const [diary, setDiary] = useState<DiaryParams | null>();
  const [stableName, setStableName] = useState('');
  const { get: getGroup, loading: loadingGroup } = useApi();
  const {
    get: getWeights,
    put: sendEdit,
    post: sendCreate,
    sendDelete,
    loading: working,
  } = useApi();

  const [weights, setWeights] = useState<StatWeightTransfer[]>([]);
  const [statWeightToEdit, setStatWeightToEdit] = useState<StatWeightTransfer>();
  const [transWeightsStats, setTransWeightsStats] = useState<DiaryWeightsStatsTransfer>();
  const [blocks, setBlocks] = useState<StatBlockTransfer[]>();

  const isLoading = useCallback(() => loadingGroup || working, [working, loadingGroup]);

  const onData = useCallback((response: StatFullTransfer) => {
    setDiary({
      diaryId: response.diaryId,
      actualDate: response.actualDate,
      weekOfAge: response.weekOfAge,
      dayOfAge: response.dayOfAge,
    });
    setTransWeightsStats(calcWeightsStatistics(response.weights, response.infos));
    setWeights(response.weights);
    setBlocks(calculateDiaryBlocks(response.infos));
  }, []);

  const onError = useCallback((error: any) => {
    console.error('Error on getting statistical weight data from backend : ', error);
    setTransWeightsStats(calcWeightsStatistics(undefined, undefined));
    setWeights([]);
    setBlocks(calculateDiaryBlocks([]));
  }, []);

  const callGetWeights = useCallback(() => {
    getWeights(getWeightsUrl(id, diaryId, weightType), onData, onError);
  }, [id, diaryId, weightType, onData, onError, getWeights]);

  const onSendingData = useCallback(
    (response) => {
      callGetWeights();
    },
    [callGetWeights]
  );

  const onSendingError = useCallback((response) => {
    console.log('Error with backend : ', response);
  }, []);

  const callGetGroup = useCallback(() => {
    getGroup(
      getGroupUrl(id),
      (response: GroupTransfer) => {
        setGroup(response);
        callGetWeights();
      },
      onSendingError
    );
  }, [getGroup, id, onSendingError, callGetWeights]);

  useEffect(() => {
    callGetGroup();
  }, [callGetGroup]);

  useEffect(() => {
    if (group) {
      setStableName(getStableNames(group));
    }
  }, [diaryId, group]);

  const [editorModal, setEditorModal] = useState(false);
  const toggleEditorModal = useCallback(() => setEditorModal((prev) => !prev), []);
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = useCallback(() => setDeleteModal((prev) => !prev), []);
  const [deleteAllModal, setDeleteAllModal] = useState(false);
  const toggleDeleteAllModal = useCallback(() => setDeleteAllModal((prev) => !prev), []);
  const [importModal, setImportModal] = useState(false);
  const toggleImportModal = useCallback(() => {
    const old = importModal;
    setImportModal(!importModal);
    old && callGetWeights();
  }, [callGetWeights, importModal]);
  const [weightIdToDel, setWeightIdToDel] = useState<number>(0);

  const doSaveWeight = useCallback(
    (newStatWeight?: StatWeightTransfer) =>
      handleSaveWeight(
        id,
        diaryId,
        weightType,
        newStatWeight,
        sendEdit,
        sendCreate,
        onSendingData,
        onSendingError
      ),
    [diaryId, id, weightType, onSendingData, onSendingError, sendCreate, sendEdit]
  );
  const doDeleteWeight = useCallback(() => {
    if (weightIdToDel) {
      sendDelete(getWeightsUrl(id, diaryId, weightType), onSendingData, onSendingError, {
        weightId: weightIdToDel,
      });
    }
  }, [diaryId, id, onSendingData, onSendingError, sendDelete, weightIdToDel, weightType]);
  const doDeleteAllWeight = useCallback(() => {
    sendDelete(getWeightsUrl(id, diaryId, weightType), onSendingData, onSendingError, {
      deleteAll: true,
    });
  }, [diaryId, id, onSendingData, onSendingError, sendDelete, weightType]);

  const callAdd = useCallback(() => {
    setStatWeightToEdit({
      id: 0,
      at: new Date(),
      weight: undefined,
      userName: getLoggedInUser().username,
    });
    toggleEditorModal();
  }, [toggleEditorModal]);
  const callDelAll = useCallback(() => toggleDeleteAllModal(), [toggleDeleteAllModal]);
  const callImport = useCallback(() => toggleImportModal(), [toggleImportModal]);
  const callEdit = useCallback(
    (weightId: number) => {
      const which = weights.find((t) => t.id === weightId);
      if (which) {
        setStatWeightToEdit(which);
        toggleEditorModal();
      }
    },
    [toggleEditorModal, weights]
  );
  const callDel = useCallback(
    (weightId: number) => {
      setWeightIdToDel(weightId);
      toggleDeleteModal();
    },
    [toggleDeleteModal]
  );

  return (
    <>
      {statWeightToEdit && (
        <EditWeightModal
          isEditing={(statWeightToEdit?.id || 0) !== 0}
          isOpen={editorModal}
          origStatWeight={{ ...statWeightToEdit }}
          toggle={toggleEditorModal}
          onSave={doSaveWeight}
        />
      )}
      <ConfirmModal
        title={t('sw-del-title')}
        bodyText={t('sw-del-msg')}
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        onSave={doDeleteWeight}
        extraContent={calcExtraContent(
          weights.find((w) => w.id === weightIdToDel),
          t
        )}
      />
      <ConfirmModal
        title={t('sw-del-all-title')}
        bodyText={t('sw-del-all-msg')}
        isOpen={deleteAllModal}
        toggle={toggleDeleteAllModal}
        onSave={doDeleteAllWeight}
      />
      <ImportModal
        isOpen={importModal}
        toggle={toggleImportModal}
        onUploadClosed={callGetWeights}
        groupId={group?.groupId}
        diaryId={+diaryId}
        weightType={weightType}
        modalType="DAILY_WEIGHTS"
      />
      <PageContainer
        title={
          <Row className="my-2 clearfix align-items-center d-flex">
            <Col sm="auto" className="align-items-center d-flex">
              <h4 className="float-left pr-3">
                {`${t('sw-title')} : ${group?.formattedName || MISSING}`}
              </h4>
              <h4 className="float-left pr-3">
                <span className="badge badge-secondary">{t(weightType)}</span>
              </h4>
              <h4 className="float-left pr-3">
                {`${t('iagroup-stable')} : ${stableName || MISSING}`}
              </h4>
              <h4 className="float-left pr-3">
                {`${t('date')} : ${diary?.actualDate ? dateToStr(diary?.actualDate) : MISSING}`}
              </h4>
              <h4 className="float-left">
                {`${t(isMother(weightType) ? 'sw-age' : 'sw-day')} : ${getNumOrMissing(
                  diary?.dayOfAge
                )}`}
              </h4>
            </Col>
          </Row>
        }
      >
        {isLoading() && <Loader />}

        <Row>
          <Col xs="5">
            <DiaryWeightsTable
              transfer={weights}
              callAdd={callAdd}
              callDelAll={callDelAll}
              callImport={callImport}
              callEdit={callEdit}
              callDel={callDel}
            />
          </Col>
          <Col xs="7">
            <Row>
              <Col>
                <DiaryWeightsStats transfer={transWeightsStats} />
              </Col>
            </Row>
            <Row>
              <Col>
                <DiaryWeightsBlocks transfer={blocks} />
              </Col>
            </Row>
          </Col>
        </Row>
      </PageContainer>
    </>
  );
}

export default WeightPage;
