import React from 'react';
import { useTranslation } from 'react-i18next';
import CageComposition from '../../models/cageComposition';
import SimpleTable from '../shared/simpleTable';

interface CageCompositionTableProps {
  cages: CageComposition[];
}

function CageCompositionTable({ cages }: CageCompositionTableProps) {
  const { t } = useTranslation();

  const columns = [
    {
      dataField: 'position',
      text: t('cage-position'),
      sort: true,
    },
    {
      dataField: 'cageType',
      text: t('cage-type'),
      sort: true,
    },
    {
      dataField: 'from',
      text: t('from'),
      sort: true,
    },
    {
      dataField: 'to',
      text: t('to'),
      sort: true,
    },
  ];

  return (
    <SimpleTable
      data={cages}
      striped
      keyField="key"
      columns={columns}
      defaultSorted={{ dataField: 'position', order: 'asc' }}
    />
  );
}

export default CageCompositionTable;
