import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useApiGet, useApiPost, useApiPut, useApi } from '../hooks/useApi';
import CreatePairings from '../models/createPairings';
import PageContainer from './PageContainer';
import { checkPostAdditionLimit } from './ListIAGoups';
import Loader from 'hyper/components/Loader';
import Mother from 'models/mother';
import IAGroupCreator from 'components/iaGroup/IAGroupCreator';
import IAGroup from 'models/IAGroup';
import CreateIA from 'models/createIA';
import IAPairing from 'models/IAPairing';
import { Pedigree } from 'models/pedigree';

function mapGroupToCreateGroup({ name, startDate }: IAGroup): CreateIA {
  return {
    motherGroup: name,
    startDate: startDate ? startDate.valueOf() : null,
  };
}

function getTitleKey(iaGroup: IAGroup | null | undefined): string {
  return iaGroup &&
    iaGroup.active &&
    (iaGroup.postAdditionCovers || checkPostAdditionLimit(iaGroup))
    ? 'closed-adding-title'
    : 'edit-ia';
}

interface EditIAGroupProps {
  id: string;
}

function EditIAGroup() {
  const { id } = useParams<EditIAGroupProps>();
  const history = useHistory();

  const [idxValue, setValue] = useState(0);
  const [iaGroup, setIaGroup] = useState<IAGroup | null>();
  const [sameIaGroupNameError, setSameIaGroupNameError] = useState(false);
  const { data: iaGroupTmp, loading: loadingIAGroup } = useApiGet<IAGroup>(
    `/api/v1/ia-group/${id}`,
    idxValue
  );

  const [mothers, setMothers] = useState<Mother[] | null>();
  const { get: getMothers, loading: loadingMothers } = useApi();
  const loadMothers = useCallback(() => {
    getMothers(`/api/v1/ia-group/${id}/available-mothers`, (data: Mother[]) => {
      setMothers(data);
    });
  }, [getMothers, id]);

  const invalidate = useCallback(() => setValue((idxValue) => ++idxValue), []);

  const [saveGroup, { error: saveError, loading: saving }] = useApiPut(
    `/api/v1/ia-group/${id}`,
    () => {
      invalidate();
      setSameIaGroupNameError(false);
    },
    (error: any) => {
      if (error && error.status && error.status === 405) {
        setSameIaGroupNameError(true);
      }
    }
  );
  const [savePairings, { error: savePairingsError, loading: savingPairings }] = useApiPost(
    `/api/v1/ia-group/${id}/covers`
  );
  const { sendDelete: deleteCover, loading: deletingCover, error: coverDeleteError } = useApi();

  const { put: putCloseGroup, error: closeGroupError, loading: savingGroup } = useApi();
  const closeGroup = useCallback(
    (callback: () => void) => {
      putCloseGroup(`/api/v1/ia-group/${id}/close`, (data: any) => {
        if (callback) callback();
      });
    },
    [id, putCloseGroup]
  );

  const removePairing = useCallback(
    (motherId: number) => {
      deleteCover(`/api/v1/ia-group/${id}/mother/${motherId}`);
    },
    [deleteCover, id]
  );

  useEffect(() => {
    setIaGroup(iaGroupTmp);
    loadMothers();
  }, [iaGroupTmp, loadMothers]);

  const isLoading = useCallback(
    () =>
      saving || savingPairings || savingGroup || loadingMothers || loadingIAGroup || deletingCover,
    [savingPairings, loadingMothers, savingGroup, saving, loadingIAGroup, deletingCover]
  );

  const isError = useCallback(
    () => savePairingsError || closeGroupError || saveError || coverDeleteError,
    [savePairingsError, closeGroupError, saveError, coverDeleteError]
  );

  const { t } = useTranslation();

  const onPairingsAdded = useCallback(
    (createPairings: CreatePairings) => {
      savePairings(createPairings);
      if (iaGroup) {
        const newPairings = mothers
          ?.filter((mother) => createPairings.motherIds.includes(mother.id))
          .map(
            (mother) =>
              ({
                earTag: mother.earTag,
                cage: mother.cage,
                tattoo: mother.tattoo,
                pedigree: createPairings.pedigree,
                spermLabel: createPairings.spermLabel,
                spermId: createPairings.spermId,
              } as IAPairing)
          );
        if (newPairings) {
          const pairings: IAPairing[] = [...iaGroup.pairings];
          pairings.push(...newPairings);
          let { postAdditionCovers } = iaGroup;
          if (iaGroup.active && !iaGroup.postAdditionCovers) postAdditionCovers = true;
          setIaGroup({ ...iaGroup, postAdditionCovers, pairings });
        }
      }
    },
    [iaGroup, mothers, savePairings]
  );

  const onPairingRemoved = useCallback(
    (motherId: number, earTag: string) => {
      if (iaGroup && (!iaGroup.active || iaGroup.postAdditionCovers)) {
        removePairing(motherId);
        const pairings = iaGroup.pairings.filter((p) => p.earTag !== earTag);
        setIaGroup({ ...iaGroup, pairings });
      }
    },
    [iaGroup, removePairing]
  );

  const onDataChanged = useCallback(
    (name?: string, startDate?: Date) => {
      if (iaGroup) {
        if (name) {
          setIaGroup({ ...iaGroup, name });
        }
        if (startDate) {
          setIaGroup({ ...iaGroup, startDate });
        }
      }
    },
    [iaGroup]
  );

  const onCloseGroup = useCallback(() => {
    if (iaGroup && (!iaGroup.active || iaGroup.postAdditionCovers)) {
      closeGroup(loadMothers);
      setIaGroup({ ...iaGroup, active: true, postAdditionCovers: false, pairings: [] });
    }
  }, [iaGroup, closeGroup, loadMothers]);

  const onImported = useCallback(() => {
    invalidate();
  }, [invalidate]);

  const onSave = useCallback(() => {
    if (iaGroup && !iaGroup.active) {
      saveGroup(mapGroupToCreateGroup(iaGroup));
    }
  }, [iaGroup, saveGroup]);

  const onCancel = useCallback(() => history.push('/ia-groups'), [history]);

  return (
    <PageContainer title={getTitleKey(iaGroup)}>
      {isLoading() && <Loader />}
      {iaGroup && <h2 className="text-center m-0 mb-1">{iaGroup.formattedName}</h2>}
      {!sameIaGroupNameError && isError() && <Alert color="danger">{t('failed-to-save')}</Alert>}
      {sameIaGroupNameError && <Alert color="danger">{t('same-iagroup-edit-error')}</Alert>}
      {iaGroup && (
        <IAGroupCreator
          mothers={mothers || []}
          pedigrees={Object.values(Pedigree).sort()}
          onSave={onSave}
          onClose={onCloseGroup}
          onPairingsAdded={onPairingsAdded}
          onPairingRemoved={onPairingRemoved}
          onCancel={onCancel}
          onDataChanged={onDataChanged}
          onImported={onImported}
          isEdit
          iaGroup={iaGroup}
        />
      )}
    </PageContainer>
  );
}

export default EditIAGroup;
