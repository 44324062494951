import i18n from '../../../../i18n';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Container, Row, Col, Card, CardBody, Label, FormGroup, Button, Alert } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';

import { loginUser } from '../../../redux/actions';
import { isUserAuthenticated } from '../../../helpers/authUtils';
import LoaderWidget from '../../../components/Loader';
import { Trans } from 'react-i18next'

class Login extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.state = {
            username: '',
            password: '',
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * Handles the submit
     */
    handleValidSubmit = (event, values) => {
        this.props.loginUser(values.username, values.password, this.props.history);
    };

    /**
     * Redirect to root
     */
    renderRedirectToRoot = () => {
        const isAuthTokenValid = isUserAuthenticated();
        if (isAuthTokenValid) {
            return <Redirect to="/" />;
        }
    };

    render() {
        const isAuthTokenValid = isUserAuthenticated();
        return (
            <React.Fragment>
                {this.renderRedirectToRoot()}

                {(this._isMounted || !isAuthTokenValid) && (
                    <div className="account-pages mt-5 mb-5">
                        <Container>
                            <Row className="justify-content-center">
                                <Col lg={5}>
                                    <Card>
                                        <div className="card-header pt-2 pb-2 text-center bg-primary">
                                            <span>
                                                <img src="/rabbit-logo.png" alt="" height="80" />
                                            </span>
                                        </div>

                                        <CardBody className="p-4 position-relative">
                                            {/* preloader */}
                                            {this.props.loading && <LoaderWidget />}

                                            <Row>
                                                <Col className="col-12 text-center">
                                                    <p className="text-muted">
                                                    <Trans i18nKey="welcome"/>
                                                    </p>
                                                </Col>
                                            </Row>

                                            <div className="text-center w-75 m-auto">
                                                <h4 className="text-dark-50 text-center mt-0 font-weight-bold">
                                                    <Trans i18nKey="login" />
                                                </h4>
                                            </div>

                                            {this.props.error && (
                                                <Alert color="danger" isOpen={this.props.error ? true : false}>
                                                    <div>{this.props.error}</div>
                                                </Alert>
                                            )}

                                            <AvForm onValidSubmit={this.handleValidSubmit}>
                                                <AvGroup>
                                                    <Label for="username"><Trans i18nKey="username"/></Label>
                                                    <AvInput
                                                        name="username"
                                                        id="username"
                                                        placeholder={i18n.t('auth.name-please')}
                                                        value={this.state.username}
                                                        required
                                                    />
                                                    <AvFeedback><Trans i18nKey="empty-field-error"/></AvFeedback>
                                                </AvGroup>

                                                <AvGroup>
                                                    <Label for="password"><Trans i18nKey="password"/></Label>
                                                    <AvInput
                                                        type="password"
                                                        name="password"
                                                        id="password"
                                                        placeholder={i18n.t('auth.password-please')}
                                                        value={this.state.password}
                                                        required
                                                    />
                                                    <AvFeedback><Trans i18nKey="empty-field-error"/></AvFeedback>
                                                </AvGroup>

                                                <FormGroup className="text-center">
                                                    <Button color="success"><Trans i18nKey="login"/></Button>
                                                </FormGroup>
                                            </AvForm>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col className="col-12 text-center">
                                    <span>
                                        <img src="infoblock.png" alt="" height="220" />
                                    </span>
                                </Col>

                            </Row>
                        </Container>
                        <Row className="position-absolute text-center w-100" style={{ bottom: 0 }}>
                                <Col className="col-12 text-center">
                                    <p className="text-muted mb-0">
                                    <Trans i18nKey="rabbit"/>
                                    </p>
                                    <p className="text-muted mb-0">
                                    <Trans i18nKey="all-rights-reserved"/>
                                    </p>
                                    <p className="text-muted my-1" style={{ fontSize: "smaller" }}>
                                        <a href="/terms-and-conditions-en.html">
                                            <Trans i18nKey="terms-and-conditions"/>
                                        </a>
                                        <span className="mx-1">|</span>
                                        <a href="/privacy-policy-en.html">
                                            <Trans i18nKey="privacy-policy"/>
                                        </a>
                                    </p>
                                </Col>
                            </Row>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { user, loading, error } = state.Auth;
    return { user, loading, error };
};

export default connect(
    mapStateToProps,
    { loginUser }
)(Login);
