import { useState, useEffect, useCallback } from 'react';
import { fetchJSON, fetchText } from 'hyper/helpers/api';

const TYPE_JSON = 'application/json';

const headersForJson = {
  'Content-Type': TYPE_JSON,
  Accept: TYPE_JSON,
};

const headersForText = {
  'Content-Type': 'text/plain',
  Accept: TYPE_JSON,
};

interface UseApi {
  get: <T>(path: string, onData: (data: T) => void, body?: unknown) => void;
  getText: <T>(
    path: string,
    onData: (data: T) => void,
    onError?: (error: T) => void,
    body?: unknown
  ) => void;
  put: <T>(
    path: string,
    onData?: (data: T) => void,
    onError?: (error: T) => void,
    body?: unknown
  ) => void;
  post: <T>(
    path: string,
    onData?: (data: T) => void,
    onError?: (error: T) => void,
    body?: unknown
  ) => void;
  postAsText: <T>(
    path: string,
    onData?: (data: T) => void,
    onError?: (error: T) => void,
    body?: unknown
  ) => void;
  sendDelete: <T>(
    path: string,
    onData?: (data: T) => void,
    onError?: (error: T) => void,
    body?: unknown
  ) => void;
  loading: boolean;
  error: boolean;
}

function useApi(): UseApi {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const send = useCallback(
    <T>(
      path: string,
      method: string,
      onData?: (data: T) => void,
      body?: unknown,
      onError?: (error: T) => void
    ) => {
      const sendRequest = async () => {
        setLoading(true);
        try {
          const response = await fetchJSON<T>(path, {
            method,
            headers: headersForJson,
            body: JSON.stringify(body),
          });
          onData && onData(response);
          setError(false);
        } catch (e) {
          setError(true);
          onError && onError(e);
        } finally {
          setLoading(false);
        }
      };

      return sendRequest();
    },
    []
  );

  const sendText = useCallback(
    <T>(
      path: string,
      method: string,
      onData?: (data: T) => void,
      onError?: (error: T) => void,
      body?: unknown
    ) => {
      const send = async () => {
        setLoading(true);
        try {
          const response = await fetchText<T>(path, {
            method,
            headers: headersForJson,
            body: JSON.stringify(body),
          });
          onData && onData(response);
          setError(false);
        } catch (e) {
          setError(true);
          onError && onError(e);
        } finally {
          setLoading(false);
        }
      };

      return send();
    },
    []
  );

  const sendAsText = useCallback(
    <T>(
      path: string,
      method: string,
      onData?: (data: T) => void,
      onError?: (error: T) => void,
      body?: unknown
    ) => {
      const send = async () => {
        setLoading(true);
        try {
          const response = await fetchText<T>(path, {
            method,
            headers: headersForText,
            body: body as string,
          });
          onData && onData(response);
          setError(false);
        } catch (e) {
          setError(true);
          onError && onError(e);
        } finally {
          setLoading(false);
        }
      };

      return send();
    },
    []
  );

  const get = useCallback(
    <T>(path: string, onData: (data: T) => void) => {
      send(path, 'GET', onData);
    },
    [send]
  );

  const getText = useCallback(
    <T>(path: string, onData: (data: T) => void, onError?: (error: T) => void, body?: unknown) => {
      sendText(path, 'GET', onData, onError, body);
    },
    [sendText]
  );

  const put = useCallback(
    <T>(path: string, onData?: (data: T) => void, onError?: (error: T) => void, body?: unknown) => {
      send(path, 'PUT', onData, body, onError);
    },
    [send]
  );

  const post = useCallback(
    <T>(path: string, onData?: (data: T) => void, onError?: (error: T) => void, body?: unknown) => {
      send(path, 'POST', onData, body, onError);
    },
    [send]
  );

  const postAsText = useCallback(
    <T>(path: string, onData?: (data: T) => void, onError?: (error: T) => void, body?: unknown) => {
      sendAsText(path, 'POST', onData, onError, body);
    },
    [sendAsText]
  );

  const sendDelete = useCallback(
    <T>(path: string, onData?: (data: T) => void, onError?: (error: T) => void, body?: unknown) => {
      send(path, 'DELETE', onData, body, onError);
    },
    [send]
  );

  return {
    get,
    getText,
    put,
    post,
    postAsText,
    sendDelete,
    loading,
    error,
  };
}

function useApiGet<T>(
  path: string,
  index?: number
): { data: T | null; loading: boolean; error: boolean } {
  const [data, setData] = useState<T | null>(null);
  const { get, error, loading } = useApi();

  useEffect(() => {
    get<T>(path, (data: T) => setData(data));
  }, [path, get, index]);

  return { data, loading, error };
}

function useApiPost<T>(
  path: string,
  onData?: (data: T) => void,
  onError?: (data: T) => void
): [(body?: unknown) => void, { error: boolean; loading: boolean }] {
  const { post, error, loading } = useApi();

  const send = useCallback((body?: unknown) => post<T>(path, onData, onError, body), [
    post,
    path,
    onData,
    onError,
  ]);

  return [send, { error, loading }];
}

function useApiPut<T>(
  path: string,
  onData?: (data: T) => void,
  onError?: (data: T) => void
): [(body?: unknown) => void, { error: boolean; loading: boolean }] {
  const { put, error, loading } = useApi();

  const send = useCallback((body?: unknown) => put<T>(path, onData, onError, body), [
    put,
    path,
    onData,
    onError,
  ]);

  return [send, { error, loading }];
}

function useSendApiGet<T>(
  path: string,
  onData: (data: T) => void
): [() => void, { error: boolean; loading: boolean }] {
  const { get, error, loading } = useApi();

  const send = useCallback(() => get(path, onData), [get, path, onData]);

  return [send, { error, loading }];
}

export { useApiGet, useApiPost, useApiPut, useSendApiGet, useApi };
