import React, { useCallback, useEffect, useState } from 'react';
import { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import moment from 'moment';
import RemoteTable from '../components/shared/remoteTable';
import usePaginatedData, { PageRequest } from '../hooks/usePaginatedData';
import PageContainer from './PageContainer';
import Loader from 'hyper/components/Loader';
import { getTableClasses } from 'helpers/tables';
import Command from 'models/commad';
import CommandFilter, { CommandFilterValues } from 'components/command/commandFilter';
import Datepicker from 'hyper/components/Datepicker';
import { dateStr, dateTimeStr } from 'components/grouppages/gpUtil';
import JsonModal from 'components/command/jsonModal';

const PAYLOAD_LENGTH = 0;

function versionFormatter(value: Date) {
  return dateTimeStr(value);
}

function getMaxLenText(value: string, len: number): string {
  if (len < 1 || value.length < len) return value;
  return `${value.substring(0, len)}...`;
}

function payloadFormatter(cell: unknown, row: Command, rowIndex: number, formatExtraData: any) {
  if (!row || !row.payload) return '';
  const txt = getMaxLenText(row.payload, PAYLOAD_LENGTH);
  return (
    <>
      <Button
        type="button"
        color="primary"
        className="p-0 mr-2"
        onClick={() => formatExtraData.action(row.command, row.payload)}
      >
        <i id="placement" className="mdi mdi-information-outline" />
      </Button>
      <span>{txt}</span>
    </>
  );
}

function CommandList() {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<CommandFilterValues>({} as CommandFilterValues);
  const [sort, setSort] = useState<{ dataField: string; order: 'asc' | 'desc' }>({
    dataField: 'version',
    order: 'desc',
  });
  const { loadMore, data: commands, loading } = usePaginatedData<Command>('/api/v1/commands-view');
  const [commandsLoaded, setCommandsLoaded] = useState(false);
  const [startDate, setStartDate] = useState<Date>(moment().add(-1, 'month').toDate());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const [jsonTitle, setJsonTitle] = useState('');
  const [jsonText, setJsonText] = useState('');
  const [jsonModal, setJsonModal] = useState(false);
  const toggleJsonModal = useCallback(() => setJsonModal((prev) => !prev), []);

  const openJsonModal = useCallback((title: string, text: string) => {
    setJsonTitle(title);
    setJsonText(text);
    setJsonModal(true);
  }, []);

  const loadCommands = useCallback(
    (pageRequest: PageRequest, filter: CommandFilterValues) => {
      loadMore(pageRequest, { from: dateStr(startDate), to: dateStr(endDate), ...filter });
    },
    [endDate, loadMore, startDate]
  );

  const load = useCallback(() => {
    loadCommands(
      {
        page: 1,
        pageSize: 100,
        sortFields: [sort.dataField],
        sortOrder: sort.order,
      },
      { ...filter }
    );
  }, [filter, loadCommands, sort.dataField, sort.order]);

  const onFilter = useCallback(
    (newFilter: CommandFilterValues) => {
      setFilter(newFilter);
      loadCommands(
        {
          page: 1,
          pageSize: 100,
          sortFields: [sort.dataField],
          sortOrder: sort.order,
        },
        { ...newFilter }
      );
    },
    [loadCommands, setFilter, sort]
  );

  const onFilterChange = useCallback(
    (newFilter: CommandFilterValues) => {
      setFilter(newFilter);
    },
    [setFilter]
  );

  const getSortField = useCallback((sortField: string) => {
    if (sortField === 'farmName') return 'f.name';
    if (sortField === 'username') return 'a.username';
    return sortField;
  }, []);

  const onTableChange = useCallback(
    (type: TableChangeType, newState: TableChangeState<Command>) => {
      setSort({ dataField: getSortField(newState.sortField), order: newState.sortOrder });
      loadCommands(
        {
          page: newState.page,
          pageSize: newState.sizePerPage,
          sortFields: [getSortField(newState.sortField)],
          sortOrder: newState.sortOrder,
        },
        { ...filter }
      );
    },
    [getSortField, loadCommands, filter]
  );

  const onPageChange = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  const columns = [
    {
      dataField: 'id',
      text: t('command-id'),
      sort: true,
    },
    {
      dataField: 'version',
      text: t('command-version'),
      formatter: versionFormatter,
      sort: true,
    },
    {
      dataField: 'farmName',
      text: t('command-farm'),
      sort: true,
    },
    {
      dataField: 'username',
      text: t('command-user'),
      sort: true,
    },
    {
      dataField: 'command',
      text: t('command-name'),
      sort: true,
    },
    {
      dataField: 'status',
      text: t('command-status'),
      sort: true,
    },
    {
      dataField: 'source',
      text: t('command-source'),
      sort: true,
    },
    {
      dataField: 'payload',
      text: t('command-payload'),
      formatExtraData: { action: openJsonModal },
      formatter: payloadFormatter,
    },
  ];

  useEffect(() => {
    if (!commandsLoaded) {
      setCommandsLoaded(true);
    }
  }, [commands, commandsLoaded]);

  const isLoading = useCallback(() => loading, [loading]);

  return (
    <PageContainer
      title={
        <div className="mb-1 mt-2 clearfix">
          <Row>
            <Col>
              <h4>{t('commands')}</h4>
            </Col>
            <Col md="auto" className="d-flex justify-content-end">
              <Row>
                <Datepicker
                  id="startDate"
                  startDate={startDate}
                  dateFormat="yyyy-MM-dd"
                  onDateChange={setStartDate}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  todayButton={t('date-picker-today')}
                />

                <h4 className="mx-1"> - </h4>

                <Datepicker
                  id="endDate"
                  startDate={endDate}
                  dateFormat="yyyy-MM-dd"
                  onDateChange={setEndDate}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  todayButton={t('date-picker-today')}
                />
              </Row>
            </Col>
            <Col md="auto" className="d-flex justify-content-end ml-2">
              <Button onClick={load} block color="primary">
                {t('filter')}
              </Button>
            </Col>
          </Row>
        </div>
      }
    >
      <JsonModal title={jsonTitle} json={jsonText} isOpen={jsonModal} toggle={toggleJsonModal} />

      {commandsLoaded && (
        <div className="mt-1 mb-2">
          <Row>
            <Col>
              <CommandFilter
                outerFilter={filter}
                onFilter={onFilter}
                onFilterChange={onFilterChange}
              />
            </Col>
          </Row>
        </div>
      )}

      {isLoading() && <Loader />}
      <RemoteTable
        wrapperClasses={getTableClasses('command-table')}
        data={commands}
        onTableChange={onTableChange}
        columns={columns}
        defaultSorted={sort}
        keyField="id"
        sizePerPage={100}
        onPageChange={onPageChange}
        condensed
        paginationSize={11}
      />
    </PageContainer>
  );
}

export default CommandList;
