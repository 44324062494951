import { TFunction } from 'i18next';
import moment from 'moment';
import _ from 'lodash';
import MotherDetails from '../models/motherDetails';
import Cover from '../models/cover';
import { dateComparator } from './dateUtils';

export function defaultOrValue(value: any, t?: TFunction, translate?: boolean) {
  if (translate && t) {
    return value ? t(value) : '-';
  }

  return value !== null && value !== '' ? value : '-';
}

export function getDefaultNumber(value: number) {
  return value !== null ? value : '';
}

const sumFarrowings = (covers: Cover[]) =>
  covers.reduce((count, { viable, unviable }) => {
    if (viable + unviable > 0) {
      count++;
    }
    return count;
  }, 0);

const countSeparations = (covers: Cover[]) =>
  covers.reduce((count, { nestCount }) => {
    if (nestCount > 0) {
      count++;
    }
    return count;
  }, 0);

export function getCalculatedDetails(mother: MotherDetails) {
  const lastCover = [...mother.covers].sort((a, b) =>
    dateComparator(new Date(a.group.startDate), new Date(b.group.startDate), 'desc')
  )[0];

  const firstCover = [...mother.covers].sort((a, b) =>
    dateComparator(new Date(a.group.startDate), new Date(b.group.startDate), 'asc')
  )[0];

  const farrowingCount = sumFarrowings(mother.covers);

  const nannyCount = mother.covers.reduce((count, { nanny }) => {
    if (nanny) {
      count++;
    }
    return count;
  }, 0);

  const gridCount = mother.covers.reduce((count, { grid }) => {
    if (grid) {
      count++;
    }
    return count;
  }, 0);

  const separationCount = countSeparations(mother.covers);

  return {
    iaCount: mother.covers.length,
    farrowingCount,
    separationCount,
    nannyCount,
    lastIaDate: lastCover ? lastCover.group.startDate : undefined,
    firstIaDate: firstCover ? firstCover.group.startDate : undefined,
    pregnantInLastIa: lastCover ? lastCover.pregnant : false,
    buck: lastCover ? lastCover.sperm : '',
    gridCount,
  };
}

export function aggregateCovers(covers: Cover[]) {
  const pregnantCount = covers.reduce((count, { pregnant }) => {
    if (pregnant) {
      count++;
    }
    return count;
  }, 0);

  const countPregnantPercent = (pregnantCount: number, coversLength: number) => {
    if (coversLength === 0 || (coversLength === 0 && pregnantCount === 0)) return 0;

    return Math.round(((pregnantCount / covers.length) * 100 + Number.EPSILON) * 100) / 100;
  };

  const farrowingCount = sumFarrowings(covers);
  const sumAlive = covers.reduce((count, { viable }) => count + viable, 0);
  const sumDead = covers.reduce((count, { unviable }) => count + unviable, 0);
  const sumSeparWeight = covers.reduce((weight, { weight4Week }) => weight + weight4Week, 0);
  const sumSeparated = covers.reduce((count, { nestCount }) => count + nestCount, 0);

  const separationCount = countSeparations(covers);

  const countAverageValues = (sum: number, divider: number) =>
    divider > 0 ? Math.round((10 * sum) / divider + Number.EPSILON) / 10 : 0;

  return {
    coverCount: covers.length,
    pregnantPercent: countPregnantPercent(pregnantCount, covers.length),
    farrowingCount,
    sumBorn: sumAlive + sumDead,
    sumAlive,
    sumSeparWeight,
    sumSeparated,
    separationCount,
    averageBorn: countAverageValues(sumAlive + sumDead, farrowingCount),
    averageAlive: countAverageValues(sumAlive, farrowingCount),
    averageSeparated: countAverageValues(sumSeparated, separationCount),
    averageSeparWeight: countAverageValues(sumSeparWeight, sumSeparated),
  };
}

export function formatDate(date: string | null | undefined) {
  if (date === null || date === undefined) {
    return '-';
  }
  return moment(date).format('YYYY-MM-DD');
}

export function isInputValueInvalid(value: number, max: number) {
  return value < 0 || value > max;
}

export function isNullOrInvalid(value: number | undefined, minValue: number, maxValue: number) {
  return value === undefined || value === null || value < minValue || value > maxValue;
}

export function roundAndRemove(event: React.FormEvent<HTMLInputElement>, noRounding?: boolean) {
  if (noRounding) {
    event.currentTarget.valueAsNumber = +event.currentTarget.value.replace(/-/, '');
  } else {
    event.currentTarget.valueAsNumber = Math.round(+event.currentTarget.value.replace(/-/, ''));
  }
}

export interface LimitException {
  name: string;
  limit: number;
}

export function isInputValuesBetweenLimit(
  editDiaryDetails: object | undefined,
  excludedKeys: string[],
  limit: number,
  exceptions?: LimitException[]
): boolean {
  let valid = true;

  _.each(editDiaryDetails, (value, key) => {
    if (typeof value === 'object' && value !== null) {
      valid = isInputValuesBetweenLimit(value, excludedKeys, limit, exceptions);
      return valid;
    }

    if (exceptions && exceptions?.some((exception) => exception.name === key)) {
      const exceptionLimit =
        exceptions?.find((exception) => exception.name === key)?.limit || 20000;

      valid = value >= 0 && value <= exceptionLimit;
      return valid;
    }

    if (
      !excludedKeys.includes(key) &&
      !exceptions?.some((exception) => exception.name === key) &&
      typeof value === 'number' &&
      (value > limit || value < 0)
    ) {
      valid = false;
      return valid;
    }
  });

  return valid;
}
