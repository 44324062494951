import { TFunction } from 'i18next';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import {
  boolToStr,
  classBase,
  classCellC,
  classCellL,
  classCellR,
  classCellTr,
  classSideButton,
  classTable,
  Counter,
  createClimateDataBlock,
  createCommentDataBlock,
  createTd,
  createTh2,
  createThL2,
  dateStr,
  divide,
  isParentInMergedGrouppage,
  monthDayToStr,
  renderWeightCell,
  round,
  SUBPAGE_SEPARATOR,
  toStr,
} from './gpUtil';
import EditFatteningDiariesModal from './modal/fattening/editFatteningDiariesModal';
import FatteningDiaryElementTransfer from 'models/grouppages/fatteningDiaryElementTransfer';
import EditFatteningTransfer from 'models/grouppages/editFatteningTransfer';
import { DailyWeightType } from 'models/grouppages/dailyWeightType';
import { RedZoneData } from 'pages/grouppages/FatteningEdit';

const drinkingWaterTT = 'ygpd-1-drinking-water-tt';
const medication1TT = 'ygpd-1-medication1-tt';
const medication2TT = 'ygpd-1-medication2-tt';
const deadTT = 'fgpt4-dead-tt';
const deadRespTT = 'fgpt4-dead-resp-tt';
const deadEreTT = 'fgpt4-dead-ere-tt';
const weightTT = 'fgpt4-weight-tt';
const gainWeightTT = 'fgpt4-gain-weight-tt';
const addedTT = 'fgpt4-added-tt';
const dbSoldTT = 'fgpt4-db-sold-tt';
const kgSoldTT = 'fgpt4-kg-sold-tt';
const dbDayEndTT = 'fgpt4-db-day-end-tt';
const kgDayEndTT = 'fgpt4-kg-day-end-tt';
const gpd0Indoor = 'gpd-0-indoor';
const gpdDegrees = 'gpd-degrees';
const gpdDegreesTT = 'gpd-degrees-tt';
const gpdDegreesTTmam = 'gpd-degrees-tt-mam';
const gpdRh = 'gpd-rh';
const gpdRhTT = 'gpd-rh-tt';
const gpdRhTTmam = 'gpd-rh-tt-mam';
const gpdComment = 'gpd-comment';
const gpStable = 'grouppage-stable';

const isWeekend = (row: FatteningDiaryElementTransfer): boolean =>
  ['SUNDAY', 'SATURDAY'].includes(row.dayOfWeek);

const createDiaryButton = (
  title: string,
  idx: number,
  closeDay: boolean,
  disabled: boolean,
  today = false,
  allButton = true,
  diary: FatteningDiaryElementTransfer,
  toggle: (diary: FatteningDiaryElementTransfer) => void
) => {
  const color = disabled ? 'secondary' : 'primary';
  let classname = `text-center ${classBase}`;
  if (today) {
    classname = `bg-warning ${classname}`;
  }
  const key = `td-${idx}`;
  return !disabled || allButton ? (
    <td className={classname} key={key}>
      <Button
        key={title}
        color={color}
        className={`${classSideButton} px-1`}
        onClick={() => {
          toggle(diary);
        }}
      >
        <span style={{ fontSize: 'smaller' }}>{title}</span>
      </Button>
    </td>
  ) : (
    <td className={classCellC} key={key}>
      {title}
    </td>
  );
};

interface WeeklyData {
  weekLength: Map<number, number>;
}

const calcWeeklyData = (data: FatteningDiaryElementTransfer[] | undefined): WeeklyData => {
  const ret: WeeklyData = {
    weekLength: new Map<number, number>(),
  };
  if (!data) return ret;

  let oldWeek = -1;
  let oldLength = -1;
  data.forEach((row) => {
    if (oldWeek !== row.weekOfAge) {
      if (oldWeek !== -1 && oldLength > 0) {
        ret.weekLength.set(oldWeek, oldLength);
      }
      oldLength = 0;
      oldWeek = row.weekOfAge;
    }
    oldLength += 1;
  });
  if (oldWeek !== -1 && oldLength > 0) {
    ret.weekLength.set(oldWeek, oldLength);
  }
  return ret;
};

const getTempTT = (newSchool: boolean, t: TFunction): string =>
  t(newSchool ? gpdDegreesTTmam : gpdDegreesTT);

const getRhTT = (newSchool: boolean, t: TFunction): string => t(newSchool ? gpdRhTTmam : gpdRhTT);

function createMergedClimateHeaderRow(
  transfer: EditFatteningTransfer,
  iidx: Counter,
  headerRow: number,
  t: TFunction
): JSX.Element[] {
  const result = [] as JSX.Element[];
  const newSchool = (transfer.version || 0) > 0;
  transfer.gpMembers?.forEach((gp, idx) => {
    const parts = gp.generatedName.split(SUBPAGE_SEPARATOR);
    if (parts.length > 1) {
      if (headerRow === 1) {
        result.push(
          createTh2(
            `${SUBPAGE_SEPARATOR}${parts[1]}`,
            iidx.idx++,
            gp.generatedName,
            {
              colSpan: newSchool ? 3 : 2,
            },
            `sg-${idx}-${parts[1]}`
          )
        );
      } else {
        newSchool &&
          result.push(
            createThL2(
              t(gpStable).substring(0, 1),
              iidx.idx++,
              t(gpStable),
              undefined,
              `deg${idx}${parts[1]}`
            )
          );
        result.push(
          createThL2(
            t(gpdDegrees),
            iidx.idx++,
            getTempTT(newSchool, t),
            undefined,
            `deg${idx}${parts[1]}`
          )
        );
        result.push(
          createThL2(t(gpdRh), iidx.idx++, getRhTT(newSchool, t), undefined, `rh${idx}${parts[1]}`)
        );
      }
    }
  });
  return result;
}

const createClimateHeader = (
  transfer: EditFatteningTransfer | null | undefined,
  iidx: Counter,
  merged: boolean,
  headerRow: number,
  t: TFunction
): JSX.Element[] => {
  if (!transfer) return [];
  const result = [] as JSX.Element[];
  const newSchool = (transfer.version || 0) > 0;
  const num = transfer.gpMembers?.length || 0;
  if (!merged || num <= 1) {
    if (headerRow === 0) {
      result.push(
        createTh2(t(gpd0Indoor), iidx.idx++, '', { colSpan: newSchool ? 3 : 2, rowSpan: 2 })
      );
    } else if (headerRow === 2) {
      newSchool && result.push(createThL2(t(gpStable).substring(0, 1), iidx.idx++, t(gpStable)));
      result.push(createThL2(t(gpdDegrees), iidx.idx++, getTempTT(newSchool, t)));
      result.push(createThL2(t(gpdRh), iidx.idx++, getRhTT(newSchool, t)));
    }
  } else {
    if (headerRow === 0) {
      result.push(createTh2(t(gpd0Indoor), iidx.idx++, '', { colSpan: 3 * (num - 1) }));
    } else {
      result.push(...createMergedClimateHeaderRow(transfer, iidx, headerRow, t));
    }
  }
  return result;
};

const createCommentHeader = (
  transfer: EditFatteningTransfer | null | undefined,
  iidx: Counter,
  merged: boolean,
  headerRow: number,
  t: TFunction
): JSX.Element[] => {
  if (!transfer) return [];
  const num = transfer.gpMembers?.length || 0;
  if (!merged || num <= 1) {
    const headerRows = [[createTh2(t(gpdComment), iidx.idx++, '', { rowSpan: 3 })], [], []];
    return headerRows[headerRow];
  } else {
    const result = [] as JSX.Element[];
    if (headerRow === 0) {
      return [createTh2(t(gpdComment), iidx.idx++, '', { colSpan: num, rowSpan: 2 })];
    } else if (headerRow === 2) {
      transfer.gpMembers?.forEach((gp, idx) => {
        const parts = gp.generatedName.split(SUBPAGE_SEPARATOR);
        const key = `com${idx}${parts[1]}`;
        result.push(
          parts.length > 1
            ? createTh2(
                `${SUBPAGE_SEPARATOR}${parts[1]}`,
                iidx.idx++,
                gp.generatedName,
                undefined,
                key
              )
            : createTh2(gp.generatedName, iidx.idx++)
        );
      });
    }
    return result;
  }
};

const createFeedBlock = (
  row: FatteningDiaryElementTransfer,
  iidx: Counter,
  t: TFunction,
  cellL: string,
  cellNum: string,
  cellTr: string
): JSX.Element[] => {
  const feedType = row.value.feed?.type ? t(`${row.value.feed?.type}`) : '';
  return [
    createTd(row.id, iidx.idx++, toStr(row.value.dailyFeedAmount), cellNum),
    createTd(row.id, iidx.idx++, toStr(row.value.feed?.amount), cellNum),
    createTd(row.id, iidx.idx++, row.value.feed?.productNumber, cellTr, true),
    createTd(row.id, iidx.idx++, feedType, cellTr),
    createTd(row.id, iidx.idx++, row.value.feed?.siloName, cellL),
    createTd(row.id, iidx.idx++, row.value.feed?.serialNumber, cellTr, true),
  ];
};

const createHealthBlock = (
  row: FatteningDiaryElementTransfer,
  iidx: Counter,
  cellC: string,
  cellTr: string
): JSX.Element[] => [
  createTd(row.id, iidx.idx++, row.value.medicine?.treatment, cellTr, true),
  createTd(row.id, iidx.idx++, boolToStr(row.value.medicine?.drinkingWater), cellC),
  createTd(row.id, iidx.idx++, boolToStr(row.value.medicine?.medication1), cellC),
  createTd(row.id, iidx.idx++, boolToStr(row.value.medicine?.medication2), cellC),
];

const createDataRow = (
  now: string,
  closedAt: string,
  row: FatteningDiaryElementTransfer,
  idx: number,
  week: number,
  weeklyData: WeeklyData,
  diaryMap: Map<number, number> | undefined,
  redZoneMap: Map<number, RedZoneData> | undefined,
  t: TFunction,
  toggle: (diary: FatteningDiaryElementTransfer) => void,
  merged: boolean,
  transfer: EditFatteningTransfer | null | undefined
) => {
  const fisrtWeekday = idx === 0 || row.dayOfAge % 7 === 0;
  const dayName = row.dayOfWeek ? t(`${row.dayOfWeek}`) : '';
  const actDay = dateStr(row.actualDate);
  const closeDay = actDay === closedAt;
  const noButton = (closedAt.length > 0 ? closedAt : now) < actDay;
  const today = now === actDay;
  const background = closeDay ? ' bg-danger' : today ? ' bg-warning' : '';
  const cellL = `${classCellL}${background}`;
  const cellC = `${classCellC}${background}`;
  const cellR = `${classCellR}${background}`;
  const cellTr = `${classCellTr}${background}`;
  const weight = row.value.weights.find((w) => w.type === DailyWeightType.FAT);
  const iidx: Counter = { idx: 0 };
  const firstColRowSpan = weeklyData.weekLength.get(row.weekOfAge);
  const deadRespPercent = toStr(divide(100 * (row.value.deadResp || 0), row.value.dissected), 2);
  const deadErePercent = toStr(divide(100 * (row.value.deadEre || 0), row.value.dissected), 2);
  const redZoneData = redZoneMap && redZoneMap.get(row.dayOfAge);
  const cellDead = redZoneData && redZoneData.redZone ? `${classCellC} bg-danger` : cellC;
  const newSchool = (transfer?.version || 0) > 0;
  const tds = [] as JSX.Element[];

  fisrtWeekday &&
    tds.push(
      createTd(row.id, iidx.idx++, toStr(row.weekOfAge), classCellC, undefined, {
        rowSpan: firstColRowSpan,
      })
    );
  tds.push(
    createDiaryButton(
      monthDayToStr(row.actualDate),
      iidx.idx++,
      closeDay,
      noButton,
      today,
      false,
      row,
      toggle
    )
  );
  tds.push(createTd(row.id, iidx.idx++, `${dayName}.`, cellC, false, undefined, isWeekend(row)));
  tds.push(createTd(row.id, iidx.idx++, `${row.dayOfAge}`, cellC));

  tds.push(createTd(row.id, iidx.idx++, toStr(row.value.dead), cellDead));
  tds.push(createTd(row.id, iidx.idx++, deadRespPercent, cellC));
  tds.push(createTd(row.id, iidx.idx++, deadErePercent, cellC));
  tds.push(
    createTd(
      row.id,
      iidx.idx++,
      renderWeightCell(row, weight?.weight, 'FAT', noButton, merged),
      cellC
    )
  );
  tds.push(createTd(row.id, iidx.idx++, toStr(round(row.weightGain, 1), 1), cellC));
  tds.push(createTd(row.id, iidx.idx++, toStr(row.addedNum), cellC));

  tds.push(...createClimateDataBlock(newSchool, row, iidx, merged, transfer, cellC, cellC));

  !merged && tds.push(...createFeedBlock(row, iidx, t, cellL, cellC, cellTr));

  tds.push(...createCommentDataBlock(row, iidx, merged, transfer, cellTr));

  !merged && tds.push(...createHealthBlock(row, iidx, cellC, cellTr));

  tds.push(createTd(row.id, iidx.idx++, toStr(row.value.soldNum), cellC));
  tds.push(createTd(row.id, iidx.idx++, toStr(row.value.soldWeight), cellC));

  tds.push(createTd(row.id, iidx.idx++, toStr(row.dayEndNum), cellC));
  tds.push(createTd(row.id, iidx.idx++, toStr(round(row.dayEndWeight, 1), 1), cellC));

  return <tr key={`dtr-${row.id}`}>{tds}</tr>;
};

const renderBody = (
  transfer: EditFatteningTransfer | null | undefined,
  diaryMap: Map<number, number> | undefined,
  redZoneMap: Map<number, RedZoneData> | undefined,
  t: TFunction,
  toggle: (diary: FatteningDiaryElementTransfer) => void,
  merged: boolean
) => {
  const data = transfer?.diary.diaries;
  if (!data) {
    return <></>;
  }
  const weeklyData = calcWeeklyData(data);
  const result: JSX.Element[] = [];
  const closedAt = dateStr(transfer?.endDate);
  const now = dateStr(new Date());
  data.forEach((row, idx) => {
    result.push(
      createDataRow(
        now,
        closedAt,
        row,
        idx,
        (idx / 7) >> 0,
        weeklyData,
        diaryMap,
        redZoneMap,
        t,
        toggle,
        merged,
        transfer
      )
    );
  });
  return result;
};

interface FatteningEditDiariesProps {
  transfer: EditFatteningTransfer | null | undefined;
  diaryMap: Map<number, number> | undefined;
  redZoneMap: Map<number, RedZoneData> | undefined;
  refresh: () => void;
}

function FatteningEditDiaries({
  transfer,
  diaryMap,
  redZoneMap,
  refresh,
}: FatteningEditDiariesProps) {
  const { t } = useTranslation();

  const [isFatteningDiaryEditOpen, setIsFatteningDiaryEditOpen] = useState<boolean>(false);
  const [selectedDiary, setSelectedDiary] = useState<FatteningDiaryElementTransfer | undefined>(
    undefined
  );

  const refreshPage = useCallback(() => {
    refresh();
  }, [refresh]);

  const toggle = useCallback(() => {
    setIsFatteningDiaryEditOpen(!isFatteningDiaryEditOpen);
  }, [isFatteningDiaryEditOpen]);

  const toggleDiaryEditModal = useCallback(
    (diary: FatteningDiaryElementTransfer) => {
      setSelectedDiary(diary);
      toggle();
    },
    [toggle]
  );

  const tableHeader = useMemo(() => {
    const db = t('piece');
    const kg = t('kilogram');
    const gPerDay = t('fgpt3-g-per-day');
    const g = t('gramm');
    const percent = t('gp-percent');
    const merged = isParentInMergedGrouppage(transfer);
    const tr1 = [] as JSX.Element[];
    const tr2 = [] as JSX.Element[];
    const tr3 = [] as JSX.Element[];
    const iidx = { idx: 0 };
    if (merged) {
      tr1.push(createTh2(t('gpd-0-diary'), iidx.idx++, '', { colSpan: 4, rowSpan: 2 }));
      tr1.push(createTh2(t('fgpd-0-dead'), iidx.idx++, t('ygpd-0-dead'), { rowSpan: 2 }));
      tr1.push(createTh2(t('fgpd-0-dead-resp'), iidx.idx++, t(deadRespTT), { rowSpan: 2 }));
      tr1.push(createTh2(t('ygpd-0-dead-ere'), iidx.idx++, t(deadEreTT), { rowSpan: 2 }));
      tr1.push(createTh2(t('ygpd-0-weight'), iidx.idx++, '', { rowSpan: 2 }));
      tr1.push(
        createTh2(
          t('fgpd4-gain-weight'),
          iidx.idx++,
          t(gainWeightTT),
          { rowSpan: 2 },
          `h${gainWeightTT}`
        )
      );
      tr1.push(createTh2(t('fgpd4-added-short'), iidx.idx++, t('fgpd4-added'), { rowSpan: 2 }));
      tr1.push(...createClimateHeader(transfer, iidx, merged, 0, t));
      tr1.push(...createCommentHeader(transfer, iidx, merged, 0, t));
      tr1.push(createTh2(t('ygpd-0-sold'), iidx.idx++, '', { colSpan: 2, rowSpan: 2 }));
      tr1.push(createTh2(t('ygpd-0-day-end'), iidx.idx++, '', { colSpan: 2, rowSpan: 2 }));

      iidx.idx = 0;
      tr2.push(...createClimateHeader(transfer, iidx, merged, 1, t));

      iidx.idx = 0;
      tr3.push(createThL2(t('gpd-week'), iidx.idx++, t('gpd-week-tt')));
      tr3.push(createThL2(t('gpd-date'), iidx.idx++));
      tr3.push(createThL2(t('gpd-day'), iidx.idx++, t('gpd-day-tt')));
      tr3.push(createThL2(t('gpd-age'), iidx.idx++, t('gpd-age-tt')));
      tr3.push(createThL2(db, iidx.idx++, t(deadTT), undefined, deadTT));
      tr3.push(createThL2(percent, iidx.idx++, t(deadRespTT), undefined, deadRespTT));
      tr3.push(createThL2(percent, iidx.idx++, t(deadEreTT), undefined, deadEreTT));
      tr3.push(createThL2(g, iidx.idx++, t(weightTT), undefined, weightTT));
      tr3.push(createThL2(gPerDay, iidx.idx++, t(gainWeightTT), undefined, gainWeightTT));
      tr3.push(createThL2(db, iidx.idx++, t(addedTT), undefined, addedTT));
      tr3.push(...createClimateHeader(transfer, iidx, merged, 2, t));
      tr3.push(...createCommentHeader(transfer, iidx, merged, 2, t));
      tr3.push(createThL2(db, iidx.idx++, t(dbSoldTT), undefined, dbSoldTT));
      tr3.push(createThL2(kg, iidx.idx++, t(kgSoldTT), undefined, kgSoldTT));
      tr3.push(createThL2(db, iidx.idx++, t(dbDayEndTT), undefined, dbDayEndTT));
      tr3.push(createThL2(kg, iidx.idx++, t(kgDayEndTT), undefined, kgDayEndTT));
    } else {
      tr1.push(createTh2(t('gpd-0-diary'), iidx.idx++, '', { colSpan: 4, rowSpan: 2 }));
      tr1.push(createTh2(t('fgpd-0-dead'), iidx.idx++, t('ygpd-0-dead'), { rowSpan: 2 }));
      tr1.push(createTh2(t('fgpd-0-dead-resp'), iidx.idx++, t(deadRespTT), { rowSpan: 2 }));
      tr1.push(createTh2(t('ygpd-0-dead-ere'), iidx.idx++, t(deadEreTT), { rowSpan: 2 }));
      tr1.push(createTh2(t('ygpd-0-weight'), iidx.idx++, '', { rowSpan: 2 }));
      tr1.push(
        createTh2(
          t('fgpd4-gain-weight'),
          iidx.idx++,
          t(gainWeightTT),
          { rowSpan: 2 },
          `h${gainWeightTT}`
        )
      );
      tr1.push(createTh2(t('fgpd4-added-short'), iidx.idx++, t('fgpd4-added'), { rowSpan: 2 }));
      tr1.push(...createClimateHeader(transfer, iidx, merged, 0, t));
      tr1.push(createTh2(t('gpd-0-food'), iidx.idx++, '', { colSpan: 6, rowSpan: 2 }));
      tr1.push(...createCommentHeader(transfer, iidx, merged, 0, t));
      tr1.push(createTh2(t('gpd-0-medicine'), iidx.idx++, '', { colSpan: 4 }));
      tr1.push(createTh2(t('ygpd-0-sold'), iidx.idx++, '', { colSpan: 2, rowSpan: 2 }));
      tr1.push(createTh2(t('ygpd-0-day-end'), iidx.idx++, '', { colSpan: 2, rowSpan: 2 }));

      iidx.idx = 0;
      tr2.push(createTh2('', iidx.idx++, ''));
      tr2.push(
        createTh2(
          toStr(transfer?.diary.medicineSumValues.drinkingWaterSum) || '0',
          iidx.idx++,
          t(drinkingWaterTT),
          undefined,
          drinkingWaterTT
        )
      );
      tr2.push(
        createTh2(
          toStr(transfer?.diary.medicineSumValues.medicine1Sum) || '0',
          iidx.idx++,
          t(medication1TT),
          undefined,
          medication1TT
        )
      );
      tr2.push(
        createTh2(
          toStr(transfer?.diary.medicineSumValues.medicine2Sum) || '0',
          iidx.idx++,
          t(medication2TT),
          undefined,
          medication2TT
        )
      );

      iidx.idx = 0;
      tr3.push(createThL2(t('gpd-week'), iidx.idx++, t('gpd-week-tt')));
      tr3.push(createThL2(t('gpd-date'), iidx.idx++));
      tr3.push(createThL2(t('gpd-day'), iidx.idx++, t('gpd-day-tt')));
      tr3.push(createThL2(t('gpd-age'), iidx.idx++, t('gpd-age-tt')));
      tr3.push(createThL2(db, iidx.idx++, t(deadTT), undefined, deadTT));
      tr3.push(createThL2(percent, iidx.idx++, t(deadRespTT), undefined, deadRespTT));
      tr3.push(createThL2(percent, iidx.idx++, t(deadEreTT), undefined, deadEreTT));
      tr3.push(createThL2(g, iidx.idx++, t(weightTT), undefined, weightTT));
      tr3.push(createThL2(gPerDay, iidx.idx++, t(gainWeightTT), undefined, gainWeightTT));
      tr3.push(createThL2(db, iidx.idx++, t(addedTT), undefined, addedTT));
      tr3.push(...createClimateHeader(transfer, iidx, merged, 2, t));
      tr3.push(createThL2(t('gpd-daily-feed'), iidx.idx++, t('gpd-daily-feed-tt')));
      tr3.push(createThL2(t('gpd-feed-amount-short'), iidx.idx++, t('gpd-feed-amount-tt')));
      tr3.push(createThL2(t('gpd-product-short'), iidx.idx++, t('gpd-product')));
      tr3.push(createThL2(t('gpd-type'), iidx.idx++, t('gpd-type-tt')));
      tr3.push(createThL2(t('gpd-silo'), iidx.idx++));
      tr3.push(createThL2(t('gpd-serial'), iidx.idx++));
      tr3.push(createThL2(t('gpd-treatment-short'), iidx.idx++, t('gpd-treatment')));
      tr3.push(
        createThL2(
          t('gpd-drinking-water'),
          iidx.idx++,
          t('gpd-drinking-water-tt'),
          undefined,
          'gpd-drinking-water'
        )
      );
      tr3.push(...createCommentHeader(transfer, iidx, merged, 2, t));
      tr3.push(createThL2(t('gpd-medication1'), iidx.idx++, t('gpd-medication1-tt')));
      tr3.push(createThL2(t('gpd-medication2'), iidx.idx++, t('gpd-medication2-tt')));
      tr3.push(createThL2(db, iidx.idx++, t(dbSoldTT), undefined, dbSoldTT));
      tr3.push(createThL2(kg, iidx.idx++, t(kgSoldTT), undefined, kgSoldTT));
      tr3.push(createThL2(db, iidx.idx++, t(dbDayEndTT), undefined, dbDayEndTT));
      tr3.push(createThL2(kg, iidx.idx++, t(kgDayEndTT), undefined, kgDayEndTT));
    }
    return (
      <>
        <tr>{tr1}</tr>
        <tr>{tr2}</tr>
        <tr>{tr3}</tr>
      </>
    );
  }, [t, transfer]);

  const tableContent = useMemo(() => {
    const merged = isParentInMergedGrouppage(transfer);
    return (
      <>
        <thead>{tableHeader}</thead>
        <tbody>{renderBody(transfer, diaryMap, redZoneMap, t, toggleDiaryEditModal, merged)}</tbody>
      </>
    );
  }, [tableHeader, diaryMap, redZoneMap, t, toggleDiaryEditModal, transfer]);

  return (
    <>
      {selectedDiary && (
        <EditFatteningDiariesModal
          id={transfer?.id}
          isParent={isParentInMergedGrouppage(transfer)}
          toggle={toggle}
          isOpen={isFatteningDiaryEditOpen}
          onSave={refreshPage}
          diaryDetails={selectedDiary}
        />
      )}
      <div className="react-bootstrap-table table-responsive">
        <table
          className={`${classTable} table-striped gp-table data-cy-diary-table`}
          id={'fattening-diary-table'}
        >
          {tableContent}
        </table>
      </div>
    </>
  );
}

export default FatteningEditDiaries;
