import React, { useCallback, useEffect, useState } from 'react';
import '../../../../css/modal.css';
import StableModal from './stableModal';
import { useApiPut } from 'hooks/useApi';
import Farm from 'models/farm';
import Stable from 'models/stable';
import { isNullOrInvalid } from 'helpers/rabbit';
import { FATTENING_AGE_MAX, FATTENING_AGE_MIN } from 'components/grouppages/gpUtil';
import { GroupMarker } from 'models/grouppages/groupMarker';

const FATTENING_AGE_LIMIT = FATTENING_AGE_MAX - 1;

function calcURL(type: GroupMarker, groupId: number, deliveryAge: number | undefined): string {
  let result = '';
  if (type === GroupMarker.FATTENING)
    result = `/api/v1/group/fattening/${groupId}?deliveryAge=${deliveryAge ?? ''}`;
  if (type === GroupMarker.MOTHER) result = `/api/v1/group/mother/${groupId}`;
  return result;
}

interface OptionType {
  value: number;
  label: string;
}

interface MultiStableProps {
  id: number;
  type: GroupMarker;
  isOpen: boolean;
  isMulti: boolean;
  stables: Stable[];
  forbiddenStables: Stable[];
  plannedDeliveryAge?: number;
  onSave: () => void;
  toggle: () => void;
  farms: Farm[] | null | undefined;
}

function MultiStableModal({
  id,
  type,
  isOpen,
  isMulti,
  stables,
  forbiddenStables,
  plannedDeliveryAge,
  toggle,
  onSave,
  farms,
}: MultiStableProps) {
  const [selectedStables, setSelectedStables] = useState<OptionType[] | null>(null);
  const [backendMessages, setBackendMessages] = useState<string[] | null>(null);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [deliveryAge, setDeliveryAge] = useState<number>();

  const [saveGrouppageDetails, { loading: saving }] = useApiPut<{ messages: string[] }>(
    calcURL(type, id, deliveryAge),
    (response) => {
      if (response.messages && response.messages.length > 0) {
        setBackendMessages(response.messages);
      } else {
        setBackendMessages(null);
        onSave();
        toggle();
      }
    }
  );

  useEffect(() => {
    if (isOpen && stables) {
      const selected: OptionType[] = [];
      stables.forEach((stable) =>
        selected.push({
          value: stable.id,
          label: stable.name,
        })
      );
      setSelectedStables(selected);
    } else {
      setSelectedStables(null);
    }

    setDeliveryAge(plannedDeliveryAge);
    setIsValid(true);
  }, [isOpen, plannedDeliveryAge, stables]);

  useEffect(() => {
    setBackendMessages(null);
  }, [selectedStables]);

  const onChange = useCallback((value) => {
    const next = Array.isArray(value) ? value : [value];
    setSelectedStables(next);
    setIsValid(true);
  }, []);

  const submit = useCallback(() => {
    if (
      selectedStables &&
      selectedStables?.length > 0 &&
      (deliveryAge === undefined ||
        !isNullOrInvalid(deliveryAge, FATTENING_AGE_MIN, FATTENING_AGE_LIMIT))
    ) {
      const stables: Stable[] = [];
      selectedStables.forEach((selectedStable) =>
        stables.push({ id: selectedStable.value, name: selectedStable.label })
      );
      saveGrouppageDetails(type === GroupMarker.MOTHER ? stables[0] : stables);
    } else {
      setIsValid(false);
    }
  }, [deliveryAge, saveGrouppageDetails, selectedStables, type]);

  const onValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDeliveryAge(+event.target.value);
    setIsValid(true);
  }, []);

  return (
    <StableModal
      isOpen={isOpen}
      farms={farms}
      backendMessages={backendMessages}
      selectedStable={selectedStables}
      forbiddenStable={forbiddenStables}
      saving={saving}
      isValid={isValid}
      isMulti={isMulti}
      deliveryAge={deliveryAge}
      isFattening={type === GroupMarker.FATTENING}
      submit={submit}
      onChange={onChange}
      onDeliveryAgeChange={onValueChange}
      toggle={toggle}
    />
  );
}

export default MultiStableModal;
