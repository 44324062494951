import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import RabbitDetails from '../components/rabbit/rabbitDetails';
import { useSendApiGet } from '../hooks/useApi';
import MotherDetails from '../models/motherDetails';
import { getCalculatedDetails, aggregateCovers } from '../helpers/rabbit';
import PageContainer from './PageContainer';
import RabbitPerformance from 'components/rabbit/rabbitPerformance';
import Covers from 'components/rabbit/covers';
import Loader from 'hyper/components/Loader';
import CalculatedDetails from 'models/calculatedDetails';

interface RabbitSummaryProps {
  id: string;
}

function RabbitSummary() {
  const { id } = useParams<RabbitSummaryProps>();
  const [mother, setMother] = useState<MotherDetails>();
  const [loadMotherDetails, { loading }] = useSendApiGet<MotherDetails>(
    `/api/v1/rabbit/${id}`,
    setMother
  );
  const [rabbitDetails, setRabbitDetails] = useState<MotherDetails & CalculatedDetails>();

  useEffect(() => {
    setMother(undefined);
    loadMotherDetails();
  }, [loadMotherDetails]);

  useEffect(() => {
    if (mother) {
      setRabbitDetails({ ...getCalculatedDetails(mother), ...mother });
    }
  }, [mother]);

  const onDetailsModified = useCallback(() => {
    loadMotherDetails();
  }, [loadMotherDetails]);

  return (
    <PageContainer title="rabbit-summary">
      {loading && <Loader />}
      {rabbitDetails && (
        <RabbitDetails data={rabbitDetails} onDetailsModified={onDetailsModified} />
      )}
      {mother && <RabbitPerformance rabbitPerformance={aggregateCovers(mother.covers)} />}
      {mother && <Covers covers={mother.covers} onDetailsModified={onDetailsModified} />}
    </PageContainer>
  );
}

export default RabbitSummary;
