import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logoutUser } from 'hyper/redux/actions';

interface LogoutProps {
  logoutUser: typeof logoutUser;
}

function Logout(props: LogoutProps) {
  const history = useHistory();

  useEffect(() => {
    props.logoutUser(history);
  });

  return null;
}

export default connect(null, { logoutUser })(Logout);
