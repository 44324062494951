import { TFunction } from 'i18next';
import React, { useCallback, useState } from 'react';
import { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Alert, Button, ButtonGroup } from 'reactstrap';
import moment from 'moment';
import RemoteTable from '../components/shared/remoteTable';
import usePaginatedData, { PageRequest } from '../hooks/usePaginatedData';
import IAGroup from '../models/IAGroup';
import PageContainer from './PageContainer';
import Loader from 'hyper/components/Loader';
import { getTableClasses } from 'helpers/tables';
import ConfirmModal from 'components/shared/confirmModal';
import { useApi } from 'hooks/useApi';

export function checkPostAdditionLimit(iaGroup: IAGroup): boolean {
  const dateFrom = moment(new Date()).add(-73, 'days').toDate();
  return new Date(iaGroup.startDate).getTime() >= dateFrom.getTime();
}

const renderActions = (t: TFunction, toggle: (iaGroup: IAGroup) => void) =>
  function getButtons(id: number, group: IAGroup) {
    const needAdding = group.postAdditionCovers || checkPostAdditionLimit(group);
    return (
      <ButtonGroup>
        {!group.active && (
          <>
            <Button className="mr-2" color="primary" tag={Link} to={`/ia-group/${id}/edit`}>
              {t('edit')}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                toggle(group);
              }}
            >
              {t('delete')}
            </Button>
          </>
        )}
        {group.active && needAdding && (
          <Button className="mr-2" color="primary" tag={Link} to={`/ia-group/${id}/edit`}>
            {group.postAdditionCovers ? t('closed-adding-continue') : t('closed-adding')}
          </Button>
        )}
      </ButtonGroup>
    );
  };

const convertTimestampIntoDate = (timestamp: number) => {
  if (timestamp) {
    return new Date(timestamp).toLocaleDateString();
  }
};

const calculateFarrowingDate = (row: IAGroup) => {
  const startDate = new Date(row.startDate);
  return new Date(startDate.setDate(startDate.getDate() + 30));
};

const calculateSeparationDate = (row: IAGroup) => {
  const startDate = new Date(row.startDate);
  return new Date(startDate.setDate(startDate.getDate() + 65));
};

const defaultPageRequest: PageRequest = {
  page: 1,
  pageSize: 10,
  sortFields: ['startDate', 'name'],
  sortOrder: 'desc',
};

function ListIAGroups() {
  const { t } = useTranslation();
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [selectedIaGroup, setSelectedIaGroup] = useState<IAGroup>();
  const [pageRequest, setPageRequest] = useState<PageRequest>(defaultPageRequest);
  const [deleteError, setDeleteError] = useState<boolean>(false);
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
  const { loadMore, data: iaGroups, loading: loadingIAGroups } = usePaginatedData<IAGroup>(
    '/api/v1/ia-groups'
  );

  const { sendDelete: deleteIaGroup, loading: deletingIaGroup } = useApi();

  const toggleConfirmModal = useCallback(() => setIsConfirmOpen(!isConfirmOpen), [isConfirmOpen]);

  const toggle = useCallback(
    (iaGroup: IAGroup) => {
      setDeleteError(false);
      setDeleteSuccess(false);
      setSelectedIaGroup(iaGroup);
      toggleConfirmModal();
    },
    [toggleConfirmModal]
  );

  const deleteIAGroup = useCallback(() => {
    if (selectedIaGroup?.id) {
      deleteIaGroup(
        `/api/v1/ia-group/${selectedIaGroup.id}`,
        () => {
          loadMore({ ...pageRequest });
          setDeleteSuccess(true);
        },
        () => {
          setDeleteError(true);
        }
      );
    }
  }, [deleteIaGroup, loadMore, pageRequest, selectedIaGroup]);

  const onTableChange = useCallback(
    (type: TableChangeType, newState: TableChangeState<IAGroup>) => {
      const pageRequest = {
        page: newState.page,
        pageSize: newState.sizePerPage,
        sortFields: newState.sortField === 'ia-name' ? ['startDate', 'name'] : [newState.sortField],
        sortOrder: newState.sortOrder,
      };
      setPageRequest({ ...pageRequest });
      loadMore({ ...pageRequest });
    },
    [loadMore]
  );

  const columns = [
    {
      dataField: 'ia-name',
      isDummyField: true,
      text: t('name'),
      sort: true,
      formatter: (cell: string, row: IAGroup) => row.formattedName,
      sortValue: (cell: string, row: IAGroup) => row.formattedName,
    },
    {
      dataField: 'name',
      text: t('mother-group'),
      sort: true,
      formatter: (cell: undefined, { name }: IAGroup) => name,
      sortValue: (cell: undefined, { name }: IAGroup) => name,
    },
    {
      dataField: 'startDate',
      text: t('start-date'),
      sort: true,
      formatter: (cell: number) => convertTimestampIntoDate(cell),
      sortValue: (cell: number) => cell && new Date(cell).getTime(),
    },
    {
      dataField: 'farrowing',
      text: t('farrowing'),
      sort: false,
      isDummyField: true,
      formatter: (cell: undefined, row: IAGroup) =>
        calculateFarrowingDate(row).toLocaleDateString(),
      sortValue: (cell: number, row: IAGroup) => calculateFarrowingDate(row).getTime(),
    },
    {
      dataField: 'separation',
      text: t('separation'),
      sort: false,
      isDummyField: true,
      formatter: (cell: undefined, row: IAGroup) =>
        calculateSeparationDate(row).toLocaleDateString(),
      sortValue: (cell: number, row: IAGroup) => calculateSeparationDate(row).getTime(),
    },
    {
      dataField: 'id',
      text: t('operations'),
      sort: false,
      formatter: renderActions(t, toggle),
    },
  ];

  return (
    <PageContainer
      title={
        <div className="mb-3 mt-3 clearfix">
          <h4 className="float-left">{t('ia-groups')}</h4>
          <Button color="primary" className="ml-3" tag={Link} to="/ia-group/create">
            <i className="mdi mdi-plus-circle mr-2" />
            {t('new-ia')}
          </Button>
        </div>
      }
    >
      {(loadingIAGroups || deletingIaGroup) && <Loader />}
      {deleteError && (
        <Alert color="danger">
          {`'${selectedIaGroup?.formattedName}' ${t('delete-iagroup-failed')}`}
        </Alert>
      )}

      {deleteSuccess && (
        <Alert color="success">
          {`'${selectedIaGroup?.formattedName}' ${t('delete-iagroup-success')}`}
        </Alert>
      )}

      <ConfirmModal
        title={`${t('delete-iagroup-title')} ${selectedIaGroup?.formattedName}`}
        bodyText={t('delete-iagroup-text')}
        isOpen={isConfirmOpen}
        toggle={toggleConfirmModal}
        onSave={deleteIAGroup}
      />
      <RemoteTable
        wrapperClasses={getTableClasses('listiagroup-table')}
        onTableChange={onTableChange}
        data={iaGroups}
        columns={columns}
        defaultSorted={{ dataField: 'ia-name', order: 'desc' }}
        keyField="id"
      />
    </PageContainer>
  );
}

export default ListIAGroups;
