import { TFunction } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import {
  classCellBg,
  classCellBgBrdr,
  classCellLBg,
  classCommon,
  classSideButton,
  classTable,
  createBaseTd,
  createTh,
  dateStr,
  dateToStr,
  divide,
  FATTENING_AGE_MAX,
  FATTENING_AGE_MIN,
  isParentInMergedGrouppage,
  round,
  round0,
  toStr,
  toStrF,
} from './gpUtil';
import ResultInfo from './resultInfo';
import EditFatteningOriginModal, { OriginParams } from './modal/fattening/editFatteningOriginModal';
import OriginCommonTransfer from 'models/grouppages/originCommonTransfer';
import EditFatteningTransfer from 'models/grouppages/editFatteningTransfer';
import Loader from 'hyper/components/Loader';
import { useApi } from 'hooks/useApi';
import ConfirmModal from 'components/shared/confirmModal';
import { RedZoneData, RED_ZONE_LIMIT } from 'pages/grouppages/FatteningEdit';
import Farm from 'models/farm';

const classTableDiv = 'react-bootstrap-table table-responsive align-top';
const classSmallTable = `${classTable} mb-1 gp-table`;
const classCell = `bg-light text-dark text-right ${classCommon}`;
const classCell2 = `text-dark text-right ${classCommon}`;

const deadCellNormal = `${classCell2} bg-light`;
const deadCellYellow = `${classCell2} bg-warning`;
const deadCellRed = `${classCell2} bg-danger`;

function getDeadPercentClass(data: FatteningData | undefined): string {
  if (data) {
    if (data.redZone) return deadCellRed;
    if (data.yellowZone) return deadCellYellow;
  }
  return deadCellNormal;
}

interface FatteningData {
  actualAge: string;
  actualNum: string;
  soldSum: string;
  deadSum: string;
  deadPercent: string;
  allowedDeadNum: string;
  deadDifference: string;
  deadExpected: string;
  redZone: boolean;
  yellowZone: boolean;
}

function calculateOriginSum(data: EditFatteningTransfer | null | undefined): number | undefined {
  if (data && data.origins) {
    return data.origins.reduce((sum, { num }) => {
      if (num) sum += num;
      return sum;
    }, 0);
  }
  return undefined;
}

function calcRedYellowZone(redZoneMap: Map<number, RedZoneData> | undefined) {
  if (!redZoneMap) return { redZone: false, yellowZone: false };
  let redZone = false;
  for (let i = FATTENING_AGE_MAX; i >= 0; i -= 1) {
    if (redZoneMap.has(i)) {
      const data = redZoneMap.get(i);
      if (data?.redZone && redZoneMap.has(i - 1)) {
        redZone = redZoneMap.get(i - 1)?.redZone || false;
      }
      break;
    }
  }
  let yellowZone = false;
  if (!redZone) {
    let allDead = 0;
    let startNum = 0;
    for (let i = FATTENING_AGE_MIN; i <= FATTENING_AGE_MAX; i += 1) {
      if (redZoneMap.has(i)) {
        startNum = redZoneMap.get(i)?.dayStartNum || 0;
        break;
      }
    }
    redZoneMap?.forEach((value) => {
      allDead += value.dailyDead;
    });
    yellowZone =
      startNum > 0 && allDead > 0
        ? allDead >= startNum || allDead / startNum > RED_ZONE_LIMIT
        : false;
  }
  return { redZone, yellowZone };
}

function convertFatteningData(
  data: EditFatteningTransfer | null | undefined,
  redZoneMap: Map<number, RedZoneData> | undefined
): FatteningData {
  if (!data) {
    return {
      actualAge: '',
      actualNum: '',
      soldSum: '',
      deadSum: '',
      deadPercent: '',
      allowedDeadNum: '',
      deadDifference: '',
      deadExpected: '',
      redZone: false,
      yellowZone: false,
    };
  }
  const originalNum = calculateOriginSum(data);
  const actualNum =
    (originalNum || 0) - (data.sumValues.deadSum || 0) - (data.sumValues.soldSum || 0);
  const deadPercent = divide(100 * (data.sumValues.deadSum || 0), originalNum || 1);
  const deadDifference =
    (data.sumValues.deadSum || 0) - (round(data.sumValues.allowedDeadSum, 0) || 0);
  const startAge = data.diary.diaries[0].dayOfAge || FATTENING_AGE_MIN;
  const age = data.actualAge - startAge;
  const averageDead = divide(data.sumValues.deadSum || 0, age);
  const fullAge = (data.plannedDeliveryAge || FATTENING_AGE_MAX) - startAge;
  const deadExpected = (averageDead || 0) * fullAge;
  const { redZone, yellowZone } = calcRedYellowZone(redZoneMap);
  return {
    actualAge: toStr(data.actualAge),
    actualNum: toStr(actualNum),
    soldSum: toStr(data.sumValues.soldSum || 0),
    deadSum: toStr(data.sumValues.deadSum || 0),
    deadPercent: toStr(round0(deadPercent, 2), 2),
    allowedDeadNum: toStr(data.sumValues.allowedDeadSum, 0),
    deadDifference: toStr(deadDifference),
    deadExpected: toStr(deadExpected, 0),
    redZone,
    yellowZone,
  };
}

interface OriginData {
  origins: OriginCommonTransfer[];
  avgWeights: (string | undefined)[];
  sum: string;
  sumWeight: string;
  avgSumWeight: string;
}

function getAvg(num: number, weight: number, multiplier = 1): number {
  const result = num ? round((multiplier * weight) / num, 1) : 0;
  return result || 0;
}

function convertOriginData(origins: OriginCommonTransfer[] | undefined): OriginData {
  if (!origins) {
    return {
      origins: [],
      avgWeights: [],
      sum: '',
      sumWeight: '',
      avgSumWeight: '',
    } as OriginData;
  }
  const avgWeights: (string | undefined)[] = [];
  let sum = 0;
  let sumWeight = 0;
  for (const origin of origins) {
    if (origin) {
      sum += origin.num;
      sumWeight += origin.weight;
    }
    const strAvgSumWeight = origin ? toStr(getAvg(origin.num, origin.weight, 1000), 0) : undefined;
    avgWeights.push(strAvgSumWeight === '0' ? undefined : strAvgSumWeight);
  }
  const avgSumWeight = getAvg(sum, sumWeight, 1000);
  return {
    origins,
    avgWeights,
    sum: toStr(sum),
    sumWeight: toStr(sumWeight),
    avgSumWeight: toStr(avgSumWeight, 0),
  };
}

export function getMovingGroupContent(
  groupName: string | undefined,
  hasAssoc: string | boolean | undefined,
  isFrom: boolean
): JSX.Element {
  if (isFrom)
    return (
      <>
        {hasAssoc && <i className="mdi mdi-export mr-2" />}
        {groupName}
      </>
    );
  return (
    <>
      {groupName}
      {hasAssoc && <i className="mdi mdi-import ml-2" />}
    </>
  );
}

const createOriginRows = (
  dataOrigin: OriginData,
  originEdit: boolean,
  toggleEdit: (origin: OriginCommonTransfer) => void,
  toggleDelete: (origin: OriginCommonTransfer) => void
): JSX.Element[] => {
  const allTr: JSX.Element[] = [];
  for (let i = 0; i < dataOrigin.origins.length; i++) {
    const origin = dataOrigin.origins[i];
    const from = origin.num < 0;
    allTr.push(
      <tr key={origin.id}>
        {createBaseTd(`${toStr(i + 1)}.`, classCell)}
        {createBaseTd(origin.farmName, classCellLBg)}
        {createBaseTd(
          getMovingGroupContent(origin.groupName, origin.associatedId, from),
          classCellLBg
        )}
        {createBaseTd(toStr(origin.age), classCellBg)}
        {createBaseTd(dateToStr(origin.arrivedDate), classCellBg)}
        {createBaseTd(toStr(origin.num), classCellBg)}
        {createBaseTd(toStr(origin.weight), classCellBg)}
        {createBaseTd(dataOrigin.avgWeights[i], classCell)}
        {originEdit && (
          <td className="text-center align-middle m-0 p-0">
            {!origin.associatedId && (
              <Button
                color="primary"
                className={`${classSideButton} origin-edit mr-1`}
                onClick={() => toggleEdit(origin)}
              >
                <i className="mdi mdi-pencil p-1" />
              </Button>
            )}
            <Button
              color="primary"
              className={`${classSideButton} origin-delete`}
              onClick={() => toggleDelete(origin)}
            >
              <i className="mdi mdi-delete p-1" />
            </Button>
          </td>
        )}
      </tr>
    );
  }
  return allTr;
};

function getOriginIndex(
  data: OriginData | undefined,
  selectedOrigin: OriginCommonTransfer | undefined
): string {
  if (data && selectedOrigin) {
    for (let i = 0; i < data.origins.length; i++) {
      if (data.origins[i].id === selectedOrigin.id) {
        return `${i + 1}`;
      }
    }
  }
  return '';
}

function getOriginInfo(origin: OriginCommonTransfer | undefined, t: TFunction): string {
  const result = [];
  if (origin) {
    if (origin.farmName) {
      result.push(`${t('orig-farm')} ${origin.farmName}`);
    }
    if (origin.groupName) {
      result.push(`${t('orig-group')} ${origin.groupName}`);
    }
    result.push(`${t('orig-date')} ${origin.arrivedDate}`);
    result.push(`${t('orig-comm-num')} ${Math.abs(origin.num)}`);
    if (origin.weight) {
      result.push(`${t('orig-comm-weight')} ${Math.abs(origin.weight)}`);
    }
  }
  return `${result.join(', ')}`;
}

interface GainWeightRowData {
  weekOfAge: string;
  gPerWeek: string;
  gPerDayStacked: string;
  gPerDayWeekly: string;
}
interface GainWeightData {
  rows: GainWeightRowData[];
  sumGPerWeek: string;
  sumGPerDayStacked: string;
  sumGPerDayWeekly: string;
}

function calculateStartWeights(data: EditFatteningTransfer | null | undefined): number {
  if (!data || !data.origins) return 0;

  const startDate = dateStr(data.startDate);
  let sumNum = 0;
  let sumWeight = 0;
  for (let i = 0; i < data.origins.length; i++) {
    const origin = data.origins[i];
    if (startDate === dateStr(origin.arrivedDate) && origin.weight) {
      sumNum += origin.num;
      sumWeight += origin.weight;
    }
  }
  return sumNum > 0 ? round(divide(1000 * sumWeight, sumNum), 1) || 0 : 0;
}

function convertGainWeightData(data: EditFatteningTransfer | null | undefined): GainWeightData {
  const result = {
    rows: [],
    sumGPerWeek: '',
    sumGPerDayStacked: '',
    sumGPerDayWeekly: '',
  };
  if (!data || !data.weightGains) return result;

  const firstWeight = calculateStartWeights(data);
  const startWeight = firstWeight || 0;
  const startDay = data.diary.diaries ? data.diary.diaries[0].dayOfAge : FATTENING_AGE_MIN;
  let sumGPerWeek = 0;
  let sumGPerDayStacked = '';
  let sumGPerDayWeekly = '';
  let prevWeight = startWeight;
  let prevDay = startDay;
  const rows = [];
  for (let i = 0; i < data.weightGains.length; i++) {
    const weightGain = data.weightGains[i];

    const deltaWeight = round((weightGain.weight || 0) - prevWeight, 1);
    sumGPerDayStacked = toStrF(
      round(
        divide(
          (weightGain.weight || 0) - startWeight,
          (weightGain.dayOfAge || FATTENING_AGE_MIN) - startDay
        ),
        1
      ),
      1
    );
    rows.push({
      weekOfAge: toStr(weightGain.weekOfAge),
      gPerWeek: toStr(deltaWeight, 1),
      gPerDayStacked: sumGPerDayStacked,
      gPerDayWeekly: toStrF(
        divide(
          (weightGain.weight || 0) - prevWeight,
          (weightGain.dayOfAge || FATTENING_AGE_MIN) - prevDay
        ),
        1
      ),
    });
    sumGPerWeek += deltaWeight || 0;
    sumGPerDayWeekly = toStrF(
      round(
        divide((weightGain.weight || 0) - startWeight, weightGain.dayOfAge || FATTENING_AGE_MIN),
        1
      )
    );

    prevWeight = weightGain.weight || 0;
    prevDay = weightGain.dayOfAge || FATTENING_AGE_MIN;
  }

  return {
    rows,
    sumGPerWeek: toStr(sumGPerWeek),
    sumGPerDayStacked,
    sumGPerDayWeekly,
  };
}

const createGainWeightRows = (dataGainWeight: GainWeightData, week: string): JSX.Element[] => {
  const allTr: JSX.Element[] = [];
  for (let i = 0; i < dataGainWeight.rows.length; i++) {
    const gainWeightRow = dataGainWeight.rows[i];
    allTr.push(
      <tr key={gainWeightRow.weekOfAge}>
        {createBaseTd(`${gainWeightRow.weekOfAge}${week}`, classCell)}
        {createBaseTd(gainWeightRow.gPerWeek, classCellBg)}
        {createBaseTd(gainWeightRow.gPerDayStacked, classCellBg)}
        {createBaseTd(gainWeightRow.gPerDayWeekly, classCellBg)}
      </tr>
    );
  }
  return allTr;
};

const calcOriginStartDate = (transfer: EditFatteningTransfer | null | undefined): string => {
  const now = new Date();
  if (!transfer?.diary.diaries) return dateStr(now);
  const firstDiaryDate = new Date(transfer.diary.diaries[0].actualDate);
  return dateStr(firstDiaryDate < now ? firstDiaryDate : now);
};

const calcOriginEndDate = (transfer: EditFatteningTransfer | null | undefined): string => {
  const now = new Date();
  if (!transfer?.diary.diaries) return dateStr(now);
  const lastDiaryDate = new Date(
    transfer.diary.diaries[transfer.diary.diaries.length - 1].actualDate
  );
  return dateStr(lastDiaryDate < now ? lastDiaryDate : now);
};

interface FatteningEditSmallTablesProps {
  transfer: EditFatteningTransfer | null | undefined;
  redZoneMap: Map<number, RedZoneData> | undefined;
  refresh: () => void;
  farms: Farm[] | null | undefined;
}

function FatteningEditSmallTables({
  transfer,
  redZoneMap,
  refresh,
  farms,
}: FatteningEditSmallTablesProps) {
  const { t } = useTranslation();
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [selectedOrigin, setSelectedOrigin] = useState<OriginCommonTransfer>();
  const [deleteError, setDeleteError] = useState<string>();
  const [deleteSuccess, setDeleteSuccess] = useState<string>();
  const { sendDelete: deleteOrigin, loading: deletingOrigin } = useApi();

  const [dataFattening, setDataFattening] = useState<FatteningData>(
    convertFatteningData(transfer, redZoneMap)
  );
  const [dataOrigin, setDataOrigin] = useState<OriginData>(convertOriginData(transfer?.origins));
  const [dataGainWeight, setDataGainWeight] = useState<GainWeightData>(
    convertGainWeightData(transfer)
  );

  const [isOriginModalOpen, setIsOriginModalOpen] = useState<boolean>(false);
  const toggleOriginModal = useCallback(() => setIsOriginModalOpen(!isOriginModalOpen), [
    isOriginModalOpen,
  ]);
  const [groupStartDate, setGroupStartDate] = useState<Date>();
  const [originStartDate, setOriginStartDate] = useState<string>(calcOriginStartDate(transfer));
  const [originEndDate, setOriginEndDate] = useState<string>(calcOriginEndDate(transfer));

  const [originEdit, setOriginEdit] = useState<boolean>(true);

  const refreshPage = useCallback(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    setDataFattening(convertFatteningData(transfer, redZoneMap));
    setDataOrigin(convertOriginData(transfer?.origins));
    setDataGainWeight(convertGainWeightData(transfer));
    setGroupStartDate(transfer?.startDate || new Date());
    setOriginStartDate(calcOriginStartDate(transfer));
    setOriginEndDate(calcOriginEndDate(transfer));
    setOriginEdit(!isParentInMergedGrouppage(transfer));
  }, [transfer, redZoneMap]);

  const toggleConfirmModal = useCallback(() => setIsConfirmOpen(!isConfirmOpen), [isConfirmOpen]);

  const toggleDelete = useCallback(
    (origin: OriginCommonTransfer) => {
      setDeleteError(undefined);
      setDeleteSuccess(undefined);
      setSelectedOrigin(origin);
      toggleConfirmModal();
    },
    [toggleConfirmModal]
  );

  const toggleEdit = useCallback(
    (origin: OriginCommonTransfer) => {
      setDeleteError(undefined);
      setDeleteSuccess(undefined);
      setSelectedOrigin(origin);
      toggleOriginModal();
    },
    [toggleOriginModal]
  );

  const doDeleteOrigin = useCallback(() => {
    if (selectedOrigin?.id) {
      deleteOrigin(
        `/api/v1/group/fattening/${transfer?.id}/origin/${selectedOrigin.id}`,
        () => {
          let info = getOriginInfo(selectedOrigin, t);
          info = info ? `(${info})` : '';
          setDeleteSuccess(t('delete-origin-success', { info }));
          refreshPage();
        },
        () => {
          setDeleteError(t('delete-origin-failed'));
        }
      );
    }
  }, [deleteOrigin, refreshPage, selectedOrigin, t, transfer]);

  const createDeleteTitle = useCallback(
    () => (selectedOrigin?.associatedId ? t('delete-move-fattening') : t('delete-origin-young')),
    [selectedOrigin, t]
  );

  const createDeleteBody = useCallback(() => {
    let info = getOriginInfo(selectedOrigin, t);
    info = info ? `(${info})` : '';
    const idx = getOriginIndex(dataOrigin, selectedOrigin);
    const msg = selectedOrigin?.associatedId ? 'delete-move-message' : 'delete-origin-message';
    return `${t(msg, { idx, info })}`;
  }, [dataOrigin, selectedOrigin, t]);

  const defaulOrigin = useCallback(
    (needAssociatedId: boolean) => {
      const now = dateStr(new Date());
      const when = originEndDate && originEndDate < now ? originEndDate : now;
      let result = {
        arrivedDate: needAssociatedId ? new Date(when) : groupStartDate,
        num: 0,
      } as OriginCommonTransfer;
      if (needAssociatedId) {
        result = { ...result, associatedId: uuidv4() };
      }
      return result;
    },
    [groupStartDate, originEndDate]
  );

  const getOrigin = useCallback(() => {
    let result = defaulOrigin(false);
    if (selectedOrigin) {
      result = {
        ...selectedOrigin,
        arrivedDate: selectedOrigin.arrivedDate || groupStartDate,
      };
    }
    return {
      origin: result,
      isMoving: result.associatedId && result.associatedId.trim().length > 0,
      isMovingSource: result.num < 0,
      maxNum: +dataFattening.actualNum,
      maxWeight: undefined,
    } as OriginParams;
  }, [dataFattening.actualNum, defaulOrigin, groupStartDate, selectedOrigin]);

  return (
    <>
      {deletingOrigin && <Loader />}

      <ConfirmModal
        title={createDeleteTitle()}
        bodyText={createDeleteBody()}
        isOpen={isConfirmOpen}
        toggle={toggleConfirmModal}
        onSave={doDeleteOrigin}
      />
      <ResultInfo
        success={!!deleteSuccess}
        successMsg={deleteSuccess}
        error={!!deleteError}
        errorMsg={deleteError}
      />
      {
        <EditFatteningOriginModal
          groupId={transfer?.id || 0}
          generatedName={transfer?.generatedName || ''}
          startDate={originStartDate}
          endDate={originEndDate}
          isOpen={isOriginModalOpen}
          toggle={toggleOriginModal}
          onSave={refresh}
          originDetails={getOrigin()}
          allOrigins={transfer?.origins}
          diaries={transfer?.diary.diaries}
          farms={farms}
        />
      }
      <Row className="justify-content-around">
        <Col xs="2">
          <div>
            <h5 className="card-title align-middle">{t('ygpt2-title')}</h5>
            <div className={classTableDiv}>
              <table className={`${classSmallTable} fattening-summary-table`}>
                <tbody>
                  <tr key={1}>
                    {createBaseTd(t('fgpt1-age'), classCellBgBrdr, { colSpan: 2 })}
                    {createBaseTd(dataFattening.actualAge, classCell)}
                  </tr>
                  <tr key={2}>
                    {createBaseTd(t('fgpt1-num'), classCellBgBrdr, { colSpan: 2 })}
                    {createBaseTd(dataFattening.actualNum, classCell)}
                  </tr>
                  <tr key={4}>
                    {createBaseTd(t('fgpt1-dead'), classCellBgBrdr, {
                      rowSpan: 5,
                      width: 10,
                    })}
                    {createBaseTd(t('fgpt1-dead-real'), classCellBgBrdr)}
                    {createBaseTd(dataFattening.deadSum, classCell)}
                  </tr>
                  <tr key={5}>
                    {createBaseTd(t('fgpt1-dead-percent'), classCellBgBrdr)}
                    {createBaseTd(dataFattening.deadPercent, getDeadPercentClass(dataFattening))}
                  </tr>
                  <tr key={6}>
                    {createBaseTd(t('fgpt1-dead-allowed'), classCellBgBrdr)}
                    {createBaseTd(dataFattening.allowedDeadNum, classCell)}
                  </tr>
                  <tr key={7}>
                    {createBaseTd(t('fgpt1-dead-diff'), classCellBgBrdr)}
                    {createBaseTd(dataFattening.deadDifference, classCell)}
                  </tr>
                  <tr key={8}>
                    {createBaseTd(t('fgpt1-dead-expected'), classCellBgBrdr)}
                    {createBaseTd(dataFattening.deadExpected, classCell)}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Col>

        <Col xs="7">
          <div>
            <h5 className="card-title">{t('ygpt3-title')}</h5>
            <div className={classTableDiv}>
              <table className={`${classSmallTable} fattening-origin-table`}>
                <thead>
                  <tr>
                    {createTh('', 0)}
                    {createTh(t('ygpt3-farm'), 1)}
                    {createTh(t('ygpt3-group'), 2)}
                    {createTh(t('ygpt3-age'), 3)}
                    {createTh(t('ygpt3-date'), 4)}
                    {createTh(t('ygpt3-num'), 5)}
                    {createTh(t('ygpt3-sum-weight'), 6)}
                    {createTh(t('ygpt3-avg-weight'), 7)}
                    {originEdit && (
                      <th className="text-center align-middle">
                        <Button
                          id={'new-origin-row'}
                          color="primary"
                          className={`${classSideButton}`}
                          onClick={() => toggleEdit(defaulOrigin(false))}
                        >
                          <i className="mdi mdi-plus p-1" id="new-origin-row-icon" />
                        </Button>
                        <Button
                          id={'new-move-row'}
                          color="primary"
                          className={`${classSideButton} ml-1`}
                          onClick={() => toggleEdit(defaulOrigin(true))}
                        >
                          <i className="mdi mdi-export p-1" id="new-move-row-icon" />
                        </Button>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <>{createOriginRows(dataOrigin, originEdit, toggleEdit, toggleDelete)}</>
                  <tr>
                    {createBaseTd(t('ygpt3-sum'), classCell, { colSpan: 5 })}
                    {createBaseTd(dataOrigin.sum, classCell)}
                    {createBaseTd(dataOrigin.sumWeight, classCell)}
                    {createBaseTd(dataOrigin.avgSumWeight, classCell)}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <UncontrolledTooltip
            modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
            placement="top"
            target="new-origin-row"
          >
            {t('edit-origin-new')}
          </UncontrolledTooltip>
          <UncontrolledTooltip
            modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
            placement="top"
            target="new-move-row"
          >
            {t('move-origin-new')}
          </UncontrolledTooltip>
        </Col>

        <Col xs="3">
          <div>
            <h5 className="card-title">{t('fgpt3-title')}</h5>
            <div className={classTableDiv}>
              <table className={classSmallTable}>
                <thead>
                  <tr>
                    {createTh('', 0, '', { rowSpan: 2 })}
                    {createTh(t('fgpt3-g-per-week'), 1, '', { rowSpan: 2 })}
                    {createTh(t('fgpt3-g-per-day'), 2, '', { colSpan: 2 })}
                  </tr>
                  <tr>
                    {createTh(t('fgpt3-stacked'), 3)}
                    {createTh(t('fgpt3-weekly'), 4)}
                  </tr>
                </thead>
                <tbody>
                  <>{createGainWeightRows(dataGainWeight, t('fgpt3-nth-week'))}</>
                  <tr>
                    {createBaseTd(t('fgpt3-sum'), classCell)}
                    {createBaseTd(dataGainWeight.sumGPerWeek, classCell)}
                    {createBaseTd(dataGainWeight.sumGPerDayStacked, classCell)}
                    {createBaseTd(dataGainWeight.sumGPerDayWeekly, classCell)}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default FatteningEditSmallTables;
