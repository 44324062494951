import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  ModalFooter,
  Container,
  Row,
  Col,
  Alert,
} from 'reactstrap';
import '../../../../css/modal.css';
import moment from 'moment';
import { useApiPut } from 'hooks/useApi';
import Loader from 'hyper/components/Loader';
import SucklingNumTransfer from 'models/grouppages/sucklingNumTransfer';
import Datepicker from 'hyper/components/Datepicker';
import { isInputValuesBetweenLimit, roundAndRemove } from 'helpers/rabbit';
import { useInput } from 'hooks/useInput';
import { ValueType } from 'models/valueType';

const LIMIT = 40000;

interface EditSucklingDetailsProps {
  id: number | undefined;
  isOpen: boolean;
  sucklingDetails: SucklingNumTransfer | undefined;
  baseWeaningDate: Date | undefined;
  onSave: () => void;
  toggle: () => void;
}

function EditSucklingDetailsModal({
  id,
  isOpen,
  toggle,
  onSave,
  sucklingDetails,
  baseWeaningDate,
}: EditSucklingDetailsProps) {
  const { t } = useTranslation();
  const [editSucklingDetails, setEditSucklingDetails, onSimpleValueChange] = useInput<
    SucklingNumTransfer | undefined
  >(sucklingDetails);
  const [isAllValid, setIsAllValid] = useState<boolean>(true);
  const [backendMessages, setBackendMessages] = useState<string[] | null>(null);
  const [saveSucklingDetails, { loading: saving }] = useApiPut<{ messages: string[] }>(
    `/api/v1/group/mother/${id}/sucklings`,
    (response) => {
      if (response.messages && response.messages.length > 0) {
        setBackendMessages(response.messages);
      } else {
        setBackendMessages(null);
        onSave();
        toggle();
      }
    }
  );

  useEffect(() => {
    if (isOpen) {
      if (!sucklingDetails?.weaningDate) {
        const date = baseWeaningDate
          ? moment(new Date(baseWeaningDate)).add(66, 'days').toDate()
          : new Date();

        const copy = {
          ...sucklingDetails,
          weaningDate: date,
        };
        setEditSucklingDetails({ ...copy });
      } else {
        setEditSucklingDetails({ ...sucklingDetails });
      }
    }
  }, [baseWeaningDate, isOpen, setEditSucklingDetails, sucklingDetails]);

  useEffect(() => {
    setIsAllValid(true);
    setBackendMessages(null);
  }, [editSucklingDetails]);

  const submit = useCallback(() => {
    const isValid = isInputValuesBetweenLimit(editSucklingDetails, [], LIMIT);

    if (isValid) {
      setBackendMessages(null);
      saveSucklingDetails(editSucklingDetails);
    }
    setIsAllValid(isValid);
  }, [editSucklingDetails, saveSucklingDetails]);

  const onWeaningDateChange = useCallback(
    (weaningDate: Date) => {
      const copy = { ...editSucklingDetails, weaningDate };
      setEditSucklingDetails(copy);
    },
    [editSucklingDetails, setEditSucklingDetails]
  );

  const isInvalid = useCallback(
    (value: number | undefined) => (value && value > LIMIT) || false,
    []
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{`${t('suckling-modal-title')}`}</ModalHeader>
      <ModalBody>
        {saving && <Loader />}
        <Container fluid>
          <Row className="" />
        </Container>
        <Form className="form d-flex flex-column justify-content-center">
          <Row className="mb-2">
            <Col className="text-center">
              <b> {t('suckling')}</b>
            </Col>
            <Col className="text-center">
              <b>{t('cage-count')}</b>
            </Col>
          </Row>

          <Row className="d-flex align-items-center m-1">
            <Col>
              <Col className="text-center">
                <b>{t('suckling-num')}</b>
              </Col>
            </Col>
            <Col>
              <Input
                type="number"
                name={'sucklingNum'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editSucklingDetails?.sucklingNum)}
                className="text-right"
                defaultValue={editSucklingDetails?.sucklingNum}
                onChange={onSimpleValueChange('sucklingNum', ValueType.NUMBER)}
                onInput={roundAndRemove}
              />
            </Col>
          </Row>

          <Row className="d-flex align-items-center m-1">
            <Col>
              <Col className="text-center">
                <b>{t('suckling-viable')}</b>
              </Col>
            </Col>
            <Col>
              <Input
                type="number"
                name={'sucklingViable'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editSucklingDetails?.sucklingViable)}
                className="text-right"
                defaultValue={editSucklingDetails?.sucklingViable}
                onChange={onSimpleValueChange('sucklingViable', ValueType.NUMBER)}
                onInput={roundAndRemove}
              />
            </Col>
          </Row>

          <Row className="d-flex align-items-center m-1">
            <Col>
              <Col className="text-center">
                <b>{t('suckling-adjusted')}</b>
              </Col>
            </Col>
            <Col>
              <Input
                type="number"
                name={'sucklingAdjusted'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editSucklingDetails?.sucklingAdjusted)}
                className="text-right"
                defaultValue={editSucklingDetails?.sucklingAdjusted}
                onChange={onSimpleValueChange('sucklingAdjusted', ValueType.NUMBER)}
                onInput={roundAndRemove}
              />
            </Col>
          </Row>

          <Row className="d-flex align-items-center m-1">
            <Col>
              <Col className="text-center">
                <b>{t('suckling-weaned')}</b>
              </Col>
            </Col>
            <Col>
              <Input
                type="number"
                name={'sucklingWeaned'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editSucklingDetails?.sucklingWeaned)}
                className="text-right"
                defaultValue={editSucklingDetails?.sucklingWeaned}
                onChange={onSimpleValueChange('sucklingWeaned', ValueType.NUMBER)}
                onInput={roundAndRemove}
              />
            </Col>
          </Row>

          <Row className="d-flex align-items-center m-1">
            <Col>
              <Col className="text-center">
                <b>{t('weaning-date')}</b>
              </Col>
            </Col>
            <Col>
              <Datepicker
                startDate={
                  editSucklingDetails?.weaningDate && new Date(editSucklingDetails?.weaningDate)
                }
                dateFormat="yyyy-MM-dd"
                onDateChange={onWeaningDateChange}
              />
            </Col>
          </Row>

          <Row className="pt-2">
            <Col>
              {!isAllValid && (
                <Alert color="danger" className="text-center">
                  {t('form-has-errors')}
                </Alert>
              )}
              {backendMessages && backendMessages.length > 0 && (
                <Alert color="danger" className="text-center">
                  {backendMessages.map((msg: string) => (
                    <li key={msg}>{t(msg)}</li>
                  ))}
                </Alert>
              )}
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={submit} className="mr-2">
          {t('save')}
        </Button>
        <Button color="primary" onClick={toggle}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditSucklingDetailsModal;
