import i18n, { TFunction } from 'i18next';
import { initReactI18next } from 'react-i18next';
import hu from './translations/hu.json';
import en from './translations/en.json';
import fr from './translations/fr.json';
import es from './translations/es.json';
import { Languages } from 'models/languages';

export const DEFAULT_LANGUAGE = 'en';

const LS_KEY_LANGUAGE = 'USER_LANGUAGE';

export const getBrowserLanguage = (): string => {
  const browserLanguage =
    navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
  let language = browserLanguage
    ? browserLanguage.length < 3
      ? browserLanguage.toLowerCase()
      : browserLanguage.substr(0, 2).toLowerCase()
    : DEFAULT_LANGUAGE;
  if (!Object.values(Languages).includes(language as Languages)) {
    language = DEFAULT_LANGUAGE;
  }
  return language;
};

export const setLanguage = (language: string) => {
  if (!Object.values(Languages).includes(language as Languages)) {
    console.error(
      `setLanguage : Unsupported language code ("${language}"), the default one will be set: "${DEFAULT_LANGUAGE}"`
    );
    language = DEFAULT_LANGUAGE;
  }
  window.localStorage.setItem(LS_KEY_LANGUAGE, language);
  if (i18n.language !== language) {
    i18n.changeLanguage(language, (error: any, t: TFunction) => {
      if (!error) window.location.reload();
    });
  }
};

export const getLanguage = (): string | null => window.localStorage.getItem(LS_KEY_LANGUAGE);

export interface LangInfo {
  title: string;
  flagFile?: string;
}

export const SupportedLanguages = new Map<Languages, LangInfo>([
  [Languages.HUNGARIAN, { title: 'Magyar' } as LangInfo],
  [Languages.ENGLISH, { title: 'English' } as LangInfo],
  [Languages.FRENCH, { title: 'Français' } as LangInfo],
  [Languages.SPAIN, { title: 'Español' } as LangInfo],
  // [Languages.ITALIAN, { title: 'Italiano' } as LangInfo],
  // [Languages.RUSSIAN, { title: 'Русский' } as LangInfo],
  // [Languages.SLOVAK, { title: 'Slovenská' } as LangInfo],
]);

const resources = {
  hu: {
    translation: hu,
  },
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
  es: {
    translation: es,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: getLanguage() || '',
  fallbackLng: DEFAULT_LANGUAGE,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
