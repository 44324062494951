import { TFunction } from 'i18next';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import { useApiGet } from '../hooks/useApi';
import User from '../models/user';
import PageContainer from './PageContainer';
import DefaultSorted from 'models/defaultSorted';
import Loader from 'hyper/components/Loader';
import { getUserRole, setNewUserRole } from 'helpers/authUtils';
import SimpleTable from 'components/shared/simpleTable';
import { getTableClasses } from 'helpers/tables';
import { UserRole } from 'models/userRole';

const renderActions = (t: TFunction) =>
  function getButton(id: number) {
    return (
      <Button color="primary" tag={Link} to={`/user/${id}/edit`}>
        {t('edit')}
      </Button>
    );
  };

function UserManagement() {
  const { data, loading } = useApiGet<User[]>('/api/v1/users');
  const { t } = useTranslation();
  const history = useHistory();

  const formatRole = (t: TFunction) => (role: string) => t(role);

  const createNewUser = (role: UserRole) => (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    setNewUserRole(role);
    history.push('/user/create');
  };

  const columns = [
    {
      dataField: 'username',
      text: t('username'),
      sort: true,
    },
    {
      dataField: 'email',
      text: t('email'),
      sort: true,
    },
    {
      dataField: 'phoneNumber',
      text: t('phone'),
      sort: true,
    },
    {
      dataField: 'farm.name',
      text: t('farm'),
      sort: true,
    },
    {
      dataField: 'role',
      text: t('role'),
      sort: true,
      formatter: formatRole(t),
    },
    {
      dataField: 'id',
      text: t('operations'),
      sort: false,
      formatter: renderActions(t),
    },
  ];

  const defaultSorted: DefaultSorted = {
    dataField: columns[0].dataField,
    order: 'asc',
  };

  const getButtonLabel = () => {
    if (getUserRole() === UserRole.OWNER) {
      return 'create-new-admin';
    } else {
      return 'create-new-worker';
    }
  };

  useEffect(() => {
    setNewUserRole();
  }, []);

  return (
    <PageContainer
      title={
        <div className="mb-3 mt-3 clearfix">
          <h4 className="float-left">{t('user-management')}</h4>
          {getUserRole() === UserRole.SUPERADMIN && (
            <UncontrolledButtonDropdown className="ml-3">
              <DropdownToggle color="primary" caret>
                {t('create-new-user')}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={createNewUser(UserRole.OWNER)}>{t('owner')}</DropdownItem>
                <DropdownItem onClick={createNewUser(UserRole.EAR_TAG_TECHNICIAN)}>
                  {t('ear_tag_technician')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          )}
          {getUserRole() !== UserRole.SUPERADMIN && (
            <Button color="primary" className="ml-3" tag={Link} to="/user/create">
              <i className="mdi mdi-plus-circle mr-2" />
              {t(getButtonLabel())}
            </Button>
          )}
        </div>
      }
    >
      {loading && <Loader />}
      <SimpleTable
        wrapperClasses={getTableClasses('usermanagement-table')}
        data={data || []}
        columns={
          getUserRole() === UserRole.SUPERADMIN
            ? columns.filter((column) => column.dataField !== 'farm.name')
            : columns
        }
        defaultSorted={defaultSorted}
        keyField="id"
      />
    </PageContainer>
  );
}

export default UserManagement;
