import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Container } from 'reactstrap';
import Logout from './pages/Logout';
import UserManagement from './pages/UserManagement';
import FarmManagement from './pages/FarmManagement';
import AppMenu from './components/shared/appMenu';
import CreateIAGroup from './pages/CreateIAGroup';
import HomePage from './pages/HomePage';
import Login from 'hyper/pages/auth/Login';

import 'hyper/assets/scss/Saas.scss';
import { isUserAuthenticated, getUserRole } from 'helpers/authUtils';
import Insemination from 'pages/Insemination';
import EditUser from 'pages/EditUser';
import CreateUser from 'pages/CreateUser';
import { ApplicationState } from 'hyper/redux';
import EditIAGroup from 'pages/EditIAGroup';
import ListIAGroups from 'pages/ListIAGoups';
import StockList from 'pages/StockList';
import RabbitSummary from 'pages/RabbitSummary';
import Statistic from 'pages/Statistic';
import Adjustment from 'pages/Adjustment';
import IATaskManagement from 'pages/IATaskManagement';
import EarTagList from 'pages/EarTagList';
import Equalization from 'pages/Equalization';
import MotherList from 'pages/grouppages/MotherList';
import MotherEdit from 'pages/grouppages/MotherEdit';
import YoungList from 'pages/grouppages/YoungList';
import YoungEdit from 'pages/grouppages/YoungEdit';
import FatteningList from 'pages/grouppages/FatteningList';
import FatteningEdit from 'pages/grouppages/FatteningEdit';
import ProductionGroupManagement from 'pages/ProductionGroupManagement';
import FatteningSummary from 'pages/grouppages/FatteningSummary';
import MotherSummary from 'pages/grouppages/MotherSummary';
import YoungGroupSummary from 'pages/grouppages/YoungGroupSummary';
import { UserRole } from 'models/userRole';
import WeightEdit from 'pages/grouppages/WeightEdit';
import CuttingFatteningPage from 'pages/grouppages/CuttingFattening';
import EartaggingList from 'pages/grouppages/EartaggingList';
import CommandList from 'pages/CommandList';

const renderPublicRoutes = () => (
  <>
    <Redirect to="/login" />
    <Route path="/login">
      <Login />
    </Route>
  </>
);

const renderPrivateRoutes = (role: UserRole) => (
  <>
    {role === UserRole.ADMIN && (
      <>
        <Route path="/ia-group/:id/edit">
          <EditIAGroup />
        </Route>
        <Route path="/insemination">
          <Insemination />
        </Route>
        <Route path="/adjustment/:iaGroupId?">
          <Adjustment />
        </Route>
        <Route path="/ia-task-management">
          <IATaskManagement />
        </Route>
        <Route path="/ia-groups">
          <ListIAGroups />
        </Route>
        <Route path="/ia-group/create">
          <CreateIAGroup />
        </Route>
        <Route path="/rabbits" exact>
          <StockList />
        </Route>
        <Route path="/rabbits/:id">
          <RabbitSummary />
        </Route>
        <Route path="/statistic">
          <Statistic />
        </Route>
        <Route path="/equalization">
          <Equalization />
        </Route>
        <Route path="/ear-tagging">
          <EartaggingList />
        </Route>
        <Route path="/grouppage-mother" exact>
          <MotherList />
        </Route>
        <Route path="/grouppage-mother/:id/edit">
          <MotherEdit />
        </Route>
        <Route path="/grouppage-mother/:id/diary/:diaryId/weight/:weightType">
          <WeightEdit />
        </Route>
        <Route path="/grouppage-mother-summary" exact>
          <MotherSummary />
        </Route>
        <Route path="/grouppage-young" exact>
          <YoungList />
        </Route>
        <Route path="/grouppage-young/:id/edit">
          <YoungEdit />
        </Route>
        <Route path="/grouppage-young/:id/diary/:diaryId/weight/:weightType">
          <WeightEdit />
        </Route>
        <Route path="/grouppage-young-summary" exact>
          <YoungGroupSummary />
        </Route>
        <Route path="/grouppage-fattening" exact>
          <FatteningList />
        </Route>
        <Route path="/grouppage-fattening/:id/edit">
          <FatteningEdit />
        </Route>
        <Route path="/grouppage-fattening/:id/diary/:diaryId/weight/:weightType">
          <WeightEdit />
        </Route>
        <Route path="/grouppage-fattening-summary" exact>
          <FatteningSummary />
        </Route>
        <Route path="/grouppage-fattening/:groupId/slaughtering/:slaughteringId/cutting">
          <CuttingFatteningPage />
        </Route>
        <Route path="/production-group-management">
          <ProductionGroupManagement />
        </Route>
      </>
    )}

    {role === UserRole.SUPERADMIN && (
      <>
        <Route path="/ear-tag-management">
          <EarTagList />
        </Route>
        <Route path="/commands">
          <CommandList />
        </Route>
      </>
    )}

    {[UserRole.ADMIN, UserRole.OWNER].includes(role) && (
      <Route path="/farms">
        <FarmManagement />
      </Route>
    )}

    <Route path="/logout">
      <Logout />
    </Route>
    <Route path="/users">
      <UserManagement />
    </Route>
    <Route path="/user/create">
      <CreateUser />
    </Route>
    <Route path="/user/:id/edit">
      <EditUser />
    </Route>
    <Route path="/">
      <HomePage />
    </Route>
  </>
);

const mapStateToProps = (state: ApplicationState) => {
  const { user } = state.Auth;
  return { user };
};

const connector = connect(mapStateToProps, null);

function App({ user }: ConnectedProps<typeof connector>) {
  const [isAuthenticated, setAuthenticated] = useState<boolean>(isUserAuthenticated());

  useEffect(() => {
    setAuthenticated(isUserAuthenticated());
  }, [user]);

  return (
    <Router>
      {isAuthenticated && <AppMenu />}
      <Container fluid>
        <div className="mx-3">
          <Switch>
            {isAuthenticated ? renderPrivateRoutes(getUserRole()) : renderPublicRoutes()}
          </Switch>
        </div>
      </Container>
    </Router>
  );
}

export default connector(App);
