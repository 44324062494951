import React, { useCallback, useEffect, useState } from 'react';
import {
  ColumnDescription,
  SelectRowProps,
  TableChangeState,
  TableChangeType,
} from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { TFunction } from 'i18next';
import RemoteTable from '../components/shared/remoteTable';
import RabbitFilter, { RabbitFilterValues } from '../components/stock/rabbitFilter';
import RabbitImportExport from '../components/stock/rabbitImportExport';
import { useApi } from '../hooks/useApi';
import usePaginatedData, { PageRequest } from '../hooks/usePaginatedData';
import RabbitFilterOptions from '../models/rabbitFilterOptions';
import PageContainer from './PageContainer';
import Rabbit from 'models/rabbit';
import Loader from 'hyper/components/Loader';
import { getTableClasses } from 'helpers/tables';
import EditRabbitStatus from 'components/rabbit/edit/editRabbitStatus';
import { StockListFilter } from 'models/stockListFilter';
import i18n from 'i18n';
import Page from 'models/page';

const getExportImportInfo = (importExportDisabled: boolean, t: TFunction): string | undefined => {
  if (!importExportDisabled) return undefined;
  return i18n.t('stock-list-import-export-info', {
    allMother: `${t('ALL_MOTHER')}, ${t('CAGELESS_MOTHER')}`,
  });
};

const renderActions = (cell: any, row: any, rowIndex: number, formatExtraData: any) => {
  if (!row || !row.tattoo || !formatExtraData) return undefined;
  return (
    <Button color="primary" className="py-0" tag={Link} target="_blank" to={`/rabbits/${cell}`}>
      {formatExtraData.translate('details')}
    </Button>
  );
};

const renderActionsHeader = (
  column: ColumnDescription<any>,
  selectedRabbitCount: number,
  toggle: () => void
) => (
  <>
    <span className="align-bottom">{column.text}</span>
    <Button
      color="primary"
      className={'btn-sm m-0 p-0 ml-2'}
      onClick={() => {
        toggle();
      }}
      disabled={selectedRabbitCount < 1}
    >
      <i className="mdi mdi-pencil p-1" />
    </Button>
  </>
);

const baseShiftSelect = (
  selectedRabbit: Rabbit,
  lastSelectedRabbit: Rabbit | undefined,
  selectedRabbits: Rabbit[],
  rabbits: Page<Rabbit> | undefined,
  sort: { dataField: keyof Rabbit; order: 'asc' | 'desc' }
) => {
  const page = rabbits?.content || [];
  const order = sort.order === 'asc' ? 1 : -1;
  const sorted = page.sort((a, b) =>
    a[sort.dataField] > b[sort.dataField] ? order * 1 : order * -1
  );

  const lastSelected = lastSelectedRabbit || page[0];

  const from = sorted.findIndex(({ id }) => id === selectedRabbit.id);
  const to = sorted.findIndex(({ id }) => id === lastSelected.id);
  const idxFrom = Math.min(from, to);
  const idxTo = Math.max(from, to);

  const selectedIds = selectedRabbits.map(({ id }) => id);
  return sorted.reduce<Rabbit[]>((accumulator, current, currentIndex) => {
    if ((idxFrom <= currentIndex && currentIndex <= idxTo) || selectedIds.includes(current.id)) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);
};

const baseSelect = (
  rabbit: Rabbit,
  selected: boolean,
  shiftPressed: boolean,
  lastSelectedRabbit: Rabbit | undefined,
  selectedRabbits: Rabbit[],
  rabbits: Page<Rabbit> | undefined,
  sort: { dataField: keyof Rabbit; order: 'asc' | 'desc' }
): Rabbit[] => {
  let newSelectedRabbits: Rabbit[];
  if (selected) {
    if (shiftPressed) {
      newSelectedRabbits = baseShiftSelect(
        rabbit,
        lastSelectedRabbit,
        selectedRabbits,
        rabbits,
        sort
      );
    } else {
      newSelectedRabbits = [...selectedRabbits, rabbit];
    }
  } else {
    const index = selectedRabbits.findIndex(({ id }) => id === rabbit.id);
    newSelectedRabbits = [...selectedRabbits];
    newSelectedRabbits.splice(index, 1);
  }
  return newSelectedRabbits;
};

const getNonSelectable = (rabbits: Page<Rabbit> | undefined): number[] => {
  let newNonSelectable: number[] = [];
  if (rabbits && rabbits.content && rabbits.content.length) {
    newNonSelectable = rabbits.content
      .filter((rabbit) => !rabbit.tattoo)
      .flatMap((value) => [value.key]);
  }
  return newNonSelectable;
};

const handleShiftKeyEvent = (
  key: string,
  handler: React.Dispatch<React.SetStateAction<boolean>>,
  down: boolean
) => {
  if (key === 'Shift') {
    handler(down);
  }
};

function StockList() {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<RabbitFilterValues>({
    stockListFilter: StockListFilter.ALL_CAGE,
  });
  const [sort, setSort] = useState<{ dataField: keyof Rabbit; order: 'asc' | 'desc' }>({
    dataField: 'cage',
    order: 'asc',
  });
  const { loadMore, data: rabbits, loading } = usePaginatedData<Rabbit>('/api/v1/rabbits');
  const [rabbitsLoaded, setRabbitsLoaded] = useState(false);
  const { get: getFilterOptions, loading: loadingFilterOptions } = useApi();
  const [filterOptions, setFilterOptions] = useState<RabbitFilterOptions>();
  const [importExportDisabled, setImportExportDisabled] = useState(false);
  const [title, setTitle] = useState<string>();
  const [selectedRabbits, setSelectedRabbits] = useState<Rabbit[]>([]);
  const [nonSelectableRows, setNonSelectableRows] = useState<number[]>([]);
  const [shiftPressed, setShiftPressed] = useState<boolean>(false);
  const [lastSelectedRabbit, setLastSelectedRabbit] = useState<Rabbit>();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const loadFilterOptions = useCallback(() => {
    getFilterOptions(
      '/api/v1/filter-options/rabbit',
      (fo: RabbitFilterOptions) => {
        setFilterOptions(fo);
      },
      (error: any) => console.log(error)
    );
  }, [getFilterOptions, setFilterOptions]);

  const toggleStatusModal = useCallback(() => {
    if (selectedRabbits.length < 1) return;
    setIsEditModalOpen(!isEditModalOpen);
  }, [isEditModalOpen, selectedRabbits]);

  const loadRabbits = useCallback(
    (pageRequest: PageRequest, filter: RabbitFilterValues) => {
      loadMore(pageRequest, { ...filter });
    },
    [loadMore]
  );

  const onFilterChange = useCallback(
    (newFilter: RabbitFilterValues) => {
      setFilter(newFilter);
      loadRabbits(
        {
          page: 1,
          pageSize: 100,
          sortFields: [sort.dataField],
          sortOrder: sort.order,
        },
        { ...newFilter }
      );
      setSelectedRabbits([]);
    },
    [loadRabbits, setFilter, sort]
  );

  const onTableChange = useCallback(
    (type: TableChangeType, newState: TableChangeState<Rabbit>) => {
      setSort({ dataField: newState.sortField as keyof Rabbit, order: newState.sortOrder });
      loadRabbits(
        {
          page: newState.page,
          pageSize: newState.sizePerPage,
          sortFields: [newState.sortField],
          sortOrder: newState.sortOrder,
        },
        { ...filter }
      );
      setSelectedRabbits([]);
    },
    [loadRabbits, filter]
  );

  const onPageChange = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  const onStockListFilterChange = useCallback(
    (stockListFilter: StockListFilter) => {
      setImportExportDisabled(
        stockListFilter !== StockListFilter.ALL_MOTHER &&
          stockListFilter !== StockListFilter.CAGELESS_MOTHER
      );
      setTitle(t(stockListFilter));
    },
    [t]
  );

  const onSaveStatus = useCallback(() => {
    onFilterChange(filter);
  }, [filter, onFilterChange]);

  const getSelectedRabbitKeys = useCallback(() => selectedRabbits.map(({ key }) => key), [
    selectedRabbits,
  ]);

  const getSelectedRabbitIds = useCallback(() => selectedRabbits.map(({ id }) => id), [
    selectedRabbits,
  ]);

  const columns = [
    {
      dataField: 'cage',
      text: t('cage'),
      sort: true,
    },
    {
      dataField: 'earTag',
      text: t('ear-tag'),
      sort: true,
    },
    {
      dataField: 'tattoo',
      text: t('tattoo'),
      sort: true,
    },
    {
      dataField: 'breed',
      text: t('breed'),
      formatter: (cell: string) => t(cell),
    },
    {
      dataField: 'sperm',
      text: t('father'),
      sort: true,
    },
    {
      dataField: 'mother',
      text: t('mother'),
      sort: true,
    },
    {
      dataField: 'previousIA',
      text: t('previous-ia'),
    },
    {
      dataField: 'currentIA',
      text: t('current-ia'),
    },
    {
      dataField: 'iaCount',
      text: t('ia-count'),
      formatter: (cell: any, row: any) => (row.id ? cell : ''),
    },
    {
      dataField: 'status',
      text: t('status'),
      formatter: (cell: string) => t(cell),
    },
    {
      dataField: 'healthCondition',
      text: t('health-condition'),
      formatter: (cell: string) => t(cell),
    },
    {
      dataField: 'id',
      text: t('operations'),
      formatExtraData: { translate: t },
      formatter: renderActions,
      headerFormatter: (column: ColumnDescription<any>) =>
        renderActionsHeader(column, selectedRabbits.length, toggleStatusModal),
    },
  ];

  const handleKeyPress = useCallback(
    (isDownPressed: boolean) => ({ key }: KeyboardEvent) =>
      handleShiftKeyEvent(key, setShiftPressed, isDownPressed),

    [setShiftPressed]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress(true));
    window.addEventListener('keyup', handleKeyPress(false));

    return () => {
      window.removeEventListener('keydown', handleKeyPress(true));
      window.removeEventListener('keyup', handleKeyPress(false));
    };
  }, [handleKeyPress]);

  useEffect(() => {
    setNonSelectableRows(getNonSelectable(rabbits));
  }, [rabbits]);

  useEffect(() => {
    if (rabbitsLoaded) {
      loadFilterOptions();
    }
  }, [rabbitsLoaded, loadFilterOptions]);

  useEffect(() => {
    if (!rabbitsLoaded) {
      setRabbitsLoaded(true);
    }
  }, [rabbits, rabbitsLoaded]);

  const selectRow: SelectRowProps<Rabbit> = {
    mode: 'checkbox',
    clickToSelect: true,
    nonSelectable: nonSelectableRows,
    selected: getSelectedRabbitKeys(),
    onSelect: (rabbit: Rabbit, selected: boolean) => {
      const newSelectedRabbits = baseSelect(
        rabbit,
        selected,
        shiftPressed,
        lastSelectedRabbit,
        selectedRabbits,
        rabbits,
        sort
      );
      setSelectedRabbits(newSelectedRabbits);
      if (selected) {
        setLastSelectedRabbit(rabbit);
      }
    },
    onSelectAll: (isSelect: boolean, rows: Rabbit[], e: React.SyntheticEvent) => {
      if (isSelect) {
        setSelectedRabbits(rows);
      } else {
        setSelectedRabbits([]);
      }
      setLastSelectedRabbit(undefined);
    },
  };

  const isLoading = useCallback(() => loading || loadingFilterOptions, [
    loading,
    loadingFilterOptions,
  ]);

  return (
    <PageContainer
      title={
        <div className="mb-1 mt-3 clearfix">
          <h4 className="float-left">
            {t('stock')}
            {title ? ` - ${title}` : ''}
          </h4>
          <RabbitImportExport
            id="RIE"
            filter={filter}
            sort={sort}
            disabled={importExportDisabled}
            disablingCauseText={getExportImportInfo(importExportDisabled, t)}
          />
        </div>
      }
    >
      {rabbitsLoaded && (
        <div className="my-2 mx-2">
          <RabbitFilter
            disabled={isLoading()}
            options={filterOptions || undefined}
            outerFilter={filter}
            onFilter={onFilterChange}
            onStockListFilterChange={onStockListFilterChange}
          />
        </div>
      )}

      {isLoading() && <Loader />}
      <RemoteTable
        wrapperClasses={getTableClasses('stocklist-table')}
        data={rabbits}
        onTableChange={onTableChange}
        columns={columns}
        defaultSorted={sort}
        keyField="key"
        sizePerPage={100}
        onPageChange={onPageChange}
        condensed
        selectRow={selectRow}
      />

      <EditRabbitStatus
        toggle={toggleStatusModal}
        isOpen={isEditModalOpen}
        onSave={onSaveStatus}
        motherIds={getSelectedRabbitIds()}
      />
    </PageContainer>
  );
}

export default StockList;
