import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import SimpleTable from '../shared/simpleTable';
import IAPairing from 'models/IAPairing';

interface PairingListProps {
  pairings: IAPairing[];
  onPairingRemoved: (id: string) => void;
}

function PairingList({ pairings, onPairingRemoved }: PairingListProps) {
  const [lastSelectedRowId, setLastSelectedRowId] = useState<string>('');
  const [clicked, setClicked] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(
    useCallback(() => {
      if (lastSelectedRowId && clicked) {
        onPairingRemoved(lastSelectedRowId);
        setClicked(false);
      }
    }, [onPairingRemoved, clicked, lastSelectedRowId])
  );

  const deleteButtonFormatter = () => (
    <div
      className="text-center"
      onClick={() => {
        setClicked(true);
      }}
    >
      <span>
        <i className="mdi mdi-18px mdi-close" />
      </span>
    </div>
  );

  const columns = [
    {
      dataField: 'earTag',
      text: t('ear-tag'),
      sort: true,
    },
    {
      dataField: 'cage',
      text: t('cage'),
      sort: true,
    },
    {
      dataField: 'tattoo',
      text: t('tattoo'),
      sort: true,
    },
    {
      dataField: 'pedigree',
      text: t('pedigree'),
      sort: false,
    },
    {
      dataField: 'spermLabel',
      text: t('sperm'),
      sort: false,
    },
    {
      dataField: '',
      text: t('delete'),
      sort: false,
      formatter: deleteButtonFormatter,
    },
  ];

  const rowEvents = {
    onClick: (e: any, row: IAPairing) => {
      setLastSelectedRowId(row.earTag);
    },
  };

  return (
    <SimpleTable
      data={pairings}
      keyField="earTag"
      columns={columns}
      defaultSorted={{ dataField: 'cage', order: 'asc' }}
      rowEvents={rowEvents}
      dataCy="pairingList"
    />
  );
}

export default PairingList;
