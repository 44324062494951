import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Badge, Button, Col, Row } from 'reactstrap';
import PageContainer from '../PageContainer';
import { DownloadExport } from './export/DownloadExport';
import YoungEditDiaries from 'components/grouppages/youngEditDiaries';
import YoungEditSmallTables from 'components/grouppages/youngEditSmallTables';
import { useApiGet } from 'hooks/useApi';
import Loader from 'hyper/components/Loader';
import EditYoungTransfer from 'models/grouppages/editYoungTransfer';
import YoungStableModal from 'components/grouppages/modal/young/youngStableModal';
import { getStableNames } from 'components/grouppages/gpUtil';
import Farm from 'models/farm';

interface YoungListRouteParams {
  id: string;
}

function YoungEdit() {
  const { id } = useParams<YoungListRouteParams>();
  const { t } = useTranslation();
  const [idxValue, setValue] = useState(0);
  const [group, setGroup] = useState<EditYoungTransfer | null>();
  const { data: groupTmp, loading: loadingGroup } = useApiGet<EditYoungTransfer>(
    `/api/v1/group/young/${id}`,
    idxValue
  );
  const { data: farms, loading: loadingFarm } = useApiGet<Farm[]>('/api/v1/farms');

  const [isSelectStableOpen, setIsSelectStableOpen] = useState<boolean>(false);

  const invalidate = useCallback(() => setValue((idxValue) => ++idxValue), []);

  const isLoading = useCallback(() => loadingGroup || loadingFarm, [loadingFarm, loadingGroup]);

  const refresh = useCallback(() => invalidate(), [invalidate]);

  const toggleStableModal = useCallback(() => {
    setIsSelectStableOpen(!isSelectStableOpen);
  }, [isSelectStableOpen]);

  useEffect(() => {
    setGroup(groupTmp);
  }, [groupTmp]);

  return (
    <>
      {group && (
        <YoungStableModal
          id={group.id}
          toggle={toggleStableModal}
          stables={group.stables}
          isOpen={isSelectStableOpen}
          onSave={refresh}
          farms={farms}
        />
      )}
      <PageContainer
        title={
          <Row className="my-2 clearfix align-items-center d-flex">
            <Col sm="auto" className="align-items-center d-flex">
              <h4 className="float-left pr-3">
                {`${t('grouppage-edit-young')} : ${group?.generatedName}`}
              </h4>
              {group?.endDate && (
                <h4 className="float-left pr-3">
                  <Badge color="secondary" className="ml-3">
                    {t('closed-grouppage')}
                  </Badge>
                </h4>
              )}
              <h4 className="float-left pr-3">
                {`${t('grouppage-stable')} : ${getStableNames(group)}`}
              </h4>
              <Button
                id="stables-edit-button"
                color="primary"
                className="btn btn-sm p-0"
                onClick={() => {
                  toggleStableModal();
                }}
              >
                <i className="mdi mdi-pencil p-1" />
              </Button>
            </Col>
            <Col className="d-flex justify-content-end mr-2">
              <DownloadExport
                id={group?.id}
                spreadSheetName={'grouppage-edit-young'}
                type={'young'}
                isSummary={false}
                generatedName={group?.generatedName}
              />
            </Col>
          </Row>
        }
      >
        {isLoading() && <Loader />}

        <YoungEditSmallTables transfer={group} refresh={refresh} farms={farms} />

        <YoungEditDiaries transfer={group} refresh={refresh} />
      </PageContainer>
    </>
  );
}

export default YoungEdit;
