import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
  Row,
  FormGroup,
  Col,
  Alert,
} from 'reactstrap';
import Select, { ValueType } from 'react-select';
import { EarTagFilterOptions } from '../earTagFilter';
import SearchResult from 'models/searchResult';
import { useApiGet, useApiPost } from 'hooks/useApi';
import i18n from 'i18n';
import Loader from 'hyper/components/Loader';

export type EarTagOperationType = 'placement' | 'relocation';

interface OptionType {
  value: number;
  label: string;
}

interface EarTagModalProps {
  isOpen: boolean;
  toggle: (
    isSuccess?: boolean
  ) => (event: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLInputElement>) => void;
  type: EarTagOperationType;
  earTagFilterOptions: EarTagFilterOptions;
  totalElements: number | undefined;
}

export const EarTagModal: React.FC<EarTagModalProps> = ({
  isOpen,
  toggle,
  type,
  earTagFilterOptions,
  totalElements,
}: EarTagModalProps): JSX.Element => {
  const { t } = useTranslation();

  const { data: farms, loading } = useApiGet<SearchResult[]>('/api/v1/search/farm?search=');

  const [farmId, setFarmId] = useState<number>();
  const [movedEarTags, setMovedEarTags] = useState<number>();
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);

  const [moveEarTags, { loading: saving }] = useApiPost<number>(
    `/api/v1/ear-tag/${type}?farmId=${farmId}`,
    (successfullyMovedEarTags) => {
      setMovedEarTags(successfullyMovedEarTags);
      setIsSuccess(true);
    },
    () => {
      setIsSuccess(false);
    }
  );

  const onSelectChange = useCallback((value: ValueType<OptionType>) => {
    setFarmId((value as OptionType).value);
  }, []);

  const submit = useCallback(() => {
    moveEarTags(earTagFilterOptions);
  }, [earTagFilterOptions, moveEarTags]);

  const isLoading = useCallback(() => loading || saving, [loading, saving]);

  useEffect(() => {
    setFarmId(undefined);
    setMovedEarTags(undefined);
    setIsSuccess(undefined);
  }, [isOpen]);

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle(isSuccess)} size="md">
        <ModalHeader toggle={toggle(isSuccess)}>
          {type === 'placement' && t('ear-tag-placement')}
          {type === 'relocation' && t('ear-tag-relocation')}
        </ModalHeader>
        <ModalBody className="align-items-center">
          {isLoading() && <Loader />}
          {!isSuccess && (
            <>
              {type === 'placement' && (
                <p className="text-center">{i18n.t('ear-tag-place', { count: totalElements })}</p>
              )}
              {type === 'relocation' && (
                <p className="text-center">
                  {i18n.t('ear-tag-relocate', { count: totalElements })}
                </p>
              )}
              <Row className="justify-content-center my-2">
                <Col sm="8">
                  <FormGroup className="mb-0">
                    <Label>{t('ear-tag-farm')}</Label>
                    <Select
                      className="react-select"
                      classNamePrefix="react-select"
                      placeholder={t('select-please')}
                      onChange={onSelectChange}
                      options={farms
                        ?.filter((farm) => farm.id !== earTagFilterOptions.farmId)
                        .map((farm) => ({ value: farm.id || 0, label: farm.label }))}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
          {!isLoading() &&
            isSuccess &&
            (type === 'placement' ? (
              <Alert className="mt-2 text-center" color="success">
                {i18n.t('successful-ear-tag-placement', {
                  count: movedEarTags,
                  target: farms?.find((farm) => farm.id === farmId)?.label,
                })}
              </Alert>
            ) : (
              <Alert className="mt-2 text-center" color="success">
                {i18n.t('successful-ear-tag-relocation', {
                  count: movedEarTags,
                  from: farms?.find((farm) => farm.id === earTagFilterOptions.farmId)?.label,
                  target: farms?.find((farm) => farm.id === farmId)?.label,
                })}
              </Alert>
            ))}
          {!isLoading() && isSuccess === false && (
            <Alert className="mt-2 text-center" color="danger">
              {t('general-error-message')}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          {!isSuccess && (
            <>
              <Button
                color="success"
                disabled={!farmId || isLoading()}
                onClick={submit}
                className="mr-2"
              >
                {t('save')}
              </Button>
              <Button color="primary" onClick={toggle(isSuccess)}>
                {t('cancel')}
              </Button>
            </>
          )}
          {isSuccess && (
            <>
              <Button color="info" onClick={toggle(isSuccess)}>
                {t('ok')}
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};
