import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

interface StaticLabelProps {
  label: string;
  value: string | number;
}

function StaticLabel({ label, value }: StaticLabelProps) {
  const { t } = useTranslation();

  return (
    <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
      <Col sm="4">
        <b>{t(label)}:</b>
      </Col>
      <Col className="d-flex justify-content-start">{value}</Col>
    </Row>
  );
}

export default StaticLabel;
