import React from 'react';
import Plot from 'react-plotly.js';

interface ChartProps {
  data: Plotly.Data[];
  layout?: Partial<Plotly.Layout>;
  frames?: Plotly.Frame[] | undefined;
  config?: Partial<Plotly.Config> | undefined;
  id?: string;
}

export const Chart = ({ data, layout, frames, config, id }: ChartProps): JSX.Element => (
  <Plot
    className="border"
    data={data}
    layout={layout || { autosize: true }}
    frames={frames}
    config={config}
    useResizeHandler
    divId={id}
  />
);

export default Chart;
