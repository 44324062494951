import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardBody, Col, ListGroup, Row } from 'reactstrap';
import BlockInfo from './blockInfo';
import StatBlockTransfer from 'models/grouppages/statBlockTransfer';

interface DiaryWeightsBlocksProps {
  transfer: StatBlockTransfer[] | undefined;
}

function DiaryWeightsBlocks({ transfer }: DiaryWeightsBlocksProps) {
  const { t } = useTranslation();

  return (
    <>
      <Row className="justify-content-around">
        <Col>
          <h4>{t('sw-pda')}</h4>
        </Col>
      </Row>
      {transfer && transfer.length > 0 && (
        <CardBody className="p-1" style={{ height: '80vh', overflow: 'auto' }}>
          <ListGroup>
            {transfer
              .sort(
                (a, b) =>
                  new Date(a.blocks[0].startAt).getTime() - new Date(b.blocks[0].startAt).getTime()
              )
              .map((userBlock: StatBlockTransfer) => (
                <div key={userBlock.userName}>
                  <BlockInfo transfer={userBlock} />
                </div>
              ))}
          </ListGroup>
        </CardBody>
      )}
      {(!transfer || transfer.length < 1) && (
        <Row>
          <Col>{t('sw-no-pda-data')}</Col>
        </Row>
      )}
    </>
  );
}

export default DiaryWeightsBlocks;
