import React from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import MedicineTransfer from 'models/grouppages/medicineTransfer';
import { ValueType } from 'models/valueType';

interface CheckBoxDetails {
  onMedicineValueChange: <S>(
    objectKey: React.ReactText,
    key: keyof S,
    valueType: ValueType
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  medicineDetails: MedicineTransfer | undefined;
  label: string;
  medicineKey: keyof MedicineTransfer;
}

function CheckBox({ medicineDetails, onMedicineValueChange, label, medicineKey }: CheckBoxDetails) {
  const { t } = useTranslation();

  return (
    <Col className="d-flex align-items-center justify-content-center">
      <Row className="w-100 d-flex align-items-center justify-content-center">
        <Label
          check
          className="w-100 cursor-pointer d-flex flex-row align-items-center justify-content-center"
        >
          <Input
            className="cursor-pointer position-relative mb-1 mr-1"
            type="checkbox"
            name={label}
            defaultChecked={(medicineDetails && medicineDetails[medicineKey]) ?? false}
            onChange={onMedicineValueChange('medicine', medicineKey, ValueType.BOOLEAN)}
          />
          <h4 className="m-0">{t(label)}</h4>
        </Label>
      </Row>
    </Col>
  );
}

export default CheckBox;
