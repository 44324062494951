import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Badge, Button, Col, Row } from 'reactstrap';
import PageContainer from '../PageContainer';
import { DownloadExport } from './export/DownloadExport';
import FatteningEditDiaries from 'components/grouppages/fatteningEditDiaries';
import { useApiGet } from 'hooks/useApi';
import Loader from 'hyper/components/Loader';
import EditFatteningTransfer from 'models/grouppages/editFatteningTransfer';
import {
  dateStr,
  getMergedGPButton,
  getStableNames,
  isParentInMergedGrouppage,
  PLANNED_DELIVERY_AGE,
} from 'components/grouppages/gpUtil';
import MultiStableModal from 'components/grouppages/modal/common/multiStableModal';
import FatteningSlaughteringTable from 'components/grouppages/fatteningSlaughteringTable';
import FatteningEditSmallTables from 'components/grouppages/fatteningEditSmallTables';
import Stable from 'models/stable';
import { GroupMarker } from 'models/grouppages/groupMarker';
import Farm from 'models/farm';

function calculateAddedForDiaries(transfer: EditFatteningTransfer | null): Map<number, number> {
  const result = new Map<number, number>();
  if (transfer && transfer.origins && transfer.diary.diaries) {
    const sumToDate = new Map<string, number>();
    for (const origin of transfer.origins) {
      if (origin) {
        const day = dateStr(origin.arrivedDate);
        sumToDate.set(day, origin.num + (sumToDate.get(day) || 0));
      }
    }
    if (sumToDate.size > 0) {
      for (const diary of transfer.diary.diaries) {
        const day = dateStr(diary.actualDate);
        if (sumToDate.has(day)) {
          result.set(diary.dayOfAge, sumToDate.get(day) || 0);
        }
      }
    }
  }
  return result;
}
interface FatteningListRouteParams {
  id: string;
}

function calcMainStarterNum(transfer: EditFatteningTransfer | null | undefined): number {
  if (!transfer || !transfer.origins || transfer.origins.length < 1) return 0;
  const limit = new Date(transfer.startDate).getTime();
  return transfer.origins
    .filter((origin) => new Date(origin.arrivedDate).getTime() <= limit)
    .reduce((sum, { num }) => {
      if (num) sum += num;
      return sum;
    }, 0);
}

export const RED_ZONE_LIMIT = 0.002;

export interface RedZoneData {
  dayStartNum: number;
  dayEndNum: number;
  dailyDead: number;
  redZone: boolean;
}

function calcDayRedZoneData(
  transfer: EditFatteningTransfer | null | undefined
): Map<number, RedZoneData> {
  const result = new Map<number, RedZoneData>();
  if (!transfer || !transfer.diary.diaries || transfer.diary.diaries.length < 1) return result;
  const lowerLimitDay = new Date(transfer.diary.diaries[0].actualDate).getTime();
  const now = new Date().getTime();
  const veryLastDay = new Date(
    transfer.diary.diaries[transfer.diary.diaries.length - 1].actualDate
  ).getTime();
  const upperLimitDay = transfer.endDate || (now < veryLastDay ? now : veryLastDay);
  let actualNum = calcMainStarterNum(transfer);
  for (const diary of transfer.diary.diaries) {
    if (new Date(diary.actualDate).getTime() > upperLimitDay) break;
    const startNum = actualNum;
    if (diary.addedNum && lowerLimitDay < new Date(diary.actualDate).getTime())
      actualNum += diary.addedNum;
    actualNum -= (diary.value.dead || 0) + (diary.value.soldNum || 0);
    const redZone = diary.value.dead ? diary.value.dead / startNum > RED_ZONE_LIMIT : false;
    result.set(diary.dayOfAge, {
      dayStartNum: startNum,
      dayEndNum: actualNum,
      dailyDead: diary.value.dead || 0,
      redZone,
    });
  }
  return result;
}

function calcForbiddenStables(data: EditFatteningTransfer | null): Stable[] {
  const result = [] as Stable[];
  if (data && data.gpMembers && data.gpMembers.length > 0) {
    for (const member of data.gpMembers) {
      if (member.groupId !== data.parentId && member.stables && member.groupId !== data.id) {
        member.stables.forEach((stable) => result.push(stable));
      }
    }
  }
  return result;
}

function FatteningEdit() {
  const { id } = useParams<FatteningListRouteParams>();
  const { t } = useTranslation();
  const [idxValue, setValue] = useState(0);
  const [group, setGroup] = useState<EditFatteningTransfer | null>();
  const { data: groupTmp, loading: loadingGroup } = useApiGet<EditFatteningTransfer>(
    `/api/v1/group/fattening/${id}`,
    idxValue
  );
  const { data: farms, loading: loadingFarm } = useApiGet<Farm[]>('/api/v1/farms');

  const [isParent, setIsParent] = useState(false);
  const [diaryMap, setDiaryMap] = useState<Map<number, number>>();
  const [redZoneMap, setRedZoneMap] = useState<Map<number, RedZoneData>>();

  const [isSelectStableOpen, setIsSelectStableOpen] = useState<boolean>(false);

  const [forbiddenStables, setForbiddenStables] = useState<Stable[]>([]);

  const invalidate = useCallback(() => setValue((idxValue) => ++idxValue), []);

  const isLoading = useCallback(() => loadingGroup || loadingFarm, [loadingGroup, loadingFarm]);

  const refresh = useCallback(() => invalidate(), [invalidate]);

  const toggleStableModal = useCallback(() => {
    setIsSelectStableOpen(!isSelectStableOpen);
  }, [isSelectStableOpen]);

  useEffect(() => {
    const g = groupTmp;
    setForbiddenStables(calcForbiddenStables(g));
    setGroup(g);
    setIsParent(isParentInMergedGrouppage(g));
    setDiaryMap(calculateAddedForDiaries(groupTmp));
    setRedZoneMap(calcDayRedZoneData(groupTmp));
  }, [groupTmp]);

  const getTitle = useCallback(() => t(`${isParent ? 'merged-' : ''}grouppage-edit-fattening`), [
    isParent,
    t,
  ]);

  return (
    <>
      {group && (
        <MultiStableModal
          id={group.id}
          type={GroupMarker.FATTENING}
          toggle={toggleStableModal}
          isMulti={isParent || (group.version || 0) < 1}
          stables={group.stables}
          forbiddenStables={forbiddenStables}
          isOpen={isSelectStableOpen}
          plannedDeliveryAge={group.plannedDeliveryAge ?? PLANNED_DELIVERY_AGE}
          onSave={refresh}
          farms={farms}
        />
      )}
      <PageContainer
        title={
          <>
            <Row className="mt-2 clearfix align-items-center d-flex">
              <Col sm="auto" className="align-items-center d-flex">
                <h4 className="float-left pr-3">{`${getTitle()} : ${group?.generatedName}`}</h4>
                {group?.endDate && (
                  <h4 className="float-left pr-3">
                    <Badge color="secondary" className="ml-3">
                      {t('closed-grouppage')}
                    </Badge>
                  </h4>
                )}
                <h4 className="float-left pr-3">
                  {`${t('grouppage-stable')} : ${getStableNames(group)}`}
                </h4>
                {!isParent && (
                  <h4 className="float-left pr-3">
                    {`${t('grouppage-fattening-planned-age')} : ${
                      group && group.plannedDeliveryAge ? group.plannedDeliveryAge : '?'
                    } ${t('day')}`}
                  </h4>
                )}
                {!isParent && (
                  <Button
                    id="stables-edit-button"
                    color="primary"
                    className="btn btn-sm p-0"
                    onClick={() => {
                      toggleStableModal();
                    }}
                  >
                    <i className="mdi mdi-pencil p-1" />
                  </Button>
                )}
              </Col>
              <Col className="d-flex justify-content-end mr-2">
                <DownloadExport
                  id={group?.id}
                  spreadSheetName={'grouppage-edit-fattening'}
                  type={`${isParent ? 'merged-' : ''}fattening`}
                  isSummary={false}
                  generatedName={group?.generatedName}
                />
              </Col>
            </Row>
            <Row className="mt-1 mb-2 clearfix align-items-center d-flex">
              <Col sm="auto" className="align-items-left d-flex">
                {group &&
                  group.gpMembers &&
                  group.gpMembers.map((member) =>
                    getMergedGPButton(member, group.id, group.parentId, 'fattening')
                  )}
              </Col>
            </Row>
          </>
        }
      >
        {isLoading() && <Loader />}

        <FatteningEditSmallTables
          transfer={group}
          redZoneMap={redZoneMap}
          refresh={refresh}
          farms={farms}
        />

        {!isParent && <FatteningSlaughteringTable transfer={group} refresh={refresh} />}

        <FatteningEditDiaries
          transfer={group}
          diaryMap={diaryMap}
          redZoneMap={redZoneMap}
          refresh={refresh}
        />
      </PageContainer>
    </>
  );
}

export default FatteningEdit;
