import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import {
  classCellBg,
  classCellCenter,
  classCellMain,
  classCommon,
  classSideButton,
  classTable,
  createBaseTd,
  createTh,
  dateToStr,
  isParentInMergedGrouppage,
  round,
} from './gpUtil';
import EditMotherNumModal from './modal/mother/editMotherNumModal';
import EditSucklingDetailsModal from './modal/mother/editSucklingNumModal';
import MotherNumTransfer from 'models/grouppages/motherNumTransfer';
import MotherNumData from 'models/grouppages/motherNumData';
import EditMotherTransfer from 'models/grouppages/editMotherTransfer';
import SucklingNumTransfer from 'models/grouppages/sucklingNumTransfer';
import SucklingWeightGain from 'models/grouppages/sucklingWeightGain';
import Farm from 'models/farm';

const classTableDiv = 'react-bootstrap-table table-responsive';
const classSmallTable = `${classTable} mb-1 gp-table`;
const classCell = `bg-light text-dark text-right ${classCommon}`;
const classButton = `${classSideButton} ml-2`;

const sum = (...rest: (number | undefined)[]) =>
  rest &&
  rest.reduce((a, b) => {
    if (a) {
      if (b) {
        return a + b;
      } else {
        return a;
      }
    } else if (b) {
      return b;
    }
    return undefined;
  });

const percent = (actual: number | undefined, origin: number | undefined) => {
  if (!actual || !origin) return undefined;

  return Math.round(1000 * (actual / origin)) / 10;
};

const round1 = (value: number | undefined) => round(value, 1);

const toStr = (item: number | undefined, decimal = false) =>
  item || item === 0 ? (decimal ? `${item.toFixed(1)}` : `${item}`) : '';

function getEmptyTransfer(): MotherNumTransfer {
  return {
    iaIncycle: undefined,
    iaOutcycle: undefined,
    iaVirgin: undefined,
    pregnantIncycle: undefined,
    pregnantOutcycle: undefined,
    pregnantVirgin: undefined,
    populatedIncycle: undefined,
    populatedOutcycle: undefined,
    populatedVirgin: undefined,
    populatedWhenFrom: undefined,
    populatedWhenTo: undefined,
    farrowedIncycle: undefined,
    farrowedOutcycle: undefined,
    farrowedVirgin: undefined,
    nursingMother: undefined,
    nursingMotherVirgin: undefined,
    weanedMother: undefined,
    weanedMotherVirgin: undefined,
  };
}

function getEmptyMotherNumData(): MotherNumData {
  return {
    transfer: getEmptyTransfer(),

    iaSum: undefined,

    pregnantSum: undefined,
    pregnantIncyclePercent: undefined,
    pregnantOutcyclePercent: undefined,
    pregnantVirginPercent: undefined,
    pregnantSumPercent: undefined,

    populatedSum: undefined,
    populatedFrom: undefined,
    populatedTo: undefined,

    farrowedSum: undefined,
    farrowedIncyclePercent: undefined,
    farrowedOutcyclePercent: undefined,
    farrowedVirginPercent: undefined,
    farrowedSumPercent: undefined,

    nursingIncycleOutcycleSumPercent: undefined,
    nursingVirginPercent: undefined,
    nursingMotherSum: undefined,
    nursingMotherSumPercent: undefined,

    weanedIncycleOutcycSumPercent: undefined,
    weanedVirginPercent: undefined,
    weanedMotherSum: undefined,
    weanedMotherSumPercent: undefined,
  };
}

function calcMotherNumData(transfer: MotherNumTransfer | null | undefined): MotherNumData {
  if (!transfer) getEmptyMotherNumData();

  const iaSumTmp = sum(transfer?.iaIncycle, transfer?.iaOutcycle, transfer?.iaVirgin);
  const pregnantSumTmp = sum(
    transfer?.pregnantIncycle,
    transfer?.pregnantOutcycle,
    transfer?.pregnantVirgin
  );
  const populatedSumTmp = sum(
    transfer?.populatedIncycle,
    transfer?.populatedOutcycle,
    transfer?.populatedVirgin
  );
  const farrowedSumTmp = sum(
    transfer?.farrowedIncycle,
    transfer?.farrowedOutcycle,
    transfer?.farrowedVirgin
  );
  const nursingMotherVirginSum = sum(transfer?.nursingMother, transfer?.nursingMotherVirgin);
  const weanedMotherVirginSum = sum(transfer?.weanedMother, transfer?.weanedMotherVirgin);
  const farrowedMotherSum = sum(transfer?.farrowedIncycle, transfer?.farrowedOutcycle);
  return {
    transfer: transfer || getEmptyTransfer(),

    iaSum: iaSumTmp,

    pregnantSum: pregnantSumTmp,
    pregnantIncyclePercent: percent(transfer?.pregnantIncycle, transfer?.iaIncycle),
    pregnantOutcyclePercent: percent(transfer?.pregnantOutcycle, transfer?.iaOutcycle),
    pregnantVirginPercent: percent(transfer?.pregnantVirgin, transfer?.iaVirgin),
    pregnantSumPercent: percent(pregnantSumTmp, iaSumTmp),

    populatedSum: populatedSumTmp,
    populatedFrom: transfer?.populatedFrom,
    populatedTo: transfer?.populatedTo,

    farrowedSum: farrowedSumTmp,
    farrowedIncyclePercent: percent(transfer?.farrowedIncycle, transfer?.iaIncycle),
    farrowedOutcyclePercent: percent(transfer?.farrowedOutcycle, transfer?.iaOutcycle),
    farrowedVirginPercent: percent(transfer?.farrowedVirgin, transfer?.iaVirgin),
    farrowedSumPercent: percent(farrowedSumTmp, iaSumTmp),

    nursingIncycleOutcycleSumPercent: percent(transfer?.nursingMother, farrowedMotherSum),
    nursingVirginPercent: percent(transfer?.nursingMotherVirgin, transfer?.farrowedVirgin),
    nursingMotherSum: nursingMotherVirginSum,
    nursingMotherSumPercent: percent(nursingMotherVirginSum, farrowedSumTmp),

    weanedIncycleOutcycSumPercent: percent(transfer?.weanedMother, farrowedMotherSum),
    weanedVirginPercent: percent(transfer?.weanedMotherVirgin, transfer?.farrowedVirgin),
    weanedMotherSum: weanedMotherVirginSum,
    weanedMotherSumPercent: percent(weanedMotherVirginSum, farrowedSumTmp),
  };
}
interface MotherData {
  id: number;
  ia?: string;
  pregnant?: string;
  pregnantPercent?: string;
  populated?: string;
  farrowed?: string;
  farrowedPercent?: string;
  nursing?: string;
  nursingPercent?: string;
  weaning?: string;
  weaningPercent?: string;
}

function getEmptyMotherData(): MotherData[] {
  const ret = [];
  for (let i = 1; i < 5; i++) {
    ret.push({
      id: i,
      ia: undefined,
      pregnant: undefined,
      pregnantPercent: undefined,
      populated: undefined,
      farrowed: undefined,
      farrowedPercent: undefined,
      nursing: undefined,
      nursingPercent: undefined,
      weaning: undefined,
      weaningPercent: undefined,
    });
  }
  return ret;
}

function convertMotherData(data: MotherNumData): MotherData[] {
  const ret = [];
  ret.push({
    id: 1,
    ia: toStr(data.transfer.iaIncycle),
    pregnant: toStr(data.transfer.pregnantIncycle),
    pregnantPercent: toStr(round1(data.pregnantIncyclePercent), true),
    populated: toStr(data.transfer.populatedIncycle),
    farrowed: toStr(data.transfer.farrowedIncycle),
    farrowedPercent: toStr(round1(data.farrowedIncyclePercent), true),
    nursing: toStr(data.transfer.nursingMother),
    nursingPercent: toStr(round1(data.nursingIncycleOutcycleSumPercent), true),
    weaning: toStr(data.transfer.weanedMother),
    weaningPercent: toStr(round1(data.weanedIncycleOutcycSumPercent), true),
  });
  ret.push({
    id: 2,
    ia: toStr(data.transfer.iaOutcycle),
    pregnant: toStr(data.transfer.pregnantOutcycle),
    pregnantPercent: toStr(round1(data.pregnantOutcyclePercent), true),
    populated: toStr(data.transfer.populatedOutcycle),
    farrowed: toStr(data.transfer.farrowedOutcycle),
    farrowedPercent: toStr(round1(data.farrowedOutcyclePercent), true),
  });
  ret.push({
    id: 3,
    ia: toStr(data.transfer.iaVirgin),
    pregnant: toStr(data.transfer.pregnantVirgin),
    pregnantPercent: toStr(round1(data.pregnantVirginPercent), true),
    populated: toStr(data.transfer.populatedVirgin),
    farrowed: toStr(data.transfer.farrowedVirgin),
    farrowedPercent: toStr(round1(data.farrowedVirginPercent), true),
    nursing: toStr(data.transfer.nursingMotherVirgin),
    nursingPercent: toStr(round1(data.nursingVirginPercent), true),
    weaning: toStr(data.transfer.weanedMotherVirgin),
    weaningPercent: toStr(round1(data.weanedVirginPercent), true),
  });
  ret.push({
    id: 4,
    ia: toStr(data.iaSum),
    pregnant: toStr(data.pregnantSum),
    pregnantPercent: toStr(round1(data.pregnantSumPercent), true),
    populated: toStr(data.populatedSum),
    farrowed: toStr(data.farrowedSum),
    farrowedPercent: toStr(round1(data.farrowedSumPercent), true),
    nursing: toStr(data.nursingMotherSum),
    nursingPercent: toStr(round1(data.nursingMotherSumPercent), true),
    weaning: toStr(data.weanedMotherSum),
    weaningPercent: toStr(round1(data.weanedMotherSumPercent), true),
  });
  return ret;
}

interface SucklingData {
  sucklingNum?: string;
  sucklingViable?: string;
  sucklingAdjusted?: string;
  sucklingWeaned?: string;
  weaningDate?: string;
  sucklingNumAvg?: string;
  sucklingViableAvg?: string;
  sucklingAdjustedAvg?: string;
  sucklingWeanedAvg?: string;
}

function getEmptySucklingData(): SucklingData {
  return {
    sucklingNum: undefined,
    sucklingViable: undefined,
    sucklingAdjusted: undefined,
    sucklingWeaned: undefined,
    weaningDate: undefined,
    sucklingNumAvg: undefined,
    sucklingViableAvg: undefined,
    sucklingAdjustedAvg: undefined,
    sucklingWeanedAvg: undefined,
  };
}

function convertSucklingData(
  data: SucklingNumTransfer | null | undefined,
  sumFarrowed?: string,
  sumNursing?: string
): SucklingData {
  const numSumFarrowed = sumFarrowed ? +sumFarrowed : undefined;
  const numSumNursing = sumNursing ? +sumNursing : undefined;
  return {
    sucklingNum: toStr(data?.sucklingNum),
    sucklingViable: toStr(data?.sucklingViable),
    sucklingAdjusted: toStr(data?.sucklingAdjusted),
    sucklingWeaned: toStr(data?.sucklingWeaned),
    weaningDate: dateToStr(data?.weaningDate, 0),

    sucklingNumAvg:
      data?.sucklingNum && numSumFarrowed
        ? toStr(round1(data.sucklingNum / numSumFarrowed), true)
        : '',
    sucklingViableAvg:
      data?.sucklingViable && numSumFarrowed
        ? toStr(round1(data.sucklingViable / numSumFarrowed), true)
        : '',
    sucklingAdjustedAvg:
      data?.sucklingAdjusted && numSumNursing
        ? toStr(round1(data.sucklingAdjusted / numSumNursing), true)
        : '',
    sucklingWeanedAvg:
      data?.sucklingWeaned && numSumFarrowed
        ? toStr(round1(data.sucklingWeaned / numSumFarrowed), true)
        : '',
  };
}

interface WeightData {
  id: number;
  weight?: string;
  gain?: string;
}

function isNotNull(dailyWeightTransfer: SucklingWeightGain) {
  return dailyWeightTransfer.weight !== undefined && dailyWeightTransfer.weight !== null;
}

function convertWeightData(data: SucklingWeightGain[] | null | undefined): WeightData[] {
  const result = [] as WeightData[];
  for (let i = 0; i < 6; i++) {
    result.push({
      id: i,
      weight: '',
      gain: '',
    });
  }
  if (!data) return result;
  let lastTransfer = { dayOfAge: 0, weight: 0 };
  let idx = 0;
  for (const transfer of data) {
    if (isNotNull(transfer)) {
      const weight = transfer.weight || 0;
      const days = (transfer.dayOfAge || 0) - lastTransfer.dayOfAge;
      idx = ((transfer.dayOfAge || 0) / 7) >> 0;
      result[idx] = {
        id: idx + 1,
        weight: toStr(weight),
        gain: idx ? toStr(round1((weight - lastTransfer.weight) / days), true) : '',
      };
      lastTransfer = {
        dayOfAge: transfer.dayOfAge || lastTransfer.dayOfAge,
        weight: weight || lastTransfer.weight,
      };
    }
    idx += 1;
  }
  return result;
}

function populatedWhenToStr(motherNumData: MotherNumData | null | undefined): string {
  if (!motherNumData) return '';

  let result = '';
  if (motherNumData.populatedFrom) {
    result = `${dateToStr(motherNumData.populatedFrom)} -`;
  }
  if (motherNumData.populatedTo) {
    if (!result.endsWith('-')) result += '-';
    result += ` ${dateToStr(motherNumData.populatedTo)}`;
  }
  return result;
}

function calcDate(when: Date | undefined, plusDays = 0): Date | undefined {
  if (!when) return undefined;

  return plusDays ? moment(when).add(plusDays, 'days').toDate() : new Date(when);
}

interface MotherEditSmallTablesProps {
  transfer: EditMotherTransfer | null | undefined;
  refresh: () => void;
  farms: Farm[] | null | undefined;
}

function MotherEditSmallTables({ transfer, refresh, farms }: MotherEditSmallTablesProps) {
  const { t } = useTranslation();

  const [dataMother, setDataMother] = useState<MotherData[]>(getEmptyMotherData());
  const [dataNumMother, setDataNumMother] = useState<MotherNumData>(getEmptyMotherNumData());

  const [touchingDate, setTouchingDate] = useState<Date>();
  const [farrowingDate, setFarrowingDate] = useState<Date>();

  const [dataSuckling, setDataSuckling] = useState<SucklingData>(getEmptySucklingData());

  const [dataWeight, setDataWeight] = useState<WeightData[]>(
    convertWeightData(transfer?.weightGains)
  );

  const [smallEdit, setSmallEdit] = useState<boolean>(true);

  const strItem = t('piece');
  const strPercent = t('gp-percent');

  const [isEditMotherNumOpen, setIsEditMotherNumOpen] = useState<boolean>(false);
  const [isEditSucklingDetailsOpen, setIsEditSucklingDetailsOpen] = useState<boolean>(false);

  const toggleEditMotherNumModal = useCallback(() => {
    setIsEditMotherNumOpen(!isEditMotherNumOpen);
  }, [isEditMotherNumOpen]);

  const toggleEditSucklingDetailsModal = useCallback(() => {
    setIsEditSucklingDetailsOpen(!isEditSucklingDetailsOpen);
  }, [isEditSucklingDetailsOpen]);

  const refreshPage = useCallback(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    setDataNumMother(calcMotherNumData(transfer?.motherNum));
    setTouchingDate(calcDate(transfer?.startDate, 17));
    setFarrowingDate(calcDate(transfer?.startDate, 31));
    setSmallEdit(!isParentInMergedGrouppage(transfer));
  }, [transfer]);

  useEffect(() => {
    setDataMother(convertMotherData(calcMotherNumData(transfer?.motherNum)));
    setDataWeight(convertWeightData(transfer?.weightGains));
  }, [dataNumMother, transfer]);

  useEffect(() => {
    setDataSuckling(
      convertSucklingData(transfer?.sucklingNum, dataMother[3].farrowed, dataMother[0].nursing)
    );
  }, [dataMother, transfer]);

  return (
    <>
      <EditMotherNumModal
        id={transfer?.id}
        touchingDate={touchingDate}
        farrowingDate={farrowingDate}
        toggle={toggleEditMotherNumModal}
        isOpen={isEditMotherNumOpen}
        onSave={refreshPage}
        motherDetails={transfer?.motherNum}
      />
      <EditSucklingDetailsModal
        id={transfer?.id}
        toggle={toggleEditSucklingDetailsModal}
        isOpen={isEditSucklingDetailsOpen}
        onSave={refreshPage}
        sucklingDetails={transfer?.sucklingNum}
        baseWeaningDate={transfer?.startDate}
      />
      <Row className="justify-content-around mb-1">
        <Col xs="auto">
          <div>
            <h5 className="card-title align-middle mt-1">{t('mgpt1-title')}</h5>
            <div className={classTableDiv}>
              <table className={classSmallTable}>
                <tbody>
                  <tr>
                    <td className={classCellMain} colSpan={2}>
                      {t('mgpt1-mother')}
                    </td>
                    <td className={classCellMain}>
                      <b>{transfer?.actualMotherNum}</b>
                    </td>
                  </tr>
                  <tr>
                    <td className={classCellMain} colSpan={2}>
                      {t('mgpt1-suckling')}
                    </td>
                    <td className={classCellMain}>
                      <b>{transfer?.actualSucklingNum}</b>
                    </td>
                  </tr>
                  <tr>
                    <td className={classCellMain} rowSpan={2}>
                      <span id={'mother-dead'}>{t('mgpt1-mother-dead')}</span>
                      <UncontrolledTooltip placement="top" target={'mother-dead'}>
                        {t('mgpt1-mother-dead-tt')}
                      </UncontrolledTooltip>
                    </td>
                    <td className={classCellMain}>{t('piece')}</td>
                    <td className={classCellMain}>{transfer?.motherDeadSum}</td>
                  </tr>
                  <tr>
                    <td className={classCellMain}>{strPercent}</td>
                    <td className={classCellMain}>
                      {toStr(round1(transfer?.motherDeadPercent), true)}
                    </td>
                  </tr>
                  <tr>
                    <td className={classCellMain} rowSpan={2}>
                      <span id={'suckling-dead'}>{t('mgpt1-suckling-dead')}</span>
                      <UncontrolledTooltip placement="top" target={'suckling-dead'}>
                        {t('mgpt1-suckling-dead-tt')}
                      </UncontrolledTooltip>
                    </td>
                    <td className={classCellMain}>{t('piece')}</td>
                    <td className={classCellMain}>{transfer?.sucklingDeadSum}</td>
                  </tr>
                  <tr>
                    <td className={classCellMain}>{strPercent}</td>
                    <td className={classCellMain}>
                      {toStr(round1(transfer?.sucklingDeadPercent), true)}
                    </td>
                  </tr>
                  <tr>
                    <td className={classCellMain} rowSpan={2}>
                      <span id={'suckling-exterm'}>{t('mgpt1-suckling-exterm')}</span>
                      <UncontrolledTooltip placement="top" target={'suckling-exterm'}>
                        {t('mgpt1-suckling-exterm-tt')}
                      </UncontrolledTooltip>
                    </td>
                    <td className={classCellMain}>{t('piece')}</td>
                    <td className={classCellMain}>{transfer?.sucklingExtermSum}</td>
                  </tr>
                  <tr>
                    <td className={classCellMain}>{strPercent}</td>
                    <td className={classCellMain}>
                      {toStr(round1(transfer?.sucklingExtermPercent), true)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Col>

        <Col xs="auto">
          <div>
            <h5 className="card-title d-flex align-items-center">
              {t('mgpt2-title')}
              {smallEdit && (
                <Button
                  id="mothers-edit-button"
                  color="primary"
                  className={classButton}
                  onClick={() => {
                    toggleEditMotherNumModal();
                  }}
                >
                  <i className="mdi mdi-pencil p-1" />
                </Button>
              )}
            </h5>
            <div className={classTableDiv}>
              <table className={classSmallTable}>
                <thead>
                  <tr>
                    {createTh('', 0, '', { rowSpan: 3 })}
                    {createTh(t('mgpt2-ia'), 1)}
                    {createTh(t('mgpt2-pergnant'), 2, '', { colSpan: 2 })}
                    {createTh(t('mgpt2-populated'), 3)}
                    {createTh(t('mgpt2-farrowed'), 4, '', { colSpan: 2 })}
                    {createTh(t('mgpt2-nursing-short'), 5, t('mgpt2-nursing'), { colSpan: 2 })}
                    {createTh(t('mgpt2-weaning-short'), 6, t('mgpt2-weaning'), { colSpan: 2 })}
                  </tr>
                  <tr>
                    {createTh(dateToStr(transfer?.startDate, 0), 7)}
                    {createTh(dateToStr(transfer?.startDate, 17), 8, '', { colSpan: 2 })}
                    {createTh(populatedWhenToStr(dataNumMother), 9)}
                    {createTh(dateToStr(transfer?.startDate, 31), 10, '', { colSpan: 2 })}
                    {createTh('', 11, '', { colSpan: 2 })}
                    {createTh(dateToStr(transfer?.startDate, 66), 12, '', { colSpan: 2 })}
                  </tr>
                  <tr>
                    {createTh(strItem, 13)}
                    {createTh(strItem, 14)}
                    {createTh(strPercent, 15)}
                    {createTh(strItem, 16)}
                    {createTh(strItem, 17)}
                    {createTh(strPercent, 18)}
                    {createTh(strItem, 19)}
                    {createTh(strPercent, 20)}
                    {createTh(strItem, 21)}
                    {createTh(strPercent, 22)}
                  </tr>
                </thead>
                <tbody>
                  <tr key={dataMother[0].id}>
                    {createBaseTd(t('mgpt2-row1'), classCell)}
                    {createBaseTd(dataMother[0].ia, classCellBg)}
                    {createBaseTd(dataMother[0].pregnant, classCellBg)}
                    {createBaseTd(dataMother[0].pregnantPercent, classCell)}
                    {createBaseTd(dataMother[0].populated, classCellBg)}
                    {createBaseTd(dataMother[0].farrowed, classCellBg)}
                    {createBaseTd(dataMother[0].farrowedPercent, classCell)}
                    {createBaseTd(dataMother[0].nursing, classCellBg, { rowSpan: 2 })}
                    {createBaseTd(dataMother[0].nursingPercent, classCell, { rowSpan: 2 })}
                    {createBaseTd(dataMother[0].weaning, classCellBg, { rowSpan: 2 })}
                    {createBaseTd(dataMother[0].weaningPercent, classCell, { rowSpan: 2 })}
                  </tr>
                  <tr key={dataMother[1].id}>
                    {createBaseTd(t('mgpt2-row2'), classCell)}
                    {createBaseTd(dataMother[1].ia, classCellBg)}
                    {createBaseTd(dataMother[1].pregnant, classCellBg)}
                    {createBaseTd(dataMother[1].pregnantPercent, classCell)}
                    {createBaseTd(dataMother[1].populated, classCellBg)}
                    {createBaseTd(dataMother[1].farrowed, classCellBg)}
                    {createBaseTd(dataMother[1].farrowedPercent, classCell)}
                  </tr>
                  <tr key={dataMother[2].id}>
                    {createBaseTd(t('mgpt2-row3'), classCell)}
                    {createBaseTd(dataMother[2].ia, classCellBg)}
                    {createBaseTd(dataMother[2].pregnant, classCellBg)}
                    {createBaseTd(dataMother[2].pregnantPercent, classCell)}
                    {createBaseTd(dataMother[2].populated, classCellBg)}
                    {createBaseTd(dataMother[2].farrowed, classCellBg)}
                    {createBaseTd(dataMother[2].farrowedPercent, classCell)}
                    {createBaseTd(dataMother[2].nursing, classCellBg)}
                    {createBaseTd(dataMother[2].nursingPercent, classCell)}
                    {createBaseTd(dataMother[2].weaning, classCellBg)}
                    {createBaseTd(dataMother[2].weaningPercent, classCell)}
                  </tr>
                  <tr key={dataMother[3].id}>
                    {createBaseTd(t('mgpt2-sum'), classCell)}
                    {createBaseTd(dataMother[3].ia, classCell)}
                    {createBaseTd(dataMother[3].pregnant, classCell)}
                    {createBaseTd(dataMother[3].pregnantPercent, classCell)}
                    {createBaseTd(dataMother[3].populated, classCell)}
                    {createBaseTd(dataMother[3].farrowed, classCell)}
                    {createBaseTd(dataMother[3].farrowedPercent, classCell)}
                    {createBaseTd(dataMother[3].nursing, classCell)}
                    {createBaseTd(dataMother[3].nursingPercent, classCell)}
                    {createBaseTd(dataMother[3].weaning, classCell)}
                    {createBaseTd(dataMother[3].weaningPercent, classCell)}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Col>

        <Col xs="auto">
          <div>
            <h5 className="card-title d-flex align-items-center">
              {t('mgpt3-title')}
              {smallEdit && (
                <Button
                  id="sucklings-edit-button"
                  color="primary"
                  className={classButton}
                  onClick={() => {
                    toggleEditSucklingDetailsModal();
                  }}
                >
                  <i className="mdi mdi-pencil p-1" />
                </Button>
              )}
            </h5>
            <div className={classTableDiv}>
              <table className={classSmallTable}>
                <thead>
                  <tr>
                    {createTh('', 0)}
                    {createTh(strItem, 1)}
                    {createTh(t('mgpt2-avg-item'), 3)}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {createBaseTd(t('mgpt3-sum-born'), classCell)}
                    {createBaseTd(dataSuckling.sucklingNum, classCellBg)}
                    {createBaseTd(dataSuckling.sucklingNumAvg, classCell)}
                  </tr>
                  <tr>
                    {createBaseTd(t('mgpt3-alive-born'), classCell)}
                    {createBaseTd(dataSuckling.sucklingViable, classCellBg)}
                    {createBaseTd(dataSuckling.sucklingViableAvg, classCell)}
                  </tr>
                  <tr>
                    {createBaseTd(t('mgpt3-egalised'), classCell)}
                    {createBaseTd(dataSuckling.sucklingAdjusted, classCellBg)}
                    {createBaseTd(dataSuckling.sucklingAdjustedAvg, classCell)}
                  </tr>
                  <tr>
                    {createBaseTd(t('mgpt3-weaned'), classCell)}
                    {createBaseTd(dataSuckling.sucklingWeaned, classCellBg)}
                    {createBaseTd(dataSuckling.sucklingWeanedAvg, classCell)}
                  </tr>
                  <tr>
                    {createBaseTd(t('mgpt3-weaned-date'), classCell)}
                    {createBaseTd(dataSuckling.weaningDate, `${classCellBg} text-center`, {
                      colSpan: 2,
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Col>

        <Col xs="auto">
          <div>
            <h5 className="card-title d-flex align-items-center">{t('mgpt4-title')}</h5>
            <div className={classTableDiv}>
              <table className={classSmallTable}>
                <thead>
                  <tr>
                    {createTh(t('mgpt4-week'), 0)}
                    {createTh(t('mgpt4-gramm'), 1)}
                    {createTh(t('mgpt4-avg'), 2)}
                  </tr>
                </thead>
                <tbody>
                  {dataWeight.map((row, idx) => (
                    <tr key={toStr(idx + 1)}>
                      {createBaseTd(toStr(idx + 1), classCellCenter)}
                      {createBaseTd(row.weight, classCellBg)}
                      {createBaseTd(row.gain, classCell)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default MotherEditSmallTables;
