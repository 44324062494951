import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Button,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Select, { GroupedOptionsType, OptionsType } from 'react-select';
import ModalError from './modalError';
import { delayedRun, formatGrouppageName } from './gpUtil';
import { useApi, useApiGet, useApiPost } from 'hooks/useApi';
import Datepicker from 'hyper/components/Datepicker';
import Loader from 'hyper/components/Loader';
import CreateMotherTransfer from 'models/grouppages/createMotherTransfer';
import Farm from 'models/farm';
import Stable from 'models/stable';

interface OptionType {
  value: number;
  label: string;
}

function checkStable(
  farms: Farm[] | null
):
  | OptionsType<{
      value: number;
      label: string;
    }>
  | GroupedOptionsType<{
      value: number;
      label: string;
    }>
  | undefined {
  if (!farms || farms.length < 1 || !farms[0].stables) return undefined;
  return farms[0].stables.map((stable: Stable) => ({
    value: stable.id,
    label: stable.name,
  }));
}

function calcExpectedNames(
  expectedGroupName: string | undefined,
  selectedStables: OptionType[] | undefined
): JSX.Element | JSX.Element[] | undefined {
  const result = [] as JSX.Element[];
  const name = expectedGroupName || '';
  const index = 'abcdefghijklmnopqrstuvwxyz';
  result.push(
    <tr key="main">
      <td>{name}</td>
      <td />
    </tr>
  );
  if (selectedStables && selectedStables.length > 1) {
    for (let i = 0; i < selectedStables.length; i++) {
      const stable = selectedStables[i];
      result.push(
        <tr key={stable.value}>
          <td>{`${name}#${index[i]}`}</td>
          <td>
            <Badge key={`bdg-${stable.value}`} className="ml-2" color="info">
              {stable.label}
            </Badge>
          </td>
        </tr>
      );
    }
  }
  return (
    <table>
      <tbody>{result}</tbody>
    </table>
  );
}

function isNameWrong(name: string) {
  return name === null || name.trim().length < 1;
}

function isMotherDetailsWrong(motherDetails: CreateMotherTransfer) {
  return motherDetails.startDate === null || isNameWrong(motherDetails.name);
}

function isDataWrong(
  motherDetails: CreateMotherTransfer,
  selectedStables: OptionType[] | null | undefined
) {
  return (
    isMotherDetailsWrong(motherDetails) ||
    selectedStables === undefined ||
    selectedStables === null ||
    selectedStables.length < 1
  );
}

function calcFarrowingDate(startDate: Date) {
  if (!startDate) return '';

  return moment(startDate).add(31, 'days').format('YYYY.MM.DD.');
}

function getStableSelectClass(selectedStables: OptionType[] | null | undefined) {
  return `react-select w-100${
    selectedStables === undefined || selectedStables === null || selectedStables.length < 1
      ? ' border border-danger rounded'
      : ''
  }`;
}

interface CreateMotherProps {
  onSave: () => void;
  isOpen: boolean;
  toggle: () => void;
  baseMotherDetails: CreateMotherTransfer;
}

function CreateMotherModal({ isOpen, toggle, onSave, baseMotherDetails }: CreateMotherProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const { data: farms, loading } = useApiGet<Farm[]>('/api/v1/farms');
  const [motherDetails, setMotherDetails] = useState<CreateMotherTransfer>({
    ...baseMotherDetails,
  });
  const [isAllValid, setIsAllValid] = useState<boolean>(true);
  const [isChecked, setIsCheked] = useState<boolean>(false);
  const [expectedGroupName, setExpectedGroupName] = useState<
    JSX.Element | JSX.Element[] | undefined
  >(undefined);
  const [backendMessages, setBackendMessages] = useState<string[] | null>(null);
  const { getText } = useApi();
  const [selectedStables, setSelectedStables] = useState<OptionType[]>();
  const [farrowingDate, setFarrowingDate] = useState<string>(
    calcFarrowingDate(baseMotherDetails.startDate)
  );
  const [saveMotherDetails, { loading: saving }] = useApiPost<{ messages: string[] }>(
    '/api/v1/group/mother',
    (response) => {
      if (response.messages && response.messages.length > 0)
        return setBackendMessages(response.messages);

      const newId = typeof response === 'number' ? response : 0;
      if (newId > 0)
        return delayedRun(() => {
          history.push(`/grouppage-mother/${newId}/edit`);
        });

      setBackendMessages(null);
      onSave();
      toggle();
    },
    (error: any) => {
      if (error && error.status && error.status === 405)
        return setBackendMessages(['INVALID_GROUP_NAME']);

      if (`${error}`.includes('NetworkError')) return setBackendMessages(['NETWORK_ERROR']);

      setBackendMessages(['SAVING_ERROR']);
    }
  );

  const onChange = useCallback((value) => {
    setSelectedStables(value);
    setIsAllValid(true);
    setBackendMessages(null);
    setExpectedGroupName(undefined);
    setIsCheked(false);
  }, []);

  useEffect(() => {
    setIsAllValid(true);
    setBackendMessages(null);
    setExpectedGroupName(undefined);
    setIsCheked(false);
  }, [motherDetails]);

  useEffect(() => {
    if (isOpen) {
      setMotherDetails({
        ...baseMotherDetails,
      });
    }
  }, [isOpen, baseMotherDetails]);

  const submit = useCallback(() => {
    if (isDataWrong(motherDetails, selectedStables)) {
      setIsAllValid(false);
    } else {
      setIsAllValid(true);
      const stables: Stable[] = [];
      selectedStables?.forEach((selectedStable) =>
        stables.push({ id: selectedStable.value, name: selectedStable.label })
      );
      saveMotherDetails({ ...motherDetails, stables });
    }
  }, [motherDetails, saveMotherDetails, selectedStables]);

  const checkExpectedName = useCallback(() => {
    if (isDataWrong(motherDetails, selectedStables)) {
      setIsAllValid(false);
    } else {
      setIsAllValid(true);
      getText(
        `/api/v1/group/mother/check?name=${motherDetails.name}&startDate=${new Date(
          motherDetails.startDate
        ).toISOString()}`,
        (expectedName) => {
          setExpectedGroupName(calcExpectedNames(expectedName, selectedStables));
          setIsCheked(true);
        },
        (error: any) => {
          if (`${error}`.includes('NetworkError')) return setBackendMessages(['NETWORK_ERROR']);

          setBackendMessages(['SAVING_ERROR']);
        }
      );
    }
  }, [getText, motherDetails, selectedStables]);

  const onStartDateChange = useCallback(
    (value: Date) => {
      setMotherDetails({ ...motherDetails, startDate: value });
      const nextDateStr = calcFarrowingDate(value);
      setFarrowingDate(nextDateStr);
    },
    [motherDetails]
  );

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newName = event.target.value ? formatGrouppageName(event.target.value) : '';
      const copy = { ...motherDetails, name: newName };
      setMotherDetails(copy);
    },
    [motherDetails]
  );

  const isWorking = useCallback(() => loading || saving, [loading, saving]);

  return (
    motherDetails && (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{`${t('create-mother-grouppage')}`}</ModalHeader>
        <ModalBody>
          {isWorking() && <Loader />}
          <Container fluid>
            <Row className="">
              <Col className="d-flex justify-content-center">
                <div>
                  <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                    <Col sm="6">
                      <b>{t('group-ia-date')}:</b>
                      <span className="text-danger">*</span>
                    </Col>
                    <Col className="d-flex justify-content-start">
                      <Datepicker
                        startDate={new Date(motherDetails.startDate)}
                        dateFormat="yyyy.MM.dd."
                        onDateChange={onStartDateChange}
                      />
                    </Col>
                  </Row>

                  <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                    <Col sm="6">{t('farrowing-date')}:</Col>
                    <Col className="d-flex justify-content-start">
                      <Input
                        name="farrowing-date"
                        className="text-left"
                        disabled
                        value={farrowingDate}
                      />
                    </Col>
                  </Row>

                  <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                    <Col sm="6">
                      <b>{t('group-name')}:</b>
                      <span className="text-danger">*</span>
                    </Col>
                    <Col className="d-flex justify-content-start">
                      <Input
                        id={'mother-group-name'}
                        className="text-left"
                        value={motherDetails.name}
                        onChange={onNameChange}
                        invalid={isNameWrong(motherDetails.name)}
                      />
                    </Col>
                  </Row>

                  <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                    <Col sm="3">
                      <b>{t('grouppage-stable')}:</b>
                      <span className="text-danger">*</span>
                    </Col>
                    <Col className="d-flex justify-content-start">
                      <Select
                        id="grouppage-stable"
                        className={getStableSelectClass(selectedStables)}
                        classNamePrefix="react-select"
                        placeholder={t('stable-placeholder')}
                        noOptionsMessage={t('empty-list')}
                        isMulti
                        onChange={onChange}
                        options={checkStable(farms)}
                      />
                    </Col>
                  </Row>

                  {expectedGroupName && (
                    <Row className="d-flex align-items-center mt-3" style={{ width: '100%' }}>
                      <Col sm="6">
                        <b>{t('group-expected-name')}:</b>
                      </Col>
                      <Col className="d-flex justify-content-start">
                        <b>{expectedGroupName}</b>
                      </Col>
                    </Row>
                  )}
                </div>
              </Col>
            </Row>

            <Row className="pt-2">
              <Col>
                <ModalError isInputValid={isAllValid} serverErrors={backendMessages} />
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          {!isChecked && (
            <Button color="info" onClick={checkExpectedName} className="mr-2">
              {t('check')}
            </Button>
          )}
          {isChecked && (
            <Button color="success" onClick={submit} className="mr-2">
              {t('save')}
            </Button>
          )}
          <Button color="primary" onClick={toggle}>
            {t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  );
}

export default CreateMotherModal;
