import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  ModalFooter,
  Container,
  Row,
  Col,
  Alert,
} from 'reactstrap';
import '../../../../css/modal.css';
import cloneDeep from 'lodash/cloneDeep';
import { dateToStr, FEED_LIMIT } from '../../gpUtil';
import FeedDetails from '../common/feedDetails';
import HealthDetails from '../common/healthDetails';
import { useApiPut } from 'hooks/useApi';
import Loader from 'hyper/components/Loader';
import FatteningDiaryElementTransfer from 'models/grouppages/fatteningDiaryElementTransfer';
import FatteningDiaryEditTransfer from 'models/grouppages/fatteningDiaryEditTransfer';
import { useInput } from 'hooks/useInput';
import { ValueType } from 'models/valueType';
import { isInputValuesBetweenLimit, LimitException, roundAndRemove } from 'helpers/rabbit';

const LIMIT = 20000;
const SOLD_WEIGHT_LIMIT = 90000;

const GPD_COMMENT = 'gpd-comment';

const excludedKeys = ['id', 'diaryId'];
const limitExceptions: LimitException[] = [
  { name: 'amount', limit: FEED_LIMIT },
  { name: 'soldWeight', limit: SOLD_WEIGHT_LIMIT },
];

function isDeadValuesValid(editDiaryDetails: FatteningDiaryEditTransfer | undefined) {
  const dead = editDiaryDetails?.dead;
  const dissected = editDiaryDetails?.dissected;
  if (dissected && (!dead || dead < dissected)) {
    return false;
  }
  const deadResp = editDiaryDetails?.deadResp;
  if (deadResp && (!dissected || dissected < deadResp)) {
    return false;
  }
  const deadEre = editDiaryDetails?.deadEre;
  if (deadEre && (!dissected || dissected < deadEre)) {
    return false;
  }

  return true;
}

function isDiaryDetailsValid(editDiaryDetails: FatteningDiaryEditTransfer | undefined) {
  return (
    isInputValuesBetweenLimit(editDiaryDetails, excludedKeys, LIMIT, limitExceptions) &&
    isDeadValuesValid(editDiaryDetails)
  );
}

interface EditFatteningDiaryDetailProps {
  id: number | undefined;
  isParent: boolean;
  isOpen: boolean;
  diaryDetails: FatteningDiaryElementTransfer;
  onSave: () => void;
  toggle: () => void;
}

function EditFatteningDiariesModal({
  id,
  isParent,
  isOpen,
  toggle,
  onSave,
  diaryDetails,
}: EditFatteningDiaryDetailProps) {
  const { t } = useTranslation();
  const [
    editFatteningDiaryDetails,
    setEditFatteningDiaryDetails,
    onSimpleValueChange,
    onObjectValueChange,
  ] = useInput<FatteningDiaryEditTransfer>(diaryDetails.value);
  const [isAllValid, setIsAllValid] = useState<boolean>(true);
  const [backendMessages, setBackendMessages] = useState<string[] | null>(null);
  const [saveDiaryDetails, { loading: saving }] = useApiPut<{ messages: string[] }>(
    `/api/v1/group/fattening/${id}/diary/${diaryDetails?.id}`,
    (response) => {
      if (response.messages && response.messages.length > 0) {
        setBackendMessages(response.messages);
      } else {
        setBackendMessages(null);
        onSave();
        toggle();
      }
    }
  );

  useEffect(() => {
    if (isOpen) {
      setEditFatteningDiaryDetails(cloneDeep(diaryDetails.value));
    }
  }, [isOpen, diaryDetails, setEditFatteningDiaryDetails]);

  useEffect(() => {
    setIsAllValid(true);
    setBackendMessages(null);
  }, [editFatteningDiaryDetails]);

  const submit = useCallback(() => {
    const isValid = isDiaryDetailsValid(editFatteningDiaryDetails);

    if (isValid) {
      setBackendMessages(null);
      saveDiaryDetails(editFatteningDiaryDetails);
    }
    setIsAllValid(isValid);
  }, [editFatteningDiaryDetails, saveDiaryDetails]);

  const isInvalid = useCallback(
    (value: number | undefined, limit: number = LIMIT) => (value && value > limit) || false,
    []
  );

  const setValue = useCallback(
    (key: keyof FatteningDiaryEditTransfer, valueType: ValueType, value: unknown) => {
      let next: string | number | boolean | undefined;
      if (valueType === ValueType.STRING) {
        if (typeof value === 'string') {
          next = value;
        } else {
          next = `${value}`;
        }
      } else if (valueType === ValueType.NUMBER) {
        if (typeof value === 'string') {
          next = +value;
        } else if (typeof value === 'number') {
          next = value;
        }
      } else if (valueType === ValueType.BOOLEAN) {
        next = !!value;
      }
      if (next !== undefined) setEditFatteningDiaryDetails((old) => ({ ...old, [key]: next }));
    },
    [setEditFatteningDiaryDetails]
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="modal-content-size"
      className="modal-align"
    >
      <ModalHeader toggle={toggle}>
        {`${t('add-diary-details')} - ${dateToStr(diaryDetails.actualDate)} ${t('gpd-age')}: ${
          diaryDetails.dayOfAge
        }`}
      </ModalHeader>
      <ModalBody>
        {saving && <Loader />}
        <Container fluid>
          <Row className="" />
        </Container>
        <Form className="form d-flex flex-column justify-content-center">
          {isParent && (
            <Row className="">
              <Col className="mx-4 my-4">
                <Row className="d-flex justify-content-center align-items-center">
                  <h4>{t('add-comment-parent')}</h4>
                </Row>
                <Row>
                  <Col className="d-flex flex-column align-items-center">
                    <Row>
                      <b>{t(GPD_COMMENT)}</b>
                    </Row>
                    <Row className="w-100">
                      <Input
                        type="text"
                        name={GPD_COMMENT}
                        maxLength={512}
                        defaultValue={editFatteningDiaryDetails.comment}
                        onChange={onSimpleValueChange('comment', ValueType.STRING)}
                        data-cy="gp-comment"
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          {!isParent && (
            <>
              <Row className="">
                <Col>
                  <Row className="d-flex justify-content-center align-items-center">
                    <h4>{t('diary-fattening-daily-details')}</h4>
                  </Row>

                  <Row>
                    <Col className="d-flex flex-column justify-content-center align-items-center">
                      <Row className="d-flex justify-content-center">
                        <b>{t('gpd-dead')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'dead'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={
                            isInvalid(editFatteningDiaryDetails.dead) ||
                            !isDeadValuesValid(editFatteningDiaryDetails)
                          }
                          defaultValue={editFatteningDiaryDetails.dead}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('dead', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>

                    <Col className="d-flex flex-column justify-content-center align-items-center">
                      <Row className="d-flex justify-content-center">
                        <b>{t('gpd-dissected')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'dissected'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={
                            isInvalid(editFatteningDiaryDetails.dissected) ||
                            !isDeadValuesValid(editFatteningDiaryDetails)
                          }
                          defaultValue={editFatteningDiaryDetails.dissected}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('dissected', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>

                    <Col className="d-flex flex-column justify-content-center align-items-center">
                      <Row className="d-flex justify-content-center">
                        <b>{t('gpd-dead-resp')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'deadResp'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={
                            isInvalid(editFatteningDiaryDetails.deadResp) ||
                            !isDeadValuesValid(editFatteningDiaryDetails)
                          }
                          defaultValue={editFatteningDiaryDetails.deadResp}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('deadResp', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>

                    <Col className="d-flex flex-column justify-content-center align-items-center">
                      <Row className="d-flex justify-content-center">
                        <b>{t('gpd-dead-ere')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'deadEre'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={
                            isInvalid(editFatteningDiaryDetails.deadEre) ||
                            !isDeadValuesValid(editFatteningDiaryDetails)
                          }
                          defaultValue={editFatteningDiaryDetails.deadEre}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('deadEre', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col>
                      <Row className="d-flex justify-content-center align-items-center">
                        <h4>{t('diary-climate-details')}</h4>
                      </Row>

                      <Row>
                        <Col className="d-flex flex-column align-items-center">
                          <Row>
                            <b>{t('gpd-degrees')}</b>
                          </Row>
                          <Row className="w-100">
                            <Input
                              type="number"
                              name={'gpd-degrees'}
                              min="0"
                              max={LIMIT}
                              className="text-right"
                              invalid={isInvalid(
                                editFatteningDiaryDetails?.indoorData?.temperature
                              )}
                              defaultValue={editFatteningDiaryDetails?.indoorData?.temperature}
                              onInput={(event) => {
                                roundAndRemove(event, true);
                              }}
                              onChange={onObjectValueChange(
                                'indoorData',
                                'temperature',
                                ValueType.NUMBER
                              )}
                            />
                          </Row>
                        </Col>

                        <Col className="d-flex flex-column align-items-center">
                          <Row>
                            <b>{t('gpd-rh')}</b>
                          </Row>
                          <Row className="w-100">
                            <Input
                              type="number"
                              name={'gpd-rh'}
                              min="0"
                              max={LIMIT}
                              className="text-right"
                              invalid={isInvalid(
                                editFatteningDiaryDetails?.indoorData?.relativeHumidity
                              )}
                              defaultValue={editFatteningDiaryDetails?.indoorData?.relativeHumidity}
                              onInput={(event) => {
                                roundAndRemove(event, true);
                              }}
                              onChange={onObjectValueChange(
                                'indoorData',
                                'relativeHumidity',
                                ValueType.NUMBER
                              )}
                            />
                          </Row>
                        </Col>
                      </Row>
                    </Col>

                    <Col>
                      <Row className="d-flex justify-content-center align-items-center">
                        <h4>{t('add-comment')}</h4>
                      </Row>
                      <Row>
                        <Col className="d-flex flex-column align-items-center">
                          <Row>
                            <b>{t(GPD_COMMENT)}</b>
                          </Row>
                          <Row className="w-100">
                            <Input
                              type="text"
                              name={GPD_COMMENT}
                              maxLength={512}
                              defaultValue={editFatteningDiaryDetails.comment}
                              onChange={onSimpleValueChange('comment', ValueType.STRING)}
                              data-cy="gp-comment"
                            />
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <FeedDetails
                onFeedValueChange={onObjectValueChange}
                onChange={onSimpleValueChange}
                editDiaryDetails={editFatteningDiaryDetails}
                isFattening
                setValue={setValue}
              />

              <HealthDetails
                onMedicineValueChange={onObjectValueChange}
                medicineDetails={editFatteningDiaryDetails.medicine}
              />

              <Row className="mt-3">
                <Col>
                  <Row className="d-flex justify-content-center align-items-center">
                    <h4>{t('diary-fattening-sell-details')}</h4>
                  </Row>

                  <Row>
                    <Col className="d-flex flex-column justify-content-center align-items-center">
                      <Row className="d-flex justify-content-center">
                        <b>{t('fgpd-sold-piece')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'soldNum'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={isInvalid(editFatteningDiaryDetails.soldNum)}
                          defaultValue={editFatteningDiaryDetails.soldNum}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('soldNum', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>

                    <Col className="d-flex flex-column justify-content-center align-items-center">
                      <Row className="d-flex justify-content-center">
                        <b>{t('fgpd-sold-weight')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'soldWeight'}
                          min="0"
                          max={SOLD_WEIGHT_LIMIT}
                          className="text-right"
                          invalid={isInvalid(
                            editFatteningDiaryDetails.soldWeight,
                            SOLD_WEIGHT_LIMIT
                          )}
                          defaultValue={editFatteningDiaryDetails.soldWeight}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('soldWeight', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}

          <Row className="pt-2">
            <Col>
              {!isAllValid && (
                <Alert color="danger" className="text-center">
                  {t('form-has-errors')}
                </Alert>
              )}
              {backendMessages && backendMessages.length > 0 && (
                <Alert color="danger" className="text-center">
                  {backendMessages.map((msg: string) => (
                    <li key={msg}>{t(msg)}</li>
                  ))}
                </Alert>
              )}
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={submit} className="mr-2">
          {t('save')}
        </Button>
        <Button color="primary" onClick={toggle}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditFatteningDiariesModal;
