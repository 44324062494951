import moment from 'moment';
import React, { AllHTMLAttributes, Fragment } from 'react';
import { Badge, Button, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import MotherDiaryElementTransfer from 'models/grouppages/motherDiaryElementTransfer';
import YoungDiaryElementTransfer from 'models/grouppages/youngDiaryElementTransfer';
import FatteningDiaryElementTransfer from 'models/grouppages/fatteningDiaryElementTransfer';
import SlaughteringTransfer from 'models/grouppages/slaughteringTransfer';
import EditFatteningTransfer from 'models/grouppages/editFatteningTransfer';
import EditMotherTransfer from 'models/grouppages/editMotherTransfer';
import EditYoungTransfer from 'models/grouppages/editYoungTransfer';
import GPMemberTransfer from 'models/grouppages/gpMemberTransfer';
import IndoorAndStableTransfer from 'models/grouppages/indoorAndStableTransfer';
import IndoorDataTransfer from 'models/grouppages/indoorDataTransfer';

export const YOUNG_AGE_MIN = 28;
export const YOUNG_AGE_MAX = 146;
export const FATTENING_AGE_MIN = 28;
export const FATTENING_AGE_MAX = 98;
export const PLANNED_DELIVERY_AGE = 90;
export const NUMBER_LIMIT = 20000;
export const FEED_LIMIT = 30000;

export const CUT_LENGTH = 10;

export const SUBPAGE_SEPARATOR = '#';

export const classTable = 'table table-bordered border table-sm';
export const classBase = 'align-middle m-0 p-0';
export const classCommon = `${classBase} px-1`;
export const classHeader = `border border-dark text-dark text-center ${classCommon} gptable`;
export const classCellL = `text-left ${classCommon}`;
export const classCellC = `text-center ${classCommon}`;
export const classCellR = `text-right ${classCommon}`;
export const classCellTr = `text-truncate ${classCellL}`;
export const classCellBg = `text-right ${classCommon}`;
export const classCellLBg = `text-left ${classCommon}`;
export const classCellMain = `border border-dark bg-light text-dark ${classCellR}`;
export const classCellCenter = `bg-light text-dark ${classCellC}`;
export const classSideButton = 'btn-sm m-0 p-0';
export const classCellBgBrdr = `text-dark border border-dark text-right ${classCommon}`;

const re = new RegExp('[^a-zA-Z0-9]', 'g');

export const dateStr = (date: Date | null | undefined): string => {
  if (!date) return '';

  return moment(date).format('YYYY-MM-DD');
};

export const dateTimeStr = (date: Date | null | undefined): string => {
  if (!date) return '';

  return moment(date).format('YYYY. MM. DD. HH:mm:ss');
};

export const dateToStr = (date: Date | undefined, plus = 0, format = 'YYYY. MM. DD.') => {
  if (!date) return '';

  const newDate = new Date(date);
  if (plus !== 0) {
    newDate.setDate(newDate.getDate() + plus);
  }
  return moment(newDate).format(format);
};

export const monthDayToStr = (date: Date | undefined, plus = 0) => dateToStr(date, plus, 'MM. DD.');

export const minuteToStr = (min: number | null | undefined): string => {
  if (!min) return '';
  const mod = min % 60;
  return `${(min / 60) >> 0}:${mod < 10 ? '0' : ''}${mod}`;
};

export const secondToStr = (sec: number | null | undefined): string => {
  if (!sec) return '';
  const hour = (sec / 3600) >> 0;
  const min = ((sec - hour * 3600) / 60) >> 0;
  const mod = sec % 60;
  return `${hour}:${min < 10 ? '0' : ''}${min}:${mod < 10 ? '0' : ''}${mod}`;
};

export const timeToStr = (date: Date | null | undefined): string => {
  if (!date) return '';
  return moment(date).format('HH:mm:ss');
};

export const boolToStr = (value: boolean | null | undefined) =>
  value ? <i className="mdi mdi-circle px-1" /> : '';

export const round = (value: number | undefined, dec = 0) => {
  if (!value) return undefined;

  const exp = Math.pow(10, dec);
  return Math.round(exp * value) / exp;
};

export const round0 = (value: number | undefined, dec = 0) => {
  if (value === 0) return 0;

  return round(value, dec);
};

export const toStrF = (value: number | null | undefined, dec = -1) => {
  if (value === null || value === undefined) return '';

  if (dec >= 0) {
    return value === null || value === undefined ? '' : `${value.toFixed(dec)}`;
  }
  return `${value}`;
};

export const toStr = (value: number | null | undefined, dec = -1) => {
  if (value === null || value === undefined) return '';

  if (dec >= 0) {
    const next = value ? round(value, dec) : 0;
    return next === null || next === undefined ? '' : `${next.toFixed(dec)}`;
  }
  return `${value}`;
};

export const cut = (value: string | null | undefined, length = CUT_LENGTH) => {
  if (!value) return '';

  if (value.length > length) {
    return `${value.substr(0, length)}...`;
  }
  return value;
};

export function divide(
  value: number | null | undefined,
  divider: number | null | undefined
): number | undefined {
  if (value !== null && value !== undefined && divider) {
    return value / divider;
  }
  return undefined;
}

export const convertDateToStr = (when: Date | undefined) => {
  if (!when) return '';
  return new Date(when).toLocaleDateString();
};

export const delayedRun = (toDo: () => void, delayTimeMs = 200) => {
  setTimeout(() => {
    toDo();
  }, delayTimeMs);
};

export const createTh2 = (
  title: string,
  idx?: number,
  tooltipText = '',
  attrs: AllHTMLAttributes<HTMLElement> | undefined = undefined,
  uuid = ''
) => {
  let content = <>{title}</>;
  if (tooltipText && tooltipText.length > 0) {
    const iid = uuid || `th_${title.replace(re, '_')}`;
    content = (
      <>
        <span id={iid}>{title}</span>
        <UncontrolledTooltip placement="top" target={iid}>
          {tooltipText}
        </UncontrolledTooltip>
      </>
    );
  }
  const key = idx === undefined ? undefined : `th-${idx}`;
  return (
    <th className={classHeader} {...attrs} key={key}>
      {content}
    </th>
  );
};

export const createTh = (
  title: string,
  idx?: number,
  tooltipText = '',
  attrs: AllHTMLAttributes<HTMLElement> | undefined = undefined,
  uuid = ''
) => createTh2(title, idx, tooltipText, attrs, uuid);

export const createThL2 = (
  title: string,
  idx?: number,
  tooltipText = '',
  attrs: AllHTMLAttributes<HTMLElement> | undefined = undefined,
  uuid = ''
) => {
  let content = <>{title}</>;
  if (tooltipText && tooltipText.length > 0) {
    const iid = uuid || `th_${title.replace(re, '_')}`;
    const parts = tooltipText.replace(/[<]br[ ]*[/]?[>]/gi, '\n').split('\n');
    const newTT = [] as (JSX.Element | string)[];
    newTT.push(parts[0]);
    for (let i = 1; i < parts.length; i++) {
      newTT.push(<br />);
      newTT.push(parts[i]);
    }
    content = (
      <>
        <span id={iid}>{title}</span>
        <UncontrolledTooltip placement="top" target={iid}>
          {newTT}
        </UncontrolledTooltip>
      </>
    );
  }
  const key = idx === undefined ? undefined : `th-${idx}`;
  return (
    <th className={`${classHeader} position-sticky`} {...attrs} key={key}>
      {content}
    </th>
  );
};

export const createThL = (
  title: string,
  idx?: number,
  tooltipText = '',
  attrs: AllHTMLAttributes<HTMLElement> | undefined = undefined,
  uuid = ''
) => createThL2(title, idx, tooltipText, attrs, uuid);

export const createBaseTd = (
  text: JSX.Element | string | undefined,
  className?: string,
  attrs: AllHTMLAttributes<HTMLElement> | undefined = undefined
) => {
  const props = { ...attrs };
  return className ? (
    <td className={className} {...props}>
      {text}
    </td>
  ) : (
    <td {...attrs}>{text}</td>
  );
};

export const createTd = (
  id: number,
  idx: number,
  text: string | JSX.Element | undefined,
  className?: string,
  tooltip = false,
  attrs: AllHTMLAttributes<HTMLElement> | undefined = undefined,
  bold = false
) => {
  const props = { ...attrs };
  let title = text;
  let needCut = false;
  if (tooltip && text && typeof text === 'string') {
    needCut = text.length > CUT_LENGTH;
    if (needCut) {
      title = cut(text);
    }
  }
  title = bold ? <b>{title}</b> : <>{title}</>;
  if (needCut) {
    const iid = `spn_${id}_${idx}`;
    title = (
      <>
        <span id={iid}>{title}</span>
        <UncontrolledTooltip placement="top" target={iid}>
          {text}
        </UncontrolledTooltip>
      </>
    );
  }
  const key = `td-${idx}`;
  return className ? (
    <td className={className} {...props} key={key}>
      {title}
    </td>
  ) : (
    <td {...attrs} key={key}>
      {title}
    </td>
  );
};

export function formatGrouppageName(name: string): string {
  const re = new RegExp('[^A-Z0-9.,:;/_-]', 'g');
  return name.toUpperCase().replace(re, '');
}

export function setDefaultValues(details: any | undefined) {
  const copy: any = { ...details };

  for (const key in details) {
    copy[key] = details[key] || 0;
  }

  return copy;
}

export function isDateBetweenRange(
  toCompare: Date | string | undefined,
  startDate: Date | string | undefined,
  endDate: Date | string | undefined
): boolean {
  if (!toCompare) return false;

  const when = new Date(toCompare);
  return moment(when).isBetween(
    moment(startDate).startOf('day'),
    moment(endDate).endOf('day'),
    null,
    '[]' //brackets means, that you include start and end value into the range, while you can use parantheses '()' to exclude start or end value. Also you can combine them, like '(]'
  );
}

export function getGroupUrlBasePart(weightType: string): string {
  return weightType === 'MOT' || weightType === 'SUC'
    ? 'mother'
    : weightType === 'FAT'
    ? 'fattening'
    : weightType === 'PS' || weightType === 'GP'
    ? 'young'
    : 'xxx';
}

export function renderWeightCell(
  row: MotherDiaryElementTransfer | FatteningDiaryElementTransfer | YoungDiaryElementTransfer,
  weight: number | undefined | null,
  mode: string,
  isInFuture: boolean,
  merged?: boolean
) {
  // icon can be : 'mdi-scale-balance' or 'mdi-scale'
  if (isInFuture) return <></>;
  const aClass = `p-0 m-0 ${
    weight === undefined || weight === null
      ? 'border border-primary rounded px-1'
      : 'font-weight-bold'
  } data-cy-goto-${mode}`;
  const iid = `${row.id}`;
  if (merged) {
    return weight === undefined || weight === null ? <></> : <span id={iid}>{toStr(weight)}</span>;
  }
  const inner =
    weight === undefined || weight === null ? (
      <>
        <i className="mdi mdi-scale px-0" />
      </>
    ) : (
      <>{toStr(weight)}</>
    );
  const base = getGroupUrlBasePart(mode);
  return (
    <a
      href={`/grouppage-${base}/${row.groupId}/diary/${row.id}/weight/${mode}`}
      rel="noopener noreferrer"
      target="_blank"
      className={aClass}
    >
      <span id={iid}>{inner}</span>
    </a>
  );
}

export function getSlaughteringCreateDate(
  slaughtering: SlaughteringTransfer | undefined
): Date | undefined {
  if (!slaughtering || slaughtering.createDate === undefined) return undefined;
  return new Date(slaughtering.createDate);
}

export function isMergedGrouppage(
  group: EditFatteningTransfer | EditMotherTransfer | undefined | null
): boolean {
  return group ? (group.parentId || -1) !== -1 : false;
}

export function isParentInMergedGrouppage(
  group: EditFatteningTransfer | EditMotherTransfer | undefined | null
): boolean {
  return group ? group.id === group.parentId : false;
}

export function getStableNames(
  group: EditFatteningTransfer | EditMotherTransfer | EditYoungTransfer | undefined | null
): string {
  if (group && group.stables && group.stables.length > 0)
    return group.stables.map((s) => s.name).join(', ');
  return '?';
}

export function getMergedGPButton(
  member: GPMemberTransfer,
  actualId: number | undefined,
  parentId: number | undefined,
  mode: string
): JSX.Element {
  const disabled = actualId ? actualId === member.groupId : false;
  const id = `${member.groupId}`;
  const names = member.stables ? member.stables.map((stable) => stable.name).join(', ') : undefined;
  const stable = names ? (
    <Badge key={`bdg-${id}`} className="ml-2" color="light">
      {names}
    </Badge>
  ) : (
    <></>
  );
  const color = disabled ? 'secondary' : 'primary';
  return (
    <Button
      key={`btn-${id}`}
      color={color}
      className="btn-sm py-0 mr-2"
      disabled={disabled}
      tag={Link}
      to={`/grouppage-${mode}/${id}/edit`}
    >
      {member.generatedName}
      {stable}
    </Button>
  );
}

export interface Counter {
  idx: number;
}

const isOldSchool = (
  merged: boolean,
  transfer: EditFatteningTransfer | EditMotherTransfer | null | undefined
): boolean => !merged || !transfer || (transfer.gpMembers?.length || 0) <= 1;

const NO_CLIMATE_VALUE = '-';

const calcTempText = (
  newSchool: boolean,
  indoor: IndoorAndStableTransfer | IndoorDataTransfer | undefined
): string | JSX.Element | undefined => {
  if (!indoor?.minTemp && !indoor?.temperature && !indoor?.maxTemp) return '';
  return (
    <>
      {`${indoor?.minTemp ? toStr(indoor.minTemp, 1) : NO_CLIMATE_VALUE} | `}
      <b>{`${indoor?.temperature ? toStr(indoor.temperature, 1) : NO_CLIMATE_VALUE}`}</b>
      {` | ${indoor?.maxTemp ? toStr(indoor.maxTemp, 1) : NO_CLIMATE_VALUE}`}
    </>
  );
};

const calcRHText = (
  newSchool: boolean,
  indoor: IndoorAndStableTransfer | IndoorDataTransfer | undefined
): string | JSX.Element | undefined => {
  if (!indoor?.minRH && !indoor?.relativeHumidity && !indoor?.maxRH) return '';
  return (
    <>
      {`${indoor?.minRH ? toStr(indoor.minRH) : NO_CLIMATE_VALUE} | `}
      <b>{`${indoor?.relativeHumidity ? toStr(indoor.relativeHumidity) : NO_CLIMATE_VALUE}`}</b>
      {` | ${indoor?.maxRH ? toStr(indoor.maxRH) : NO_CLIMATE_VALUE}`}
    </>
  );
};

export const createClimateDataBlock = (
  newSchool: boolean,
  row: FatteningDiaryElementTransfer | MotherDiaryElementTransfer,
  iidx: Counter,
  merged: boolean,
  transfer: EditFatteningTransfer | EditMotherTransfer | null | undefined,
  cellNum: string,
  cellC: string
): JSX.Element[] => {
  const result = [] as JSX.Element[];
  if (isOldSchool(merged, transfer)) {
    newSchool && result.push(createTd(row.id, iidx.idx++, row.stableName || '', cellC));
    result.push(
      createTd(row.id, iidx.idx++, calcTempText(newSchool, row.value.indoorData), cellNum)
    );
    result.push(createTd(row.id, iidx.idx++, calcRHText(newSchool, row.value.indoorData), cellNum));
  } else {
    transfer?.gpMembers?.forEach((gp) => {
      if (gp.groupId !== transfer.id) {
        const indoor = row.indoorDataByGroupName
          ? row.indoorDataByGroupName[gp.generatedName]
          : undefined;
        result.push(createTd(row.id, iidx.idx++, indoor?.stableName || '', cellC));
        result.push(createTd(row.id, iidx.idx++, calcTempText(newSchool, indoor), cellNum));
        result.push(createTd(row.id, iidx.idx++, calcRHText(newSchool, indoor), cellNum));
      }
    });
  }
  return result;
};

export const createCommentDataBlock = (
  row: FatteningDiaryElementTransfer | MotherDiaryElementTransfer,
  iidx: Counter,
  merged: boolean,
  transfer: EditFatteningTransfer | EditMotherTransfer | null | undefined,
  cellTr: string
): JSX.Element[] => {
  if (isOldSchool(merged, transfer)) {
    return [createTd(row.id, iidx.idx++, row.value.comment, cellTr, true)];
  }
  const result = [] as JSX.Element[];
  const mainComment =
    row.commentByGroupName && Object.keys(row.commentByGroupName).length > 1
      ? row.value.comment
      : '';
  transfer?.gpMembers?.forEach((gp, idx) => {
    const comment = row.commentByGroupName ? row.commentByGroupName[gp.generatedName] : undefined;
    result.push(
      createTd(row.id, iidx.idx++, comment || (idx === 0 ? mainComment : ''), cellTr, true)
    );
  });
  return result;
};

type ReactChildArray = ReturnType<typeof React.Children.toArray>;

export const reactFragmentFlatten = (
  item: JSX.Element | JSX.Element[] | undefined
): JSX.Element[] => {
  if (!item || (Array.isArray(item) && item.length < 1)) return [];
  const result = [] as JSX.Element[];
  const all = [] as JSX.Element[];
  if (Array.isArray(item)) all.push(...item);
  else all.push(item);
  all.forEach((act) => {
    if ((act as React.ReactElement<any>).type === Fragment) {
      const { children } = (act as React.ReactElement<any>).props;
      const childResult = reactFragmentFlatten(children);
      if (childResult && childResult.length > 0) result.push(...childResult);
    } else {
      result.push(act);
    }
  });
  return result;
};
