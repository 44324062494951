import { useState, useCallback } from 'react';
import Page from '../../models/page';
import { fetchJSON } from 'hyper/helpers/api';
import { toQueryString, QueryParams } from 'helpers/api';

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

interface UsePaginatedData<T> {
  loadMore: (request: PageRequest, extraParams?: QueryParams) => void;
  data?: Page<T>;
  loading: boolean;
  error: boolean;
}

export interface PageRequest extends QueryParams {
  page: number;
  pageSize: number;
  sortOrder: 'asc' | 'desc';
  sortFields: string[];
}

function usePaginatedData<T>(path: string): UsePaginatedData<T> {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<Page<T>>();

  const loadMore = useCallback(
    (request: PageRequest, extraParams?: QueryParams) => {
      request.page = request.page - 1;
      const sendRequest = async () => {
        setLoading(true);
        try {
          const response = await fetchJSON<Page<T>>(
            `${path}?${toQueryString({ ...request, ...extraParams })}`,
            {
              method: 'GET',
              headers,
            }
          );
          setData(response);
          setError(false);
        } catch (e) {
          setError(true);
        } finally {
          setLoading(false);
        }
      };

      return sendRequest();
    },
    [path]
  );

  return {
    loadMore,
    data,
    loading,
    error,
  };
}

export default usePaginatedData;
