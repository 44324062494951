import React, { useEffect, useState } from 'react';
import { Col, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import Datepicker from 'hyper/components/Datepicker';
import { toQueryString } from 'helpers/api';
import { dateStr } from 'components/grouppages/gpUtil';

interface CullingListExport {
  onPathChange: (path: string) => void;
}

function CullingListExport({ onPathChange }: CullingListExport) {
  const { t } = useTranslation();
  const [from, setFrom] = useState<Date>(moment().add(-2, 'year').toDate());
  const [to, setTo] = useState<Date>(new Date());

  useEffect(() => {
    if (!(from && to)) return;
    const arr = [from, to];
    onPathChange(
      `/api/v1/export/culling-list?${toQueryString({
        from: dateStr(_.min(arr)),
        to: dateStr(_.max(arr)),
      })}`
    );
  }, [onPathChange, from, to]);

  return (
    <Row className="d-flex justify-content-center align-items-center mt-2">
      <Col xs="auto">
        <Row>
          <Label className="">{t('culling-list-date-interval')}</Label>
        </Row>
        <Row>
          <Datepicker
            id="startDate"
            startDate={from}
            dateFormat="yyyy-MM-dd"
            onDateChange={setFrom}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            todayButton={t('date-picker-today')}
          />
          <h4 className="mx-1"> - </h4>
          <Datepicker
            id="endDate"
            startDate={to}
            dateFormat="yyyy-MM-dd"
            onDateChange={setTo}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            todayButton={t('date-picker-today')}
          />
        </Row>
      </Col>
    </Row>
  );
}

export default CullingListExport;
