import React from 'react';
import { useTranslation } from 'react-i18next';
import SimpleTable from '../shared/simpleTable';
import IAGroupSummaryDetails from 'models/iaGroupSummaryDetails';

interface IAGroupSummaryProps {
  iaGroupSummaryDetails: IAGroupSummaryDetails;
}

function decimalFormatter(value: number) {
  return (Math.round(value * 100) / 100).toFixed(2);
}

function IAGroupSummary({ iaGroupSummaryDetails }: IAGroupSummaryProps) {
  const { t } = useTranslation();

  const columns = [
    {
      dataField: 'sumPregnant',
      text: t('sum-pregnant'),
    },
    {
      dataField: 'sumBorn',
      text: t('sum-born'),
    },
    {
      dataField: 'avgBorn',
      text: t('average-born'),
      formatter: decimalFormatter,
    },
    {
      dataField: 'sumViable',
      text: t('sum-alive'),
    },
    {
      dataField: 'avgViable',
      text: t('average-alive'),
      formatter: decimalFormatter,
    },
    {
      dataField: 'sumUnviable',
      text: t('sum-dead'),
    },
    {
      dataField: 'avgUnviable',
      text: t('average-dead'),
      formatter: decimalFormatter,
    },
    {
      dataField: 'sumAdded',
      text: t('sum-added'),
    },
    {
      dataField: 'sumTaken',
      text: t('sum-taken'),
    },
    {
      dataField: 'sumAdjusted',
      text: t('sum-adjusted'),
    },
  ];

  return (
    <>
      <h4 className="page-title">{t('ia-group-summary')}</h4>
      <SimpleTable data={[iaGroupSummaryDetails]} striped keyField="sumBorn" columns={columns} />
    </>
  );
}

export default IAGroupSummary;
