import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Button, Col, Input, Row } from 'reactstrap';
import PageContainer from './PageContainer';
import PaginatedTable from 'components/shared/paginatedTable';
import { getTableClasses } from 'helpers/tables';
import { useApi } from 'hooks/useApi';
import Loader from 'hyper/components/Loader';
import DefaultSorted from 'models/defaultSorted';
import PermittedIAGroupNameTransfer from 'models/PermittedIAGroupNameTransfer';

const renderAction = (t: TFunction, handleDelete: (nameId: number) => void) =>
  function getButtons(id: number) {
    return (
      <Button
        color="primary"
        className="sm"
        onClick={() => {
          handleDelete(id);
        }}
      >
        {t('delete')}
      </Button>
    );
  };

function formatName(name: string): string {
  const re = new RegExp('[^A-Z0-9]', 'g');
  return name.trim().toUpperCase().replace(re, '');
}

function isNameWrong(name: string, names: PermittedIAGroupNameTransfer[] | undefined | null) {
  if (!name) return false;
  if (name.trim().length > 5) return true;
  if (names) {
    const same = names.find((t) => t.name === name)?.name;
    if (same && name === same) return true;
  }
  return false;
}

function isNameFullWrong(name: string, names: PermittedIAGroupNameTransfer[] | undefined | null) {
  return name === null || name.trim().length < 1 || isNameWrong(name, names);
}

const baseURI = '/api/v1/ia-group/permitted-name';

function ProductionGroupManagement() {
  const { t } = useTranslation();
  const { sendDelete, loading: deleting } = useApi();
  const { post, loading: creating } = useApi();
  const { get, loading } = useApi();
  const [newName, setNewName] = useState<string>('');
  const [names, setNames] = useState<PermittedIAGroupNameTransfer[]>([]);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  const loadNames = useCallback(() => {
    get(baseURI, (data: PermittedIAGroupNameTransfer[]) => {
      setNames(data);
      setNewName('');
      setButtonDisabled(true);
    });
  }, [get]);

  const handleDelete = useCallback(
    (nameId: number) => {
      sendDelete(`${baseURI}/${nameId}`, () => loadNames());
    },
    [loadNames, sendDelete]
  );

  const columns = [
    {
      dataField: 'name',
      text: t('production-group-name'),
      sort: true,
      align: 'right',
      headerAlign: 'right',
    },
    {
      dataField: 'id',
      text: t('operations'),
      sort: false,
      formatter: renderAction(t, handleDelete),
    },
  ];

  const defaultSorted: DefaultSorted = {
    dataField: columns[0].dataField,
    order: 'asc',
  };

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.value ? formatName(event.target.value) : '';
      setNewName(name);
      setButtonDisabled(isNameFullWrong(name, names));
    },
    [names]
  );

  const onCreateName = useCallback(() => {
    if (!isNameWrong(newName, names)) {
      const data = { id: 0, name: newName };
      post(
        baseURI,
        () => {
          loadNames();
        },
        undefined,
        data
      );
      setNewName('');
    }
  }, [loadNames, names, newName, post]);

  const isLoading = useCallback(() => loading || deleting || creating, [
    creating,
    deleting,
    loading,
  ]);

  useEffect(() => loadNames(), [loadNames]);

  return (
    <PageContainer title={<h4>{t('production-group-title')}</h4>}>
      {isLoading() && <Loader />}
      {!isLoading() && (
        <>
          <Row className="mb-3">
            <Col className="text-right">
              <div className="mt-2 font-weight-bold">{t('production-group-name-new')}:</div>
            </Col>
            <Col>
              <Input
                type="text"
                name={'name'}
                value={newName}
                invalid={isNameWrong(newName, names)}
                onChange={onNameChange}
              />
            </Col>
            <Col>
              <Button color="primary" disabled={buttonDisabled} onClick={onCreateName}>
                {t('production-group-name-creation')}
              </Button>
            </Col>
          </Row>
          <PaginatedTable
            wrapperClasses={getTableClasses('adjustment-table')}
            data={names || []}
            keyField={columns[1].dataField}
            columns={columns}
            defaultSorted={defaultSorted}
          />
        </>
      )}
    </PageContainer>
  );
}

export default ProductionGroupManagement;
