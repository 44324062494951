import React from 'react';
import { useTranslation } from 'react-i18next';
import SimpleTable from '../shared/simpleTable';

interface RabbitPerformanceProps {
  rabbitPerformance: {
    coverCount: number;
    pregnantPercent: number;
    farrowingCount: number;
    sumBorn: number;
    separationCount: number;
    sumAlive: number;
    sumSeparWeight: number;
    sumSeparated: number;
    averageBorn: number;
    averageAlive: number;
    averageSeparated: number;
    averageSeparWeight: number;
  };
}

function percentFormatter(value: number) {
  return `${value}%`;
}

function kilogramFormatter(value: number) {
  return `${value} kg`;
}

function RabbitPerformance({ rabbitPerformance }: RabbitPerformanceProps) {
  const { t } = useTranslation();

  const columns = [
    {
      dataField: 'coverCount',
      text: t('cover-count'),
    },
    {
      dataField: 'farrowingCount',
      text: t('sum-farrowing'),
    },
    {
      dataField: 'separationCount',
      text: t('sum-separ'),
    },
    {
      dataField: 'pregnantPercent',
      text: t('pregnant-percent'),
      formatter: percentFormatter,
    },
    {
      dataField: 'sumBorn',
      text: t('sum-born'),
    },
    {
      dataField: 'sumAlive',
      text: t('sum-alive'),
    },
    {
      dataField: 'sumSeparated',
      text: t('sum-separ-count'),
    },
    {
      dataField: 'sumSeparWeight',
      text: t('sum-separ-weight'),
      formatter: kilogramFormatter,
    },
    {
      dataField: 'averageBorn',
      text: t('average-born'),
    },
    {
      dataField: 'averageAlive',
      text: t('average-alive'),
    },
    {
      dataField: 'averageSeparated',
      text: t('average-separated'),
    },
    {
      dataField: 'averageSeparWeight',
      text: t('average-separ-weight'),
      formatter: kilogramFormatter,
    },
  ];

  return (
    <>
      <h4 className="page-title">{t('rabbit-performance')}</h4>
      <SimpleTable data={[rabbitPerformance]} striped keyField="sumBorn" columns={columns} />
    </>
  );
}

export default RabbitPerformance;
