import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  ModalFooter,
  Container,
  Row,
  Col,
  Alert,
} from 'reactstrap';
import EditRabbitWeight from './editRabbitWeight';
import NumericInput from './numericInput';
import SelectInput from './selectInput';
import StaticLabel from './staticLabel';
import MotherEditTransfer from 'models/motherEditDetails';
import MotherDetails from 'models/motherDetails';
import CalculatedDetails from 'models/calculatedDetails';
import AutoCompleteInput from 'components/shared/autoCompleteInput';
import SearchResult from 'models/searchResult';
import '../../../css/modal.css';
import Datepicker from 'hyper/components/Datepicker';
import { useApiPost } from 'hooks/useApi';
import Loader from 'hyper/components/Loader';
import { defaultOrValue, formatDate, getDefaultNumber, isInputValueInvalid } from 'helpers/rabbit';
import { Unit } from 'models/unit';
import CageInput from 'components/shared/cageInput';
import { RabbitOrigin } from 'models/rabbitOrigin';
import { HealthCondition } from 'models/healthCondition';
import { RabbitStatus } from 'models/rabbitStatus';

const BREAST_LIMIT = 20;
const TATTOO_LENGTH_MIN = 6;
const TATTOO_LENGTH_MAX = 255;
const MIN_TERM_LENGTH = 2;

interface EditRabbitDetailsProps {
  onSave: () => void;
  isOpen: boolean;
  toggle: () => void;
  motherDetails: MotherDetails & CalculatedDetails;
}

function getDefaultValue(value: string) {
  return value || '';
}

function isValidTattoo(tattoo: string): boolean {
  return TATTOO_LENGTH_MIN <= tattoo.length && tattoo.length <= TATTOO_LENGTH_MAX;
}

function isMotherDetailsWrong(editMotherDetails: MotherEditTransfer, hasWeightFormError: boolean) {
  return (
    editMotherDetails.earTagId === 0 ||
    editMotherDetails.earTagId === null ||
    editMotherDetails.spermId === 0 ||
    editMotherDetails.motherId === 0 ||
    editMotherDetails.id === editMotherDetails.motherId ||
    !isValidTattoo(editMotherDetails.tattoo) ||
    isInputValueInvalid(editMotherDetails.breastNumber, BREAST_LIMIT) ||
    hasWeightFormError
  );
}

function EditRabbitDetails({ isOpen, toggle, onSave, motherDetails }: EditRabbitDetailsProps) {
  const {
    id,
    tattoo,
    origin,
    breastNumber,
    healthCondition,
    cageId,
    status,
    birthDate,
    weight18w,
    weight32d,
    weight66d,
    weightThirdYean,
    statisticalWeight,
  } = motherDetails;

  const defaultMotherDetails: MotherEditTransfer = {
    id,
    earTagId: null,
    tattoo,
    spermId: null,
    motherId: null,
    origin,
    breastNumber,
    cageId,
    healthCondition,
    status,
    birthDate: new Date(birthDate),
    weight32d,
    weight66d,
    weight18w,
    weightThirdYean,
    statisticalWeight,
  };

  const { t } = useTranslation();
  const [editMotherDetails, setEditMotherDetails] = useState<MotherEditTransfer>(
    defaultMotherDetails
  );
  const [isAllValid, setIsAllValid] = useState<boolean>(true);
  const [backendMessages, setBackendMessages] = useState<string[] | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [hasWeightFormError, setHasWeightFormError] = useState<boolean>(false);
  const [saveMotherDetails, { loading: saving }] = useApiPost<{ messages: string[] }>(
    `/api/v1/rabbit/${editMotherDetails.id}`,
    (response) => {
      if (response.messages && response.messages.length > 0) {
        setBackendMessages(response.messages);
      } else {
        setBackendMessages(null);
        onSave();
        toggle();
      }
    }
  );

  useEffect(() => {
    if (isOpen) {
      setEditMotherDetails(defaultMotherDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setIsAllValid(true);
    setBackendMessages(null);
  }, [editMotherDetails]);

  const submit = useCallback(() => {
    setSubmitted(true);
    const isWrong = isMotherDetailsWrong(editMotherDetails, hasWeightFormError);

    if (!isWrong) {
      setBackendMessages(null);
      saveMotherDetails(editMotherDetails);
    }
    setIsAllValid(!isWrong);
  }, [editMotherDetails, hasWeightFormError, saveMotherDetails]);

  const onEartagSelected = useCallback(
    (earTag: SearchResult) => {
      setSubmitted(false);
      const earTagId = earTag.id;
      const copy = { ...editMotherDetails, earTagId };
      setEditMotherDetails(copy);
    },
    [editMotherDetails]
  );

  const onFatherSelected = useCallback(
    (sperm: SearchResult) => {
      setSubmitted(false);
      const spermId = sperm.id;
      const copy = { ...editMotherDetails, spermId };
      setEditMotherDetails(copy);
    },
    [editMotherDetails]
  );

  const onMotherSelected = useCallback(
    (motherTattoo: SearchResult) => {
      setSubmitted(false);
      const motherId = motherTattoo.id;
      const copy = { ...editMotherDetails, motherId };
      setEditMotherDetails(copy);
    },
    [editMotherDetails]
  );

  const onOriginChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const origin = event.target.value;
      const copy = { ...editMotherDetails, origin };
      setEditMotherDetails(copy);
    },
    [editMotherDetails]
  );

  const onTattooChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const tattoo = event.target.value;
      const copy = { ...editMotherDetails, tattoo };
      setEditMotherDetails(copy);
    },
    [editMotherDetails]
  );

  const onBreastNumberChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const breastNumber = +event.target.value;
      const copy = { ...editMotherDetails, breastNumber };
      setEditMotherDetails(copy);
    },
    [editMotherDetails]
  );

  const onHealthConditionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const healthCondition = event.target.value;
      const copy = { ...editMotherDetails, healthCondition };
      setEditMotherDetails(copy);
    },
    [editMotherDetails]
  );

  const onStatusChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const status = event.target.value;
      const copy = { ...editMotherDetails, status };
      setEditMotherDetails(copy);
    },
    [editMotherDetails]
  );

  const onBirthDateChange = useCallback(
    (birthDate: Date) => {
      const copy = { ...editMotherDetails, birthDate };
      setEditMotherDetails(copy);
    },
    [editMotherDetails]
  );

  const onWeight32DayChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const weight32d = +event.target.value;
      const copy = { ...editMotherDetails, weight32d };
      setEditMotherDetails(copy);
    },
    [editMotherDetails]
  );

  const onWeight66DayChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const weight66d = +event.target.value;
      const copy = { ...editMotherDetails, weight66d };
      setEditMotherDetails(copy);
    },
    [editMotherDetails]
  );

  const onWeight18WeekChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const weight18w = +event.target.value;
      const copy = { ...editMotherDetails, weight18w };
      setEditMotherDetails(copy);
    },
    [editMotherDetails]
  );

  const onWeightThirdYeanChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const weightThirdYean = +event.target.value;
      const copy = { ...editMotherDetails, weightThirdYean };
      setEditMotherDetails(copy);
    },
    [editMotherDetails]
  );

  const onStatisticalWeightChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const statisticalWeight = +event.target.value;
      const copy = { ...editMotherDetails, statisticalWeight };
      setEditMotherDetails(copy);
    },
    [editMotherDetails]
  );

  const onSelecCage = useCallback(
    (cageId: number) => {
      const copy = { ...editMotherDetails, cageId };
      setEditMotherDetails(copy);
    },
    [editMotherDetails]
  );

  return (
    motherDetails && (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        contentClassName="modal-content-size"
        className="modal-align"
      >
        <ModalHeader toggle={toggle}>{`${t('edit-stock')}`}</ModalHeader>
        <ModalBody>
          {saving && <Loader />}
          <Container fluid>
            <Row className="">
              <Col className="d-flex justify-content-center">
                <div style={{ width: '330px' }}>
                  <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                    <Col sm="4">
                      <b>{t('ear-tag')}:</b>
                      <span className="text-danger">*</span>
                    </Col>
                    <Col className="d-flex justify-content-start">
                      <AutoCompleteInput
                        key={'ear-tag'}
                        onSelected={onEartagSelected}
                        searchType={'ear-tag'}
                        defaultValue={getDefaultValue(motherDetails.earTag)}
                        submitted={submitted}
                        minTermLength={MIN_TERM_LENGTH}
                      />
                    </Col>
                  </Row>

                  <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                    <Col sm="4">
                      <b>{t('tattoo')}:</b>
                    </Col>
                    <Col className="d-flex justify-content-start">
                      <Input
                        onChange={onTattooChange}
                        value={editMotherDetails.tattoo}
                        valid={isValidTattoo(editMotherDetails.tattoo)}
                        invalid={
                          !isValidTattoo(editMotherDetails.tattoo) ||
                          editMotherDetails.id === editMotherDetails.motherId
                        }
                      />
                    </Col>
                  </Row>

                  <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                    <Col sm="4">
                      <b>{t('father')}:</b>
                    </Col>
                    <Col className="d-flex justify-content-start">
                      <AutoCompleteInput
                        key={'sperm'}
                        onSelected={onFatherSelected}
                        searchType={'sperm'}
                        defaultValue={getDefaultValue(motherDetails.sperm)}
                        submitted={submitted}
                        minTermLength={MIN_TERM_LENGTH}
                      />
                    </Col>
                  </Row>

                  <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                    <Col sm="4">
                      <b>{t('mother')}:</b>
                    </Col>
                    <Col className="d-flex justify-content-start">
                      <AutoCompleteInput
                        key={'tattoo'}
                        onSelected={onMotherSelected}
                        searchType={'tattoo'}
                        defaultValue={getDefaultValue(motherDetails.mother)}
                        submitted={submitted}
                        minTermLength={MIN_TERM_LENGTH}
                        otherInvalid={editMotherDetails.id === editMotherDetails.motherId}
                      />
                    </Col>
                  </Row>

                  <StaticLabel
                    label={'family-genetics'}
                    value={defaultOrValue(motherDetails.familyGenetics)}
                  />

                  <StaticLabel
                    label={'breed'}
                    value={defaultOrValue(motherDetails.breed, t, true)}
                  />
                </div>
              </Col>

              <Col className="d-flex justify-content-center">
                <div style={{ width: '330px' }}>
                  <SelectInput
                    label={'origin'}
                    value={editMotherDetails.origin}
                    onChange={onOriginChange}
                    options={Object.values(RabbitOrigin)}
                  />

                  <NumericInput
                    value={motherDetails.nannyCount}
                    label={'nanny'}
                    unit={Unit.piece}
                    disabled
                  />

                  <NumericInput
                    value={motherDetails.gridCount}
                    label={'grid'}
                    unit={Unit.piece}
                    disabled
                  />

                  <NumericInput
                    onChange={onBreastNumberChange}
                    value={getDefaultNumber(editMotherDetails.breastNumber)}
                    label={'breast-number'}
                    max={BREAST_LIMIT}
                    invalid={editMotherDetails.breastNumber > BREAST_LIMIT}
                    unit={Unit.piece}
                  />

                  <SelectInput
                    name="healthConditionSelect"
                    label={'health-condition'}
                    value={editMotherDetails.healthCondition}
                    onChange={onHealthConditionChange}
                    options={Object.values(HealthCondition)}
                  />

                  <SelectInput
                    name="statusSelect"
                    label={'status'}
                    value={editMotherDetails.status}
                    onChange={onStatusChange}
                    options={Object.values(RabbitStatus)}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-3 ml-1 mr-4">
              <Col>
                <CageInput
                  cageId={editMotherDetails.cageId}
                  onSelectCage={onSelecCage}
                  disabled={editMotherDetails.status === RabbitStatus.DEAD}
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col className="d-flex justify-content-center">
                <div style={{ width: '330px' }}>
                  <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                    <Col sm="4">
                      <b>{t('birth-date')}:</b>
                    </Col>
                    <Col className="d-flex justify-content-start">
                      <Datepicker
                        startDate={new Date(editMotherDetails.birthDate)}
                        dateFormat="yyyy-MM-dd"
                        onDateChange={onBirthDateChange}
                      />
                    </Col>
                  </Row>

                  <StaticLabel label={'bred-from'} value={formatDate(motherDetails.firstIaDate)} />
                  <StaticLabel label={'wasted-at'} value={formatDate(motherDetails.wastedAt)} />
                  <StaticLabel
                    label={'last-ia-date'}
                    value={formatDate(motherDetails.lastIaDate)}
                  />
                  <StaticLabel label={'buck'} value={defaultOrValue(motherDetails.buck)} />
                  <StaticLabel
                    label={'pregnant'}
                    value={motherDetails.pregnantInLastIa ? '+' : '-'}
                  />
                </div>
              </Col>

              <Col className="d-flex justify-content-center">
                <div style={{ width: '330px' }}>
                  <EditRabbitWeight
                    onWeight32DayChange={onWeight32DayChange}
                    onWeight66DayChange={onWeight66DayChange}
                    onWeight18WeekChange={onWeight18WeekChange}
                    onWeightThirdYeanChange={onWeightThirdYeanChange}
                    onStatisticalWeightChange={onStatisticalWeightChange}
                    editMotherDetails={editMotherDetails}
                    setHasFormError={setHasWeightFormError}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col>
                {!isAllValid && (
                  <Alert color="danger" className="text-center">
                    {t('form-has-errors')}
                  </Alert>
                )}
                {backendMessages && backendMessages.length > 0 && (
                  <Alert color="danger" className="text-center">
                    {backendMessages.map((msg: string) => (
                      <li key={msg}>{t(msg)}</li>
                    ))}
                  </Alert>
                )}
              </Col>
            </Row>
          </Container>
          <Form className="form d-flex flex-column justify-content-center pl-5">
            <div className="" />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={submit} className="mr-2">
            {t('save')}
          </Button>
          <Button color="primary" onClick={toggle}>
            {t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  );
}

export default EditRabbitDetails;
