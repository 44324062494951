import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ListGroupItem, Row } from 'reactstrap';
import { classCommon, classHeader, classTable, secondToStr, timeToStr, toStr } from './gpUtil';
import StatBlockTransfer from 'models/grouppages/statBlockTransfer';
import StatInfoTransfer from 'models/grouppages/statInfoTransfer';

const classSmallTable = `${classTable} mb-1 gp-table`;
const classCell = `text-dark text-right ${classCommon}`;
const classSumHeader = 'text-right px-1';

interface BlockInfoProps {
  transfer: StatBlockTransfer;
}

function BlockInfo({ transfer }: BlockInfoProps) {
  const { t } = useTranslation();

  const allNum = transfer.blocks.reduce((s, a) => s + a.measureNum, 0);

  return (
    <ListGroupItem key={transfer.blocks[0].id}>
      <Row>
        <Col>
          <Row>
            <Col>
              <h5>{transfer.userName}</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <table className={classSmallTable}>
                <thead>
                  <tr>
                    <th className={classHeader}>{t('sw-start-time')}</th>
                    <th className={classHeader}>{t('sw-end-time')}</th>
                    <th className={classHeader}>{t('sw-period')}</th>
                    <th className={classHeader}>{t('sw-measured-num')}</th>
                  </tr>
                </thead>
                <tbody>
                  {transfer &&
                    transfer.blocks.map((info: StatInfoTransfer) => (
                      <tr key={info.id}>
                        <td className={classCell}>{timeToStr(info.startAt)}</td>
                        <td className={classCell}>{timeToStr(info.endAt)}</td>
                        <td className={classCell}>{secondToStr(info.periodLengthInSec)}</td>
                        <td className={classCell}>{info.measureNum}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Col>
          </Row>
        </Col>
        <Col xs="auto">
          <table className="border border-secondary rounded">
            <tbody>
              <tr>
                <td className={classSumHeader}>{`${t('sw-sum-num')} : `}</td>
                <td className={classCell}>{allNum}</td>
              </tr>
              <tr>
                <td className={classSumHeader}>{`${t('sw-sum-time')} : `}</td>
                <td className={classCell}>{secondToStr(Math.max(1, transfer.sumPeriod))}</td>
              </tr>
              <tr>
                <td className={classSumHeader}>{`${t('sw-num-by-min')} : `}</td>
                <td className={classCell}>{toStr(transfer.sumNumByMinute, 1)}</td>
              </tr>
              <tr>
                <td className={classSumHeader}>{`${t('sw-num-by-hour')} : `}</td>
                <td className={classCell}>{toStr(transfer.sumNumByHour, 1)}</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </ListGroupItem>
  );
}

export default BlockInfo;
