import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Form, Label, Input, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useSendApiGet } from '../../hooks/useApi';
import IATaskUser from 'models/iaTaskUser';
import IATaskDetails from 'models/iaTaskDetails';
import { TaskType } from 'models/taskType';
import Loader from 'hyper/components/Loader';

interface UserIds {
  userIds: number[];
}

interface DedicateuserModalProps {
  onSave: (userIds: UserIds) => void;
  isOpen: boolean;
  toggle: () => void;
  iaTask: IATaskDetails;
  iaGroupId: number;
  iaGroupName: string | null;
}

function DedicateUserModal({
  iaGroupName,
  iaGroupId,
  iaTask,
  onSave,
  isOpen,
  toggle,
}: DedicateuserModalProps) {
  const defaultUserIds: UserIds = {
    userIds: [],
  };
  const [workers, setWorkers] = useState<IATaskUser[]>();
  const [userIds, setUserIds] = useState<UserIds>(defaultUserIds);
  const [loadIaTaskUsers, { loading }] = useSendApiGet<IATaskUser[]>(
    `/api/v1/ia-group/${iaGroupId}/task/${iaTask.iaTaskId}/users`,
    setWorkers
  );

  useEffect(() => {
    if (isOpen) {
      setUserIds({ userIds: [] });
      loadIaTaskUsers();
    }
  }, [loadIaTaskUsers, toggle, isOpen]);

  const removeUser = useCallback(
    (userId: number) => {
      const filteredIds = userIds.userIds.filter((id) => id !== userId);
      const idObject: UserIds = {
        userIds: filteredIds,
      };
      setUserIds(idObject);
    },
    [userIds]
  );

  const addUser = useCallback(
    (userId: number) => {
      const idObject: UserIds = {
        ...userIds,
      };

      if (!idObject.userIds.includes(userId)) {
        idObject.userIds.push(userId);
      }
      setUserIds(idObject);
    },
    [userIds]
  );

  const { t } = useTranslation();

  const submit = useCallback(() => {
    if (userIds.userIds.length > 0) {
      onSave(userIds);
    }
    toggle();
  }, [onSave, toggle, userIds]);

  const handleWorkerClick = useCallback(
    (worker: IATaskUser) => (event: ChangeEvent<HTMLInputElement>) => {
      event.target.checked ? addUser(worker.userId) : removeUser(worker.userId);
    },
    [addUser, removeUser]
  );

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} size="md">
        <ModalHeader toggle={toggle}>
          {`${t('dedicate-user')}${iaGroupName} - ${t(TaskType[iaTask.type])}`}
        </ModalHeader>
        <ModalBody>
          {!loading && workers && workers.filter((worker) => !worker.dedicated).length === 0 && (
            <div className="text-center">
              <h4>{t('empty-worker-list')}</h4>
            </div>
          )}
          <Form className="form d-flex flex-column justify-content-center pl-5">
            <div className="">
              {loading && <Loader />}
              {!loading &&
                workers &&
                workers
                  .filter((worker) => !worker.dedicated)
                  .map((worker: IATaskUser) => (
                    <div key={worker.userId}>
                      {
                        <Label check className="cursor-pointer my-2">
                          <Input
                            className="cursor-pointer position-relative mr-1"
                            type="checkbox"
                            onChange={handleWorkerClick(worker)}
                          />
                          {`${t('worker')}${worker.name}`}
                        </Label>
                      }
                    </div>
                  ))}
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={submit} className="mr-2">
            {t('save')}
          </Button>
          <Button color="primary" onClick={toggle}>
            {t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default DedicateUserModal;
