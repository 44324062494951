// @ts-ignore
import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import FlagIcon from './flagIcon';
import { Languages } from 'models/languages';
import { setLanguage, getLanguage, SupportedLanguages, DEFAULT_LANGUAGE } from 'i18n';
import { useApi } from 'hooks/useApi';

const TT_ID = 'langSelectorCtrl';

const COUNTRY_FOR_DEFAULT_LANGUAGE = 'gb';

function convertLanguageToCountry(lang: string): string {
  return lang
    ? lang === DEFAULT_LANGUAGE
      ? COUNTRY_FOR_DEFAULT_LANGUAGE
      : lang
    : COUNTRY_FOR_DEFAULT_LANGUAGE;
}

interface LanguageSelectorProps {
  onLanguageChanged?: (language: string) => void;
  onError?: (language: string) => void;
}

function LanguageSelector({ onLanguageChanged, onError }: LanguageSelectorProps) {
  const { t } = useTranslation();
  const { postAsText } = useApi();
  const [actual, setActual] = useState<string>();

  const sendNewLanguage = useCallback(
    (language: string) => {
      postAsText(
        '/api/v1/user/language',
        (data: any) => {
          setActual(language);
          setLanguage(language);
          onLanguageChanged && onLanguageChanged(language);
        },
        (error: any) => {
          console.error(`Error when changing user language to "${language}": `, error);
          onError && onError(language);
        },
        language
      );
    },
    [onError, onLanguageChanged, postAsText]
  );

  const changeLanguage = useCallback(
    (lang: string) => (event: React.MouseEvent<HTMLElement>) => {
      sendNewLanguage(lang);
    },
    [sendNewLanguage]
  );

  useEffect(() => {
    const storedLang = getLanguage() || '';
    setActual(storedLang);
  }, []);

  return (
    <>
      <UncontrolledButtonDropdown size="sm" className="mr-3">
        <DropdownToggle id={TT_ID} caret>
          {actual && actual.length ? (
            <>
              <FlagIcon code={convertLanguageToCountry(actual)} />{' '}
            </>
          ) : (
            t('choose-language')
          )}
        </DropdownToggle>
        <DropdownMenu>
          {Object.values(Languages).map((lang) => (
            <DropdownItem key={lang} onClick={changeLanguage(lang)}>
              <span
                className="border border-secondary bg-light mr-2"
                style={{
                  paddingLeft: '7px',
                  paddingRight: '7px',
                  paddingTop: '1px',
                  paddingBottom: '1px',
                }}
              >
                <FlagIcon code={convertLanguageToCountry(lang)} />
              </span>
              {SupportedLanguages.get(lang)?.title}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
      <UncontrolledTooltip placement="left" target={TT_ID}>
        {actual && actual.length
          ? SupportedLanguages.get(actual as Languages)?.title
          : t('choose-language')}
      </UncontrolledTooltip>
    </>
  );
}

export default LanguageSelector;
