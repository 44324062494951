import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import Farm from 'models/farm';
import Stable from 'models/stable';
import Loader from 'hyper/components/Loader';
import { FATTENING_AGE_MAX, FATTENING_AGE_MIN } from 'components/grouppages/gpUtil';
import { isNullOrInvalid, roundAndRemove } from 'helpers/rabbit';

const FATTENING_AGE_LIMIT = FATTENING_AGE_MAX - 1;

interface OptionType {
  value: number;
  label: string;
}

function filterStableForForbidden(stable: Stable | undefined, forbiddenStable: Stable[]): boolean {
  if (!stable) return false;
  for (const badStable of forbiddenStable) {
    if (badStable.name === stable.name) return false;
  }
  return true;
}

function checkStable(
  farms: Farm[] | null,
  forbiddenStable: Stable[] | undefined
): OptionType[] | undefined {
  if (!farms || farms.length < 1 || !farms[0].stables) return undefined;
  const stables =
    forbiddenStable && forbiddenStable.length > 0
      ? farms[0].stables.filter((stable) => filterStableForForbidden(stable, forbiddenStable))
      : farms[0].stables;
  return stables.map((stable: Stable) => ({
    value: stable.id,
    label: stable.name,
  }));
}

interface StableModalProps {
  isOpen: boolean;
  farms: Farm[] | null | undefined;
  backendMessages: string[] | null;
  selectedStable: OptionType | OptionType[] | null;
  forbiddenStable?: Stable[] | undefined;
  saving: boolean;
  loading?: boolean;
  isValid: boolean;
  isMulti?: boolean;
  isFattening?: boolean;
  deliveryAge?: number;
  submit: () => void;
  onChange: (value: any) => void;
  onDeliveryAgeChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  toggle: () => void;
}

function StableModal({
  isOpen,
  farms,
  backendMessages,
  selectedStable,
  forbiddenStable,
  saving,
  loading,
  isValid,
  isMulti,
  isFattening,
  deliveryAge,
  submit,
  onChange,
  onDeliveryAgeChange,
  toggle,
}: StableModalProps) {
  const { t } = useTranslation();
  const [notAllowedStables, setNotAllowedStables] = useState<Stable[]>();

  useEffect(() => {
    setNotAllowedStables(isOpen ? forbiddenStable : undefined);
  }, [isOpen, forbiddenStable]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{`${t('choose-stable-title')}`}</ModalHeader>
      <ModalBody>
        {(saving || loading) && <Loader />}
        <Form className="form d-flex flex-column justify-content-center">
          <Row className="d-flex justify-content-center">
            <Col sm="9">
              <FormGroup className="mb-0">
                <Label className="mr-sm-2">{t(isMulti ? 'stables' : 'grouppage-stable')}</Label>
                {farms && farms[0].stables && (
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    placeholder={t('stable-placeholder')}
                    noOptionsMessage={() => t('empty-list')}
                    isMulti={isMulti}
                    onChange={onChange}
                    options={checkStable(farms, notAllowedStables)}
                    value={selectedStable}
                  />
                )}
              </FormGroup>
            </Col>
          </Row>

          {isFattening && (
            <Row className="d-flex justify-content-center mt-3">
              <Col sm="9">
                <Label className="mr-sm-2">{t('grouppage-fattening-planned-age-range')}</Label>
                <Input
                  type="number"
                  name={'mgpd-mother-resp'}
                  min={FATTENING_AGE_MIN}
                  max={FATTENING_AGE_LIMIT}
                  className="text-right"
                  defaultValue={deliveryAge}
                  invalid={isNullOrInvalid(deliveryAge, FATTENING_AGE_MIN, FATTENING_AGE_LIMIT)}
                  onInput={roundAndRemove}
                  onChange={onDeliveryAgeChange}
                />
              </Col>
            </Row>
          )}

          <Row className="pt-2">
            <Col>
              {!isValid && (
                <Alert color="danger" className="text-center">
                  {isFattening ? t('form-has-errors') : t('choose-stable')}
                </Alert>
              )}

              {backendMessages && backendMessages.length > 0 && (
                <Alert color="danger" className="text-center">
                  {backendMessages.map((msg: string) => (
                    <li key={msg}>{t(msg)}</li>
                  ))}
                </Alert>
              )}
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={submit} className="mr-2">
          {t('save')}
        </Button>
        <Button color="primary" onClick={toggle}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default StableModal;
