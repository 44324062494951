import React from 'react';
import { Alert } from 'reactstrap';

interface ResultInfoProps {
  success: boolean;
  successMsg?: string;
  error: boolean;
  errorMsg?: string;
}

function ResultInfo({ success, successMsg, error, errorMsg }: ResultInfoProps) {
  return (
    <>
      {success && successMsg && <Alert color="success">{`${successMsg}`}</Alert>}
      {error && errorMsg && <Alert color="danger">{`${errorMsg}`}</Alert>}
    </>
  );
}

export default ResultInfo;
