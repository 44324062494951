import React, { useState, useCallback, useEffect } from 'react';
import { Label, Col, Row, Button, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Select, { ValueType } from 'react-select';
import _ from 'lodash';
import AutoCompleteInput from 'components/shared/autoCompleteInput';
import SearchResult from 'models/searchResult';
import { RabbitStatus } from 'models/rabbitStatus';
import { HealthCondition } from 'models/healthCondition';
import { useApiGet } from 'hooks/useApi';

const SEARCHTYPE = 'ear-tag-all';
const MIN_TERM_LENGTH = 1;
const NOFILTER = 'ear-tag-no-filter';
const NO = 'ear-tag-no';
const YES = 'ear-tag-yes';

const regexEarTag = /^([a-zA-Z]-[0-9]{5})$/;

const calcEstimatedRowNum = (tagStartFrom?: string, tagEndTo?: string): number | undefined => {
  if (tagStartFrom && !regexEarTag.test(tagStartFrom)) tagStartFrom = undefined;
  if (tagEndTo && !regexEarTag.test(tagEndTo)) tagEndTo = undefined;
  if (!tagStartFrom || !tagEndTo) return undefined;

  let minIdx = 0;
  let maxIdx = 99999;
  if (tagStartFrom && tagEndTo) {
    if (tagEndTo < tagStartFrom) {
      return undefined;
    }
    const color1 = tagStartFrom && tagStartFrom.length ? tagStartFrom[0].toUpperCase() : '';
    const color2 = tagEndTo && tagEndTo.length ? tagEndTo[0].toUpperCase() : '';
    if (color1 !== color2) return undefined;
  }
  if (tagStartFrom) minIdx = +tagStartFrom.substring(2);
  if (tagEndTo) maxIdx = +tagEndTo.substring(2);
  return maxIdx - minIdx + 1;
};

export interface EarTagFilterOptions {
  tagStartFrom?: string;
  tagEndTo?: string;
  farmId?: number;
  active?: boolean;
  used?: boolean;
  statuses?: string[];
  healthConditions?: string[];
}

interface FormValues {
  tagStartFrom?: string;
  tagEndTo?: string;
  farmId?: OptionType | null;
  active?: OptionType | null;
  used?: OptionType | null;
  statuses?: OptionType[] | null;
  healthConditions?: OptionType[] | null;
}

interface EarTagFilterProps {
  clearFilterValues: number;
  totalNum: number;
  disabled: boolean;
  onFilter: (filter: EarTagFilterOptions) => void;
}

interface OptionType {
  value: string | number | boolean;
  label: string;
}

const defaultFormValues: FormValues = {
  tagStartFrom: '',
  tagEndTo: '',
  farmId: null,
  active: null,
  used: null,
  statuses: null,
  healthConditions: null,
};

function EarTagFilter({ clearFilterValues, totalNum, disabled, onFilter }: EarTagFilterProps) {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [hasFormError, setHasFormError] = useState<boolean>(false);
  const { data: farms } = useApiGet<SearchResult[]>('/api/v1/search/farm?search=');
  const [formValues, setFormValues] = useState<FormValues>(defaultFormValues);
  const [noFarmOption, setNoFarmOption] = useState<boolean>(false);
  const [estimatedRowNum, setEstimatedRowNum] = useState<number>();

  const onEarTagChange = useCallback(
    (field: keyof FormValues) => (searchResult: SearchResult) => {
      setSubmitted(false);
      setHasFormError(searchResult.id === 0);
      setFormValues((prev) => ({ ...prev, [field]: searchResult.label }));
    },
    []
  );

  const onSelectChange = useCallback(
    (field: keyof EarTagFilterOptions) => (value: ValueType<OptionType>) => {
      setFormValues((prev) => ({ ...prev, [field]: value }));
    },
    []
  );

  const getFilterValue = (value: string | OptionType | OptionType[]) => {
    if (Array.isArray(value)) return ((value as unknown) as OptionType[]).map(({ value }) => value);
    if (typeof value === 'object') return value.value;
    return value;
  };

  const generateFilterValues = useCallback((formValues: FormValues) => {
    let newFilter: EarTagFilterOptions = {};
    _.each(formValues, (value, key) => {
      if (!value || (Array.isArray(value) && !value.length)) return;
      newFilter = { ...newFilter, [key]: getFilterValue(value) };
    });

    return newFilter;
  }, []);

  const onCallFilter = useCallback(() => {
    setSubmitted(true);
    if (!hasFormError) {
      const filter = generateFilterValues(formValues);
      onFilter(filter);
    }
  }, [hasFormError, generateFilterValues, formValues, onFilter]);

  useEffect(() => {
    if (formValues.farmId?.value !== -1) return setNoFarmOption(false);

    setFormValues((prev) => ({
      ...prev,
      active: { value: true, label: t(YES) },
      used: { value: false, label: t(NO) },
      statuses: null,
      healthConditions: null,
    }));
    setNoFarmOption(true);
  }, [formValues.farmId, t]);

  useEffect(() => {
    if (formValues.used?.value !== false) return;

    setFormValues((prev) => ({
      ...prev,
      statuses: null,
      healthConditions: null,
    }));
  }, [formValues.used, t]);

  useEffect(() => {
    setEstimatedRowNum(calcEstimatedRowNum(formValues.tagStartFrom, formValues.tagEndTo));
  }, [formValues.tagStartFrom, formValues.tagEndTo]);

  useEffect(() => {
    if (clearFilterValues > 0) {
      setFormValues({ ...defaultFormValues });
      const filter = generateFilterValues(defaultFormValues);
      onFilter(filter);
    }
  }, [clearFilterValues, generateFilterValues, onFilter]);

  return (
    <>
      <Row className="align-items-end">
        <Col>
          <FormGroup className="mb-0">
            <Label>{t('from-ear-tag')}</Label>
            <AutoCompleteInput
              disabled={disabled}
              key={SEARCHTYPE}
              onSelected={onEarTagChange('tagStartFrom')}
              searchType={SEARCHTYPE}
              defaultValue={formValues.tagStartFrom ?? ''}
              submitted={submitted}
              minTermLength={MIN_TERM_LENGTH}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="mb-0">
            <Label>{t('to-ear-tag')}</Label>
            <AutoCompleteInput
              disabled={disabled}
              key={SEARCHTYPE}
              onSelected={onEarTagChange('tagEndTo')}
              searchType={SEARCHTYPE}
              defaultValue={formValues.tagEndTo ?? ''}
              submitted={submitted}
              minTermLength={MIN_TERM_LENGTH}
            />
          </FormGroup>
        </Col>

        <Col>
          <FormGroup className="mb-0">
            <Label>{t('ear-tag-farm')}</Label>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              isClearable
              value={formValues.farmId}
              placeholder={t(NOFILTER)}
              onChange={onSelectChange('farmId')}
              options={[
                { value: -1, label: t('ear-tag-no-farm') },
                ...(farms?.map((farm) => ({ value: farm.id || 0, label: farm.label })) || []),
              ]}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="mb-0">
            <Label>{t('active')}</Label>
            <Select
              isDisabled={disabled || noFarmOption}
              className="react-select"
              classNamePrefix="react-select"
              isClearable
              value={formValues.active}
              placeholder={t(NOFILTER)}
              onChange={onSelectChange('active')}
              options={[
                { value: true, label: '✔' },
                { value: false, label: '✖' },
              ]}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="mb-0">
            <Label>{t('ear-tag-used')}</Label>
            <Select
              isDisabled={disabled || noFarmOption}
              className="react-select"
              classNamePrefix="react-select"
              isClearable
              value={formValues.used}
              placeholder={t(NOFILTER)}
              onChange={onSelectChange('used')}
              options={[
                { value: true, label: t(YES) },
                { value: false, label: t(NO) },
              ]}
            />
          </FormGroup>
        </Col>

        <Col>
          <FormGroup className="mb-0">
            <Label className="mr-sm-2">{t('status')}</Label>
            <Select
              isDisabled={disabled || (formValues.used && !formValues.used.value) || noFarmOption}
              className="react-select"
              classNamePrefix="react-select"
              isClearable
              isMulti
              value={formValues.statuses}
              placeholder={t(NOFILTER)}
              onChange={onSelectChange('statuses')}
              options={Object.keys(RabbitStatus).map((rabbitStatus) => ({
                value: rabbitStatus,
                label: t(rabbitStatus),
              }))}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="mb-0">
            <Label className="mr-sm-2">{t('health-condition')}</Label>
            <Select
              isDisabled={disabled || (formValues.used && !formValues.used.value) || noFarmOption}
              className="react-select"
              classNamePrefix="react-select"
              isClearable
              isMulti
              value={formValues.healthConditions}
              placeholder={t(NOFILTER)}
              onChange={onSelectChange('healthConditions')}
              options={Object.keys(HealthCondition).map((healthCondition) => ({
                value: healthCondition,
                label: t(healthCondition),
              }))}
            />
          </FormGroup>
        </Col>
        <Col className="">
          <Button
            block
            color="primary"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              onCallFilter();
            }}
          >
            {t('filter')}
          </Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <div>
            <Label className="mr-1">{t('estimated-row-num')}</Label>
            <Label className="mr-3">{estimatedRowNum ?? '?'}</Label>
          </div>
          <div>
            <Label className="mr-1">{t('listed-row-num')}</Label>
            <Label className="">{totalNum || 0}</Label>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default EarTagFilter;
