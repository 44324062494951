import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Input,
  ModalFooter,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Stable from 'models/stable';

interface StableFormModalProps {
  stable?: Stable & { farmId: number };
  onSave: (stable: Stable & { farmId: number }) => void;
  isOpen: boolean;
  toggle: () => void;
}

const defaultStable = {
  id: 0,
  name: '',
  farmId: 0,
};

function StableFormModal({ stable, onSave, isOpen, toggle }: StableFormModalProps) {
  const [formError, setFormError] = useState<boolean>(false);
  const [newStable, setNewStable] = useState<Stable & { farmId: number }>(stable || defaultStable);
  const { t } = useTranslation();

  useEffect(() => {
    setFormError(false);
    if (isOpen && stable) {
      setNewStable({ ...stable });
    } else {
      setNewStable({ ...defaultStable });
    }
  }, [toggle, isOpen, stable]);

  const handleStableNameChange = (value: string) => {
    setFormError(!value);
    setNewStable({ ...newStable, name: value });
  };

  const submit = () => {
    setFormError(!newStable.name);
    if (newStable.name) {
      onSave(newStable);
      toggle();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        {stable?.id ? t('edit-stable') : t('create-stable')}
      </ModalHeader>
      <ModalBody>
        <Form className="form">
          <FormGroup>
            <Label>
              {t('stable-name')}
              <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              name="name"
              defaultValue={newStable.name}
              invalid={formError}
              onChange={(e) => handleStableNameChange(e.target.value)}
            />
            <FormFeedback>{t('stable-name-error')}</FormFeedback>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={submit} className="mr-2">
          {t('save')}
        </Button>
        <Button color="primary" onClick={toggle}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default StableFormModal;
