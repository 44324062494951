import React from 'react';
import BootstrapTable, { SelectRowProps, RowEventHandlerProps } from 'react-bootstrap-table-next';
import { Col, Row } from 'reactstrap';
import DefaultSorted from '../../models/defaultSorted';
import Column from 'models/column';
import { getRowCountLocaleString } from 'helpers/tables';

interface SimpleTableProps {
  wrapperClasses?: string;
  condensed?: boolean;
  data: unknown[];
  columns: Column[];
  defaultSorted?: DefaultSorted;
  keyField: string;
  striped?: boolean;
  selectRow?: SelectRowProps<any>;
  rowEvents?: RowEventHandlerProps<any>;
  onSort?: (field: string, order: 'desc' | 'asc') => void;
  expandRow?: {
    renderer: (row: unknown, rowIndex: number) => JSX.Element;
  };
  dataCy?: string;
  showTotalNumber?: boolean;
}

const expandHeader = ({ isAnyExpands }: { isAnyExpands: boolean }) =>
  isAnyExpands ? <i className="dripicons-minus" /> : <i className="dripicons-plus" />;

const expandColumn = ({ expanded }: { expanded: boolean }) =>
  expanded ? <i className="dripicons-minus" /> : <i className="dripicons-plus" />;

const expandRowOptions = {
  showExpandColumn: true,
  onlyOneExpanding: true,
  expandHeaderColumnRenderer: expandHeader,
  expandColumnRenderer: expandColumn,
};

function SimpleTable({
  wrapperClasses,
  condensed,
  data,
  columns,
  defaultSorted,
  keyField,
  expandRow,
  selectRow,
  rowEvents,
  onSort,
  striped = true,
  dataCy,
  showTotalNumber = false,
}: SimpleTableProps) {
  const cy = dataCy ? ` data-cy-${dataCy}` : '';

  return (
    <>
      {showTotalNumber && (
        <Row className="justify-content-end">
          <Col xs="auto">
            <span>{getRowCountLocaleString(data.length)}</span>
          </Col>
        </Row>
      )}
      <BootstrapTable
        bootstrap4
        striped={striped}
        keyField={keyField}
        data={data}
        columns={columns}
        selectRow={selectRow}
        rowEvents={rowEvents}
        expandRow={expandRow ? ({ ...expandRow, ...expandRowOptions } as any) : undefined}
        defaultSorted={defaultSorted ? [defaultSorted] : undefined}
        onSort={onSort}
        wrapperClasses={wrapperClasses || `table-responsive${cy}`}
        condensed={condensed}
      />
    </>
  );
}

export default SimpleTable;
