import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  ModalFooter,
  Container,
  Row,
  Col,
  Alert,
} from 'reactstrap';
import '../../../../css/modal.css';
import cloneDeep from 'lodash/cloneDeep';
import { dateToStr, FEED_LIMIT } from '../../gpUtil';
import FeedDetails from '../common/feedDetails';
import HealthDetails from '../common/healthDetails';
import { useApiPut } from 'hooks/useApi';
import Loader from 'hyper/components/Loader';
import YoungDiaryElementTransfer from 'models/grouppages/youngDiaryElementTransfer';
import YoungDiaryEditTransfer from 'models/grouppages/youngDiaryEditTransfer';
import { useInput } from 'hooks/useInput';
import { ValueType } from 'models/valueType';
import { isInputValuesBetweenLimit, LimitException, roundAndRemove } from 'helpers/rabbit';

const LIMIT = 20000;

const excludedKeys = ['id', 'diaryId'];
const limitExceptions: LimitException[] = [{ name: 'amount', limit: FEED_LIMIT }];

interface EditYoungDiaryDetailProps {
  id: number | undefined;
  isOpen: boolean;
  diaryDetails: YoungDiaryElementTransfer;
  onSave: () => void;
  toggle: () => void;
}

function isDeadPSValuesValid(editDiaryDetails: YoungDiaryEditTransfer | undefined) {
  const dead = editDiaryDetails?.deadPS;
  const dissected = editDiaryDetails?.dissectedPS;
  if (dissected && (!dead || dead < dissected)) {
    return false;
  }
  const deadResp = editDiaryDetails?.deadRespPS;
  if (deadResp && (!dissected || dissected < deadResp)) {
    return false;
  }
  const deadEre = editDiaryDetails?.deadErePS;
  if (deadEre && (!dissected || dissected < deadEre)) {
    return false;
  }

  return true;
}

function isDeadGPValuesValid(editDiaryDetails: YoungDiaryEditTransfer | undefined) {
  const dead = editDiaryDetails?.deadGP;
  const dissected = editDiaryDetails?.dissectedGP;
  if (dissected && (!dead || dead < dissected)) {
    return false;
  }
  const deadResp = editDiaryDetails?.deadRespGP;
  if (deadResp && (!dissected || dissected < deadResp)) {
    return false;
  }
  const deadEre = editDiaryDetails?.deadEreGP;
  if (deadEre && (!dissected || dissected < deadEre)) {
    return false;
  }

  return true;
}

function isDiaryDetailsValid(editDiaryDetails: YoungDiaryEditTransfer | undefined) {
  return (
    isInputValuesBetweenLimit(editDiaryDetails, excludedKeys, LIMIT, limitExceptions) &&
    isDeadPSValuesValid(editDiaryDetails) &&
    isDeadGPValuesValid(editDiaryDetails)
  );
}

function EditYoungDiariesModalModal({
  id,
  isOpen,
  toggle,
  onSave,
  diaryDetails,
}: EditYoungDiaryDetailProps) {
  const { t } = useTranslation();
  const [
    editYoungDiaryDetails,
    setEditYoungDiaryDetails,
    onSimpleValueChange,
    onObjectValueChange,
  ] = useInput<YoungDiaryEditTransfer>(diaryDetails.value);
  const [isAllValid, setIsAllValid] = useState<boolean>(true);
  const [backendMessages, setBackendMessages] = useState<string[] | null>(null);
  const [saveDiaryDetails, { loading: saving }] = useApiPut<{ messages: string[] }>(
    `/api/v1/group/young/${id}/diary/${diaryDetails?.id}`,
    (response) => {
      if (response.messages && response.messages.length > 0) {
        setBackendMessages(response.messages);
      } else {
        setBackendMessages(null);
        onSave();
        toggle();
      }
    }
  );

  useEffect(() => {
    if (isOpen) {
      setEditYoungDiaryDetails(cloneDeep(diaryDetails.value));
    }
  }, [isOpen, diaryDetails, setEditYoungDiaryDetails]);

  useEffect(() => {
    setIsAllValid(true);
    setBackendMessages(null);
  }, [editYoungDiaryDetails]);

  const submit = useCallback(() => {
    const isValid = isDiaryDetailsValid(editYoungDiaryDetails);

    if (isValid) {
      setBackendMessages(null);
      saveDiaryDetails(editYoungDiaryDetails);
    }
    setIsAllValid(isValid);
  }, [editYoungDiaryDetails, saveDiaryDetails]);

  const isInvalid = useCallback(
    (value: number | undefined) => (value && value > LIMIT) || false,
    []
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="modal-content-size"
      className="modal-align"
    >
      <ModalHeader toggle={toggle}>
        {`${t('add-diary-details')} - ${dateToStr(diaryDetails.actualDate)} ${t('gpd-age')}: ${
          diaryDetails.dayOfAge
        }`}
      </ModalHeader>
      <ModalBody>
        {saving && <Loader />}
        <Container fluid>
          <Row className="" />
        </Container>
        <Form className="form d-flex flex-column justify-content-center">
          <Row className="">
            <Col>
              <Row className="d-flex justify-content-center align-items-center">
                <h4>{t('diary-young-daily-details')}</h4>
              </Row>

              <Row>
                <Col>
                  <Row className="d-flex justify-content-center">
                    <b>{t('ygpd-0-dead')}</b>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('ygpt2-ps')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'deadPS'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={
                            isInvalid(editYoungDiaryDetails.deadPS) ||
                            !isDeadPSValuesValid(editYoungDiaryDetails)
                          }
                          defaultValue={editYoungDiaryDetails.deadPS}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('deadPS', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>
                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('ygpt2-gp')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'deadGP'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={
                            isInvalid(editYoungDiaryDetails.deadGP) ||
                            !isDeadGPValuesValid(editYoungDiaryDetails)
                          }
                          defaultValue={editYoungDiaryDetails.deadGP}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('deadGP', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <Row className="d-flex justify-content-center">
                    <b>{t('gpd-dissected')}</b>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('ygpt2-ps')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'dissectedPS'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={
                            isInvalid(editYoungDiaryDetails.dissectedPS) ||
                            !isDeadPSValuesValid(editYoungDiaryDetails)
                          }
                          defaultValue={editYoungDiaryDetails.dissectedPS}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('dissectedPS', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>
                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('ygpt2-gp')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'dissectedGP'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={
                            isInvalid(editYoungDiaryDetails.dissectedGP) ||
                            !isDeadGPValuesValid(editYoungDiaryDetails)
                          }
                          defaultValue={editYoungDiaryDetails.dissectedGP}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('dissectedGP', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <Row className="d-flex justify-content-center">
                    <b>{t('ygpd-0-dead-resp')}</b>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('ygpt2-ps')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'deadRespPS'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={
                            isInvalid(editYoungDiaryDetails.deadRespPS) ||
                            !isDeadPSValuesValid(editYoungDiaryDetails)
                          }
                          defaultValue={editYoungDiaryDetails.deadRespPS}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('deadRespPS', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>
                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('ygpt2-gp')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'deadRespGP'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={
                            isInvalid(editYoungDiaryDetails.deadRespGP) ||
                            !isDeadGPValuesValid(editYoungDiaryDetails)
                          }
                          defaultValue={editYoungDiaryDetails.deadRespGP}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('deadRespGP', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <Row className="d-flex justify-content-center">
                    <b>{t('gpd-dead-ere')}</b>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('ygpt2-ps')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'deadErePS'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={
                            isInvalid(editYoungDiaryDetails.deadErePS) ||
                            !isDeadPSValuesValid(editYoungDiaryDetails)
                          }
                          defaultValue={editYoungDiaryDetails.deadErePS}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('deadErePS', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>
                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('ygpt2-gp')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'deadEreGP'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={
                            isInvalid(editYoungDiaryDetails.deadEreGP) ||
                            !isDeadGPValuesValid(editYoungDiaryDetails)
                          }
                          defaultValue={editYoungDiaryDetails.deadEreGP}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('deadEreGP', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col sm={4}>
                  <Row className="d-flex justify-content-center">
                    <b>{t('ygpd-0-waste')}</b>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('ygpt2-ps')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'wastePS'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={isInvalid(editYoungDiaryDetails.wastePS)}
                          defaultValue={editYoungDiaryDetails.wastePS}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('wastePS', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>
                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('ygpt2-gp')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'wasteGP'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={isInvalid(editYoungDiaryDetails.wasteGP)}
                          defaultValue={editYoungDiaryDetails.wasteGP}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('wasteGP', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col sm={4} />
              </Row>

              <Row className="mt-3">
                <Col>
                  <Row className="d-flex justify-content-center align-items-center">
                    <h4>{t('diary-climate-details')}</h4>
                  </Row>

                  <Row>
                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('gpd-degrees')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'gpd-degrees'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={isInvalid(editYoungDiaryDetails?.indoorData?.temperature)}
                          defaultValue={editYoungDiaryDetails?.indoorData?.temperature}
                          onInput={(event) => {
                            roundAndRemove(event, true);
                          }}
                          onChange={onObjectValueChange(
                            'indoorData',
                            'temperature',
                            ValueType.NUMBER
                          )}
                        />
                      </Row>
                    </Col>

                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('gpd-rh')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'gpd-rh'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={isInvalid(editYoungDiaryDetails?.indoorData?.relativeHumidity)}
                          defaultValue={editYoungDiaryDetails?.indoorData?.relativeHumidity}
                          onInput={(event) => {
                            roundAndRemove(event, true);
                          }}
                          onChange={onObjectValueChange(
                            'indoorData',
                            'relativeHumidity',
                            ValueType.NUMBER
                          )}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col>
                  <Row className="d-flex justify-content-center align-items-center">
                    <h4>{t('add-comment')}</h4>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('gpd-comment')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="text"
                          name={'gpd-comment'}
                          min="0"
                          max={LIMIT}
                          defaultValue={editYoungDiaryDetails.comment}
                          onChange={onSimpleValueChange('comment', ValueType.STRING)}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <FeedDetails
            onFeedValueChange={onObjectValueChange}
            onChange={onSimpleValueChange}
            editDiaryDetails={editYoungDiaryDetails}
          />

          <HealthDetails
            onMedicineValueChange={onObjectValueChange}
            medicineDetails={editYoungDiaryDetails.medicine}
            isVaccinable
          />

          <Row className="mt-3">
            <Col>
              <Row className="d-flex justify-content-center">
                <h4>{t('ygpd-0-sold')}</h4>
              </Row>
              <Row>
                <Col className="d-flex flex-column align-items-center">
                  <Row>
                    <b>{t('ygpt2-ps')}</b>
                  </Row>
                  <Row className="w-100">
                    <Input
                      type="number"
                      name={'soldPS'}
                      min="0"
                      max={LIMIT}
                      className="text-right"
                      invalid={isInvalid(editYoungDiaryDetails.soldPS)}
                      defaultValue={editYoungDiaryDetails.soldPS}
                      onInput={roundAndRemove}
                      onChange={onSimpleValueChange('soldPS', ValueType.NUMBER)}
                    />
                  </Row>
                </Col>
                <Col className="d-flex flex-column align-items-center">
                  <Row>
                    <b>{t('ygpt2-gp')}</b>
                  </Row>
                  <Row className="w-100">
                    <Input
                      type="number"
                      name={'soldGP'}
                      min="0"
                      max={LIMIT}
                      className="text-right"
                      invalid={isInvalid(editYoungDiaryDetails.soldGP)}
                      defaultValue={editYoungDiaryDetails.soldGP}
                      onInput={roundAndRemove}
                      onChange={onSimpleValueChange('soldGP', ValueType.NUMBER)}
                    />
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row className="d-flex justify-content-center">
                <h4>{t('ygpd-0-own-repl')}</h4>
              </Row>
              <Row>
                <Col className="d-flex flex-column align-items-center">
                  <Row>
                    <b>{t('ygpt2-ps')}</b>
                  </Row>
                  <Row className="w-100">
                    <Input
                      type="number"
                      name={'ownReplacementPS'}
                      min="0"
                      max={LIMIT}
                      className="text-right"
                      invalid={isInvalid(editYoungDiaryDetails.ownReplacementPS)}
                      defaultValue={editYoungDiaryDetails.ownReplacementPS}
                      onInput={roundAndRemove}
                      onChange={onSimpleValueChange('ownReplacementPS', ValueType.NUMBER)}
                    />
                  </Row>
                </Col>
                <Col className="d-flex flex-column align-items-center">
                  <Row>
                    <b>{t('ygpt2-gp')}</b>
                  </Row>
                  <Row className="w-100">
                    <Input
                      type="number"
                      name={'ownReplacementGP'}
                      min="0"
                      max={LIMIT}
                      className="text-right"
                      invalid={isInvalid(editYoungDiaryDetails.ownReplacementGP)}
                      defaultValue={editYoungDiaryDetails.ownReplacementGP}
                      onInput={roundAndRemove}
                      onChange={onSimpleValueChange('ownReplacementGP', ValueType.NUMBER)}
                    />
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="pt-2">
            <Col>
              {!isAllValid && (
                <Alert color="danger" className="text-center">
                  {t('form-has-errors')}
                </Alert>
              )}
              {backendMessages && backendMessages.length > 0 && (
                <Alert color="danger" className="text-center">
                  {backendMessages.map((msg: string) => (
                    <li key={msg}>{t(msg)}</li>
                  ))}
                </Alert>
              )}
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={submit} className="mr-2">
          {t('save')}
        </Button>
        <Button color="primary" onClick={toggle}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditYoungDiariesModalModal;
