import React, { useState } from 'react';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Farm from '../../models/farm';
import { hasRole } from '../../helpers/authUtils';
import { useApiPut, useApiPost } from '../../hooks/useApi';
import StableFormModal from './stableFormModal';
import FarmFormModal from './farmFormModal';
import Loader from 'hyper/components/Loader';
import { UserRole } from 'models/userRole';

interface FarmDetailsProps {
  farm: Farm;
  onChange: () => void;
}

function FarmDetails({ farm, onChange }: FarmDetailsProps) {
  const [isStableOpen, setIsStableOpen] = useState<boolean>(false);
  const [isFarmOpen, setIsFarmOpen] = useState<boolean>(false);
  const [saveFarm, { loading: savingFarm }] = useApiPut(`/api/v1/farm/${farm.id}`, onChange);
  const [saveStable, { loading: savingStable }] = useApiPost(
    `/api/v1/farm/${farm.id}/stable`,
    onChange
  );
  const { t } = useTranslation();

  const toggleStable = () => setIsStableOpen(!isStableOpen);

  const toggleFarm = () => setIsFarmOpen(!isFarmOpen);

  return (
    <>
      {(savingFarm || savingStable) && <Loader />}
      <StableFormModal isOpen={isStableOpen} toggle={toggleStable} onSave={saveStable} />
      <FarmFormModal farm={farm} isOpen={isFarmOpen} toggle={toggleFarm} onSave={saveFarm} />
      <Card>
        <CardBody>
          <Row>
            <Col sm={8}>
              <h4 className="header-title mb-3">{t('farm-details')}</h4>
              <Row>
                <Col sm={2}>
                  <b>{t('name')}:</b>
                </Col>
                <Col>{farm.name}</Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <b>{t('address')}:</b>
                </Col>
                <Col>
                  {`${farm.street || ''} ${farm.zipCode || ''} ${farm.city || ''} ${
                    farm.country || ''
                  }`}
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <b>{t('email')}:</b>
                </Col>
                <Col>{farm.email}</Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <b>{t('phone')}:</b>
                </Col>
                <Col>{farm.phoneNumber}</Col>
              </Row>
            </Col>
            <Col sm={4}>
              <div className="text-center mt-sm-0 mt-3 text-sm-right">
                {hasRole(UserRole.OWNER) && (
                  <Button type="button" color="primary" className="mr-2" onClick={toggleFarm}>
                    <i className="mdi mdi-pencil-outline mr-1" /> {t('edit')}
                  </Button>
                )}
                {hasRole(UserRole.ADMIN) && (
                  <Button type="button" color="success" onClick={toggleStable}>
                    <i className="mdi mdi-plus mr-1" /> {t('new-stable')}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default FarmDetails;
