import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Badge, Button, Col, Row } from 'reactstrap';
import PageContainer from '../PageContainer';
import { DownloadExport } from './export/DownloadExport';
import MotherEditDiaries from 'components/grouppages/motherEditDiaries';
import MotherEditSmallTables from 'components/grouppages/motherEditSmallTables';
import { useApi, useApiGet } from 'hooks/useApi';
import Loader from 'hyper/components/Loader';
import EditMotherTransfer from 'models/grouppages/editMotherTransfer';
import {
  getMergedGPButton,
  getStableNames,
  isParentInMergedGrouppage,
} from 'components/grouppages/gpUtil';
import MultiStableModal from 'components/grouppages/modal/common/multiStableModal';
import { GroupMarker } from 'models/grouppages/groupMarker';
import Stable from 'models/stable';
import { useIsMounted } from 'hooks/useIsMounted';
import MotherDiaryEditTransfer from 'models/grouppages/motherDiaryEditTransfer';
import Farm from 'models/farm';

function calcForbiddenStables(data: EditMotherTransfer | undefined): Stable[] {
  const result = [] as Stable[];
  if (data && data.gpMembers && data.gpMembers.length > 0) {
    for (const member of data.gpMembers) {
      if (member.groupId !== data.parentId && member.stables && member.groupId !== data.id) {
        member.stables.forEach((stable) => result.push(stable));
      }
    }
  }
  return result;
}

interface MotherListRouteParams {
  id: string;
}

function MotherList() {
  const { id } = useParams<MotherListRouteParams>();
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [isSaved, setIsSaved] = useState<boolean>(true);
  const [group, setGroup] = useState<EditMotherTransfer>();
  const { get, loading } = useApi();
  const { put, loading: saving } = useApi();
  const [groupTmp, setGroupTmp] = useState<EditMotherTransfer>();
  const [backendMessages, setBackendMessages] = useState<string[]>();

  const loadGroup = useCallback(() => {
    get(`/api/v1/group/mother/${id}`, (data: EditMotherTransfer) => {
      if (isMounted.current) {
        setGroupTmp(data);
      }
    });
  }, [get, id, isMounted]);
  const [reloadCounter, setReloadCounter] = useState(1);
  useEffect(() => {
    loadGroup();
  }, [loadGroup, reloadCounter]);

  const { data: farms, loading: loadingFarm } = useApiGet<Farm[]>('/api/v1/farms');

  const onResponse = useCallback(
    (response: { messages: string[] }) => {
      if (isMounted.current) {
        if (response.messages && response.messages.length > 0) {
          setBackendMessages(response.messages);
        } else {
          setReloadCounter((x) => x + 1);
          setIsSaved(true);
        }
      }
    },
    [isMounted]
  );

  const openGrouppage = useCallback(() => {
    put(`/api/v1/group/mother/${id}/reopen`, onResponse);
  }, [id, onResponse, put]);
  const closeGrouppage = useCallback(
    (diaryId: number) => {
      put(`/api/v1/group/mother/${id}/diary/${diaryId}/close`, onResponse);
    },
    [id, onResponse, put]
  );
  const saveDiaryDetails = useCallback(
    (diaryId: number, diaryData: MotherDiaryEditTransfer) => {
      setIsSaved(false);
      put(`/api/v1/group/mother/${id}/diary/${diaryId}`, onResponse, onResponse, diaryData);
    },
    [id, onResponse, put]
  );

  const [forbiddenStables, setForbiddenStables] = useState<Stable[]>([]);

  const [isSelectStableOpen, setIsSelectStableOpen] = useState<boolean>(false);

  const [isParent, setIsParent] = useState(false);

  const isWorking = useCallback(() => loading || saving || loadingFarm, [
    loading,
    saving,
    loadingFarm,
  ]);

  const refresh = useCallback(() => {
    loadGroup();
  }, [loadGroup]);

  const toggleSelectStableModal = useCallback(() => {
    setIsSelectStableOpen(!isSelectStableOpen);
  }, [isSelectStableOpen]);

  useEffect(() => {
    setGroup(groupTmp);
    setForbiddenStables(calcForbiddenStables(groupTmp));
    setIsParent(isParentInMergedGrouppage(groupTmp));
  }, [groupTmp]);

  const getTitle = useCallback(() => t(`${isParent ? 'merged-' : ''}grouppage-edit-mother`), [
    isParent,
    t,
  ]);

  return (
    <>
      {group && (
        <MultiStableModal
          id={group.id}
          type={GroupMarker.MOTHER}
          toggle={toggleSelectStableModal}
          isMulti={isParent}
          stables={group.stables}
          forbiddenStables={forbiddenStables}
          isOpen={isSelectStableOpen}
          onSave={refresh}
          farms={farms}
        />
      )}
      <PageContainer
        title={
          <>
            <Row className="my-2 clearfix align-items-center d-flex">
              <Col sm="auto" className="align-items-center d-flex">
                <h4 className="float-left pr-3">{`${getTitle()} : ${group?.generatedName}`}</h4>
                {group?.endDate && (
                  <h4 className="float-left pr-3">
                    <Badge color="secondary" className="ml-3">
                      {t('closed-grouppage')}
                    </Badge>
                  </h4>
                )}
                <h4 className="float-left pr-3">
                  {`${t('grouppage-stable')} : ${getStableNames(group)}`}
                </h4>
                {!isParent && (
                  <Button
                    id="stables-edit-button"
                    color="primary"
                    className="btn btn-sm p-0"
                    onClick={() => {
                      toggleSelectStableModal();
                    }}
                  >
                    <i className="mdi mdi-pencil p-1" />
                  </Button>
                )}
              </Col>
              <Col className="d-flex justify-content-end mr-2">
                <DownloadExport
                  id={group?.id}
                  spreadSheetName={'grouppage-edit-mother'}
                  type={`${isParent ? 'merged-' : ''}mother`}
                  isSummary={false}
                  generatedName={group?.generatedName}
                />
              </Col>
            </Row>
            <Row className="mt-1 mb-2 clearfix align-items-center d-flex">
              <Col sm="auto" className="align-items-left d-flex">
                {group &&
                  group.gpMembers &&
                  group.gpMembers.map((member) =>
                    getMergedGPButton(member, group.id, group.parentId, 'mother')
                  )}
              </Col>
            </Row>
          </>
        }
      >
        {isWorking() && <Loader />}

        <MotherEditSmallTables transfer={group} refresh={refresh} farms={farms} />

        <MotherEditDiaries
          transfer={group}
          refresh={refresh}
          onOpenGroup={openGrouppage}
          onCloseGroup={closeGrouppage}
          onSaveDiaryDetails={saveDiaryDetails}
          backendMessages={backendMessages}
          isWorking={isWorking()}
          isSaved={isSaved}
        />
      </PageContainer>
    </>
  );
}

export default MotherList;
