import { isUserAuthenticated, getLoggedInUser } from '../authUtils';

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
  options = {
    ...options,
    headers: {
      ...options.headers,
      Accept: 'application/json',
    },
  };

  if (isUserAuthenticated()) {
    options.headers['Authorization'] = `Bearer ${getLoggedInUser().token}`;
  }

  return fetch(process.env.REACT_APP_API_URL + url, options)
    .then((response) => {
      if (response.status !== 200) {
        throw response;
      }

      return response.json().catch((e) => {
        return {};
      });
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      throw error;
    });
};

const sendRequest = (url, options = {}) => {
  if (isUserAuthenticated()) {
    options.headers['Authorization'] = `Bearer ${getLoggedInUser().token}`;
  }

  return fetch(process.env.REACT_APP_API_URL + url, options)
    .then((response) => {
      if (response.status !== 200) {
        throw response;
      }

      return response;
    })
    .catch((error) => {
      throw error;
    });
};

const fetchText = (url, options = {}) => {
  if (isUserAuthenticated()) {
    options.headers['Authorization'] = `Bearer ${getLoggedInUser().token}`;
  }

  return fetch(process.env.REACT_APP_API_URL + url, options)
    .then((response) => {
      if (response.status !== 200) {
        throw response;
      }

      return response.text().catch((e) => {
        return '';
      });
    })
    .then((text) => {
      return text;
    })
    .catch((error) => {
      throw error;
    });
};

const uploadFile = (file, url) => {
  const headers = {
    Accept: 'application/json',
  };

  const body = new FormData();

  body.append('file', file);

  return fetchJSON(url, { headers, body, method: 'POST' });
};

export { fetchJSON, fetchText, sendRequest, uploadFile };
