import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import '../../../../css/modal.css';
import { TFunction } from 'i18next';
import { dateStr, isDateBetweenRange, YOUNG_AGE_MAX, YOUNG_AGE_MIN } from '../../gpUtil';
import ModalError from '../../modalError';
import { useApi, useApiGet } from 'hooks/useApi';
import Loader from 'hyper/components/Loader';
import OriginYoungTransfer from 'models/grouppages/originYoungTransfer';
import { useInput } from 'hooks/useInput';
import { ValueType } from 'models/valueType';
import { isNullOrInvalid, roundAndRemove } from 'helpers/rabbit';
import Farm from 'models/farm';
import GroupNameTransfer from 'models/grouppages/groupNameTransfer';
import { getUserFarm } from 'helpers/authUtils';
import YoungDiaryElementTransfer from 'models/grouppages/youngDiaryElementTransfer';
import { GroupMarker } from 'models/grouppages/groupMarker';
import Datepicker from 'hyper/components/Datepicker';

const NUM_LIMIT = 40000;
const WEIGHT_LIMIT = 90000;

function isNumValid(value: number, limit?: number, aboveZero = false) {
  const lower = aboveZero ? 0 < value : 0 <= value;
  const realLimit = limit === undefined ? NUM_LIMIT : limit;
  return lower && value <= realLimit;
}

function isWeightValid(value: number) {
  return 0 <= value && value <= WEIGHT_LIMIT;
}

function isSameFilled(num: number, weight: number) {
  return (num === 0 && weight === 0) || (0 < num && 0 < weight);
}

function filterGroupNameOptions(
  groupNames: GroupNameTransfer[] | undefined,
  generatedName: string,
  isMoving: boolean
): GroupNameTransfer[] | undefined {
  if (!isMoving || !groupNames || groupNames.length < 1) return groupNames;
  const result = groupNames.filter((group) => group.formattedName !== generatedName);
  return result
    .filter((group) => group.groupMarker === GroupMarker.FATTENING)
    .sort((a, b) => new Date(b.diaryStartDate).getTime() - new Date(a.diaryStartDate).getTime());
}

function getGroupByName(
  groupNameOptions: GroupNameTransfer[] | undefined,
  groupName: string
): GroupNameTransfer | undefined {
  if (!groupNameOptions) return undefined;
  const realName = groupName.trim();
  if (realName.length < 1) return undefined;
  return groupNameOptions?.find((option) => option.formattedName === realName);
}

function getGroupLifeByGroup(group: GroupNameTransfer | undefined): string[] | undefined {
  if (!group) return undefined;
  return [dateStr(group.diaryStartDate), dateStr(group.diaryEndDate)];
}

function getGroupLife(
  origin: OriginYoungTransfer | undefined,
  groupNameOptions: GroupNameTransfer[] | undefined
): string[] | undefined {
  if (!origin || !groupNameOptions) return undefined;
  const group = getGroupByName(groupNameOptions, origin.groupName || '');
  return getGroupLifeByGroup(group);
}

function isValidDateByGroup(
  origin: OriginYoungTransfer | undefined,
  group: GroupNameTransfer | undefined,
  isMoving: boolean
): boolean {
  if (!isMoving || !origin || !group) return true;
  if (!origin.groupId || !origin.arrivedDate) return false;
  const dates = getGroupLifeByGroup(group);
  if (!dates || dates.length < 2) return false;
  const when = dateStr(origin.arrivedDate);
  return dates[0] <= when && when <= dates[1];
}

function isValidDate(
  origin: OriginYoungTransfer | undefined,
  groupNameOptions: GroupNameTransfer[] | undefined,
  isMoving: boolean
): boolean {
  if (!isMoving || !origin || !groupNameOptions) return true;
  if (!origin.groupId) return false;
  const group = getGroupByName(groupNameOptions, origin.groupName || '');
  if (!group || group.groupId !== origin.groupId) return false;
  return isValidDateByGroup(origin, group, isMoving);
}

function isAgeValid(origin: OriginYoungTransfer): boolean {
  const age = origin.age ?? YOUNG_AGE_MIN;
  return YOUNG_AGE_MIN <= age && age <= YOUNG_AGE_MAX;
}

function getFirstNonNull(last: number | undefined, max: number | undefined, limit: number) {
  return last === undefined ? max || limit : last;
}

function getOriginNumLimit(
  arriveDate: Date,
  allOrigins: OriginYoungTransfer[] | undefined,
  isPS: boolean
): number | undefined {
  const when = dateStr(new Date(arriveDate));
  const selected = allOrigins
    ? allOrigins.filter((origin) => dateStr(origin.arrivedDate) <= when)
    : undefined;
  return selected
    ? selected.reduce((acc, origin) => acc + (isPS ? origin.numPS : origin.numGP), 0)
    : undefined;
}

function getDiaryForLimit(
  arriveDate: Date,
  diaries: YoungDiaryElementTransfer[] | undefined,
  allOrigins: OriginYoungTransfer[] | undefined,
  maxNumPS: number | undefined,
  maxNumGP: number | undefined
): YoungDiaryElementTransfer {
  let lastNumPS = undefined;
  let lastNumGP = undefined;
  const when = dateStr(new Date(arriveDate));
  const selected = diaries
    ? diaries.filter((diary) => dateStr(diary.actualDate) <= when)
    : undefined;
  if (selected && selected.length > 0) {
    for (let i = selected.length - 1; i >= 0; i -= 1) {
      const diary = selected[i];
      if (
        lastNumPS === undefined &&
        diary.dayEndNumPS !== undefined &&
        diary.dayEndNumPS !== null
      ) {
        lastNumPS = diary.dayEndNumPS;
      }
      if (
        lastNumGP === undefined &&
        diary.dayEndNumGP !== undefined &&
        diary.dayEndNumGP !== null
      ) {
        lastNumGP = diary.dayEndNumGP;
      }
      if (lastNumPS && lastNumGP) break;
    }
  }
  if (lastNumPS === undefined) lastNumPS = getOriginNumLimit(arriveDate, allOrigins, true);
  if (lastNumGP === undefined) lastNumGP = getOriginNumLimit(arriveDate, allOrigins, false);
  return {
    dayEndNumPS: getFirstNonNull(lastNumPS, maxNumPS, NUM_LIMIT),
    dayEndNumGP: getFirstNonNull(lastNumGP, maxNumGP, NUM_LIMIT),
  } as YoungDiaryElementTransfer;
}

function isDetailsValid(
  startDate: Date | undefined,
  endDate: Date | undefined,
  originDetails: OriginYoungTransfer,
  groupNameOptions: GroupNameTransfer[] | undefined,
  isMoving: boolean,
  maxNumPS: number | undefined,
  maxNumGP: number | undefined,
  diaries: YoungDiaryElementTransfer[] | undefined,
  allOrigins: OriginYoungTransfer[] | undefined
): string[] {
  const invalids = [];
  if (!isAgeValid(originDetails)) invalids.push('age');
  if (!isDateBetweenRange(originDetails.arrivedDate, startDate, endDate)) invalids.push('date');
  if (!isValidDate(originDetails, groupNameOptions, isMoving)) invalids.push('otherGroup');
  let numLimitPS = NUM_LIMIT;
  let numLimitGP = NUM_LIMIT;
  if (isMoving) {
    const { dayEndNumPS, dayEndNumGP } = getDiaryForLimit(
      originDetails.arrivedDate,
      diaries,
      allOrigins,
      maxNumPS,
      maxNumGP
    );
    numLimitPS = dayEndNumPS || NUM_LIMIT;
    numLimitGP = dayEndNumGP || NUM_LIMIT;
  }
  if (!isNumValid(originDetails?.numPS, numLimitPS, false)) invalids.push('numPS');
  if (!isNumValid(originDetails?.numGP, numLimitGP, false)) invalids.push('numGP');
  if (!isWeightValid(originDetails?.weightPS)) invalids.push('weightPS');
  if (!isSameFilled(originDetails?.numPS, originDetails?.weightPS)) {
    invalids.push('numPS');
    invalids.push('weightPS');
  }
  if (!isWeightValid(originDetails?.weightGP)) invalids.push('weightGP');
  if (!isSameFilled(originDetails?.numGP, originDetails?.weightGP)) {
    invalids.push('numGP');
    invalids.push('weightGP');
  }
  if (originDetails?.numPS + originDetails?.numGP === 0) {
    invalids.push('numPS');
    invalids.push('numGP');
  }
  return invalids;
}

function handleSubmit(
  startDate: string | undefined,
  endDate: string | undefined,
  maxNumPS: number | undefined,
  maxNumGP: number | undefined,
  editOriginDetails: OriginYoungTransfer,
  groupNameOptions: GroupNameTransfer[] | undefined,
  setBackendMessages: React.Dispatch<React.SetStateAction<string[] | null>>,
  isMoving: boolean,
  doSave: (originData: OriginYoungTransfer) => void,
  setIsAllValid: React.Dispatch<React.SetStateAction<boolean>>,
  diaries: YoungDiaryElementTransfer[] | undefined,
  allOrigins: OriginYoungTransfer[] | undefined
) {
  const invalids = isDetailsValid(
    startDate ? new Date(startDate) : new Date(),
    endDate ? new Date(endDate) : new Date(),
    editOriginDetails,
    groupNameOptions,
    isMoving,
    maxNumPS,
    maxNumGP,
    diaries,
    allOrigins
  );
  const isValid = invalids.length === 0;

  if (isValid) {
    setBackendMessages(null);
    doSave(editOriginDetails);
  }
  setIsAllValid(isValid);
}

function handleWeaningDateChange(
  arrivedDate: Date,
  editOriginDetails: OriginYoungTransfer,
  setEditOriginDetails: React.Dispatch<React.SetStateAction<OriginYoungTransfer>>,
  groupNameOptions: GroupNameTransfer[] | undefined,
  isMoving: boolean,
  setValidDateAndGroup: React.Dispatch<React.SetStateAction<boolean>>
) {
  const date = new Date(dateStr(arrivedDate));
  const origin = { ...editOriginDetails, arrivedDate: date };
  setEditOriginDetails(origin);
  if (!isMoving) return;

  const groupData = getGroupByName(groupNameOptions, origin.groupName || '');
  setValidDateAndGroup(isValidDateByGroup(origin, groupData, isMoving));
}

function checkFarm(
  farms: Farm[] | null | undefined,
  farmName: string | undefined | null,
  userFarmId: number | null
): string | null {
  if (farms && farms.length > 0) {
    const first = farms[0];
    if (farmName && first.name === farmName.trim()) return first.name;
    if (userFarmId && first.id === userFarmId) return first.name;
  }
  return null;
}

function calcDataFormOriginal(
  originDetails: OriginYoungTransfer,
  isMoving: boolean,
  farms: Farm[] | null | undefined,
  defaultFarmId: number | null
): OriginYoungTransfer {
  let originData = { ...originDetails };
  if (isMoving) {
    const nextFarmName = checkFarm(farms, null, defaultFarmId);
    if (nextFarmName) originData = { ...originData, farmName: nextFarmName };
    const { numPS, weightPS, numGP, weightGP } = originData;
    if (numPS) originData = { ...originData, numPS: Math.abs(numPS) };
    if (numGP) originData = { ...originData, numGP: Math.abs(numGP) };
    if (weightPS) originData = { ...originData, weightPS: Math.abs(weightPS) };
    if (weightGP) originData = { ...originData, weightGP: Math.abs(weightGP) };
  }
  return originData;
}

function handleGroupNameChange(
  event: React.ChangeEvent<HTMLInputElement>,
  groupNameOptions: GroupNameTransfer[] | undefined,
  onSimpleValueChange: (
    key: React.ReactText,
    valueType: ValueType
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  editOriginDetails: OriginYoungTransfer,
  setEditOriginDetails: React.Dispatch<React.SetStateAction<OriginYoungTransfer>>,
  isMoving: boolean,
  setGroupLife: React.Dispatch<React.SetStateAction<string[] | undefined>>,
  setValidDateAndGroup: React.Dispatch<React.SetStateAction<boolean>>
) {
  const nextName = event.target.value.trim();
  const groupData = getGroupByName(groupNameOptions, nextName);
  onSimpleValueChange('groupName', ValueType.STRING)(event);
  const nextGroupId = groupData?.groupId || undefined;
  const origin = { ...editOriginDetails, groupName: nextName, groupId: nextGroupId };
  setEditOriginDetails(origin);

  if (!isMoving) return;

  const dates = getGroupLifeByGroup(groupData);
  setGroupLife(dates);
  setValidDateAndGroup(isValidDateByGroup(origin, groupData, isMoving));
}

function opening(
  isOpen: boolean,
  originDetails: OriginYoungParams,
  defaultFarmId: number | null,
  farms: Farm[] | null | undefined,
  groupNameOptions: GroupNameTransfer[] | undefined,
  endDate: string | undefined,
  setEditOriginDetails: (value: React.SetStateAction<OriginYoungTransfer>) => void,
  setGroupLife: (value: React.SetStateAction<string[] | undefined>) => void,
  setValidDateAndGroup: (value: React.SetStateAction<boolean>) => void,
  setInitialDate: (value: React.SetStateAction<Date>) => void
) {
  if (isOpen) {
    const nextOrigin = calcDataFormOriginal(
      originDetails.origin,
      originDetails.isMoving,
      farms,
      defaultFarmId
    );
    setEditOriginDetails(nextOrigin);
    setGroupLife(getGroupLife(nextOrigin, groupNameOptions));
    setValidDateAndGroup(isValidDate(nextOrigin, groupNameOptions, originDetails.isMoving));
    let when = dateStr(new Date());
    if (endDate && endDate < when) {
      when = endDate;
    }
    setInitialDate(new Date(when));
  } else {
    setGroupLife(undefined);
    setValidDateAndGroup(true);
  }
}

function getDateInterval(dates: string[] | undefined, validDate: boolean): JSX.Element {
  if (!dates || dates.length < 2) return <p />;
  const className = `text-info font-weight-bold ${validDate ? '' : ' text-danger'}`;
  return (
    <p className={className} style={{ fontSize: '0.8rem' }}>
      <span>{`${dates[0]}`}</span>
      <span className="mx-2"> - </span>
      <span>{`${dates[1]}`}</span>
    </p>
  );
}

function getMovingModalPart(
  originDetails: OriginYoungTransfer,
  initialDate: Date,
  startDate: string | undefined,
  endDate: string | undefined,
  getDayClass: (date: Date) => string,
  onWeaningDateChange: (arrivedDate: Date) => void,
  tYgpt3Farm: string,
  tYgpt3Group: string,
  defaultFarmName: string,
  generatedName: string,
  textTrim: (event: React.FormEvent<HTMLInputElement>) => void,
  handleGroupNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  groupNameOptions: GroupNameTransfer[] | undefined,
  groupLife: string[] | undefined,
  validDateAndGroup: boolean,
  t: TFunction
): JSX.Element {
  const when = originDetails?.arrivedDate
    ? new Date(originDetails?.arrivedDate)
    : new Date(initialDate);
  const dateClassName = `ml-1${validDateAndGroup ? '' : ' text-danger'}`;
  return (
    <>
      <Row className="d-flex justify-content-center">
        <Col className={dateClassName} sm="4">
          <b>{t('gpd-date')}</b>
          <span className="text-danger">*</span>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mt-1">
        <Col sm="4">
          <Datepicker
            startDate={when}
            dateFormat="yyyy-MM-dd"
            minDate={startDate && new Date(startDate)}
            maxDate={endDate && new Date(endDate)}
            dayClassName={getDayClass}
            onDateChange={onWeaningDateChange}
            invalid={!validDateAndGroup}
          />
        </Col>
      </Row>

      <Row className="d-flex justify-content-left mt-3">
        <Col sm="2">
          <div className="text-right mr-2">
            <b>{t('move-origin-from')}</b>
          </div>
        </Col>
        <Col className="ml-1" sm="4">
          <b>{tYgpt3Farm}</b>
        </Col>
        <Col className="ml-1" sm="4">
          <b>{tYgpt3Group}</b>
        </Col>
      </Row>
      <Row className="d-flex justify-content-left mt-1 mb-3">
        <Col sm="2" />
        <Col sm="4">
          <Input
            type="text"
            name={'farmFrom'}
            data-cy="origin-from-farm"
            value={defaultFarmName}
            disabled
          />
        </Col>
        <Col sm="4">
          <Input
            type="text"
            name={'groupFrom'}
            data-cy="origin-from-group"
            value={generatedName}
            disabled
          />
        </Col>
      </Row>

      <Row className="d-flex justify-content-left">
        <Col sm="2">
          <div className="text-right mr-2">
            <b>{t('move-origin-to')}</b>
          </div>
        </Col>
        <Col className="ml-1" sm="4">
          <b>{tYgpt3Farm}</b>
        </Col>
        <Col className="ml-1" sm="4">
          <b>{tYgpt3Group}</b>
          <span className="text-danger">*</span>
        </Col>
      </Row>
      <Row className="d-flex justify-content-left mt-1">
        <Col sm="2" />
        <Col sm="4">
          <Input
            type="text"
            name={'farm'}
            data-cy="origin-to-farm"
            value={defaultFarmName}
            disabled
          />
        </Col>
        <Col sm="4">
          <Input
            type="text"
            name={'group'}
            data-cy="origin-to-group"
            defaultValue={originDetails.groupName || ''}
            onInput={textTrim}
            autoComplete="off"
            onChange={handleGroupNameChange}
            list="select-list-groupname-id"
            invalid={!validDateAndGroup}
          />
          <datalist id="select-list-groupname-id">
            {groupNameOptions?.map((groupName, idx) => (
              <option value={groupName.formattedName} key={idx} />
            ))}
          </datalist>
        </Col>
      </Row>
      <Row className="d-flex justify-content-left mt-1">
        <Col sm="6" />
        <Col sm="4">
          <div className="text-center">{getDateInterval(groupLife, validDateAndGroup)}</div>
        </Col>
      </Row>
    </>
  );
}

function getDefaultModalPart(
  originDetails: OriginYoungTransfer,
  handleFarmNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  farms: Farm[] | null | undefined,
  textTrim: (event: React.FormEvent<HTMLInputElement>) => void,
  handleGroupNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onSimpleValueChange: (
    key: React.ReactText,
    valueType: ValueType
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  groupNameOptions: GroupNameTransfer[] | undefined,
  t: TFunction
): JSX.Element {
  return (
    <>
      <Row className="d-flex justify-content-center">
        <Col className="ml-1" sm="4">
          <b> {t('ygpt3-farm')}</b>
        </Col>
        <Col className="ml-1" sm="4">
          <b>{t('ygpt3-group')}</b>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mt-1">
        <Col sm="4">
          <Input
            type="text"
            name={'farm'}
            data-cy="origin-from-farm"
            value={originDetails.farmName || ''}
            onInput={textTrim}
            autoComplete="off"
            onChange={handleFarmNameChange}
            list="select-list-farm-id"
          />
          <datalist id="select-list-farm-id">
            {farms && <option value={farms[0].name} key={'farm_name'} />}
          </datalist>
        </Col>
        <Col sm="4">
          <Input
            type="text"
            name={'group'}
            data-cy="origin-from-group"
            value={originDetails.groupName || ''}
            onInput={textTrim}
            autoComplete="off"
            onChange={handleGroupNameChange}
            list="select-list-groupname-id"
          />
          <datalist id="select-list-groupname-id">
            {groupNameOptions?.map((groupName, idx) => (
              <option value={groupName.formattedName} key={idx} />
            ))}
          </datalist>
        </Col>
        <Col sm={8}>
          <p className="text-info m-1">{t('origin-farm-hint')}</p>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center mt-2">
        <Col className="ml-1" sm="4">
          <b> {t('ygpt3-age')}</b>
        </Col>
        <Col sm="4" />
      </Row>
      <Row className="d-flex justify-content-center mt-1">
        <Col sm="4">
          <Input
            type="number"
            name={'age'}
            data-cy="origin-age"
            className="text-right"
            min={YOUNG_AGE_MIN}
            max={YOUNG_AGE_MAX}
            invalid={isNullOrInvalid(originDetails.age, YOUNG_AGE_MIN, YOUNG_AGE_MAX)}
            defaultValue={originDetails?.age}
            onInput={roundAndRemove}
            onChange={onSimpleValueChange('age', ValueType.NUMBER)}
          />
        </Col>
        <Col sm="4" />
      </Row>
    </>
  );
}

function isNumberValid(
  isMoving: boolean,
  originDetails: OriginYoungTransfer,
  diaries: YoungDiaryElementTransfer[] | undefined,
  allOrigins: OriginYoungTransfer[] | undefined,
  isPS: boolean
): boolean {
  let limit = NUM_LIMIT;
  if (isMoving) {
    const { dayEndNumPS, dayEndNumGP } = getDiaryForLimit(
      originDetails.arrivedDate,
      diaries,
      allOrigins,
      undefined,
      undefined
    );
    limit = (isPS ? dayEndNumPS : dayEndNumGP) || 0;
  }
  return isNumValid(isPS ? originDetails?.numPS : originDetails?.numGP, limit, false);
}

function getCommonModalPart(
  isMoving: boolean,
  originDetails: OriginYoungTransfer,
  diaries: YoungDiaryElementTransfer[] | undefined,
  allOrigins: OriginYoungTransfer[] | undefined,
  onSimpleValueChange: (
    key: React.ReactText,
    valueType: ValueType
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  t: TFunction
): JSX.Element {
  return (
    <>
      <Row className="d-flex justify-content-center mt-3">
        <Col className="ml-1" sm="4">
          <b>{t('ygpt3-num')}</b>
        </Col>
        <Col className="ml-1" sm="4">
          <b>{t('ygpt3-sum-weight')}</b>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col xs="2">
          <div className="text-right align-middle mt-1">
            <b>{t('ygpt2-ps')}</b>
          </div>
        </Col>
        <Col sm="4">
          <Input
            type="number"
            name={'numPS'}
            data-cy="origin-num-ps"
            className="text-right"
            min="0"
            max={NUM_LIMIT}
            invalid={
              !(
                isNumberValid(isMoving, originDetails, diaries, allOrigins, true) &&
                isSameFilled(originDetails?.numPS, originDetails?.weightPS) &&
                originDetails?.numPS + originDetails?.numGP !== 0
              )
            }
            defaultValue={originDetails?.numPS}
            onInput={roundAndRemove}
            onChange={onSimpleValueChange('numPS', ValueType.NUMBER)}
          />
        </Col>
        <Col sm="4">
          <Input
            type="number"
            name={'weightPS'}
            data-cy="origin-weight-ps"
            className="text-right"
            min="0"
            max={WEIGHT_LIMIT}
            invalid={
              !(
                isWeightValid(originDetails?.weightPS) &&
                isSameFilled(originDetails?.numPS, originDetails?.weightPS)
              )
            }
            defaultValue={originDetails?.weightPS}
            onInput={roundAndRemove}
            onChange={onSimpleValueChange('weightPS', ValueType.NUMBER)}
          />
        </Col>
      </Row>
      <Row className="mt-1">
        <Col xs="2">
          <div className="text-right align-middle mt-1">
            <b>{t('ygpt2-gp')}</b>
          </div>
        </Col>
        <Col className="text-right" sm="4">
          <Input
            type="number"
            name={'numGP'}
            data-cy="origin-num-gp"
            className="text-right"
            min="0"
            max={NUM_LIMIT}
            invalid={
              !(
                isNumberValid(isMoving, originDetails, diaries, allOrigins, false) &&
                isSameFilled(originDetails?.numGP, originDetails?.weightGP) &&
                originDetails?.numPS + originDetails?.numGP !== 0
              )
            }
            defaultValue={originDetails?.numGP}
            onInput={roundAndRemove}
            onChange={onSimpleValueChange('numGP', ValueType.NUMBER)}
          />
        </Col>
        <Col sm="4">
          <Input
            type="number"
            name={'weightGP'}
            data-cy="origin-weight-gp"
            className="text-right"
            min="0"
            max={WEIGHT_LIMIT}
            invalid={
              !(
                isWeightValid(originDetails?.weightGP) &&
                isSameFilled(originDetails?.numGP, originDetails?.weightGP)
              )
            }
            defaultValue={originDetails?.weightGP}
            onInput={roundAndRemove}
            onChange={onSimpleValueChange('weightGP', ValueType.NUMBER)}
          />
        </Col>
      </Row>
    </>
  );
}

function calcOriginData(
  originData: OriginYoungTransfer,
  defaultFarmId: number | null,
  farms: Farm[] | null | undefined,
  isMoving: boolean,
  isMovingFrom: boolean
): OriginYoungTransfer {
  const { farmName, groupName } = originData;
  if (farmName) originData = { ...originData, farmName: farmName.trim() };
  if (groupName) originData = { ...originData, groupName: groupName.trim() };
  if (isMoving && (isMovingFrom || !originData.id)) {
    const { farmName } = originData;
    if (!farmName) {
      const nextFarmName = checkFarm(farms, null, defaultFarmId);
      if (nextFarmName) originData = { ...originData, farmName: nextFarmName };
    }
    const { numPS, numGP, weightPS, weightGP } = originData;
    if (numPS) originData = { ...originData, numPS: -1 * Math.abs(originData.numPS) };
    if (numGP) originData = { ...originData, numGP: -1 * Math.abs(originData.numGP) };
    if (weightPS) originData = { ...originData, weightPS: -1 * Math.abs(originData.weightPS) };
    if (weightGP) originData = { ...originData, weightGP: -1 * Math.abs(originData.weightGP) };
  }
  return originData;
}

function handleSave(
  originDetails: OriginYoungTransfer,
  defaultFarmId: number | null,
  farms: Farm[] | null | undefined,
  isMoving: boolean,
  isMovingSource: boolean,
  groupId: number,
  saveOrigin: (groupId: number, originId: number, data: OriginYoungTransfer) => void,
  createOrigin: (groupId: number, data: OriginYoungTransfer) => void
) {
  originDetails = calcOriginData(originDetails, defaultFarmId, farms, isMoving, isMovingSource);
  originDetails.id
    ? saveOrigin(groupId, originDetails.id, originDetails)
    : createOrigin(groupId, originDetails);
}

function textTrimming(event: React.FormEvent<HTMLInputElement>): void {
  const suffixSpace = event.currentTarget.value.endsWith(' ');
  let trimmed = event.currentTarget.value.trim();
  if (trimmed !== '' && suffixSpace) trimmed = `${trimmed} `;
  event.currentTarget.value = trimmed;
}

function habdleError(
  error: any,
  setBackendMessages: React.Dispatch<React.SetStateAction<string[] | null>>
) {
  if (error.status && error.status === 405) return setBackendMessages(['SAVING_ERROR']);
  if (Array.isArray(error)) return setBackendMessages(error);
  if (`${error}`.includes('NetworkError')) return setBackendMessages(['NETWORK_ERROR']);
  setBackendMessages(['SAVING_ERROR']);
}

export interface OriginYoungParams {
  origin: OriginYoungTransfer;
  isMoving: boolean;
  isMovingSource: boolean;
  maxNumPS?: number;
  maxNumGP?: number;
}

interface EditYoungOriginProps {
  groupId: number;
  generatedName: string;
  startDate?: string;
  endDate?: string;
  isOpen: boolean;
  originDetails: OriginYoungParams;
  allOrigins: OriginYoungTransfer[] | undefined;
  diaries?: YoungDiaryElementTransfer[];
  onSave: () => void;
  toggle: () => void;
  farms: Farm[] | null | undefined;
}

function EditYoungOriginModal({
  groupId,
  generatedName,
  startDate,
  endDate,
  isOpen,
  originDetails,
  allOrigins,
  diaries,
  onSave,
  toggle,
  farms,
}: EditYoungOriginProps) {
  const { t } = useTranslation();
  const { data: groupNames, loading: groupNameLoading } = useApiGet<GroupNameTransfer[]>(
    '/api/v1/groups/name'
  );
  const [groupNameOptions, setGroupNameOptions] = useState<GroupNameTransfer[] | undefined>();
  const [editOriginDetails, setEditOriginDetails, onSimpleValueChange] = useInput<
    OriginYoungTransfer
  >({
    ...originDetails.origin,
  });
  const [isAllValid, setIsAllValid] = useState<boolean>(true);
  const [backendMessages, setBackendMessages] = useState<string[] | null>(null);
  const { put, post, loading: saving } = useApi();

  const defaultFarmId = getUserFarm();
  const [defaultFarmName, setDefaultFarmName] = useState('');
  const [isMoving, setIsMoving] = useState<boolean>(false);
  const [isMovingSource, setIsMovingSource] = useState<boolean>(false);
  const [groupLife, setGroupLife] = useState<string[]>();
  const [validDateAndGroup, setValidDateAndGroup] = useState(false);
  const [initialDate, setInitialDate] = useState<Date>(new Date());

  const onData = useCallback(() => {
    setBackendMessages(null);
    onSave();
    toggle();
  }, [onSave, toggle]);

  const onError = useCallback((error: any) => habdleError(error, setBackendMessages), []);

  const createOrigin = useCallback(
    (groupId: number, data: OriginYoungTransfer) => {
      post(`/api/v1/group/young/${groupId}/origin`, onData, onError, data);
    },
    [onData, onError, post]
  );

  const saveOrigin = useCallback(
    (groupId: number, originId: number, data: OriginYoungTransfer) => {
      put(`/api/v1/group/young/${groupId}/origin/${originId}`, onData, onError, data);
    },
    [onData, onError, put]
  );

  const doSave = useCallback(
    (originDetails: OriginYoungTransfer) =>
      handleSave(
        originDetails,
        defaultFarmId,
        farms,
        isMoving,
        isMovingSource,
        groupId,
        saveOrigin,
        createOrigin
      ),
    [defaultFarmId, farms, isMoving, isMovingSource, groupId, saveOrigin, createOrigin]
  );

  const submit = useCallback(
    () =>
      handleSubmit(
        startDate,
        endDate,
        originDetails.maxNumPS,
        originDetails.maxNumGP,
        editOriginDetails,
        groupNameOptions,
        setBackendMessages,
        isMoving,
        doSave,
        setIsAllValid,
        diaries,
        allOrigins
      ),
    [
      startDate,
      endDate,
      originDetails.maxNumPS,
      originDetails.maxNumGP,
      editOriginDetails,
      groupNameOptions,
      isMoving,
      doSave,
      diaries,
      allOrigins,
    ]
  );

  useEffect(() => {
    setIsMoving(isOpen ? originDetails.isMoving : false);
    setIsMovingSource(isOpen ? originDetails.isMovingSource : false);
  }, [isOpen, originDetails.isMoving, originDetails.isMovingSource]);

  useEffect(() => {
    opening(
      isOpen,
      originDetails,
      defaultFarmId,
      farms,
      groupNameOptions,
      endDate,
      setEditOriginDetails,
      setGroupLife,
      setValidDateAndGroup,
      setInitialDate
    );
  }, [
    defaultFarmId,
    farms,
    groupNameOptions,
    isOpen,
    originDetails,
    endDate,
    setEditOriginDetails,
  ]);

  useEffect(() => {
    setGroupNameOptions(
      filterGroupNameOptions(groupNames || undefined, generatedName, originDetails.isMoving)
    );
  }, [groupNames, generatedName, originDetails.isMoving]);

  const textTrim = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => textTrimming(event),
    []
  );

  useEffect(() => {
    if (isOpen) {
      setEditOriginDetails(originDetails.origin);
    }
  }, [isOpen, originDetails.origin, setEditOriginDetails]);

  const handleFarmNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSimpleValueChange('farmName', ValueType.STRING)(event);
      setEditOriginDetails((prevState) => ({
        ...prevState,
        groupName: undefined,
        groupId: undefined,
      }));
    },
    [onSimpleValueChange, setEditOriginDetails]
  );

  const onGroupNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      handleGroupNameChange(
        event,
        groupNameOptions,
        onSimpleValueChange,
        editOriginDetails,
        setEditOriginDetails,
        isMoving,
        setGroupLife,
        setValidDateAndGroup
      ),
    [editOriginDetails, groupNameOptions, isMoving, onSimpleValueChange, setEditOriginDetails]
  );

  const onWeaningDateChange = useCallback(
    (arrivedDate: Date) =>
      handleWeaningDateChange(
        arrivedDate,
        editOriginDetails,
        setEditOriginDetails,
        groupNameOptions,
        isMoving,
        setValidDateAndGroup
      ),
    [editOriginDetails, groupNameOptions, isMoving, setEditOriginDetails]
  );

  const getDayClass = useCallback(
    (date: Date) =>
      isDateBetweenRange(date, startDate, endDate) ? 'available-days' : 'disabled-days',
    [endDate, startDate]
  );

  const isLoading = useCallback(() => saving || groupNameLoading, [groupNameLoading, saving]);

  const tYgpt3Farm = t('ygpt3-farm');
  const tYgpt3Group = t('ygpt3-group');

  useEffect(() => {
    if (farms && farms.length > 0) {
      const { name } = farms.filter((f) => f.id === defaultFarmId)[0];
      setDefaultFarmName(name);
    }
  }, [farms, defaultFarmId]);

  useEffect(() => {
    setIsAllValid(true);
    setBackendMessages(null);
  }, [editOriginDetails]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="modal-content-size"
      className="modal-align"
    >
      <ModalHeader toggle={toggle}>
        {`${isMoving ? t('move-origin-young') : t('edit-origin')}`}
      </ModalHeader>
      <ModalBody>
        {isLoading() && <Loader />}
        <Container fluid>
          <Row className="" />
        </Container>
        <Form className="form d-flex flex-column justify-content-center">
          {/* Section for fattening moving: date, from, to */}
          {isMoving &&
            getMovingModalPart(
              editOriginDetails,
              initialDate,
              startDate,
              endDate,
              getDayClass,
              onWeaningDateChange,
              tYgpt3Farm,
              tYgpt3Group,
              defaultFarmName,
              generatedName,
              textTrim,
              onGroupNameChange,
              groupNameOptions,
              groupLife,
              validDateAndGroup,
              t
            )}

          {/* Section for default mode: from, age, date */}
          {!isMoving &&
            getDefaultModalPart(
              editOriginDetails,
              handleFarmNameChange,
              farms,
              textTrim,
              onGroupNameChange,
              onSimpleValueChange,
              groupNameOptions,
              t
            )}

          {/* Common section for pieces / kg values */}
          {getCommonModalPart(
            isMoving,
            editOriginDetails,
            diaries,
            allOrigins,
            onSimpleValueChange,
            t
          )}

          <Row className="mt-2">
            <Col>
              <ModalError isInputValid={isAllValid} serverErrors={backendMessages} />
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={submit} className="mr-2">
          {t('save')}
        </Button>
        <Button color="primary" onClick={toggle}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditYoungOriginModal;
