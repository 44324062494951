import { useState, useCallback } from 'react';
import User from '../../models/user';
import { saveUser, setNewUserRole } from '../../helpers/authUtils';

function useSaveUser(callback?: () => void) {
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const save = useCallback(
    async (user: User) => {
      try {
        setError(false);
        setLoading(true);

        await saveUser(user);

        setNewUserRole();

        callback && callback();
      } catch (e) {
        console.log(e);

        setError(true);
        setLoading(false);
      }
    },
    [callback]
  );

  return { error, loading, saveUser: save };
}

export default useSaveUser;
