import React from 'react';
import { render } from 'react-dom';
import './css/reset.css';
import './css/scrollable-table.css';
import './css/grouppage-table.css';
import './css/datepicker.css';
import { Provider } from 'react-redux';
import App from './App';
import { configureStore } from 'hyper/redux/store';
import './i18n';

render(
  <Provider store={configureStore()}>
    <App />
  </Provider>,
  document.getElementById('root')
);
