import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Row, Col } from 'reactstrap';

interface InfoBoxProps {
  title?: string;
  data: { [key: string]: string | number };
}

function InfoBox({ data, title }: InfoBoxProps) {
  const { t } = useTranslation();

  return (
    <Card className="h-100">
      <CardBody>
        {title && <h4>{title}</h4>}
        <>
          {Object.keys(data).map((label) => (
            <Row key={label}>
              <Col sm="auto">
                <b>{t(label)}:</b>
              </Col>
              <Col md="none">{data[label]}</Col>
            </Row>
          ))}
        </>
      </CardBody>
    </Card>
  );
}

export default InfoBox;
