import React, { useCallback, useEffect, useState } from 'react';
import '../../../../css/modal.css';
import StableModal from '../common/stableModal';
import { useApiPut } from 'hooks/useApi';
import Farm from 'models/farm';
import Stable from 'models/stable';

interface OptionType {
  value: number;
  label: string;
}

interface YoungStableProps {
  id: number;
  isOpen: boolean;
  stables: Stable[];
  onSave: () => void;
  toggle: () => void;
  farms: Farm[] | null | undefined;
}

function YoungStableModal({ id, isOpen, stables, toggle, onSave, farms }: YoungStableProps) {
  const [selectedStables, setSelectedStables] = useState<OptionType[] | null>(null);
  const [backendMessages, setBackendMessages] = useState<string[] | null>(null);
  const [isValid, setIsValid] = useState<boolean>(true);

  const [saveYoungDetails, { loading: saving }] = useApiPut<{ messages: string[] }>(
    `/api/v1/group/young/${id}`,
    (response) => {
      if (response.messages && response.messages.length > 0) {
        setBackendMessages(response.messages);
      } else {
        setBackendMessages(null);
        onSave();
        toggle();
      }
    }
  );

  useEffect(() => {
    if (isOpen && stables) {
      const selected: OptionType[] = [];
      stables.forEach((stable) =>
        selected.push({
          value: stable.id,
          label: stable.name,
        })
      );
      setSelectedStables(selected);
    } else {
      setSelectedStables(null);
    }

    setIsValid(true);
  }, [isOpen, stables]);

  useEffect(() => {
    setBackendMessages(null);
  }, [selectedStables]);

  const onChange = useCallback((value) => {
    setSelectedStables(value);
    setIsValid(true);
  }, []);

  const submit = useCallback(() => {
    if (selectedStables) {
      const stables: Stable[] = [];
      selectedStables.forEach((selectedStable) =>
        stables.push({ id: selectedStable.value, name: selectedStable.label })
      );
      saveYoungDetails(stables);
    } else {
      setIsValid(false);
    }
  }, [saveYoungDetails, selectedStables]);

  return (
    <StableModal
      isOpen={isOpen}
      farms={farms}
      backendMessages={backendMessages}
      selectedStable={selectedStables}
      saving={saving}
      isValid={isValid}
      isMulti
      submit={submit}
      onChange={onChange}
      toggle={toggle}
    />
  );
}

export default YoungStableModal;
