import { TFunction } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import _uniqueId from 'lodash/uniqueId';
import {
  boolToStr,
  classBase,
  classCellC,
  classCellL,
  classCellR,
  classCellTr,
  classSideButton,
  classTable,
  Counter,
  createClimateDataBlock,
  createCommentDataBlock,
  createTd,
  createTh2,
  createThL2,
  dateStr,
  isParentInMergedGrouppage,
  monthDayToStr,
  renderWeightCell,
  SUBPAGE_SEPARATOR,
  toStr,
} from './gpUtil';
import EditDiaryDetailsModal from './modal/mother/editDiaryDetailsModal';
import MotherDiaryElementTransfer from 'models/grouppages/motherDiaryElementTransfer';
import EditMotherTransfer from 'models/grouppages/editMotherTransfer';
import { DailyWeightType } from 'models/grouppages/dailyWeightType';
import MotherDiaryEditTransfer from 'models/grouppages/motherDiaryEditTransfer';

const gdp1Daily = 'gpd-1-daily';
const gdp1Weekly = 'gpd-1-weekly';
const gpd0Indoor = 'gpd-0-indoor';
const gpdDegrees = 'gpd-degrees';
const gpdDegreesTT = 'gpd-degrees-tt';
const gpdDegreesTTmam = 'gpd-degrees-tt-mam';
const gpdRh = 'gpd-rh';
const gpdRhTT = 'gpd-rh-tt';
const gpdRhTTmam = 'gpd-rh-tt-mam';
const gpdComment = 'gpd-comment';
const gpStable = 'grouppage-stable';

const isWeekend = (row: MotherDiaryElementTransfer): boolean =>
  ['SUNDAY', 'SATURDAY'].includes(row.dayOfWeek);

interface WeeklyData {
  weekNum: (number | undefined)[];
  motherSum: (number | undefined)[];
  motherPercent: (number | undefined)[];
  sucklingSum: (number | undefined)[];
  sucklingPercent: (number | undefined)[];
}

const setWeeklyData = (arr: (number | undefined)[], week: number, value: number | undefined) => {
  if (arr.length <= week) {
    arr.push(value || undefined);
    return;
  }

  if (value !== undefined && value !== null) {
    arr[week] = value;
  }
};

const calcWeeklyData = (data: MotherDiaryElementTransfer[] | undefined): WeeklyData => {
  const ret: WeeklyData = {
    weekNum: [],
    motherSum: [],
    motherPercent: [],
    sucklingSum: [],
    sucklingPercent: [],
  };
  if (!data) {
    return ret;
  }
  data.map((row, idx) => {
    const week = (idx / 7) >> 0;
    setWeeklyData(ret.weekNum, week, row.weekOfAge);
    setWeeklyData(ret.motherSum, week, row.nursingWeeklyDeadSum);
    setWeeklyData(ret.motherPercent, week, row.nursingWeeklyDeadPercent);
    setWeeklyData(ret.sucklingSum, week, row.sucklingWeeklyDeadSum);
    setWeeklyData(ret.sucklingPercent, week, row.sucklingWeeklyDeadPercent);
  });
  return ret;
};

const getTempTT = (newSchool: boolean, t: TFunction): string =>
  t(newSchool ? gpdDegreesTTmam : gpdDegreesTT);

const getRhTT = (newSchool: boolean, t: TFunction): string => t(newSchool ? gpdRhTTmam : gpdRhTT);

function createMergedClimateHeaderRow(
  transfer: EditMotherTransfer,
  iidx: Counter,
  headerRow: number,
  t: TFunction
): JSX.Element[] {
  const result = [] as JSX.Element[];
  const newSchool = (transfer.version || 0) > 0;
  transfer.gpMembers?.forEach((gp, idx) => {
    const parts = gp.generatedName.split(SUBPAGE_SEPARATOR);
    if (parts.length > 1) {
      if (headerRow === 1) {
        result.push(
          createTh2(
            `${SUBPAGE_SEPARATOR}${parts[1]}`,
            iidx.idx++,
            gp.generatedName,
            {
              colSpan: newSchool ? 3 : 2,
            },
            `sg-${idx}-${parts[1]}`
          )
        );
      } else {
        newSchool &&
          result.push(
            createThL2(
              t(gpStable).substring(0, 1),
              iidx.idx++,
              t(gpStable),
              undefined,
              `deg${idx}${parts[1]}`
            )
          );
        result.push(
          createThL2(
            t(gpdDegrees),
            iidx.idx++,
            getTempTT(newSchool, t),
            undefined,
            `deg${idx}${parts[1]}`
          )
        );
        result.push(
          createThL2(t(gpdRh), iidx.idx++, getRhTT(newSchool, t), undefined, `rh${idx}${parts[1]}`)
        );
      }
    }
  });
  return result;
}

const createClimateHeader = (
  transfer: EditMotherTransfer | null | undefined,
  iidx: Counter,
  merged: boolean,
  headerRow: number,
  t: TFunction
): JSX.Element[] => {
  if (!transfer) return [];
  const result = [] as JSX.Element[];
  const newSchool = (transfer.version || 0) > 0;
  const num = transfer.gpMembers?.length || 0;
  if (!merged || num <= 1) {
    if (headerRow === 0) {
      result.push(
        createTh2(t(gpd0Indoor), iidx.idx++, '', { colSpan: newSchool ? 3 : 2, rowSpan: 2 })
      );
    } else if (headerRow === 2) {
      newSchool && result.push(createThL2(t(gpStable).substring(0, 1), iidx.idx++, t(gpStable)));
      result.push(createThL2(t(gpdDegrees), iidx.idx++, getTempTT(newSchool, t)));
      result.push(createThL2(t(gpdRh), iidx.idx++, getRhTT(newSchool, t)));
    }
  } else {
    if (headerRow === 0) {
      result.push(createTh2(t(gpd0Indoor), iidx.idx++, '', { colSpan: 3 * (num - 1) }));
    } else {
      result.push(...createMergedClimateHeaderRow(transfer, iidx, headerRow, t));
    }
  }
  return result;
};

const createCommentHeader = (
  transfer: EditMotherTransfer | null | undefined,
  iidx: Counter,
  merged: boolean,
  headerRow: number,
  t: TFunction
): JSX.Element[] => {
  if (!transfer) return [];
  const num = transfer.gpMembers?.length || 0;
  if (!merged || num <= 1) {
    const headerRows = [[createTh2(t(gpdComment), iidx.idx++, '', { rowSpan: 3 })], [], []];
    return headerRows[headerRow];
  } else {
    const result = [] as JSX.Element[];
    if (headerRow === 0) {
      return [createTh2(t(gpdComment), iidx.idx++, '', { colSpan: num, rowSpan: 2 })];
    } else if (headerRow === 2) {
      transfer.gpMembers?.forEach((gp, idx) => {
        const parts = gp.generatedName.split(SUBPAGE_SEPARATOR);
        const key = `com${idx}${parts[1]}`;
        result.push(
          parts.length > 1
            ? createTh2(
                `${SUBPAGE_SEPARATOR}${parts[1]}`,
                iidx.idx++,
                gp.generatedName,
                undefined,
                key
              )
            : createTh2(gp.generatedName, iidx.idx++)
        );
      });
    }
    return result;
  }
};

const createFeedBlock = (
  row: MotherDiaryElementTransfer,
  iidx: Counter,
  t: TFunction,
  cellL: string,
  cellR: string,
  cellTr: string
): JSX.Element[] => {
  const feedType = row.value.feed?.type ? t(`${row.value.feed?.type}`) : '';
  return [
    createTd(row.id, iidx.idx++, toStr(row.value.dailyFeedAmount), cellR),
    createTd(row.id, iidx.idx++, toStr(row.value.feed?.amount), cellR),
    createTd(row.id, iidx.idx++, row.value.feed?.productNumber, cellTr, true),
    createTd(row.id, iidx.idx++, feedType, cellTr),
    createTd(row.id, iidx.idx++, row.value.feed?.siloName, cellL),
    createTd(row.id, iidx.idx++, row.value.feed?.serialNumber, cellTr, true),
  ];
};

const createHealthBlock = (
  row: MotherDiaryElementTransfer,
  iidx: Counter,
  cellC: string,
  cellTr: string
): JSX.Element[] => [
  createTd(row.id, iidx.idx++, row.value.medicine?.treatment, cellTr, true),
  createTd(row.id, iidx.idx++, boolToStr(row.value.medicine?.vaccination), cellC),
  createTd(row.id, iidx.idx++, boolToStr(row.value.medicine?.drinkingWater), cellC),
  createTd(row.id, iidx.idx++, boolToStr(row.value.medicine?.medication1), cellC),
  createTd(row.id, iidx.idx++, boolToStr(row.value.medicine?.medication2), cellC),
];

const createDiaryButton = (
  title: string,
  closeDay: boolean,
  disabled: boolean,
  today = false,
  allButton = true,
  diary: MotherDiaryElementTransfer,
  toggle: (diary: MotherDiaryElementTransfer) => void
) => {
  const color = disabled ? 'secondary' : 'primary';
  let classname = `text-center ${classBase}`;
  if (today) {
    classname = `bg-warning ${classname}`;
  }
  const key = _uniqueId();
  return !disabled || allButton ? (
    <td className={classname} key={key}>
      <Button
        color={color}
        className={`${classSideButton} px-1`}
        onClick={() => {
          toggle(diary);
        }}
      >
        <span style={{ fontSize: 'smaller' }}>{title}</span>
      </Button>
    </td>
  ) : (
    <td className={classCellC} key={key}>
      {title}
    </td>
  );
};

const getSuckling = (idx: number, text: string): string => (idx < 7 ? '-' : text);

const createDataRow = (
  now: string,
  closedAt: string,
  row: MotherDiaryElementTransfer,
  idx: number,
  week: number,
  weeklyData: WeeklyData,
  t: TFunction,
  toggle: (diary: MotherDiaryElementTransfer) => void,
  merged: boolean,
  transfer: EditMotherTransfer | null | undefined
) => {
  const fisrtWeekday = idx % 7 === 0;
  const dayName = row.dayOfWeek ? t(`${row.dayOfWeek}`) : '';
  const actDay = dateStr(row.actualDate);
  const closeDay = actDay === closedAt;
  const noButton = (closedAt.length > 0 ? closedAt : now) < actDay;
  const today = now === actDay;
  const background = closeDay ? ' bg-danger' : today ? ' bg-warning' : '';
  const cellL = `${classCellL}${background}`;
  const cellC = `${classCellC}${background}`;
  const cellR = `${classCellR}${background}`;
  const cellTr = `${classCellTr}${background}`;
  const weightMOT = row.value.weights?.find((w) => w.type === DailyWeightType.MOT);
  const weightSUC = row.value.weights?.find((w) => w.type === DailyWeightType.SUC);
  const motherDeadRespPercent = row.value.motherDead ? toStr(row.motherDeadRespPercent, 2) : '';
  const motherDeadErePercent = row.value.motherDead ? toStr(row.motherDeadErePercent, 2) : '';
  const iidx: Counter = { idx: 0 };
  const newSchool = (transfer?.version || 0) > 0;
  const tds = [] as JSX.Element[];

  fisrtWeekday &&
    tds.push(
      createTd(row.id, iidx.idx++, toStr(weeklyData.weekNum[week]), classCellC, undefined, {
        rowSpan: 7,
      })
    );
  tds.push(
    createDiaryButton(monthDayToStr(row.actualDate), closeDay, noButton, today, false, row, toggle)
  );
  tds.push(createTd(row.id, iidx.idx++, `${dayName}.`, cellC, false, undefined, isWeekend(row)));
  tds.push(createTd(row.id, iidx.idx++, `${row.dayOfAge}`, cellR));

  tds.push(createTd(row.id, iidx.idx++, toStr(row.value.motherDead), cellR));
  tds.push(createTd(row.id, iidx.idx++, motherDeadRespPercent, cellR));
  tds.push(createTd(row.id, iidx.idx++, motherDeadErePercent, cellR));
  tds.push(createTd(row.id, iidx.idx++, toStr(row.value.motherWaste), cellR));
  tds.push(
    createTd(
      row.id,
      iidx.idx++,
      renderWeightCell(row, weightMOT?.weight, 'MOT', noButton, merged),
      cellR
    )
  );
  fisrtWeekday &&
    tds.push(
      createTd(row.id, iidx.idx++, toStr(weeklyData.motherSum[week]), classCellC, undefined, {
        rowSpan: 7,
      })
    );
  fisrtWeekday &&
    tds.push(
      createTd(
        row.id,
        iidx.idx++,
        toStr(weeklyData.motherPercent[week], 2),
        classCellC,
        undefined,
        {
          rowSpan: 7,
        }
      )
    );

  tds.push(createTd(row.id, iidx.idx++, getSuckling(idx, toStr(row.value.sucklingDead)), cellR));
  tds.push(
    createTd(row.id, iidx.idx++, getSuckling(idx, toStr(row.sucklingDailyDeadPercent, 2)), cellR)
  );
  tds.push(createTd(row.id, iidx.idx++, getSuckling(idx, toStr(row.value.sucklingExterm)), cellR));
  tds.push(
    createTd(
      row.id,
      iidx.idx++,
      idx < 7 ? '-' : renderWeightCell(row, weightSUC?.weight, 'SUC', noButton, merged),
      cellR
    )
  );
  fisrtWeekday &&
    tds.push(
      createTd(
        row.id,
        iidx.idx++,
        getSuckling(idx, toStr(weeklyData.sucklingSum[week])),
        classCellC,
        undefined,
        {
          rowSpan: 7,
        }
      )
    );
  fisrtWeekday &&
    tds.push(
      createTd(
        row.id,
        iidx.idx++,
        getSuckling(idx, toStr(weeklyData.sucklingPercent[week], 2)),
        classCellC,
        undefined,
        {
          rowSpan: 7,
        }
      )
    );

  tds.push(...createClimateDataBlock(newSchool, row, iidx, merged, transfer, cellR, cellC));

  !merged && tds.push(...createFeedBlock(row, iidx, t, cellL, cellR, cellTr));

  tds.push(...createCommentDataBlock(row, iidx, merged, transfer, cellTr));

  !merged && tds.push(...createHealthBlock(row, iidx, cellC, cellTr));

  return <tr key={row.id}>{tds}</tr>;
};

const renderBody = (
  transfer: EditMotherTransfer | null | undefined,
  t: TFunction,
  toggle: (diary: MotherDiaryElementTransfer) => void,
  merged: boolean
) => {
  const data = transfer?.diary.diaries;
  if (!data) {
    return <></>;
  }
  const weeklyData = calcWeeklyData(data);
  const result: JSX.Element[] = [];
  const closedAt = dateStr(transfer?.endDate);
  const now = dateStr(new Date());
  data.map((row, idx) => {
    result.push(
      createDataRow(
        now,
        closedAt,
        row,
        idx,
        (idx / 7) >> 0,
        weeklyData,
        t,
        toggle,
        merged,
        transfer
      )
    );
  });
  return result;
};

interface MotherEditDiariesProps {
  transfer: EditMotherTransfer | null | undefined;
  refresh: () => void;
  onOpenGroup: () => void;
  onCloseGroup: (diaryId: number) => void;
  onSaveDiaryDetails: (diaryId: number, diaryData: MotherDiaryEditTransfer) => void;
  backendMessages: string[] | undefined;
  isWorking: boolean;
  isSaved: boolean;
}

function MotherEditDiaries({
  transfer,
  refresh,
  onOpenGroup,
  onCloseGroup,
  onSaveDiaryDetails,
  backendMessages,
  isWorking,
  isSaved,
}: MotherEditDiariesProps) {
  const { t } = useTranslation();

  const [selectedDiary, setSelectedDiary] = useState<MotherDiaryElementTransfer>();

  const toggleDiaryEditModal = useCallback((diary: MotherDiaryElementTransfer | undefined) => {
    setSelectedDiary(diary);
  }, []);

  useEffect(() => {
    if (isSaved) {
      setSelectedDiary(undefined);
    }
  }, [isSaved]);

  const tableHeader = useMemo(() => {
    const db = t('piece');
    const percent = t('gp-percent');
    const merged = isParentInMergedGrouppage(transfer);
    const tr1 = [] as JSX.Element[];
    const tr2 = [] as JSX.Element[];
    const tr3 = [] as JSX.Element[];
    const iidx = { idx: 0 };
    if (merged) {
      tr1.push(createTh2(t('gpd-0-diary'), iidx.idx++, '', { colSpan: 4, rowSpan: 2 }));
      tr1.push(createTh2(t('mgpd-0-mother'), iidx.idx++, '', { colSpan: 7 }));
      tr1.push(createTh2(t('mgpd-0-suckling'), iidx.idx++, '', { colSpan: 6 }));
      tr1.push(...createClimateHeader(transfer, iidx, merged, 0, t));
      tr1.push(...createCommentHeader(transfer, iidx, merged, 0, t));

      iidx.idx = 0;
      tr2.push(createTh2(t(gdp1Daily), iidx.idx++, '', { colSpan: 5 }));
      tr2.push(createTh2(t(gdp1Weekly), iidx.idx++, '', { colSpan: 2 }));
      tr2.push(createTh2(t(gdp1Daily), iidx.idx++, '', { colSpan: 4 }));
      tr2.push(createTh2(t(gdp1Weekly), iidx.idx++, '', { colSpan: 2 }));
      tr2.push(...createClimateHeader(transfer, iidx, merged, 1, t));

      iidx.idx = 0;
      tr3.push(createThL2(t('gpd-week'), iidx.idx++, t('gpd-week-tt')));
      tr3.push(createThL2(t('gpd-date'), iidx.idx++));
      tr3.push(createThL2(t('gpd-day'), iidx.idx++, t('gpd-day-tt')));
      tr3.push(createThL2(t('gpd-age'), iidx.idx++, t('gpd-age-tt')));
      tr3.push(createThL2(t('mgpd-mother-dead'), iidx.idx++, t('mgpd-mother-dead-tt')));
      tr3.push(createThL2(t('mgpd-mother-resp'), iidx.idx++, t('mgpd-mother-resp-tt')));
      tr3.push(createThL2(t('gpd-ere'), iidx.idx++, t('mgpd-mother-ere-tt')));
      tr3.push(createThL2(t('mgpd-mother-waste'), iidx.idx++, t('mgpd-mother-waste-tt')));
      tr3.push(createThL2(t('weight'), iidx.idx++));
      tr3.push(createThL2(db, iidx.idx++));
      tr3.push(createThL2(percent, iidx.idx++));
      tr3.push(createThL2(t('mgpd-suckling-dead'), iidx.idx++));
      tr3.push(createThL2(percent, iidx.idx++));
      tr3.push(createThL2(t('mgpd-suckling-exterm'), iidx.idx++));
      tr3.push(createThL2(t('weight'), iidx.idx++));
      tr3.push(createThL2(db, iidx.idx++));
      tr3.push(createThL2(percent, iidx.idx++));
      tr3.push(...createClimateHeader(transfer, iidx, merged, 2, t));
      tr3.push(...createCommentHeader(transfer, iidx, merged, 2, t));
    } else {
      tr1.push(createTh2(t('gpd-0-diary'), iidx.idx++, '', { colSpan: 4, rowSpan: 1 }));
      tr1.push(createTh2(t('mgpd-0-mother'), iidx.idx++, '', { colSpan: 7 }));
      tr1.push(createTh2(t('mgpd-0-suckling'), iidx.idx++, '', { colSpan: 6 }));
      tr1.push(...createClimateHeader(transfer, iidx, merged, 0, t));
      tr1.push(createTh2(t('gpd-0-food'), iidx.idx++, '', { colSpan: 6, rowSpan: 2 }));
      tr1.push(...createCommentHeader(transfer, iidx, merged, 0, t));
      tr1.push(createTh2(t('gpd-0-medicine'), iidx.idx++, '', { colSpan: 5, rowSpan: 2 }));

      iidx.idx = 0;
      tr2.push(createTh2('', iidx.idx++, '', { colSpan: 4 }));
      tr2.push(createTh2(t(gdp1Daily), iidx.idx++, '', { colSpan: 5 }));
      tr2.push(createTh2(t(gdp1Weekly), iidx.idx++, '', { colSpan: 2 }));
      tr2.push(createTh2(t(gdp1Daily), iidx.idx++, '', { colSpan: 4 }));
      tr2.push(createTh2(t(gdp1Weekly), iidx.idx++, '', { colSpan: 2 }));

      iidx.idx = 0;
      tr3.push(createThL2(t('gpd-week'), iidx.idx++, t('gpd-week-tt')));
      tr3.push(createThL2(t('gpd-date'), iidx.idx++));
      tr3.push(createThL2(t('gpd-day'), iidx.idx++, t('gpd-day-tt')));
      tr3.push(createThL2(t('gpd-age'), iidx.idx++, t('gpd-age-tt')));
      tr3.push(createThL2(t('mgpd-mother-dead'), iidx.idx++, t('mgpd-mother-dead-tt')));
      tr3.push(createThL2(t('mgpd-mother-resp'), iidx.idx++, t('mgpd-mother-resp-tt')));
      tr3.push(createThL2(t('gpd-ere'), iidx.idx++, t('mgpd-mother-ere-tt')));
      tr3.push(createThL2(t('mgpd-mother-waste'), iidx.idx++, t('mgpd-mother-waste-tt')));
      tr3.push(createThL2(t('weight'), iidx.idx++));
      tr3.push(createThL2(db, iidx.idx++));
      tr3.push(createThL2(percent, iidx.idx++));
      tr3.push(createThL2(t('mgpd-suckling-dead'), iidx.idx++));
      tr3.push(createThL2(percent, iidx.idx++));
      tr3.push(createThL2(t('mgpd-suckling-exterm'), iidx.idx++));
      tr3.push(createThL2(t('weight'), iidx.idx++));
      tr3.push(createThL2(db, iidx.idx++));
      tr3.push(createThL2(percent, iidx.idx++));
      tr3.push(...createClimateHeader(transfer, iidx, merged, 2, t));
      tr3.push(...createCommentHeader(transfer, iidx, merged, 2, t));
      tr3.push(createThL2(t('gpd-daily-feed'), iidx.idx++, t('gpd-daily-feed-tt')));
      tr3.push(createThL2(t('gpd-feed-amount'), iidx.idx++, t('gpd-feed-amount-tt')));
      tr3.push(createThL2(t('gpd-product'), iidx.idx++));
      tr3.push(createThL2(t('gpd-type'), iidx.idx++, t('gpd-type-tt')));
      tr3.push(createThL2(t('gpd-silo'), iidx.idx++));
      tr3.push(createThL2(t('gpd-serial'), iidx.idx++));
      tr3.push(createThL2(t('gpd-treatment'), iidx.idx++));
      tr3.push(createThL2(t('gpd-vaccination'), iidx.idx++, t('gpd-vaccination-tt')));
      tr3.push(
        createThL2(
          t('gpd-drinking-water'),
          iidx.idx++,
          t('gpd-drinking-water-tt'),
          undefined,
          'gpd-drinking-water'
        )
      );
      tr3.push(createThL2(t('gpd-medication1'), iidx.idx++, t('gpd-medication1-tt')));
      tr3.push(createThL2(t('gpd-medication2'), iidx.idx++, t('gpd-medication2-tt')));
    }
    return (
      <>
        <tr>{tr1}</tr>
        <tr>{tr2}</tr>
        <tr>{tr3}</tr>
      </>
    );
  }, [t, transfer]);

  const tableContent = useMemo(() => {
    const merged = isParentInMergedGrouppage(transfer);
    return (
      <>
        <thead>{tableHeader}</thead>
        <tbody>{renderBody(transfer, t, toggleDiaryEditModal, merged)}</tbody>
      </>
    );
  }, [t, tableHeader, toggleDiaryEditModal, transfer]);

  return (
    <>
      {selectedDiary && (
        <EditDiaryDetailsModal
          isParent={isParentInMergedGrouppage(transfer)}
          closeDay={dateStr(transfer?.endDate)}
          toggle={toggleDiaryEditModal}
          isOpen={!!selectedDiary}
          onOpenGroup={onOpenGroup}
          onCloseGroup={onCloseGroup}
          onSaveDiaryDetails={onSaveDiaryDetails}
          backendMessages={backendMessages}
          diaryDetails={selectedDiary}
          isWorking={isWorking}
        />
      )}
      <div className="react-bootstrap-table table-responsive">
        <table
          className={`${classTable} table-striped gp-table data-cy-diary-table`}
          id={'mother-diary-table'}
        >
          {tableContent}
        </table>
      </div>
    </>
  );
}

export default MotherEditDiaries;
