export interface QueryParams {
  [key: string]: number | string | number[] | string[] | boolean | undefined | null;
}

export function toQueryString(params: QueryParams) {
  return Object.keys(params)
    .map((key) => {
      const value = params[key];

      if (value === null || value === undefined) {
        return undefined;
      }

      return `${encodeURIComponent(key)}=${encodeURIComponent(
        Array.isArray(value) ? value.join(',') : value
      )}`;
    })
    .filter((item) => item !== undefined)
    .join('&');
}
