import React, { useCallback } from 'react';
import { Col, Alert } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSaveUser from '../hooks/useSaveUser';
import { useApiGet } from '../hooks/useApi';
import Farm from '../models/farm';
import PageContainer from './PageContainer';
import UserForm from 'components/user/userForm';
import { hasRole, getUserRole, getNewUserRole } from 'helpers/authUtils';
import Loader from 'hyper/components/Loader';
import { UserRole } from 'models/userRole';

function CreateUser() {
  const history = useHistory();
  const goToUsersPage = useCallback(() => history.push('/users'), [history]);
  const { error, loading: saving, saveUser } = useSaveUser(goToUsersPage);
  const { data: farms, loading: fetchingFarms } = useApiGet<Farm[]>('/api/v1/user-creation/farm');
  const isLoading = useCallback(() => saving || fetchingFarms, [saving, fetchingFarms]);
  const { t } = useTranslation();

  const getTitle = () => {
    if (getUserRole() === UserRole.SUPERADMIN) {
      return getNewUserRole() === UserRole.OWNER
        ? 'create-new-owner'
        : 'create-new-ear-tag-technician';
    } else if (getUserRole() === UserRole.OWNER) {
      return 'create-new-admin';
    } else {
      return 'create-new-worker';
    }
  };

  return (
    <PageContainer title={getTitle()}>
      <Col>
        {isLoading() && <Loader />}
        {error && <Alert color="danger">{t('failed-to-save')}</Alert>}
        <UserForm
          onSave={saveUser}
          onCancel={goToUsersPage}
          farms={farms || []}
          canSelectSite={hasRole(UserRole.OWNER)}
        />
      </Col>
    </PageContainer>
  );
}

export default CreateUser;
