import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Button,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { TFunction } from 'i18next';
import Select, { GroupedOptionsType, OptionsType } from 'react-select';
import ModalError from './modalError';
import {
  delayedRun,
  formatGrouppageName,
  PLANNED_DELIVERY_AGE,
  YOUNG_AGE_MAX,
  YOUNG_AGE_MIN,
} from './gpUtil';
import CreateYoungTransfer from 'models/grouppages/createYoungTransfer';
import Loader from 'hyper/components/Loader';
import Datepicker from 'hyper/components/Datepicker';
import { useApi, useApiGet } from 'hooks/useApi';
import CreateFatteningTransfer from 'models/grouppages/createFatteningTransfer';
import { roundAndRemove } from 'helpers/rabbit';
import Farm from 'models/farm';
import Stable from 'models/stable';

interface OptionType {
  value: number;
  label: string;
}

function checkStable(
  farms: Farm[] | null
):
  | OptionsType<{
      value: number;
      label: string;
    }>
  | GroupedOptionsType<{
      value: number;
      label: string;
    }>
  | undefined {
  if (!farms || farms.length < 1 || !farms[0].stables) return undefined;
  return farms[0].stables.map((stable: Stable) => ({
    value: stable.id,
    label: stable.name,
  }));
}

function calcExpectedNames(
  expectedGroupName: string | undefined,
  selectedStables: OptionType[] | undefined
): JSX.Element | JSX.Element[] | undefined {
  const result = [] as JSX.Element[];
  const name = expectedGroupName || '';
  const index = 'abcdefghijklmnopqrstuvwxyz';
  result.push(
    <tr key="main">
      <td>{name}</td>
      <td />
    </tr>
  );
  if (selectedStables && selectedStables.length > 1) {
    for (let i = 0; i < selectedStables.length; i++) {
      const stable = selectedStables[i];
      result.push(
        <tr key={stable.value}>
          <td>{`${name}#${index[i]}`}</td>
          <td>
            <Badge key={`bdg-${stable.value}`} className="ml-2" color="info">
              {stable.label}
            </Badge>
          </td>
        </tr>
      );
    }
  }
  return (
    <table>
      <tbody>{result}</tbody>
    </table>
  );
}

function getStableSelectClass(selectedStables: OptionType[] | null | undefined) {
  return `react-select w-100${
    selectedStables === undefined || selectedStables === null || selectedStables.length < 1
      ? ' border border-danger rounded'
      : ''
  }`;
}

const DATE_FORMAT = 'YYYY.MM.DD.';
const AGE_28_DAYS = 28;
const WEEK_DAYS = 7;

function getAgeMax(isYoung: boolean): number {
  return isYoung ? YOUNG_AGE_MAX : PLANNED_DELIVERY_AGE;
}

function isAgeWrong(isYoung: boolean, age: number) {
  return age === null || age < YOUNG_AGE_MIN || age > getAgeMax(isYoung);
}

function isDetailsWrong(
  isYoung: boolean,
  groupPageDetails: CreateYoungTransfer | CreateFatteningTransfer
) {
  return groupPageDetails.startDate === null || isAgeWrong(isYoung, groupPageDetails.age);
}

function isDataWrong(
  isYoung: boolean,
  groupPageDetails: CreateYoungTransfer | CreateFatteningTransfer,
  selectedStables: OptionType[] | null | undefined
) {
  if (isDetailsWrong(isYoung, groupPageDetails)) return true;
  return (
    !isYoung &&
    (selectedStables === undefined || selectedStables === null || selectedStables.length < 1)
  );
}

function calcOtherDates(isYoung: boolean, startDate: Date, ageInDays: number): string[] {
  if (!startDate || isAgeWrong(isYoung, ageInDays)) return ['', '', ''];

  const result = [];
  const date = new Date(startDate);
  date.setDate(date.getDate() - ageInDays);
  result.push(moment(date).format(DATE_FORMAT));
  date.setDate(date.getDate() + AGE_28_DAYS);
  result.push(moment(date).format(DATE_FORMAT));
  date.setDate(date.getDate() + WEEK_DAYS);
  result.push(moment(date).format(DATE_FORMAT));
  return result;
}

function getPopupName(isYoung: boolean, t: TFunction): string {
  return isYoung ? t('create-young-grouppage') : t('create-fattening-grouppage');
}

function getAgeName(isYoung: boolean, t: TFunction): string {
  return isYoung ? t('day-of-age') : t('day-of-age-fattening');
}

interface CreateGroupPageProps {
  onSave: () => void;
  isOpen: boolean;
  toggle: () => void;
  baseDetails: CreateYoungTransfer | CreateFatteningTransfer;
  isYoung: boolean;
}

function CreateGroupPageModal({
  isOpen,
  toggle,
  onSave,
  baseDetails,
  isYoung,
}: CreateGroupPageProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const { data: farms, loading } = useApiGet<Farm[]>('/api/v1/farms');
  const [groupPageDetails, setGroupPageDetails] = useState<
    CreateYoungTransfer | CreateFatteningTransfer
  >({
    ...baseDetails,
  });
  const [isAllValid, setIsAllValid] = useState<boolean>(true);
  const [backendMessages, setBackendMessages] = useState<string[] | null>(null);
  const [isChecked, setIsCheked] = useState<boolean>(false);
  const [expectedGroupName, setExpectedGroupName] = useState<
    JSX.Element | JSX.Element[] | undefined
  >(undefined);
  const [birthDate, setBirthDate] = useState<string>('');
  const [day28Date, setDay28Date] = useState<string>('');
  const [day35Date, setDay35Date] = useState<string>('');
  const { post, getText, loading: saving } = useApi();
  const [selectedStables, setSelectedStables] = useState<OptionType[]>();
  const [stableOptions, setStablesOptions] = useState<
    | OptionsType<{
        value: number;
        label: string;
      }>
    | GroupedOptionsType<{
        value: number;
        label: string;
      }>
    | undefined
  >();

  const onChange = useCallback((value) => {
    setSelectedStables(value);
    setIsAllValid(true);
    setBackendMessages(null);
    setExpectedGroupName(undefined);
    setIsCheked(false);
  }, []);

  const onData = useCallback(
    (response) => {
      if (response.messages && response.messages.length > 0)
        return setBackendMessages(response.messages);

      const newId = typeof response === 'number' ? response : 0;
      if (newId > 0) {
        const uriPostfix = isYoung ? 'young' : 'fattening';
        delayedRun(() => {
          history.push(`/grouppage-${uriPostfix}/${newId}/edit`);
        });
        return;
      }

      setBackendMessages(null);
      onSave();
      toggle();
    },
    [history, isYoung, onSave, toggle]
  );

  const onError = useCallback((error: any) => {
    if (error.status && error.status === 405) return setBackendMessages(['INVALID_GROUP_NAME']);

    if (Array.isArray(error)) return setBackendMessages(error);

    if (`${error}`.includes('NetworkError')) return setBackendMessages(['NETWORK_ERROR']);

    setBackendMessages(['SAVING_ERROR']);
  }, []);

  const createGroupPage = useCallback(
    (data: CreateYoungTransfer | CreateFatteningTransfer) => {
      const uriPostfix = isYoung ? 'young' : 'fattening';
      post(`/api/v1/group/${uriPostfix}`, onData, onError, data);
    },
    [isYoung, onData, onError, post]
  );

  const submit = useCallback(() => {
    if (isDataWrong(isYoung, groupPageDetails, selectedStables)) {
      setIsAllValid(false);
    } else {
      setIsAllValid(true);
      const stables: Stable[] = [];
      selectedStables?.forEach((selectedStable) =>
        stables.push({ id: selectedStable.value, name: selectedStable.label })
      );
      createGroupPage({ ...groupPageDetails, stables });
    }
  }, [createGroupPage, groupPageDetails, isYoung, selectedStables]);

  const recalcDays = useCallback(
    (details: CreateYoungTransfer | CreateFatteningTransfer) => {
      const result = calcOtherDates(isYoung, details.startDate, details.age);
      setBirthDate(result[0]);
      setDay28Date(result[1]);
      setDay35Date(result[2]);
    },
    [isYoung]
  );

  const onStartDateChange = useCallback(
    (value: Date) => {
      const copy = { ...groupPageDetails, startDate: value };
      setGroupPageDetails(copy);
      recalcDays(copy);
    },
    [recalcDays, groupPageDetails]
  );

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newName = event.target.value ? formatGrouppageName(event.target.value) : '';
      const copy = { ...groupPageDetails, name: newName };
      setGroupPageDetails(copy);
    },
    [groupPageDetails]
  );

  const onDayChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newAge = event.target.value ? +event.target.value : 0;
      const copy = { ...groupPageDetails, age: newAge };
      setGroupPageDetails(copy);
      recalcDays(copy);
    },
    [recalcDays, groupPageDetails]
  );

  const checkExpectedName = useCallback(() => {
    if (isDataWrong(isYoung, groupPageDetails, selectedStables)) {
      setIsAllValid(false);
    } else {
      setIsAllValid(true);
      const uriPostfix = isYoung ? 'young' : 'fattening';

      getText(
        `/api/v1/group/${uriPostfix}/check?name=${groupPageDetails.name}&startDate=${new Date(
          groupPageDetails.startDate
        ).toISOString()}&age=${groupPageDetails.age}`,
        (expectedName) => {
          setExpectedGroupName(calcExpectedNames(expectedName, selectedStables));
          setIsCheked(true);
        },
        (error: any) => {
          if (`${error}`.includes('NetworkError')) return setBackendMessages(['NETWORK_ERROR']);

          setBackendMessages(['SAVING_ERROR']);
        }
      );
    }
  }, [getText, groupPageDetails, isYoung, selectedStables]);

  useEffect(() => {
    setIsAllValid(true);
    setBackendMessages(null);
    setExpectedGroupName(undefined);
    setIsCheked(false);
  }, [groupPageDetails]);

  useEffect(() => {
    if (isOpen) {
      const copy = { ...baseDetails };
      setGroupPageDetails(copy);
      recalcDays(copy);
    }
  }, [baseDetails, isOpen, recalcDays]);

  useEffect(() => {
    setStablesOptions(checkStable(farms));
  }, [farms]);

  const isWorking = useCallback(() => loading || saving, [loading, saving]);

  return (
    groupPageDetails && (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{`${getPopupName(isYoung, t)}`}</ModalHeader>
        <ModalBody>
          {isWorking() && <Loader />}
          <Container fluid>
            <Row className="">
              <Col className="d-flex justify-content-center">
                <div>
                  <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                    <Col sm="6">
                      <b>{t('grouppage-start-young')}:</b>
                      <span className="text-danger">*</span>
                    </Col>
                    <Col className="d-flex justify-content-start">
                      <Datepicker
                        startDate={new Date(groupPageDetails.startDate)}
                        dateFormat="yyyy.MM.dd."
                        onDateChange={onStartDateChange}
                      />
                    </Col>
                  </Row>

                  <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                    <Col sm="6">
                      <b>{getAgeName(isYoung, t)}:</b>
                      <span className="text-danger">*</span>
                    </Col>
                    <Col sm="4" className="d-flex justify-content-start">
                      <Input
                        type="number"
                        name={'day-of-age'}
                        min={YOUNG_AGE_MIN}
                        max={getAgeMax(isYoung)}
                        className="text-right"
                        value={groupPageDetails.age}
                        onChange={onDayChange}
                        onInput={roundAndRemove}
                        invalid={isAgeWrong(isYoung, groupPageDetails.age)}
                      />
                    </Col>
                    <Col>{t('day')}</Col>
                  </Row>

                  <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                    <Col sm="6">{t('date-of-birth')}:</Col>
                    <Col className="d-flex justify-content-start">
                      <Input
                        name="date-of-birth"
                        className="text-left"
                        disabled
                        value={birthDate}
                      />
                    </Col>
                  </Row>
                  <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                    <Col sm="6">{t('date-of-28day')}:</Col>
                    <Col className="d-flex justify-content-start">
                      <Input
                        name="date-of-28day"
                        className="text-left"
                        disabled
                        value={day28Date}
                      />
                    </Col>
                  </Row>
                  <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                    <Col sm="6">{t('date-of-35day')}:</Col>
                    <Col className="d-flex justify-content-start">
                      <Input
                        name="date-of-35day"
                        className="text-left"
                        disabled
                        value={day35Date}
                      />
                    </Col>
                  </Row>

                  <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                    <Col sm="6">
                      <b>{t('group-name')}:</b>
                    </Col>
                    <Col className="d-flex justify-content-start">
                      <Input
                        name={'young-group-name'}
                        className="text-left"
                        value={groupPageDetails.name}
                        onChange={onNameChange}
                      />
                    </Col>
                  </Row>

                  {!isYoung && (
                    <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
                      <Col sm="3">
                        <b>{t('grouppage-stable')}:</b>
                        <span className="text-danger">*</span>
                      </Col>
                      <Col className="d-flex justify-content-start">
                        <Select
                          id="grouppage-stable"
                          className={getStableSelectClass(selectedStables)}
                          classNamePrefix="react-select"
                          placeholder={t('stable-placeholder')}
                          noOptionsMessage={t('empty-list')}
                          isMulti
                          onChange={onChange}
                          options={stableOptions}
                        />
                      </Col>
                    </Row>
                  )}

                  {expectedGroupName && (
                    <Row className="d-flex align-items-center mt-3" style={{ width: '100%' }}>
                      <Col sm="6">
                        <b>{t('group-expected-name')}:</b>
                      </Col>
                      <Col className="d-flex justify-content-start">
                        <b>{expectedGroupName}</b>
                      </Col>
                    </Row>
                  )}
                </div>
              </Col>
            </Row>

            <Row className="pt-2">
              <Col>
                <ModalError isInputValid={isAllValid} serverErrors={backendMessages} />
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          {!isChecked && (
            <Button color="info" onClick={checkExpectedName} className="mr-2">
              {t('check')}
            </Button>
          )}
          {isChecked && (
            <Button color="success" onClick={submit} className="mr-2">
              {t('save')}
            </Button>
          )}
          <Button color="primary" onClick={toggle}>
            {t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  );
}

export default CreateGroupPageModal;
