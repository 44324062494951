import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import Select from 'react-select';
import CageFilter from 'models/cageFilter';
import Loader from 'hyper/components/Loader';
import useSearchCage from 'hooks/useSearchCage';
import SearchResult from 'models/searchResult';

interface CageInputProps {
  cageId: number | null;
  disabled?: boolean;
  onSelectCage: (cageId: number) => void;
}

interface OptionType {
  value: string | number | null;
  label: string | null;
}

function CageInput({ cageId, disabled, onSelectCage }: CageInputProps) {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<CageFilter>({
    stableId: null,
    row: null,
    position: null,
    cageId,
  });
  const [selectedStable, setSelectedStable] = useState<OptionType | null>(null);
  const [selectedRow, setSelectedRow] = useState<OptionType | null>(null);
  const [selectedPosition, setSelectedPosition] = useState<OptionType | null>(null);
  const [selectedCage, setSelectedCage] = useState<OptionType | null>(null);
  const { loadAgain, data: options, loading } = useSearchCage('/api/v1/search/cage');

  const emptyOptionsMessage = useCallback(() => t('empty-list'), [t]);
  const selectPleaseMessage = useCallback(() => t('select-please'), [t]);

  const toStableItem = useCallback(
    (stable: SearchResult) => ({ value: stable.id, label: stable.label }),
    []
  );
  const toRowItem = useCallback((row: number) => ({ value: row, label: `${row}` }), []);
  const toPositionItem = useCallback(
    (position: string) => ({ value: position, label: t(position) }),
    [t]
  );
  const toCageItem = useCallback(
    (cage: SearchResult) => ({
      value: cage.id,
      label: cage.label + (cage.info === undefined ? ' : -' : ` : ${cage.info}`),
    }),
    []
  );

  useEffect(() => {
    loadAgain({
      cageId,
    });
  }, [cageId, loadAgain]);

  const findStable = useCallback(
    (which) => {
      const whichStable = which && options && options.stables.find((value) => value.id === which);
      setSelectedStable(whichStable ? toStableItem(whichStable) : null);
    },
    [options, toStableItem]
  );

  const findRow = useCallback(
    (which) => {
      const whichRow = which && options && options.rows.find((value) => value === which);
      setSelectedRow(whichRow ? toRowItem(whichRow) : null);
    },
    [options, toRowItem]
  );

  const findPosition = useCallback(
    (which) => {
      const whichPosition = which && options && options.positions.find((value) => value === which);
      setSelectedPosition(whichPosition ? toPositionItem(whichPosition) : null);
    },
    [options, toPositionItem]
  );

  const findCage = useCallback(
    (which) => {
      const whichCage = which && options && options.cages.find((value) => value.id === which);
      setSelectedCage(whichCage ? toCageItem(whichCage) : null);
    },
    [options, toCageItem]
  );

  useEffect(() => {
    options && setFilter({ ...options.filter });
  }, [options]);

  useEffect(() => {
    findStable(filter.stableId);
    findRow(filter.row);
    findPosition(filter.position);
    findCage(filter.cageId);
  }, [filter, findCage, findPosition, findRow, findStable]);

  const loadOptions = useCallback(
    (newFilter: CageFilter) => {
      loadAgain({ ...newFilter });
    },
    [loadAgain]
  );

  const onSelectStable = useCallback(
    (value) => {
      loadOptions({ stableId: value.value });
    },
    [loadOptions]
  );

  const onSelectRow = useCallback(
    (value) => {
      loadOptions({ stableId: filter.stableId, row: value.value });
    },
    [filter, loadOptions]
  );

  const onSelectPosition = useCallback(
    (value) => {
      loadOptions({
        stableId: filter.stableId,
        row: filter.row,
        position: value.value,
      });
    },
    [filter, loadOptions]
  );

  const callOnSelectCage = useCallback(
    (value) => {
      onSelectCage(value.value);
      findCage(value.value);
    },
    [findCage, onSelectCage]
  );

  return (
    <>
      <Row className="align-items-end">
        {loading && <Loader />}
        <Col>
          <FormGroup className="mb-0">
            <Label className="mr-sm-2" disabled={disabled}>
              {t('stables')}
            </Label>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              placeholder={`${selectPleaseMessage()}`}
              isDisabled={disabled}
              noOptionsMessage={emptyOptionsMessage}
              onChange={onSelectStable}
              value={selectedStable}
              options={options && options.stables.map((item: SearchResult) => toStableItem(item))}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="mb-0">
            <Label className="mr-sm-2" disabled={disabled}>
              {t('row')}
            </Label>
            <Select
              isd
              className="react-select"
              classNamePrefix="react-select"
              placeholder={`${selectPleaseMessage()}`}
              isDisabled={disabled}
              noOptionsMessage={emptyOptionsMessage}
              onChange={onSelectRow}
              valid
              value={selectedRow}
              options={options && options.rows.map((item: number) => toRowItem(item))}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="mb-0">
            <Label className="mr-sm-2" disabled={disabled}>
              {t('cage-position')}
            </Label>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              placeholder={`${selectPleaseMessage()}`}
              isDisabled={disabled}
              noOptionsMessage={emptyOptionsMessage}
              onChange={onSelectPosition}
              value={selectedPosition}
              options={options && options.positions.map((item: string) => toPositionItem(item))}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="mb-0">
            <Label className="mr-sm-2" disabled={disabled}>
              {t('cage-number')}
            </Label>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              placeholder={`${selectPleaseMessage()}`}
              isDisabled={disabled}
              noOptionsMessage={emptyOptionsMessage}
              onChange={callOnSelectCage}
              value={selectedCage}
              options={options && options.cages.map((item: SearchResult) => toCageItem(item))}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default CageInput;
