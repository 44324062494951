export enum HealthCondition {
  HEALTHY = 'HEALTHY',
  PRODUCTION_SCRAP = 'PRODUCTION_SCRAP',
  DIARRHEA = 'DIARRHEA',
  RESPIRATORY = 'RESPIRATORY',
  ABSCESS = 'ABSCESS',
  FOOT_ULCERS = 'FOOT_ULCERS',
  SKINNY = 'SKINNY',
  TOOTH = 'TOOTH',
  OTHER = 'OTHER',
}
