import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from 'reactstrap';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDownloadSpreadsheet } from '../../hooks/useDownloadFile';
import ImportModal from './importModal';
import { RabbitFilterValues } from './rabbitFilter';
import Spinner from 'hyper/components/Spinner';
import { getLoggedInUser } from 'helpers/authUtils';

interface RabbitImportExportProps {
  id: string;
  filter: RabbitFilterValues;
  sort: { dataField: string; order: 'asc' | 'desc' };
  disabled: boolean;
  disablingCauseText?: string;
}

const fileName = (name: string) =>
  `${name} - ${
    getLoggedInUser().username
  } - ${`${new Date().toLocaleDateString()} -${new Date().toLocaleTimeString()}`}.xlsx`;

function RabbitImportExport({
  id,
  filter,
  sort,
  disabled,
  disablingCauseText,
}: RabbitImportExportProps) {
  const { t } = useTranslation();
  const [isRabbitModalOpen, setRabbitModalOpen] = useState<boolean>(false);
  const [isSpermModalOpen, setSpermModalOpen] = useState<boolean>(false);
  const [download, { loading: downloading }] = useDownloadSpreadsheet();

  const downloadRabbitsExport = useCallback(() => {
    download('/api/v1/export/rabbit', fileName(t('stock-list')), {
      ...filter,
      sortFields: [sort.dataField],
      sortOrder: sort.order,
    });
  }, [download, filter, sort, t]);

  const downloadSpermExport = useCallback(() => {
    download('/api/v1/export/sperm', fileName(t('sperms')), {
      sortFields: [sort.dataField],
      sortOrder: sort.order,
    });
  }, [download, sort, t]);

  const toggleRabbitModal = useCallback(() => setRabbitModalOpen(!isRabbitModalOpen), [
    isRabbitModalOpen,
    setRabbitModalOpen,
  ]);

  const toggleSpermModal = useCallback(() => setSpermModalOpen(!isSpermModalOpen), [
    isSpermModalOpen,
    setSpermModalOpen,
  ]);

  return (
    <span id={id}>
      {disabled && disablingCauseText && disablingCauseText.length && (
        <Row className="text-right">
          <Col>
            <span>{disablingCauseText}</span>
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <UncontrolledButtonDropdown className="float-right">
            <DropdownToggle color="primary" caret={!downloading} disabled={disabled}>
              {t('export')}
              {downloading && (
                <Spinner className="spinner-border-sm ml-1" tag="span" color="white" />
              )}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={downloadRabbitsExport}>{t('rabbits')}</DropdownItem>
              <DropdownItem onClick={downloadSpermExport}>{t('sperms')}</DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>

          <UncontrolledButtonDropdown className="mr-2 float-right">
            <DropdownToggle color="primary" caret disabled={disabled}>
              {t('import')}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={toggleRabbitModal}>{t('rabbits')}</DropdownItem>
              <DropdownItem onClick={toggleSpermModal}>{t('sperms')}</DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>

      <ImportModal isOpen={isRabbitModalOpen} toggle={toggleRabbitModal} modalType="RABBITS" />

      <ImportModal isOpen={isSpermModalOpen} toggle={toggleSpermModal} modalType="SPERMS" />
    </span>
  );
}

export default RabbitImportExport;
