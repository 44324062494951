import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Row, Col, Alert, Form, Button } from 'reactstrap';
import { TFunction } from 'i18next';
import IAGroupCageInput from './iaGroupCageInput';
import ColNumericInput from 'components/rabbit/edit/colNumericInput';
import { isInputValueInvalid } from 'helpers/rabbit';
import Loader from 'hyper/components/Loader';
import { useApiPost } from 'hooks/useApi';

const DEFAULT_ADJUSTED = 8;

function getInputErrors(
  cageFrom: number | undefined,
  cageTo: number | undefined,
  adjusted: number | undefined,
  adjustLimit: number,
  t: TFunction
): string | null {
  const result: string[] = [];
  if (!cageFrom) result.push(t('no-cage-from'));
  if (!cageTo) result.push(t('no-cage-to'));
  if (!adjusted || isInputValueInvalid(adjusted, adjustLimit)) result.push(t('invalid-adjusted'));
  return result.length < 1 ? null : result.join('; ');
}

interface IAGroupEqualizationProps {
  iaGroupId: number;
  cageFrom?: number;
  cageTo?: number;
  adjusted?: number;
  adjustLimit: number;
  autoFillCageTo?: boolean;
  onSaved: () => void;
}

function IAGroupEqualization({
  iaGroupId,
  cageFrom,
  cageTo,
  adjusted,
  adjustLimit,
  autoFillCageTo,
  onSaved,
}: IAGroupEqualizationProps) {
  const { t } = useTranslation();
  const [selectedCageFrom, setSelectedCageFrom] = useState<number | undefined>(cageFrom);
  const [selectedCageTo, setSelectedCageTo] = useState<number | undefined>(cageTo);
  const [equalization, setEqualization] = useState<number | undefined>(
    adjusted || DEFAULT_ADJUSTED
  );
  const [inputMessages, setInputMessages] = useState<string | null>(null);
  const [backendMessages, setBackendMessages] = useState<string[] | null>(null);

  const [saveEqualization, { loading: savingEqualization }] = useApiPost<{ messages: string[] }>(
    `/api/v1/ia-group/${iaGroupId}/equalization`,
    (response) => {
      const res = response.messages && response.messages.length ? response.messages : null;
      setBackendMessages(res);
      !res && onSaved();
    }
  );

  const onEqualizationChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEqualization(+event.target.value);
  }, []);

  const submit = useCallback(() => {
    setBackendMessages(null);
    const errors = getInputErrors(selectedCageFrom, selectedCageTo, equalization, adjustLimit, t);
    setInputMessages(errors);
    !errors &&
      saveEqualization({
        cageFrom: selectedCageFrom,
        cageTo: selectedCageTo,
        adjusted: equalization,
      });
  }, [adjustLimit, equalization, saveEqualization, selectedCageFrom, selectedCageTo, t]);

  const isSaving = useCallback(() => savingEqualization, [savingEqualization]);

  useEffect(() => {
    autoFillCageTo && selectedCageFrom && !selectedCageTo && setSelectedCageTo(selectedCageFrom);
  }, [autoFillCageTo, selectedCageFrom, selectedCageTo]);

  return (
    <>
      {isSaving() && <Loader />}
      {!isSaving() && (
        <>
          <Row>
            <Col xs={5}>
              <Card>
                <CardBody>
                  <h5>{t('cage-from')}</h5>
                  <IAGroupCageInput
                    iaGroupId={iaGroupId}
                    cageId={selectedCageFrom || null}
                    onSelectCage={setSelectedCageFrom}
                    disabled={false}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xs={5}>
              <Card>
                <CardBody>
                  <h5>{t('cage-to')}</h5>
                  <IAGroupCageInput
                    iaGroupId={iaGroupId}
                    cageId={selectedCageTo || null}
                    onSelectCage={setSelectedCageTo}
                    disabled={false}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xs={2}>
              <Card>
                <CardBody className="mt-4">
                  <ColNumericInput
                    onChange={onEqualizationChange}
                    value={equalization || DEFAULT_ADJUSTED}
                    label={'equalized'}
                    max={adjustLimit}
                    invalid={equalization ? equalization > adjustLimit : false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              {inputMessages && (
                <Alert color="danger" className="text-center">
                  {`${t('input-errors')} : ${inputMessages}`}
                </Alert>
              )}
              {backendMessages && backendMessages.length > 0 && (
                <Alert color="danger" className="text-center">
                  {backendMessages.map((msg: string) => (
                    <li key={msg}>{t(msg)}</li>
                  ))}
                </Alert>
              )}
            </Col>
          </Row>
          <Row className="justify-content-end">
            <Col xs={2}>
              <div className="text-center">
                <Form>
                  <Button color="success" onClick={submit}>
                    {t('save-equalization')}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default IAGroupEqualization;
