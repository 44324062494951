import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Input, Label } from 'reactstrap';
import { roundAndRemove } from 'helpers/rabbit';

interface ColNumericInputProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: number | string;
  label: string;
  max?: number;
  disabled?: boolean;
  invalid?: boolean;
}

function ColNumericInput({ onChange, max, value, label, disabled, invalid }: ColNumericInputProps) {
  const { t } = useTranslation();

  return (
    <FormGroup className="mb-0">
      <Label className="mr-sm-2" disabled={disabled}>
        {t(label)}
      </Label>
      <Input
        type="number"
        name={label}
        min="0"
        max={max}
        className="text-right"
        disabled={disabled || value === ''}
        value={value}
        onChange={onChange}
        onInput={roundAndRemove}
        invalid={invalid || value < 0}
      />
    </FormGroup>
  );
}

export default ColNumericInput;
