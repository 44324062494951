import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';
import User from '../models/user';
import AuthUser from '../models/authUser';
import { sendRequest } from 'hyper/helpers/api';
import { UserRole } from 'models/userRole';

type TokenPayload = { exp: number; ROLE: string; FARM_ID: number };

/**
 * Returns the logged in user
 */
const getLoggedInUser = (): AuthUser => {
  const cookies = new Cookies();
  const user = cookies.get('user');
  return user ? (typeof user === 'object' ? user : JSON.parse(user)) : null;
};

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = (): boolean => {
  const user = getLoggedInUser();
  if (!user) {
    return false;
  }
  const decoded = jwtDecode<TokenPayload>(user.token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    console.warn('access token expired');
    return false;
  } else {
    return true;
  }
};

const getUserRole = (): UserRole => {
  const user = getLoggedInUser();
  if (!user) {
    return UserRole.NO_LOGGED_IN_USER;
  }

  const decoded = jwtDecode<TokenPayload>(user.token);

  return decoded.ROLE as UserRole;
};

const hasRole = (role: UserRole): boolean => role === getUserRole();

const getUserFarm = (): number | null => {
  const user = getLoggedInUser();
  if (!user) {
    return null;
  }

  const decoded = jwtDecode<TokenPayload>(user.token);

  return decoded.FARM_ID;
};

// user with role [key] can create user with role [value]
const userCreateRoleMapping: { [key: string]: string } = {
  SUPERADMIN: UserRole.OWNER,
  OWNER: UserRole.ADMIN,
  ADMIN: UserRole.USER,
};

const setNewUserRole = (role?: UserRole) => {
  const cookies = new Cookies();
  if (role) cookies.set('role', role, { path: '/' });
  else cookies.remove('role', { path: '/' });
};

const getNewUserRole = (): UserRole => {
  const cookies = new Cookies();
  return cookies.get('role') as UserRole;
};

const saveUser = (newUser: User): Promise<Response> => {
  const loggedInUser = getLoggedInUser();

  if (!newUser.id) {
    const newUserRole = getNewUserRole();
    newUser.role = newUserRole || userCreateRoleMapping[getUserRole()];
  }

  const options = {
    body: JSON.stringify(newUser),
    method: newUser.id ? 'PUT' : 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  if (loggedInUser.role === UserRole.ADMIN) {
    newUser.assignedFarm = getUserFarm();
  }

  const path = newUser.id ? `/api/v1/user/${newUser.id}` : '/api/v1/user';

  return sendRequest(path, options);
};

export {
  isUserAuthenticated,
  getLoggedInUser,
  hasRole,
  saveUser,
  getUserFarm,
  getUserRole,
  setNewUserRole,
  getNewUserRole,
};
