import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import '../../../../css/modal.css';
import { TFunction } from 'i18next';
import { dateStr, getSlaughteringCreateDate, isDateBetweenRange } from '../../gpUtil';
import ModalError from '../../modalError';
import { useApi } from 'hooks/useApi';
import Loader from 'hyper/components/Loader';
import Datepicker from 'hyper/components/Datepicker';
import { useInput } from 'hooks/useInput';
import { ValueType } from 'models/valueType';
import { roundAndRemove } from 'helpers/rabbit';
import SlaughteringTransfer from 'models/grouppages/slaughteringTransfer';

const NUM_LIMIT = 30000;
const WEIGHT_LIMIT = 90000;

const FIELDS = [
  'abscess',
  'polyser',
  'anorexia',
  'bloat',
  'purChest',
  'broken',
  'pneumonia',
  'trDead',
];

function isNumWeightValid(slaughtering: SlaughteringTransfer, field: string): boolean {
  const num = slaughtering[`${field}Num` as keyof SlaughteringTransfer] as number;
  const weight = slaughtering[`${field}Weight` as keyof SlaughteringTransfer] as number;
  return (num === 0 && weight === 0) || (num > 0 && weight > 0);
}

function isNumAndBothValid(slaughtering: SlaughteringTransfer, field: string): boolean {
  const num = slaughtering[`${field}Num` as keyof SlaughteringTransfer] as number;
  if (num < 0 || NUM_LIMIT < num) return false;
  return isNumWeightValid(slaughtering, field);
}

function isWeightAndBothValid(slaughtering: SlaughteringTransfer, field: string): boolean {
  const weight = slaughtering[`${field}Weight` as keyof SlaughteringTransfer] as number;
  if (weight < 0 || WEIGHT_LIMIT < weight) return false;
  return isNumWeightValid(slaughtering, field);
}

function isBothValid(slaughtering: SlaughteringTransfer): boolean {
  const num = slaughtering['num' as keyof SlaughteringTransfer] as number;
  const weight = slaughtering['weight' as keyof SlaughteringTransfer] as number;
  return (num === 0 && weight === 0) || (num > 0 && weight > 0);
}

function isNumAndWeightValid(
  slaughtering: SlaughteringTransfer,
  actualNum: number | undefined
): boolean {
  const num = slaughtering['num' as keyof SlaughteringTransfer] as number;
  if (num <= 0 || (actualNum === undefined ? NUM_LIMIT : actualNum) < num) return false;
  return isBothValid(slaughtering);
}

function isWeightAndNumValid(slaughtering: SlaughteringTransfer): boolean {
  const weight = slaughtering['weight' as keyof SlaughteringTransfer] as number;
  if (weight < 0 || WEIGHT_LIMIT < weight) return false;
  return isBothValid(slaughtering);
}

function getCreateDate(slaughtering: SlaughteringTransfer, endDate: Date | undefined): Date {
  const last = endDate ? new Date(endDate) : new Date();
  const when = getSlaughteringCreateDate(slaughtering);
  if (!when) return last;
  if (when <= last) return when;
  return last;
}

function createTableRow(
  slaughtering: SlaughteringTransfer,
  field: string,
  t: TFunction,
  onSimpleValueChange: (
    key: React.ReactText,
    valueType: ValueType
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void
): JSX.Element {
  const ttId = `id-${field}`;
  const numName = `${field}Num`;
  const weightName = `${field}Weight`;
  const forLabel = field.toLowerCase();
  return (
    <Row key={`row-${field}`} className="d-flex align-items-center mt-1">
      <Col className="mr-1 text-right" sm="4">
        <b id={ttId}>{t(`slaughter-${forLabel}`)}</b>
        <UncontrolledTooltip placement="top" target={ttId}>
          {t(`slaughter-${forLabel}-tt`)}
        </UncontrolledTooltip>
      </Col>
      <Col className="" sm="3">
        <Input
          type="number"
          className="text-right"
          min="0"
          max={NUM_LIMIT}
          data-cy={`slaughtering-${numName}`}
          defaultValue={slaughtering[numName as keyof SlaughteringTransfer] as number}
          onChange={onSimpleValueChange(numName as keyof SlaughteringTransfer, ValueType.NUMBER)}
          onInput={roundAndRemove}
          invalid={!isNumAndBothValid(slaughtering, field)}
        />
      </Col>
      <Col className="" sm="3">
        <Input
          type="number"
          className="text-right"
          min="0"
          max={WEIGHT_LIMIT}
          data-cy={`slaughtering-${weightName}`}
          defaultValue={slaughtering[weightName as keyof SlaughteringTransfer] as number}
          onChange={onSimpleValueChange(weightName as keyof SlaughteringTransfer, ValueType.NUMBER)}
          onInput={roundAndRemove}
          invalid={!isWeightAndBothValid(slaughtering, field)}
        />
      </Col>
    </Row>
  );
}

function createTableRows(
  slaughtering: SlaughteringTransfer,
  t: TFunction,
  onSimpleValueChange: (
    key: React.ReactText,
    valueType: ValueType
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void
): JSX.Element[] {
  const result = [] as JSX.Element[];
  for (const field of FIELDS) {
    result.push(createTableRow(slaughtering, field, t, onSimpleValueChange));
  }
  return result;
}

function isAllValueValid(
  startDate: Date | undefined,
  endDate: Date | undefined,
  actualNum: number | undefined,
  slaughtering: SlaughteringTransfer
) {
  const createDate = getSlaughteringCreateDate(slaughtering);
  const needDateCheck = !slaughtering.id && createDate;
  const dateValid = needDateCheck ? isDateBetweenRange(createDate, startDate, endDate) : true;
  const numbersValid = isNumAndWeightValid(slaughtering, actualNum);
  if (!dateValid || !numbersValid) return false;
  for (const field of FIELDS) {
    if (!isNumAndBothValid(slaughtering, field)) return false;
  }

  return true;
}

interface EditFatteningSlaughteringProps {
  groupId: number;
  startDate?: Date;
  endDate?: Date;
  actualNum?: number;
  isOpen: boolean;
  originalSlaughtering: SlaughteringTransfer;
  onSave: () => void;
  toggle: () => void;
}

function EditFatteningSlaughteringModal({
  groupId,
  startDate,
  endDate,
  actualNum,
  isOpen,
  originalSlaughtering,
  toggle,
  onSave,
}: EditFatteningSlaughteringProps) {
  const { t } = useTranslation();
  const [editSlaughtering, setEditSlaughtering, onSimpleValueChange] = useInput<
    SlaughteringTransfer
  >({
    ...originalSlaughtering,
  });
  const [isAllValid, setIsAllValid] = useState<boolean>(true);
  const [backendMessages, setBackendMessages] = useState<string[] | null>(null);
  const { put, post, loading: saving } = useApi();
  const [initialDate, setInitialDate] = useState<Date>(new Date());

  const onData = useCallback(() => {
    setBackendMessages(null);
    onSave();
    toggle();
  }, [onSave, toggle]);

  const onError = useCallback((error: any) => {
    if (error.status && error.status === 405) return setBackendMessages(['SAVING_ERROR']);

    if (Array.isArray(error)) return setBackendMessages(error);

    if (`${error}`.includes('NetworkError')) return setBackendMessages(['NETWORK_ERROR']);

    setBackendMessages(['SAVING_ERROR']);
  }, []);

  const createSlaughtering = useCallback(
    (groupId: number, slaughtering: SlaughteringTransfer) => {
      post(`/api/v1/group/fattening/${groupId}/slaughtering`, onData, onError, slaughtering);
    },
    [onData, onError, post]
  );

  const saveSlaughtering = useCallback(
    (groupId: number, slaughteringId: number, data: SlaughteringTransfer) => {
      put(
        `/api/v1/group/fattening/${groupId}/slaughtering/${slaughteringId}`,
        onData,
        onError,
        data
      );
    },
    [onData, onError, put]
  );

  const doSave = useCallback(
    (slaughtering: SlaughteringTransfer) => {
      slaughtering.id
        ? saveSlaughtering(groupId, slaughtering.id ?? 0, slaughtering)
        : createSlaughtering(groupId, slaughtering);
    },
    [createSlaughtering, groupId, saveSlaughtering]
  );

  useEffect(() => {
    if (isOpen) {
      setEditSlaughtering({ ...originalSlaughtering });
    }
  }, [isOpen, originalSlaughtering, setEditSlaughtering]);

  useEffect(() => {
    setIsAllValid(true);
    setBackendMessages(null);
  }, [editSlaughtering]);

  const submit = useCallback(() => {
    const isValid = isAllValueValid(startDate, endDate, actualNum, editSlaughtering);

    if (isValid) {
      setBackendMessages(null);
      doSave(editSlaughtering);
    }
    setIsAllValid(isValid);
  }, [doSave, editSlaughtering, endDate, startDate, actualNum]);

  const getDayClass = useCallback(
    (date: Date) =>
      isDateBetweenRange(date, startDate, endDate) ? 'available-days' : 'disabled-days',
    [endDate, startDate]
  );

  const onCreateDateChange = useCallback(
    (when: Date) => {
      setEditSlaughtering((slaughtering) => ({ ...slaughtering, createDate: when }));
    },
    [setEditSlaughtering]
  );

  useEffect(() => {
    if (isOpen) {
      const tmp = endDate ? new Date(endDate) : new Date();
      const when = new Date(dateStr(tmp));
      setInitialDate(when);
      setEditSlaughtering((slaughtering) => ({
        ...slaughtering,
        createDate: slaughtering.id ? slaughtering.createDate : when,
      }));
    }
  }, [endDate, isOpen, setEditSlaughtering]);

  const isLoading = useCallback(() => saving, [saving]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="modal-content-size"
      className="modal-align"
    >
      <ModalHeader toggle={toggle}>{`${t('slaughter-modal-title')}`}</ModalHeader>
      <ModalBody>
        {isLoading() && <Loader />}
        <Form className="form d-flex flex-column justify-content-center">
          <Row className="d-flex align-items-center">
            <Col className="ml-1">
              <b>{t('slaughter-slaughtering')}</b>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-2 mt-2">
            <Col className="mr-1 text-right" sm="4">
              <b>{t('slaughter-date')}</b>
              <span className="text-danger">*</span>
            </Col>
            <Col className="" sm="3">
              <Datepicker
                disabled={!!editSlaughtering.id}
                startDate={getCreateDate(editSlaughtering, initialDate)}
                dateFormat="yyyy-MM-dd"
                minDate={startDate && new Date(startDate)}
                maxDate={endDate && new Date(endDate)}
                dayClassName={getDayClass}
                onDateChange={onCreateDateChange}
              />
            </Col>
            <Col className="" sm="3">
              {!!editSlaughtering.id && <b>{t('slaughter-cant-modify')}</b>}
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col className="mr-1 text-right" sm="4">
              <b>{t('slaughter-lot')}</b>
            </Col>
            <Col className="" sm="6">
              <Input
                type="text"
                data-cy={'slaughtering-lot'}
                value={editSlaughtering.lot || ''}
                autoComplete="off"
                onChange={onSimpleValueChange('lot', ValueType.STRING)}
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-center mt-1">
            <Col className="mr-1 text-right" sm="4">
              <b>{t('slaughter-item-no')}</b>
            </Col>
            <Col className="" sm="6">
              <Input
                type="text"
                data-cy={'slaughtering-itemno'}
                value={editSlaughtering.itemNo || ''}
                autoComplete="off"
                onChange={onSimpleValueChange('itemNo', ValueType.STRING)}
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-center mt-1">
            <Col className="mr-1 text-right" sm="4">
              <b>{t('slaughter-item')}</b>
            </Col>
            <Col className="" sm="6">
              <Input
                type="text"
                data-cy={'slaughtering-item'}
                value={editSlaughtering.item || ''}
                autoComplete="off"
                onChange={onSimpleValueChange('item', ValueType.STRING)}
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-center mt-1">
            <Col sm="4" />
            <Col className="text-center" sm="3">
              <b>{t('slaughter-number')}</b>
              <span className="text-danger">*</span>
            </Col>
            <Col className="text-center" sm="3">
              <b>{t('slaughter-weight-kg')}</b>
              <span className="text-danger">*</span>
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col sm="4" />
            <Col className="ml-1" sm="3">
              <Input
                type="number"
                className="text-right"
                min="0"
                max={NUM_LIMIT}
                data-cy={'slaughtering-num'}
                defaultValue={editSlaughtering.num}
                onChange={onSimpleValueChange('num', ValueType.NUMBER)}
                onInput={roundAndRemove}
                invalid={!isNumAndWeightValid(editSlaughtering, actualNum)}
              />
            </Col>
            <Col className="mr-1" sm="3">
              <Input
                type="number"
                className="text-right"
                min="0"
                max={WEIGHT_LIMIT}
                data-cy={'slaughtering-weight'}
                defaultValue={editSlaughtering.weight}
                onChange={onSimpleValueChange('weight', ValueType.NUMBER)}
                onInput={roundAndRemove}
                invalid={!isWeightAndNumValid(editSlaughtering)}
              />
            </Col>
          </Row>

          <Row className="d-flex align-items-center mb-2 mt-3">
            <Col>
              <div className="border border-1" />
            </Col>
          </Row>

          <Row className="d-flex align-items-center">
            <Col className="ml-1" sm="4">
              <b>{t('slaughter-condemnation')}</b>
            </Col>
            <Col className="text-center" sm="3">
              <b>{t('slaughter-num')}</b>
            </Col>
            <Col className="text-center" sm="3">
              <b>{t('slaughter-kg')}</b>
            </Col>
          </Row>

          {createTableRows(editSlaughtering, t, onSimpleValueChange)}

          <Row className="mt-2">
            <Col>
              <ModalError isInputValid={isAllValid} serverErrors={backendMessages} />
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={submit} className="mr-2">
          {t('save')}
        </Button>
        <Button color="primary" onClick={toggle}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditFatteningSlaughteringModal;
