import React, { useState, useCallback, useEffect } from 'react';
import { FormGroup, Label, Col, Row, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import RabbitFilterOptions from '../../models/rabbitFilterOptions';
import { CommandStatus } from 'models/commandStatus';
import { CommandSource } from 'models/commandSource';
import { useApiGet } from 'hooks/useApi';
import SearchResult from 'models/searchResult';

interface MultiSelectOption {
  key: string;
  value: any;
  label: string;
}

function getOptionValues(
  values: string[] | CommandStatus[] | CommandSource[] | null | undefined
): MultiSelectOption[] {
  const result = [] as MultiSelectOption[];
  if (values) {
    for (const value of values) {
      result.push({ key: `${value}`, label: `${value}`, value });
    }
  }
  return result;
}

function getSelectOptions(commandTypes: string[] | null) {
  return {
    commandOptions: getOptionValues(commandTypes),
    statusOptions: getOptionValues(Object.values(CommandStatus)),
    sourceOptions: getOptionValues(Object.values(CommandSource)),
  };
}

export interface CommandFilterValues {
  farmId: number | null | undefined;
  username: string | null | undefined;
  commands: string[];
  statuses: CommandStatus[];
  sources: CommandSource[] | null;
}

interface CommandFilterProps {
  outerFilter: CommandFilterValues;
  onFilter: (filter: CommandFilterValues) => void;
  onFilterChange: (filter: CommandFilterValues) => void;
}

interface Options {
  commandOptions: MultiSelectOption[];
  statusOptions: MultiSelectOption[];
  sourceOptions: MultiSelectOption[];
}

const initOptions = {
  commandOptions: [],
  statusOptions: [],
  sourceOptions: [],
};

function CommandFilter({ outerFilter, onFilter, onFilterChange }: CommandFilterProps) {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<CommandFilterValues>(outerFilter);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectOptions, setSelectOptions] = useState<Options>(initOptions);
  const { data: farms } = useApiGet<SearchResult[]>('/api/v1/search/farm?search=');
  const { data: commandTypes } = useApiGet<string[]>('/api/v1/command-names');

  const baseOnChange = useCallback(
    (field: keyof CommandFilterValues, value: unknown) => {
      let newFilter: CommandFilterValues;
      if (!value || (Array.isArray(value) && !value.length)) {
        newFilter = { ...filter };
        delete newFilter[field];
      } else {
        newFilter = { ...filter, [field]: value };
      }
      setFilter(newFilter);
      onFilterChange(newFilter);
    },
    [filter, onFilterChange]
  );

  const onSelectChange = useCallback(
    (field: keyof CommandFilterValues) => (value: any) => {
      const newValue = Array.isArray(value) ? value.map(({ value }) => value) : value?.value;
      baseOnChange(field, newValue);
    },
    [baseOnChange]
  );

  const doFilter = useCallback(
    (filter: CommandFilterValues) => {
      onFilter(filter);
    },
    [onFilter]
  );

  useEffect(() => {
    setSelectOptions(getSelectOptions(commandTypes));
  }, [commandTypes]);

  const handleBaseEnterDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Enter') {
        doFilter(filter);
      }
    },
    [doFilter, filter]
  );

  const handleEnterDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (!menuOpen) {
        handleBaseEnterDown(event);
      }
    },
    [handleBaseEnterDown, menuOpen]
  );

  const onSimpleValueChange = (key: keyof CommandFilterValues) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    baseOnChange(key, event.target.value);
  };

  const menuOpened = useCallback(() => setMenuOpen(true), []);

  const menuClosed = useCallback(() => setMenuOpen(false), []);

  return (
    <>
      <Row className="align-items-end">
        <Col md="2">
          <FormGroup className="mb-0">
            <Label className="mr-sm-2 mb-0">{t('command-farm')}</Label>
            <Select
              className="react-select"
              classNamePrefix="react-select"
              isClearable
              value={
                filter.farmId === -1
                  ? { value: -1, label: t('ear-tag-no-farm') }
                  : {
                      value: farms?.find((f) => f.id === filter.farmId)?.id || 0,
                      label: farms?.find((f) => f.id === filter.farmId)?.label || '',
                    }
              }
              onChange={onSelectChange('farmId')}
              options={[
                ...(farms?.map((farm) => ({ value: farm.id || 0, label: farm.label })) || []),
              ]}
              onKeyDown={handleEnterDown}
              onMenuOpen={menuOpened}
              onMenuClose={menuClosed}
            />
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup className="mb-0">
            <Label className="mr-sm-2 mb-0">{t('command-user')}</Label>
            <Input
              defaultValue={filter.username || ''}
              onChange={onSimpleValueChange('username')}
              onKeyDown={handleEnterDown}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="mb-0">
            <Label className="mr-sm-2 mb-0">{t('command-status')}</Label>
            <Select
              isMulti
              isClearable
              className="react-select"
              classNamePrefix="react-select"
              placeholder={t('command-status')}
              onChange={onSelectChange('statuses')}
              value={selectOptions.statusOptions.filter(({ value }) =>
                filter.statuses?.includes(value)
              )}
              options={selectOptions.statusOptions}
              onKeyDown={handleEnterDown}
              onMenuOpen={menuOpened}
              onMenuClose={menuClosed}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup className="mb-0">
            <Label className="mr-sm-2 mb-0">{t('command-source')}</Label>
            <Select
              isMulti
              isClearable
              className="react-select"
              classNamePrefix="react-select"
              placeholder={t('command-source')}
              onChange={onSelectChange('sources')}
              value={selectOptions.sourceOptions.filter(({ value }) =>
                filter.sources?.includes(value)
              )}
              options={selectOptions.sourceOptions}
              onKeyDown={handleEnterDown}
              onMenuOpen={menuOpened}
              onMenuClose={menuClosed}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="align-items-end mt-1">
        <Col>
          <FormGroup className="mb-0">
            <Label className="mr-sm-2 mb-0">{t('command-name')}</Label>
            <Select
              isMulti
              isClearable
              className="react-select"
              classNamePrefix="react-select"
              placeholder={t('command-name')}
              onChange={onSelectChange('commands')}
              value={selectOptions.commandOptions.filter(({ value }) =>
                filter.commands?.includes(value)
              )}
              options={selectOptions.commandOptions}
              onKeyDown={handleEnterDown}
              onMenuOpen={menuOpened}
              onMenuClose={menuClosed}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default CommandFilter;
