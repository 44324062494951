import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  ModalFooter,
  Container,
  Row,
  Col,
  Alert,
} from 'reactstrap';
import '../../../../css/modal.css';
import moment from 'moment';
import { useApiPut } from 'hooks/useApi';
import Loader from 'hyper/components/Loader';
import MotherNumTransfer from 'models/grouppages/motherNumTransfer';
import { isInputValuesBetweenLimit, roundAndRemove } from 'helpers/rabbit';
import Datepicker from 'hyper/components/Datepicker';
import { isDateBetweenRange } from 'components/grouppages/gpUtil';

import { useInput } from 'hooks/useInput';
import { ValueType } from 'models/valueType';

const LIMIT = 40000;

interface EditRabbitDetailsProps {
  id: number | undefined;
  touchingDate: Date | undefined;
  farrowingDate: Date | undefined;
  onSave: () => void;
  isOpen: boolean;
  toggle: () => void;
  motherDetails: MotherNumTransfer | undefined;
}

function isMotherDetailsValid(
  editMotherDetails: MotherNumTransfer | undefined,
  touchingDate: Date | undefined,
  farrowingDate: Date | undefined
) {
  let valid = true;

  valid =
    isDateBetweenRange(editMotherDetails?.populatedFrom, touchingDate, farrowingDate) &&
    isDateBetweenRange(editMotherDetails?.populatedTo, touchingDate, farrowingDate) &&
    isInputValuesBetweenLimit(editMotherDetails, [], LIMIT);

  if (valid && editMotherDetails?.populatedFrom && editMotherDetails?.populatedTo) {
    const from = new Date(editMotherDetails?.populatedFrom);
    const to = new Date(editMotherDetails?.populatedTo);
    valid = from <= to;
  }

  return valid;
}

function getFirstNonEmptyDate(...params: (Date | string | null | undefined)[]): Date {
  for (const param of params) {
    if (param) return new Date(param);
  }
  return new Date();
}

function getFirstNonEmptyDateAsLocalDate(...params: (Date | string | null | undefined)[]): Date {
  const dateStr = moment(getFirstNonEmptyDate(...params))
    .local()
    .format('YYYY-MM-DD');
  return new Date(dateStr);
}

function EditMotherNumModal({
  id,
  touchingDate,
  farrowingDate,
  isOpen,
  toggle,
  onSave,
  motherDetails,
}: EditRabbitDetailsProps) {
  const { t } = useTranslation();
  const [editMotherDetails, setEditMotherDetails, onSimpleValueChange] = useInput<
    MotherNumTransfer | undefined
  >(motherDetails);
  const [isAllValid, setIsAllValid] = useState<boolean>(true);
  const [backendMessages, setBackendMessages] = useState<MotherNumTransfer | null>(null);
  const [saveMotherNumDetails, { loading: saving }] = useApiPut<{ messages: string[] }>(
    `/api/v1/group/mother/${id}/mothers`,
    (response) => {
      if (response.messages && response.messages.length > 0) {
        setBackendMessages(response.messages);
      } else {
        setBackendMessages(null);
        onSave();
        toggle();
      }
    }
  );
  const [settleFromLower, setSettleFromLower] = useState<Date>();
  const [settleFromHigher, setSettleFromHigher] = useState<Date>();
  const [settleToLower, setSettleToLower] = useState<Date>();
  const [settleToHigher, setSettleToHigher] = useState<Date>();

  useEffect(() => {
    if (isOpen) {
      const preparedMotherDetails: MotherNumTransfer = {
        ...motherDetails,
        populatedFrom: getFirstNonEmptyDateAsLocalDate(motherDetails?.populatedFrom, touchingDate),
        populatedTo: getFirstNonEmptyDateAsLocalDate(motherDetails?.populatedTo, farrowingDate),
      };
      setEditMotherDetails(preparedMotherDetails);
    }
  }, [farrowingDate, isOpen, motherDetails, setEditMotherDetails, touchingDate]);

  useEffect(() => {
    setIsAllValid(true);
    setBackendMessages(null);
    setSettleFromLower(getFirstNonEmptyDateAsLocalDate(touchingDate));
    setSettleFromHigher(
      getFirstNonEmptyDateAsLocalDate(editMotherDetails?.populatedTo, farrowingDate)
    );
    setSettleToLower(
      getFirstNonEmptyDateAsLocalDate(editMotherDetails?.populatedFrom, touchingDate)
    );
    setSettleToHigher(getFirstNonEmptyDateAsLocalDate(farrowingDate));
  }, [editMotherDetails, farrowingDate, touchingDate]);

  const submit = useCallback(() => {
    const isValid = isMotherDetailsValid(editMotherDetails, touchingDate, farrowingDate);

    if (isValid) {
      setBackendMessages(null);
      saveMotherNumDetails(editMotherDetails);
    }
    setIsAllValid(isValid);
  }, [editMotherDetails, farrowingDate, saveMotherNumDetails, touchingDate]);

  const isInvalid = useCallback(
    (value: number | undefined) => (value && value > LIMIT) || false,
    []
  );

  const onPopulatedDateChange = useCallback(
    (when: Date, key: keyof MotherNumTransfer) => {
      const copy: MotherNumTransfer = { ...editMotherDetails, [key]: when };
      setEditMotherDetails(copy);
    },
    [editMotherDetails, setEditMotherDetails]
  );

  const getDayClass = useCallback(
    (fromDate: Date | undefined, toDate: Date | undefined) => (date: Date) =>
      isDateBetweenRange(date, fromDate, toDate) ? 'available-days' : 'disabled-days',
    []
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="modal-content-size"
      className="modal-align"
    >
      <ModalHeader toggle={toggle}>{`${t('edit-mother-number')}`}</ModalHeader>
      <ModalBody>
        {saving && <Loader />}
        <Container fluid>
          <Row className="" />
        </Container>
        <Form className="form d-flex flex-column justify-content-center">
          <div className="" />
          <Row className="mb-2">
            <Col />
            <Col className="text-center">
              <b> {t('ia')}</b>
            </Col>
            <Col className="text-center">
              <b>{t('pregnant')}</b>
            </Col>
            <Col className="text-center">
              <b>{t('populated')}</b>
            </Col>
            <Col className="text-center">
              <b>{t('farrowed')}</b>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center align-items-center">
              <b>{t('nursing')}</b>
            </Col>
            <Col>
              <Input
                type="number"
                name={'iaIncycle'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editMotherDetails?.iaIncycle)}
                className="text-right"
                defaultValue={editMotherDetails?.iaIncycle}
                onInput={roundAndRemove}
                onChange={onSimpleValueChange('iaIncycle', ValueType.NUMBER)}
              />
            </Col>
            <Col>
              <Input
                type="number"
                name={'pregnantIncycle'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editMotherDetails?.pregnantIncycle)}
                className="text-right"
                defaultValue={editMotherDetails?.pregnantIncycle}
                onInput={roundAndRemove}
                onChange={onSimpleValueChange('pregnantIncycle', ValueType.NUMBER)}
              />
            </Col>
            <Col>
              <Input
                type="number"
                name={'populatedIncycle'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editMotherDetails?.populatedIncycle)}
                className="text-right"
                defaultValue={editMotherDetails?.populatedIncycle}
                onInput={roundAndRemove}
                onChange={onSimpleValueChange('populatedIncycle', ValueType.NUMBER)}
              />
            </Col>
            <Col>
              <Input
                type="number"
                name={'farrowedIncycle'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editMotherDetails?.farrowedIncycle)}
                className="text-right"
                defaultValue={editMotherDetails?.farrowedIncycle}
                onInput={roundAndRemove}
                onChange={onSimpleValueChange('farrowedIncycle', ValueType.NUMBER)}
              />
            </Col>
          </Row>
          <Row className="my-1">
            <Col className="d-flex justify-content-center align-items-center">
              <b>{t('outcycle')}</b>
            </Col>
            <Col>
              <Input
                type="number"
                name={'iaOutcycle'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editMotherDetails?.iaOutcycle)}
                className="text-right"
                defaultValue={editMotherDetails?.iaOutcycle}
                onInput={roundAndRemove}
                onChange={onSimpleValueChange('iaOutcycle', ValueType.NUMBER)}
              />
            </Col>
            <Col>
              <Input
                type="number"
                name={'pregnantOutcycle'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editMotherDetails?.pregnantOutcycle)}
                className="text-right"
                defaultValue={editMotherDetails?.pregnantOutcycle}
                onInput={roundAndRemove}
                onChange={onSimpleValueChange('pregnantOutcycle', ValueType.NUMBER)}
              />
            </Col>
            <Col>
              <Input
                type="number"
                name={'populatedOutcycle'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editMotherDetails?.populatedOutcycle)}
                className="text-right"
                defaultValue={editMotherDetails?.populatedOutcycle}
                onInput={roundAndRemove}
                onChange={onSimpleValueChange('populatedOutcycle', ValueType.NUMBER)}
              />
            </Col>
            <Col>
              <Input
                type="number"
                name={'farrowedOutcycle'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editMotherDetails?.farrowedOutcycle)}
                className="text-right"
                defaultValue={editMotherDetails?.farrowedOutcycle}
                onInput={roundAndRemove}
                onChange={onSimpleValueChange('farrowedOutcycle', ValueType.NUMBER)}
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center align-items-center">
              <b>{t('virgin')}</b>
            </Col>
            <Col>
              <Input
                type="number"
                name={'iaVirgin'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editMotherDetails?.iaVirgin)}
                className="text-right"
                defaultValue={editMotherDetails?.iaVirgin}
                onChange={onSimpleValueChange('iaVirgin', ValueType.NUMBER)}
                onInput={roundAndRemove}
              />
            </Col>
            <Col>
              <Input
                type="number"
                name={'pregnantVirgin'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editMotherDetails?.pregnantVirgin)}
                className="text-right"
                defaultValue={editMotherDetails?.pregnantVirgin}
                onChange={onSimpleValueChange('pregnantVirgin', ValueType.NUMBER)}
                onInput={roundAndRemove}
              />
            </Col>
            <Col>
              <Input
                type="number"
                name={'populatedVirgin'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editMotherDetails?.populatedVirgin)}
                className="text-right"
                defaultValue={editMotherDetails?.populatedVirgin}
                onChange={onSimpleValueChange('populatedVirgin', ValueType.NUMBER)}
                onInput={roundAndRemove}
              />
            </Col>
            <Col>
              <Input
                type="number"
                name={'farrowedVirgin'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editMotherDetails?.farrowedVirgin)}
                className="text-right"
                defaultValue={editMotherDetails?.farrowedVirgin}
                onChange={onSimpleValueChange('farrowedVirgin', ValueType.NUMBER)}
                onInput={roundAndRemove}
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col />
            <Col className="text-center">
              <b>{t('populated-when-from')}</b>
            </Col>
            <Col className="text-center">
              <b>{t('populated-when-to')}</b>
            </Col>
            <Col />
          </Row>
          <Row>
            <Col className="d-flex justify-content-center align-items-center">
              <b>{t('settling')}</b>
            </Col>
            <Col>
              <Datepicker
                startDate={getFirstNonEmptyDateAsLocalDate(
                  editMotherDetails?.populatedFrom,
                  touchingDate
                )}
                dateFormat="yyyy-MM-dd"
                minDate={settleFromLower}
                maxDate={settleFromHigher}
                dayClassName={getDayClass(settleFromLower, settleFromHigher)}
                onDateChange={(date: Date) => onPopulatedDateChange(date, 'populatedFrom')}
              />
            </Col>
            <Col>
              <Datepicker
                startDate={getFirstNonEmptyDateAsLocalDate(
                  editMotherDetails?.populatedTo,
                  editMotherDetails?.populatedFrom,
                  farrowingDate
                )}
                dateFormat="yyyy-MM-dd"
                minDate={settleToLower}
                maxDate={settleToHigher}
                dayClassName={getDayClass(settleToLower, settleToHigher)}
                onDateChange={(date: Date) => onPopulatedDateChange(date, 'populatedTo')}
              />
            </Col>
            <Col />
          </Row>

          <Row className="mt-4">
            <Col xs="4" className="text-center" />
            <Col xs="3" className="text-center">
              <b>{t('mgpt2-nursing')}</b>
            </Col>
            <Col xs="3" className="text-center">
              <b>{t('mgpt2-weaning')}</b>
            </Col>
          </Row>
          <Row className="my-1">
            <Col xs="4" className="d-flex justify-content-center align-items-center">
              <b>
                {t('nursing')} + {t('outcycle')}
              </b>
            </Col>
            <Col xs="3">
              <Input
                type="number"
                name={'nursingMother'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editMotherDetails?.nursingMother)}
                className="text-right"
                defaultValue={editMotherDetails?.nursingMother}
                onChange={onSimpleValueChange('nursingMother', ValueType.NUMBER)}
                onInput={roundAndRemove}
              />
            </Col>
            <Col xs="3">
              <Input
                type="number"
                name={'weanedMother'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editMotherDetails?.weanedMother)}
                className="text-right"
                defaultValue={editMotherDetails?.weanedMother}
                onChange={onSimpleValueChange('weanedMother', ValueType.NUMBER)}
                onInput={roundAndRemove}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="4" className="d-flex justify-content-center align-items-center">
              <b>{t('virgin')}</b>
            </Col>
            <Col xs="3">
              <Input
                type="number"
                name={'nursingMother'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editMotherDetails?.nursingMotherVirgin)}
                className="text-right"
                defaultValue={editMotherDetails?.nursingMotherVirgin}
                onChange={onSimpleValueChange('nursingMotherVirgin', ValueType.NUMBER)}
                onInput={roundAndRemove}
              />
            </Col>
            <Col xs="3">
              <Input
                type="number"
                name={'weanedMother'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editMotherDetails?.weanedMotherVirgin)}
                className="text-right"
                defaultValue={editMotherDetails?.weanedMotherVirgin}
                onChange={onSimpleValueChange('weanedMotherVirgin', ValueType.NUMBER)}
                onInput={roundAndRemove}
              />
            </Col>
          </Row>

          <Row className="mt-4 my-1">
            <Col className="d-flex justify-content-center align-items-center">
              <b>{t('group-sum-nextIAVirgin')}</b>
            </Col>
            <Col>
              <Input
                type="number"
                name={'nextIAVirgin'}
                min="0"
                max={LIMIT}
                invalid={isInvalid(editMotherDetails?.nextIAVirgin)}
                className="text-right"
                defaultValue={editMotherDetails?.nextIAVirgin}
                onInput={roundAndRemove}
                onChange={onSimpleValueChange('nextIAVirgin', ValueType.NUMBER)}
              />
            </Col>
            <Col />
            <Col />
            <Col />
          </Row>

          <Row className="pt-2">
            <Col>
              {!isAllValid && (
                <Alert color="danger" className="text-center">
                  {t('form-has-errors')}
                </Alert>
              )}
              {backendMessages && backendMessages.length > 0 && (
                <Alert color="danger" className="text-center">
                  {backendMessages.map((msg: string) => (
                    <li key={msg}>{t(msg)}</li>
                  ))}
                </Alert>
              )}
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={submit} className="mr-2">
          {t('save')}
        </Button>
        <Button color="primary" onClick={toggle}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditMotherNumModal;
