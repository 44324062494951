import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  ModalFooter,
  Container,
  Row,
  Col,
  Alert,
} from 'reactstrap';
import '../../../../css/modal.css';
import cloneDeep from 'lodash/cloneDeep';
import { TFunction } from 'i18next';
import { dateStr, dateToStr, FEED_LIMIT } from '../../gpUtil';
import HealthDetails from '../common/healthDetails';
import FeedDetails from '../common/feedDetails';
import Loader from 'hyper/components/Loader';
import MotherDiaryEditTransfer from 'models/grouppages/motherDiaryEditTransfer';
import MotherDiaryElementTransfer from 'models/grouppages/motherDiaryElementTransfer';
import { useInput } from 'hooks/useInput';
import { ValueType } from 'models/valueType';
import { isInputValuesBetweenLimit, LimitException, roundAndRemove } from 'helpers/rabbit';
import { useIsMounted } from 'hooks/useIsMounted';

const LIMIT = 20000;

const GPD_COMMENT = 'gpd-comment';

const excludedKeys = ['id', 'stableId', 'diaryId'];
const limitExceptions: LimitException[] = [{ name: 'amount', limit: FEED_LIMIT }];

function isDeadValuesValid(editDiaryDetails: MotherDiaryEditTransfer | undefined) {
  const dead = editDiaryDetails?.motherDead;
  const dissected = editDiaryDetails?.motherDissected;
  if (dissected && (!dead || dead < dissected)) {
    return false;
  }
  const deadResp = editDiaryDetails?.motherDeadResp;
  if (deadResp && (!dissected || dissected < deadResp)) {
    return false;
  }
  const deadEre = editDiaryDetails?.motherDeadEre;
  if (deadEre && (!dissected || dissected < deadEre)) {
    return false;
  }

  return true;
}

function isDiaryDetailsValid(editDiaryDetails: MotherDiaryEditTransfer | undefined) {
  return (
    isInputValuesBetweenLimit(editDiaryDetails, excludedKeys, LIMIT, limitExceptions) &&
    isDeadValuesValid(editDiaryDetails)
  );
}

function getOpenCloseButton(
  id: number,
  actualDate: Date,
  closeDay: string | undefined | null,
  openGrouppage: () => void,
  closeGrouppage: (diaryId: number) => void,
  t: TFunction
) {
  const isThisCloseDay = dateStr(actualDate) === closeDay;
  const needCloseButton = closeDay === undefined || closeDay === null || closeDay.length === 0;
  if (!needCloseButton && !isThisCloseDay) return <></>;
  return (
    <Button
      color="primary"
      className="mr-auto"
      onClick={() => {
        isThisCloseDay ? openGrouppage() : closeGrouppage(id);
      }}
    >
      {t(isThisCloseDay ? 'grouppage-open' : 'grouppage-close')}
    </Button>
  );
}

interface EditDiaryDetailProps {
  isParent: boolean;
  closeDay: string | undefined;
  isOpen: boolean;
  diaryDetails: MotherDiaryElementTransfer;
  onOpenGroup: () => void;
  onCloseGroup: (diaryId: number) => void;
  onSaveDiaryDetails: (diaryId: number, diaryData: MotherDiaryEditTransfer) => void;
  backendMessages: string[] | undefined;
  isWorking: boolean;
  toggle: (diary: MotherDiaryElementTransfer | undefined) => void;
}

function EditDiaryDetailsModal({
  isParent,
  closeDay,
  isOpen,
  toggle,
  onOpenGroup,
  onCloseGroup,
  onSaveDiaryDetails,
  backendMessages,
  isWorking,
  diaryDetails,
}: EditDiaryDetailProps) {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [
    editDiaryDetails,
    setEditDiaryDetails,
    onSimpleValueChange,
    onObjectValueChange,
  ] = useInput<MotherDiaryEditTransfer>(diaryDetails.value);
  const [isAllValid, setIsAllValid] = useState<boolean>(true);
  const [myBackendMessages, setMyBackendMessages] = useState<string[] | undefined>(backendMessages);

  useEffect(() => {
    if (isOpen && isMounted.current) {
      setEditDiaryDetails(cloneDeep(diaryDetails?.value));
    }
  }, [isOpen, diaryDetails, setEditDiaryDetails, isMounted]);

  useEffect(() => {
    setIsAllValid(true);
    setMyBackendMessages(undefined);
  }, [editDiaryDetails]);

  const submit = useCallback(() => {
    const isValid = isDiaryDetailsValid(editDiaryDetails);
    if (isValid) {
      setMyBackendMessages(undefined);
      onSaveDiaryDetails(diaryDetails.id, editDiaryDetails);
    }
    setIsAllValid(isValid);
  }, [diaryDetails.id, editDiaryDetails, onSaveDiaryDetails]);

  const cancel = useCallback(() => {
    toggle(undefined);
  }, [toggle]);

  const isInvalid = useCallback(
    (value: number | undefined) => (value && value > LIMIT) || false,
    []
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggle(undefined)}
      contentClassName="modal-content-size"
      className="modal-align"
    >
      <ModalHeader toggle={() => toggle(undefined)}>
        {`${t('add-diary-details')} - ${dateToStr(diaryDetails.actualDate)} ${t('gpd-age')}: ${
          diaryDetails.dayOfAge
        }`}
      </ModalHeader>
      <ModalBody>
        {isWorking && <Loader />}
        <Container fluid>
          <Row className="" />
        </Container>
        <Form className="form d-flex flex-column justify-content-center">
          {isParent && (
            <Row>
              <Col className="mx-4 my-4">
                <Row className="d-flex justify-content-center align-items-center">
                  <h4>{t('add-comment-parent')}</h4>
                </Row>
                <Row>
                  <Col className="d-flex flex-column align-items-center">
                    <Row>
                      <b>{t(GPD_COMMENT)}</b>
                    </Row>
                    <Row className="w-100">
                      <Input
                        type="text"
                        name={GPD_COMMENT}
                        maxLength={512}
                        defaultValue={editDiaryDetails.comment}
                        onChange={onSimpleValueChange('comment', ValueType.STRING)}
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          {!isParent && (
            <>
              <Row className="">
                <Col>
                  <Row className="d-flex justify-content-center align-items-center">
                    <h4>{t('diary-mother-daily-details')}</h4>
                  </Row>

                  <Row>
                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('mgpd-mother-dead')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'mgpd-mother-dead'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={
                            isInvalid(editDiaryDetails?.motherDead) ||
                            !isDeadValuesValid(editDiaryDetails)
                          }
                          defaultValue={editDiaryDetails?.motherDead}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('motherDead', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>

                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('gpd-dissected')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'gpd-dissected'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={
                            isInvalid(editDiaryDetails?.motherDissected) ||
                            !isDeadValuesValid(editDiaryDetails)
                          }
                          defaultValue={editDiaryDetails?.motherDissected}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('motherDissected', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>

                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('mgpd-mother-resp')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'mgpd-mother-resp'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={
                            isInvalid(editDiaryDetails?.motherDeadResp) ||
                            !isDeadValuesValid(editDiaryDetails)
                          }
                          defaultValue={editDiaryDetails?.motherDeadResp}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('motherDeadResp', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>

                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('gpd-ere')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'gpd-ere'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={
                            isInvalid(editDiaryDetails?.motherDeadEre) ||
                            !isDeadValuesValid(editDiaryDetails)
                          }
                          defaultValue={editDiaryDetails?.motherDeadEre}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('motherDeadEre', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>

                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('mgpd-mother-waste')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'mgpd-mother-waste'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={isInvalid(editDiaryDetails?.motherWaste)}
                          defaultValue={editDiaryDetails?.motherWaste}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('motherWaste', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mt-1">
                <Col>
                  <Row className="d-flex justify-content-center align-items-center">
                    <h4>{t('diary-suckling-daily-details')}</h4>
                  </Row>
                  <Row className="d-flex justify-content-center align-items-center">
                    <Col xs="4" className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('mgpd-suckling-dead')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'mgpd-suckling-dead'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          disabled={diaryDetails.dayOfAge < 0}
                          invalid={isInvalid(editDiaryDetails.sucklingDead)}
                          defaultValue={editDiaryDetails.sucklingDead}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('sucklingDead', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>

                    <Col xs="4" className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('mgpd-suckling-exterm')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'mgpd-suckling-exterm'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          disabled={diaryDetails.dayOfAge < 0}
                          invalid={isInvalid(editDiaryDetails?.sucklingExterm)}
                          defaultValue={editDiaryDetails?.sucklingExterm}
                          onInput={roundAndRemove}
                          onChange={onSimpleValueChange('sucklingExterm', ValueType.NUMBER)}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col>
                  <Row className="d-flex justify-content-center align-items-center">
                    <h4>{t('diary-climate-details')}</h4>
                  </Row>

                  <Row>
                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('gpd-degrees')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'gpd-degrees'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={isInvalid(editDiaryDetails?.indoorData?.temperature)}
                          defaultValue={editDiaryDetails?.indoorData?.temperature}
                          onInput={(event) => {
                            roundAndRemove(event, true);
                          }}
                          onChange={onObjectValueChange(
                            'indoorData',
                            'temperature',
                            ValueType.NUMBER
                          )}
                        />
                      </Row>
                    </Col>

                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t('gpd-rh')}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="number"
                          name={'gpd-rh'}
                          min="0"
                          max={LIMIT}
                          className="text-right"
                          invalid={isInvalid(editDiaryDetails?.indoorData?.relativeHumidity)}
                          defaultValue={editDiaryDetails?.indoorData?.relativeHumidity}
                          onInput={(event) => {
                            roundAndRemove(event, true);
                          }}
                          onChange={onObjectValueChange(
                            'indoorData',
                            'relativeHumidity',
                            ValueType.NUMBER
                          )}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <Row className="d-flex justify-content-center align-items-center">
                    <h4>{t('add-comment')}</h4>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-column align-items-center">
                      <Row>
                        <b>{t(GPD_COMMENT)}</b>
                      </Row>
                      <Row className="w-100">
                        <Input
                          type="text"
                          name={GPD_COMMENT}
                          maxLength={512}
                          defaultValue={editDiaryDetails?.comment}
                          onChange={onSimpleValueChange('comment', ValueType.STRING)}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <FeedDetails
                onFeedValueChange={onObjectValueChange}
                onChange={onSimpleValueChange}
                editDiaryDetails={editDiaryDetails}
              />

              <HealthDetails
                onMedicineValueChange={onObjectValueChange}
                medicineDetails={editDiaryDetails.medicine}
                isVaccinable
              />
            </>
          )}

          <Row className="pt-2">
            <Col>
              {!isAllValid && (
                <Alert color="danger" className="text-center">
                  {t('form-has-errors')}
                </Alert>
              )}
              {myBackendMessages && myBackendMessages.length > 0 && (
                <Alert color="danger" className="text-center">
                  {myBackendMessages.map((msg: string) => (
                    <li key={msg}>{t(msg)}</li>
                  ))}
                </Alert>
              )}
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        {!isParent &&
          getOpenCloseButton(
            diaryDetails.id,
            diaryDetails.actualDate,
            closeDay,
            onOpenGroup,
            onCloseGroup,
            t
          )}
        <Button color="success" onClick={submit} className="mr-2">
          {t('save')}
        </Button>
        <Button color="primary" onClick={cancel}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditDiaryDetailsModal;
