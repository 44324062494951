import { useCallback, useState } from 'react';
import { toQueryString } from 'helpers/api';
import { fetchJSON } from 'hyper/helpers/api';
import CageFilter from 'models/cageFilter';
import SearchResult from 'models/searchResult';

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

interface UseSearchCage {
  loadAgain: (request: CageFilter) => void;
  data?: CageSearchResult;
  loading: boolean;
  error: boolean;
}

export interface CageSearchResult {
  filter: CageFilter;
  stables: SearchResult[];
  rows: number[];
  positions: string[];
  cages: SearchResult[];
}

function useSearchCage(path: string): UseSearchCage {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<CageSearchResult>();

  const loadAgain = useCallback(
    (request: CageFilter) => {
      const sendRequest = async () => {
        setLoading(true);
        try {
          const response = await fetchJSON<CageSearchResult>(
            `${path}?${toQueryString({ ...request })}`,
            {
              method: 'GET',
              headers,
            }
          );
          setData(response);
          setError(false);
        } catch (e) {
          setError(true);
        } finally {
          setLoading(false);
        }
      };

      return sendRequest();
    },
    [path]
  );

  return {
    loadAgain,
    data,
    loading,
    error,
  };
}

export default useSearchCage;
