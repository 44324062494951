import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { TFunction } from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  classCellBg,
  classCellLBg,
  classCellMain,
  classCommon,
  classSideButton,
  classTable,
  createBaseTd,
  createTh,
  dateStr,
  round,
  toStr,
  toStrF,
  YOUNG_AGE_MAX,
} from './gpUtil';
import ResultInfo from './resultInfo';
import EditYoungOriginModal, { OriginYoungParams } from './modal/young/editYoungOriginModal';
import { getMovingGroupContent } from './fatteningEditSmallTables';
import OriginYoungTransfer from 'models/grouppages/originYoungTransfer';
import EditYoungTransfer from 'models/grouppages/editYoungTransfer';
import ConfirmModal from 'components/shared/confirmModal';
import { useApi } from 'hooks/useApi';
import Loader from 'hyper/components/Loader';
import Farm from 'models/farm';

const classTableDiv = 'react-bootstrap-table table-responsive align-top';
const classSmallTable = `${classTable} mb-1 gp-table`;
const classCell = `bg-light text-dark text-right ${classCommon}`;

interface YoungData {
  actualNumPS: string;
  actualNumGP: string;

  ownReplacementPSSum?: string;
  ownReplacementGPSum?: string;
  deadPSSum?: string;
  deadGPSum?: string;
  deadRespPSSum?: string;
  deadRespGPSum?: string;
  deadEreSumPS?: string;
  deadEreSumGP?: string;
  wastePSSum?: string;
  wasteGPSum?: string;
  soldPSSum?: string;
  soldGPSum?: string;

  age: string;
  filledRowSum?: string;

  ownReplacementPSPercent?: string;
  ownReplacementGPPercent?: string;
  deadPSPercent?: string;
  deadGPPercent?: string;
  deadRespPSPercent?: string;
  deadRespGPPercent?: string;
  deadErePSPercent?: string;
  deadEreGPPercent?: string;
}

function divide(
  value: number | null | undefined,
  divider: number | null | undefined
): number | undefined {
  return value !== null && value !== undefined && divider ? value / divider : undefined;
}

function calculateAge(data: EditYoungTransfer | null | undefined): number | undefined {
  if (!data) return undefined;

  const date = new Date();
  const now = dateStr(date);
  let result = YOUNG_AGE_MAX + 1;
  const lastDiary = data.diary.diaries[data.diary.diaries.length - 1];
  if (now > dateStr(lastDiary.actualDate)) {
    for (let i = data.diary.diaries.length - 1; i > 0; i--) {
      const diary = data.diary.diaries[i];
      if (
        diary.dayEndNumGP !== undefined &&
        diary.dayEndNumGP !== null &&
        diary.dayEndNumPS !== undefined &&
        diary.dayEndNumPS !== null &&
        diary.dayEndNumGP + diary.dayEndNumPS === 0
      ) {
        result = diary.dayOfAge;
      } else {
        break;
      }
    }
  } else {
    for (const diary of data.diary.diaries) {
      if (now === dateStr(diary.actualDate)) {
        result = diary.dayOfAge;
        break;
      }
    }
  }
  return result;
}

function convertYoungData(
  data: EditYoungTransfer | null | undefined,
  originData: OriginData | null
): YoungData {
  if (!data || !originData) {
    return {
      actualNumPS: '',
      actualNumGP: '',
      ownReplacementPSSum: '',
      ownReplacementGPSum: '',
      deadPSSum: '',
      deadGPSum: '',
      deadRespPSSum: '',
      deadRespGPSum: '',
      deadEreSumPS: '',
      deadEreSumGP: '',
      wastePSSum: '',
      wasteGPSum: '',
      soldPSSum: '',
      soldGPSum: '',
      age: '',
      filledRowSum: '',
      ownReplacementPSPercent: '',
      ownReplacementGPPercent: '',
      deadPSPercent: '',
      deadGPPercent: '',
      deadRespPSPercent: '',
      deadRespGPPercent: '',
      deadErePSPercent: '',
      deadEreGPPercent: '',
    };
  }
  const sumPS = +originData.sumPS;
  const sumGP = +originData.sumGP;
  const ownReplacementPSPercent = divide(100 * (data.sumValues.ownReplacementPSSum || 0), sumPS);
  const ownReplacementGPPercent = divide(100 * (data.sumValues.ownReplacementGPSum || 0), sumGP);
  const deadPSPercent = divide(100 * (data.sumValues.deadPSSum || 0), sumPS);
  const deadGPPercent = divide(100 * (data.sumValues.deadGPSum || 0), sumGP);
  const deadRespPSPercent = divide(
    100 * (data.sumValues.deadRespPSSum || 0),
    data.sumValues.dissectedSumPS
  );
  const deadRespGPPercent = divide(
    100 * (data.sumValues.deadRespGPSum || 0),
    data.sumValues.dissectedSumGP
  );
  const deadErePSPercent = divide(
    100 * (data.sumValues.deadEreSumPS || 0),
    data.sumValues.dissectedSumPS
  );
  const deadEreGPPercent = divide(
    100 * (data.sumValues.deadEreSumGP || 0),
    data.sumValues.dissectedSumGP
  );
  const age = calculateAge(data); //data.actualAge;
  return {
    actualNumPS: toStr(data.actualNumPS),
    actualNumGP: toStr(data.actualNumGP),
    ownReplacementPSSum: toStr(data.sumValues.ownReplacementPSSum),
    ownReplacementGPSum: toStr(data.sumValues.ownReplacementGPSum),
    deadPSSum: toStr(data.sumValues.deadPSSum),
    deadGPSum: toStr(data.sumValues.deadGPSum),
    deadRespPSSum: toStr(data.sumValues.deadRespPSSum),
    deadRespGPSum: toStr(data.sumValues.deadRespGPSum),
    deadEreSumPS: toStr(data.sumValues.deadEreSumPS),
    deadEreSumGP: toStr(data.sumValues.deadEreSumGP),
    wastePSSum: toStr(data.sumValues.wastePSSum),
    wasteGPSum: toStr(data.sumValues.wasteGPSum),
    soldPSSum: toStr(data.sumValues.soldPSSum),
    soldGPSum: toStr(data.sumValues.soldGPSum),
    age: toStr(age),
    filledRowSum: toStr(data.sumValues.filledRowSum),
    ownReplacementPSPercent: toStrF(round(ownReplacementPSPercent, 2), 2),
    ownReplacementGPPercent: toStrF(round(ownReplacementGPPercent, 2), 2),
    deadPSPercent: toStrF(round(deadPSPercent, 2), 2),
    deadGPPercent: toStrF(round(deadGPPercent, 2), 2),
    deadRespPSPercent: toStrF(round(deadRespPSPercent, 2), 2),
    deadRespGPPercent: toStrF(round(deadRespGPPercent, 2), 2),
    deadErePSPercent: toStrF(round(deadErePSPercent, 2), 2),
    deadEreGPPercent: toStrF(round(deadEreGPPercent, 2), 2),
  };
}

interface OriginData {
  origins: OriginYoungTransfer[];
  avgWeights: (string | undefined)[];
  sumPS: string;
  sumGP: string;
  sumWeightPS: string;
  sumWeightGP: string;
  avgSumWeight: string;
}

function getAvg(
  numPS: number,
  numGP: number,
  weightPS: number,
  weightGP: number,
  multiplier = 1
): number {
  const divider = numPS + numGP;
  const result = divider ? round((multiplier * (weightPS + weightGP)) / divider, 1) : 0;
  return result || 0;
}

function convertOriginData(origins: OriginYoungTransfer[] | undefined): OriginData {
  if (!origins) {
    return {
      origins: [],
      avgWeights: [],
      sumPS: '',
      sumGP: '',
      sumWeightPS: '',
      sumWeightGP: '',
      avgSumWeight: '',
    };
  }
  const avgWeights: (string | undefined)[] = [];
  let sumPS = 0;
  let sumGP = 0;
  let sumWeightPS = 0;
  let sumWeightGP = 0;
  for (const origin of origins) {
    if (origin) {
      sumPS += origin.numPS;
      sumGP += origin.numGP;
      sumWeightPS += origin.weightPS;
      sumWeightGP += origin.weightGP;
    }
    avgWeights.push(
      origin
        ? toStr(getAvg(origin.numPS, origin.numGP, origin.weightPS, origin.weightGP, 1000), 0)
        : undefined
    );
  }
  const avgSumWeight = getAvg(sumPS, sumGP, sumWeightPS, sumWeightGP, 1000);
  return {
    origins,
    avgWeights,
    sumPS: toStr(sumPS),
    sumGP: toStr(sumGP),
    sumWeightPS: toStr(sumWeightPS),
    sumWeightGP: toStr(sumWeightGP),
    avgSumWeight: toStr(avgSumWeight, 0),
  };
}

const createOriginRows = (
  dataOrigin: OriginData,
  toggleEdit: (origin: OriginYoungTransfer) => void,
  toggleDelete: (origin: OriginYoungTransfer) => void
): JSX.Element[] => {
  const allTr: JSX.Element[] = [];
  for (let i = 0; i < dataOrigin.origins.length; i++) {
    const origin = dataOrigin.origins[i];
    const from = origin.numPS < 0 || origin.numGP < 0;
    allTr.push(
      <tr key={origin.id}>
        {createBaseTd(`${toStr(i + 1)}.`, classCell)}
        {createBaseTd(origin.farmName, classCellLBg)}
        {createBaseTd(
          getMovingGroupContent(origin.groupName, origin.associatedId, from),
          classCellLBg
        )}
        {createBaseTd(toStr(origin.age), classCellBg)}
        {createBaseTd(toStr(origin.numPS), classCellBg)}
        {createBaseTd(toStr(origin.numGP), classCellBg)}
        {createBaseTd(toStr(origin.weightPS), classCellBg)}
        {createBaseTd(toStr(origin.weightGP), classCellBg)}
        {createBaseTd(dataOrigin.avgWeights[i], classCell)}
        <td className="text-center align-middle m-0 p-0">
          {!origin.associatedId && (
            <Button
              color="primary"
              className={`${classSideButton} origin-edit mr-1`}
              onClick={() => toggleEdit(origin)}
            >
              <i className="mdi mdi-pencil p-1" />
            </Button>
          )}
          <Button
            color="primary"
            className={`${classSideButton} origin-delete`}
            onClick={() => toggleDelete(origin)}
          >
            <i className="mdi mdi-delete p-1" />
          </Button>
        </td>
      </tr>
    );
  }
  return allTr;
};

function getOriginIndex(
  data: OriginData | undefined,
  selectedOrigin: OriginYoungTransfer | undefined
): string {
  if (!data || !selectedOrigin) return '';

  for (let i = 0; i < data.origins.length; i++) {
    if (data.origins[i].id === selectedOrigin.id) {
      return `${i + 1}`;
    }
  }
  return '';
}

function getOriginInfo(origin: OriginYoungTransfer | undefined, t: TFunction): string {
  if (!origin) return '';

  const result = [];
  if (origin.farmName) {
    result.push(`${t('orig-farm')} ${origin.farmName}`);
  }
  if (origin.groupName) {
    result.push(`${t('orig-group')} ${origin.groupName}`);
  }
  result.push(`${t('orig-num')} ${origin.numPS || 0}/${origin.numGP || 0}`);
  result.push(`${t('orig-weight')} ${origin.weightPS || 0}/${origin.weightGP || 0}`);
  return `${result.join(', ')}`;
}

const calcOriginStartDate = (transfer: EditYoungTransfer | null | undefined): string => {
  const now = new Date();
  if (!transfer?.diary.diaries) return dateStr(now);
  const firstDiaryDate = new Date(transfer.diary.diaries[0].actualDate);
  return dateStr(firstDiaryDate < now ? firstDiaryDate : now);
};

const calcOriginEndDate = (transfer: EditYoungTransfer | null | undefined): string => {
  const now = new Date();
  if (!transfer?.diary.diaries) return dateStr(now);
  const lastDiaryDate = new Date(
    transfer.diary.diaries[transfer.diary.diaries.length - 1].actualDate
  );
  return dateStr(lastDiaryDate < now ? lastDiaryDate : now);
};

interface YoungEditSmallTablesProps {
  transfer: EditYoungTransfer | null | undefined;
  refresh: () => void;
  farms: Farm[] | null | undefined;
}

function YoungEditSmallTables({ transfer, refresh, farms }: YoungEditSmallTablesProps) {
  const { t } = useTranslation();
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [selectedOrigin, setSelectedOrigin] = useState<OriginYoungTransfer>();
  const [deleteError, setDeleteError] = useState<string>();
  const [deleteSuccess, setDeleteSuccess] = useState<string>();
  const { sendDelete: deleteOrigin, loading: deletingOrigin } = useApi();

  const [dataYoung, setDataYoung] = useState<YoungData>(convertYoungData(transfer, null));
  const [dataOrigin, setDataOrigin] = useState<OriginData>(convertOriginData(transfer?.origins));

  const [isOriginModalOpen, setIsOriginModalOpen] = useState<boolean>(false);
  const toggleOriginModal = useCallback(() => setIsOriginModalOpen(!isOriginModalOpen), [
    isOriginModalOpen,
  ]);
  const [groupStartDate, setGroupStartDate] = useState<Date>();
  const [originStartDate, setOriginStartDate] = useState<string>(calcOriginStartDate(transfer));
  const [originEndDate, setOriginEndDate] = useState<string>(calcOriginEndDate(transfer));

  const strItem = t('piece');
  const strPercent = t('gp-percent');

  const refreshPage = useCallback(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    setDataYoung(convertYoungData(transfer, null));
    setDataOrigin(convertOriginData(transfer?.origins));
    setGroupStartDate(transfer?.startDate || new Date());
    setOriginStartDate(calcOriginStartDate(transfer));
    setOriginEndDate(calcOriginEndDate(transfer));
  }, [transfer]);

  useEffect(() => {
    setDataYoung(convertYoungData(transfer, dataOrigin));
  }, [dataOrigin, transfer]);

  const toggleConfirmModal = useCallback(() => setIsConfirmOpen(!isConfirmOpen), [isConfirmOpen]);

  const toggleDelete = useCallback(
    (origin: OriginYoungTransfer) => {
      setDeleteError(undefined);
      setDeleteSuccess(undefined);
      setSelectedOrigin(origin);
      toggleConfirmModal();
    },
    [toggleConfirmModal]
  );

  const toggleEdit = useCallback(
    (origin: OriginYoungTransfer) => {
      setDeleteError(undefined);
      setDeleteSuccess(undefined);
      setSelectedOrigin(origin);
      toggleOriginModal();
    },
    [toggleOriginModal]
  );

  const doDeleteOrigin = useCallback(() => {
    if (selectedOrigin?.id) {
      deleteOrigin(
        `/api/v1/group/young/${transfer?.id}/origin/${selectedOrigin.id}`,
        () => {
          let info = getOriginInfo(selectedOrigin, t);
          info = info ? `(${info})` : '';
          setDeleteSuccess(t('delete-origin-success', { info }));
          refreshPage();
        },
        () => {
          setDeleteError(t('delete-origin-failed'));
        }
      );
    }
  }, [deleteOrigin, refreshPage, selectedOrigin, t, transfer]);

  const createDeleteBody = useCallback(() => {
    let info = getOriginInfo(selectedOrigin, t);
    info = info ? `(${info})` : '';
    const idx = getOriginIndex(dataOrigin, selectedOrigin);
    return `${t('delete-origin-message', { idx, info })}`;
  }, [dataOrigin, selectedOrigin, t]);

  const defaulOrigin = useCallback(
    (needAssociatedId: boolean) => {
      const now = dateStr(new Date());
      const when = originEndDate && originEndDate < now ? originEndDate : now;
      let result = {
        arrivedDate: needAssociatedId ? new Date(when) : groupStartDate,
        numPS: 0,
        numGP: 0,
        weightPS: 0,
        weightGP: 0,
      } as OriginYoungTransfer;
      if (needAssociatedId) {
        result = { ...result, associatedId: uuidv4() };
      } else {
        result = { ...result, age: 28 };
      }
      return result;
    },
    [groupStartDate, originEndDate]
  );

  const getOrigin = useCallback(() => {
    let result = defaulOrigin(false);
    if (selectedOrigin) {
      result = {
        ...selectedOrigin,
        arrivedDate: selectedOrigin.arrivedDate || groupStartDate,
      };
    }
    return {
      origin: result,
      isMoving: result.associatedId && result.associatedId.trim().length > 0,
      isMovingSource: true,
      maxNumPS: +dataYoung.actualNumPS,
      maxNumGP: +dataYoung.actualNumGP,
    } as OriginYoungParams;
  }, [dataYoung.actualNumGP, dataYoung.actualNumPS, defaulOrigin, groupStartDate, selectedOrigin]);

  const createDeleteTitle = useCallback(
    () => (selectedOrigin?.associatedId ? t('delete-move-fattening') : t('delete-origin-young')),
    [selectedOrigin, t]
  );

  return (
    <>
      {deletingOrigin && <Loader />}

      <ConfirmModal
        title={createDeleteTitle()}
        bodyText={createDeleteBody()}
        isOpen={isConfirmOpen}
        toggle={toggleConfirmModal}
        onSave={doDeleteOrigin}
      />
      <ResultInfo
        success={!!deleteSuccess}
        successMsg={deleteSuccess}
        error={!!deleteError}
        errorMsg={deleteError}
      />
      <EditYoungOriginModal
        groupId={transfer?.id || 0}
        generatedName={transfer?.generatedName || ''}
        startDate={originStartDate}
        endDate={originEndDate}
        isOpen={isOriginModalOpen}
        originDetails={getOrigin()}
        allOrigins={transfer?.origins}
        diaries={transfer?.diary.diaries}
        onSave={refresh}
        toggle={toggleOriginModal}
        farms={farms}
      />
      <Row className="justify-content-around">
        <Col xs="2">
          <div>
            <h5 className="card-title align-middle">{t('ygpt1-title')}</h5>
            <div className={classTableDiv}>
              <table className={classSmallTable}>
                <tbody>
                  <tr>
                    <td className={classCellMain}>{t('ygpt1-age')}</td>
                    <td className={classCellMain}>{transfer?.actualAge}</td>
                  </tr>
                  <tr>
                    <td className={classCellMain}>{t('ygpt1-days')}</td>
                    <td className={classCellMain}>{dataYoung.filledRowSum}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Col>

        <Col xs="3">
          <div>
            <h5 className="card-title align-middle">{t('ygpt2-title')}</h5>
            <div className={classTableDiv}>
              <table className={classSmallTable}>
                <thead>
                  <tr>
                    {createTh('', 0, '', { rowSpan: 2 })}
                    {createTh(t('ygpt2-ps'), 1, '', { colSpan: 2 })}
                    {createTh(t('ygpt2-gp'), 2, '', { colSpan: 2 })}
                  </tr>
                  <tr>
                    {createTh(strItem, 3)}
                    {createTh(strPercent, 4)}
                    {createTh(strItem, 5)}
                    {createTh(strPercent, 6)}
                  </tr>
                </thead>
                <tbody>
                  <tr key={1}>
                    {createBaseTd(t('ygpt2-sum'), classCell)}
                    {createBaseTd(dataYoung.actualNumPS, classCellBg)}
                    {createBaseTd('', classCell)}
                    {createBaseTd(dataYoung.actualNumGP, classCellBg)}
                    {createBaseTd('', classCell)}
                  </tr>
                  <tr key={2}>
                    {createBaseTd(t('ygpt2-dead'), classCell)}
                    {createBaseTd(dataYoung.deadPSSum, classCellBg)}
                    {createBaseTd(dataYoung.deadPSPercent, classCellBg)}
                    {createBaseTd(dataYoung.deadGPSum, classCellBg)}
                    {createBaseTd(dataYoung.deadGPPercent, classCellBg)}
                  </tr>
                  <tr key={3}>
                    {createBaseTd(t('ygpt2-dead-resp'), classCell)}
                    {createBaseTd(dataYoung.deadRespPSSum, classCellBg)}
                    {createBaseTd(dataYoung.deadRespPSPercent, classCellBg)}
                    {createBaseTd(dataYoung.deadRespGPSum, classCellBg)}
                    {createBaseTd(dataYoung.deadRespGPPercent, classCellBg)}
                  </tr>
                  <tr key={4}>
                    {createBaseTd(t('gpd-ere'), classCell)}
                    {createBaseTd(dataYoung.deadEreSumPS, classCellBg)}
                    {createBaseTd(dataYoung.deadErePSPercent, classCellBg)}
                    {createBaseTd(dataYoung.deadEreSumGP, classCellBg)}
                    {createBaseTd(dataYoung.deadEreGPPercent, classCellBg)}
                  </tr>
                  <tr key={5}>
                    {createBaseTd(t('ygpt2-waste'), classCell)}
                    {createBaseTd(dataYoung.wastePSSum, classCellBg)}
                    {createBaseTd('', classCell)}
                    {createBaseTd(dataYoung.wasteGPSum, classCellBg)}
                    {createBaseTd('', classCell)}
                  </tr>
                  <tr key={6}>
                    {createBaseTd(t('ygpt2-sold'), classCell)}
                    {createBaseTd(dataYoung.soldPSSum, classCellBg)}
                    {createBaseTd('', classCell)}
                    {createBaseTd(dataYoung.soldGPSum, classCellBg)}
                    {createBaseTd('', classCell)}
                  </tr>
                  <tr key={7}>
                    {createBaseTd(t('ygpt2-own-replacement'), classCell)}
                    {createBaseTd(dataYoung.ownReplacementPSSum, classCellBg)}
                    {createBaseTd(dataYoung.ownReplacementPSPercent, classCellBg)}
                    {createBaseTd(dataYoung.ownReplacementGPSum, classCellBg)}
                    {createBaseTd(dataYoung.ownReplacementGPPercent, classCellBg)}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Col>

        <Col xs="7">
          <div>
            <h5 className="card-title">{t('ygpt3-title')}</h5>
            <div className={classTableDiv}>
              <table className={`${classSmallTable} young-origin-table`}>
                <thead>
                  <tr>
                    {createTh('', 0, '', { rowSpan: 2 })}
                    {createTh(t('ygpt3-farm'), 1, '', { rowSpan: 2 })}
                    {createTh(t('ygpt3-group'), 2, '', { rowSpan: 2 })}
                    {createTh(t('ygpt3-age'), 3, '', { rowSpan: 2 })}
                    {createTh(t('ygpt3-num'), 4, '', { colSpan: 2 })}
                    {createTh(t('ygpt3-sum-weight'), 5, '', { colSpan: 2 })}
                    {createTh(t('ygpt3-avg-weight'), 6, '', { rowSpan: 2 })}
                    <th className="text-center align-middle" key="th-7" rowSpan={2}>
                      <Button
                        id={'new-origin-row'}
                        color="primary"
                        className={`${classSideButton} origin-edit mr-1`}
                        onClick={() => toggleEdit(defaulOrigin(false))}
                      >
                        <i className="mdi mdi-plus p-1" />
                      </Button>
                      <Button
                        id={'new-move-row'}
                        color="primary"
                        className={`${classSideButton} origin-delete`}
                        onClick={() => toggleEdit(defaulOrigin(true))}
                      >
                        <i className="mdi mdi-export p-1" id="new-move-row-icon" />
                      </Button>
                    </th>
                  </tr>
                  <tr>
                    {createTh(t('ygpt2-ps'), 8)}
                    {createTh(t('ygpt2-gp'), 9)}
                    {createTh(t('ygpt2-ps'), 10)}
                    {createTh(t('ygpt2-gp'), 11)}
                  </tr>
                </thead>
                <tbody>
                  <>{createOriginRows(dataOrigin, toggleEdit, toggleDelete)}</>
                  <tr>
                    {createBaseTd(t('ygpt3-sum'), classCell, { colSpan: 4 })}
                    {createBaseTd(dataOrigin.sumPS, classCell)}
                    {createBaseTd(dataOrigin.sumGP, classCell)}
                    {createBaseTd(dataOrigin.sumWeightPS, classCell)}
                    {createBaseTd(dataOrigin.sumWeightGP, classCell)}
                    {createBaseTd(dataOrigin.avgSumWeight, classCell)}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <UncontrolledTooltip
            modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
            placement="top"
            target="new-origin-row"
          >
            {t('edit-origin-new')}
          </UncontrolledTooltip>
          <UncontrolledTooltip
            modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
            placement="top"
            target="new-move-row"
          >
            {t('move-origin-new')}
          </UncontrolledTooltip>
        </Col>
      </Row>
    </>
  );
}

export default YoungEditSmallTables;
