import React, { useCallback, useEffect, useState } from 'react';
import { CardBody, ListGroup } from 'reactstrap';
import IATaskProgress from 'components/iaGroup/IATaskProgress';
import IAGroupStatusDetails from 'models/iaGroupStatusDetails';
import { useSendApiGet } from 'hooks/useApi';
import Page from 'models/page';
import Loader from 'hyper/components/Loader';

interface IAGroupWithIATaskListProps {
  onIAGroupClicked: (id: number, name: string) => void;
  iaTaskChanged: number;
}

function IAGroupWithIATaskList({ onIAGroupClicked, iaTaskChanged }: IAGroupWithIATaskListProps) {
  const [selectedIaGroupId, setSelectedIaGroupId] = useState<number>();
  const [iaGroupStatusDetails, setIaGroupDetails] = useState<Page<IAGroupStatusDetails>>();
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [loadIaGroupDetails, { loading }] = useSendApiGet<Page<IAGroupStatusDetails>>(
    '/api/v1/ia-groups/status',
    setIaGroupDetails
  );

  useEffect(() => {
    loadIaGroupDetails();
  }, [loadIaGroupDetails, iaTaskChanged, selectedIaGroupId]);

  useEffect(() => {
    if (isFirstLoad && iaGroupStatusDetails) {
      const iaGroupDetail = iaGroupStatusDetails.content[0];
      onIAGroupClicked(iaGroupDetail.iaGroupId, iaGroupDetail.iaGroupName);
      setSelectedIaGroupId(iaGroupDetail.iaGroupId);
      setIsFirstLoad(false);
    }
  }, [iaGroupStatusDetails, isFirstLoad, onIAGroupClicked]);

  const handleIaGroupClick = useCallback(
    (iaGroupDetail: IAGroupStatusDetails) => (event: React.MouseEvent) => {
      onIAGroupClicked(iaGroupDetail.iaGroupId, iaGroupDetail.iaGroupName);
      setSelectedIaGroupId(iaGroupDetail.iaGroupId);
      event.preventDefault();
    },
    [onIAGroupClicked]
  );

  return (
    <>
      {loading && <Loader />}
      <CardBody className="p-1" style={{ height: '80vh', overflow: 'auto' }}>
        <ListGroup>
          {iaGroupStatusDetails &&
            iaGroupStatusDetails.content
              .sort((a, b) => {
                if (
                  a.iaGroupName === b.iaGroupName &&
                  a.numberOfCreatedIATasks - a.numberOfFinishedIATasks >
                    b.numberOfCreatedIATasks - b.numberOfFinishedIATasks
                ) {
                  return -1;
                }

                return a.closed === b.closed ? 0 : a.closed ? 1 : -1;
              })
              .map((iaGroupDetail: IAGroupStatusDetails) => (
                <div key={iaGroupDetail.iaGroupId} onClick={handleIaGroupClick(iaGroupDetail)}>
                  <IATaskProgress
                    iaGroupStatusDetails={iaGroupDetail}
                    selectedIaGroupId={selectedIaGroupId}
                  />
                </div>
              ))}
        </ListGroup>
      </CardBody>
    </>
  );
}

export default IAGroupWithIATaskList;
