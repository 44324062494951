import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Input, Row } from 'reactstrap';

interface SelectInputProps {
  name?: string;
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options: string[];
  defaultOption?: string;
  labelSm?: string;
}

function SelectInput({
  name,
  label,
  value,
  onChange,
  options,
  defaultOption,
  labelSm,
}: SelectInputProps) {
  const { t } = useTranslation();

  return (
    <Row className="d-flex align-items-center mb-1" style={{ width: '100%' }}>
      <Col sm={labelSm || '4'}>
        <b>{t(label)}:</b>
      </Col>
      <Col className="d-flex justify-content-start mr-2">
        <Input type="select" name={name || label} value={value} onChange={onChange}>
          {defaultOption && <option value={''}>{t(defaultOption)}</option>}
          {options.map((item) => (
            <option key={`origin-${item}`} value={item}>
              {t(item)}
            </option>
          ))}
        </Input>
      </Col>
    </Row>
  );
}

export default SelectInput;
